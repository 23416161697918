
import { Mapper } from '@management/base/management';
import { IPersonTimesDTO } from '@management/domain/management';
import { IPersonTimesEntity } from '@management/data/management';

export class PersonTimesMapper extends Mapper<IPersonTimesEntity, IPersonTimesDTO> {

    private static instance: PersonTimesMapper;

    private constructor() { super(); }

    public static getInstance(): PersonTimesMapper {
        if (!PersonTimesMapper.instance)
            PersonTimesMapper.instance = new PersonTimesMapper();
        return PersonTimesMapper.instance;
    }


    mapFrom(param: IPersonTimesEntity): IPersonTimesDTO {
        return {
            order_num: param.order_num,
            start_time: param.start_time,
            final_hour: param.final_hour
        };
    }
    mapFromList(params: IPersonTimesEntity[]): IPersonTimesDTO[] {
        return params.map((param: IPersonTimesEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IPersonTimesDTO): IPersonTimesEntity {
        return {
            order_num: param.order_num,
            start_time: param.start_time,
            final_hour: param.final_hour
        }
    }
    mapToList(params: IPersonTimesDTO[]): IPersonTimesEntity[] {
        return params.map((param: IPersonTimesDTO) => {
            return this.mapTo(param);
        })
    }
}