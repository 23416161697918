import { reducerAuth } from './index';
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const getAuth = createFeatureSelector<reducerAuth.AuthModel>(reducerAuth.AUTH_KEY);

//Selectors
export const getUser = createSelector(getAuth, (state: reducerAuth.AuthModel) => state?.user);
export const getPerson = createSelector(getAuth, (state: reducerAuth.AuthModel) => state?.user?.person);
export const getListCompanies = createSelector(getAuth, (state: reducerAuth.AuthModel) => state.user?.companies);
export const getCompanySelected = createSelector(getAuth, (state: reducerAuth.AuthModel) => state?.companySelected);
export const getListCampusByCompany = createSelector(getAuth, (state: reducerAuth.AuthModel) => state?.listCampusByCompany);