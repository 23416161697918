

import { Mapper } from '@management/base/management';
import { ICompanySelectedEntity } from '@management/data/management';
import { ICompanySelectedDTO } from '@management/domain/management';


export class CompanySelectedImplementationRepositoryMapper extends Mapper<ICompanySelectedEntity, ICompanySelectedDTO> {


    mapFrom(param: ICompanySelectedEntity): ICompanySelectedDTO {
        return {
            person: param.person,
            company: param.company,
            campus: param.campus,
            rol: param.rol,
            permission: param.permission
        };
    }
    mapFromList(params: ICompanySelectedEntity[]): ICompanySelectedDTO[] {
        return params.map((param: ICompanySelectedEntity) => {
            return this.mapFrom(param);
        })
    }
    mapTo(param: ICompanySelectedDTO): ICompanySelectedEntity {
        return {
            person: param.person,
            company: param.company,
            campus: param.campus,
            rol: param.rol,
            permission: param.permission
        }
    }
    mapToList(params: ICompanySelectedDTO[]): ICompanySelectedEntity[] {
        return params.map((param: ICompanySelectedDTO) => {
            return this.mapTo(param);
        })
    }
}

