<div class="container">
  <div class="container__title">Selecciona una compañia</div>

  <form class="container__form" [formGroup]="form" (ngSubmit)="submitData()">

    <dx-select-box formControlName="companyId" [searchEnabled]="true" [showClearButton]="true"
      [noDataText]="'No se encontro datos'" width="100%" [dataSource]="data.companyList" displayExpr="name"
      valueExpr="id" placeholder="Seleccionar" [value]="">
    </dx-select-box>

    <dx-button stylingMode="outlined" text="{{'Aceptar' | translate }}" type="normal" width="100%"
      [disabled]="isFormValid(form)" [useSubmitBehavior]="true">
    </dx-button>

  </form>

</div>
