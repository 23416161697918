
import { Observable } from 'rxjs';
//domain
import {
    IPersonServiceGetListPersonServiceDTO,
    IPersonServiceDTO,
    ISavePersonServiceSavePersonServiceDTO,
    ISavePersonServiceDTO,
    IDeletePersonServicePersonServiceDTO,
    IDeletePersonServiceDTO,
} from '@management/domain/management';
import { UtilsComponent } from '@management/ui';
import { selector } from '@management/base/management';

export abstract class PersonServiceRepository extends UtilsComponent {
    abstract DeletePersonServiceService(params: IDeletePersonServicePersonServiceDTO, loadService: boolean): Observable<IDeletePersonServiceDTO>;
    abstract SetSavePersonServiceService(params: ISavePersonServiceSavePersonServiceDTO, loadService: boolean): Observable<ISavePersonServiceDTO>;
    abstract GetGetListPersonServiceService(params: IPersonServiceGetListPersonServiceDTO, loadService: boolean): Observable<IPersonServiceDTO[]>;
}