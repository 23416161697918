import { actionsPersonCompany } from './index';
import { createReducer, on } from '@ngrx/store';
import {  } from "@management/domain/management";

export const PERSON_COMPANY_KEY = 'personCompany'
export interface PersonCompanyModel {
}

export const PersonCompanyInitialState: PersonCompanyModel = {
}

const _PersonCompanyReducer = createReducer(PersonCompanyInitialState,
);

export function PersonCompanyReducer(state: PersonCompanyModel, action: any) {
    return _PersonCompanyReducer(state, action);
}