<div class="container">
  <div class="container__a">
    <h3>Asignacion de infraestructura</h3>
    <small>Organiza en que lugar van a atender tus colaboradores</small>
  </div>

  <div class="container__d">
    <app-filter [filters]="filters" [isUniqueFilter]="true" [currentPage]="onDemandPagination.currentPage"
      (onFilter)="onFilter($event)" (onClear)="onClear($event, true)"></app-filter>
  </div>

  @if (persons.length) {
  <div class="container__c">
    <dx-data-grid id="gridContainerr" [dataSource]="persons" keyExpr="id" [showBorders]="true"
      [focusedRowEnabled]="false" [height]="300" [columnAutoWidth]="false" [columnHidingEnabled]="true"
      [showBorders]="true">


      <dxi-column dataField="" cellTemplate="actionsTemplate" [width]="100"></dxi-column>
      <dxi-column dataField="id" caption="{{'Id' | translate}}" [width]="50"></dxi-column>
      <dxi-column dataField="name" caption="{{'Nombre' | translate}}"></dxi-column>
      <dxi-column dataField="email" caption="{{'Correo' | translate}}"></dxi-column>
      <dxi-column dataField="identification_number" caption="{{'Identificación' | translate}}"
        [width]="'auto'"></dxi-column>
      <dxi-column dataField="cellPhone" caption="{{'Celular' | translate}}" [width]="'auto'"></dxi-column>


      <div class="dev-row container__c__a" *dxTemplate="let cell of 'actionsTemplate'">
        <dx-drop-down-button text="Acciones" [items]="actions" displayExpr="value" keyExpr="id" [width]="'100%'"
          (onItemClick)="onActionClick(cell.data, $event.itemData)" [dropDownOptions]="{ width: 200 }">
        </dx-drop-down-button>
      </div>
    </dx-data-grid>
  </div>
  }
  @if(currentFilter.length === 0){
  <app-on-demand-pagination [pagination]="onDemandPagination"></app-on-demand-pagination>
  }

</div>

@defer (when configPopupInfrastructure.visible) {
<lib-process-popup [config]="configPopupInfrastructure">
  <app-assign-infrastructure-form [configPopup]="configPopupInfrastructure" (sendForm)="submitData($event)"
    (close)="isVisiblePopupInfrastructure($event)" (deletePersonInfraestructure)="deletePersonInfrastructureDynamic($event)" component></app-assign-infrastructure-form>
</lib-process-popup>
}