
import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import { AuthRepository, ICampusByCompanyDTO } from '@management/domain/management';
import { selector } from '@management/base/management';

export class GetListCampusByCompanySelector implements UseCase<selector<ICampusByCompanyDTO[]>, selector<ICampusByCompanyDTO[]>> {
    private static instance: GetListCampusByCompanySelector;
    constructor(private authRepository: AuthRepository) { }
    public static getInstance(authRepository: AuthRepository): GetListCampusByCompanySelector {
        if (!GetListCampusByCompanySelector.instance)
            GetListCampusByCompanySelector.instance = new GetListCampusByCompanySelector(authRepository);
        return GetListCampusByCompanySelector.instance;
    }
    execute(): Observable<selector<ICampusByCompanyDTO[]>> {
        return this.authRepository.getListCampusByCompanySelector();
    }
}