
import { Mapper } from '@management/base/management';
import { IInstallationTypeDTO } from '@management/domain/management';
import { IInstallationTypeEntity } from '@management/data/management';

export class InstallationTypeMapper extends Mapper<IInstallationTypeEntity, IInstallationTypeDTO> {

    private static instance: InstallationTypeMapper;

    private constructor() { super(); }

    public static getInstance(): InstallationTypeMapper {
        if (!InstallationTypeMapper.instance)
            InstallationTypeMapper.instance = new InstallationTypeMapper();
        return InstallationTypeMapper.instance;
    }


    mapFrom(param: IInstallationTypeEntity): IInstallationTypeDTO {
        return {
            id: param.id,
            state: param.state,
            campus: param.campus,
            name: param.name,
            homologation: param.homologation,
            description: param.description,

        };
    }
    mapFromList(params: IInstallationTypeEntity[]): IInstallationTypeDTO[] {
        return params.map((param: IInstallationTypeEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IInstallationTypeDTO): IInstallationTypeEntity {
        return {
            id: param.id,
            state: param.state,
            campus: param.campus,
            name: param.name,
            homologation: param.homologation,
            description: param.description,

        }
    }
    mapToList(params: IInstallationTypeDTO[]): IInstallationTypeEntity[] {
        return params.map((param: IInstallationTypeDTO) => {
            return this.mapTo(param);
        })
    }
}