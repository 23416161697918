<div class="container">



    <form [formGroup]="form" (ngSubmit)="submitData()">
        <dx-validation-group class="form__group" #validationGroupAssignment>


            <dx-date-box formControlName="fromDate" type="date" [showClearButton]="true"
                [inputAttr]="{ 'aria-label': 'Date' }" [min]="now" displayFormat="EEEE, MMM dd"
                placeholder="Seleccionar día">
            </dx-date-box>

            <dx-tag-box formControlName="services" [items]="services" [showSelectionControls]="true"
                applyValueMode="useButtons" label="Servicios disponibles" labelMode="floating"
                [inputAttr]="{ 'aria-label': 'services' }" displayExpr="name" [searchEnabled]="true"
                [showClearButton]="true" valueExpr="id" (onValueChanged)="onChangeServices($event)">
                <dx-validator>
                    <dxi-validation-rule type="required" message="{{'Es obligatorio' | translate}}"
                        [ignoreEmptyValue]="true"></dxi-validation-rule>
                </dx-validator>
            </dx-tag-box>

            <dx-select-box formControlName="collaborator" [searchEnabled]="true" [showClearButton]="true"
                [noDataText]="'No se encontro datos'" width="150" [dataSource]="persons" displayExpr="name_person"
                valueExpr="person_id" placeholder="Seleccionar colaborador" label="Profesional" width="100%"
                labelMode="floating" (onValueChanged)="onChangePersons($event)">
                <dx-validator>
                    <dxi-validation-rule type="required"
                        message="{{'GLOBAL.obligatoryField' | translate}}"></dxi-validation-rule>
                </dx-validator>
            </dx-select-box>


            <dx-select-box formControlName="fromHour" [searchEnabled]="true" [showClearButton]="true"
                [noDataText]="'No se encontro datos'" width="150" [dataSource]="personTimes" displayExpr="start_time"
                valueExpr="order_num" placeholder="Seleccionar hora" label="hora" width="100%" labelMode="floating">
                <dx-validator>
                    <dxi-validation-rule type="required"
                        message="{{'GLOBAL.obligatoryField' | translate}}"></dxi-validation-rule>
                </dx-validator>
            </dx-select-box>

            <dx-button [focusStateEnabled]="false" stylingMode="contained" type="default"
                text="{{'Asignar cita' | translate }}" width="100%" height="26" [useSubmitBehavior]="true">
            </dx-button>


        </dx-validation-group>
    </form>







    <!-- <div class="left-content">
        <dx-text-area [height]="90" [maxLength]="200" [autoResizeEnabled]="true"
            [inputAttr]="{ 'aria-label': 'Notes' }">
        </dx-text-area>
    </div> -->
</div>