
import { Observable } from 'rxjs';
//domain
import {
    IServiceServiceDTO,
    IServiceDTO,
    ICampusServiceCampusServiceDTO,
    ICampusServiceDTO,
    ICampusServiceNameCampusServiceNameDTO,
    ICampusServiceNameDTO,
    ICampusServiceCurrencyCampusServiceCurrencyDTO,
    ICampusServiceCurrencyDTO,
    IServicesServicesDTO,
    IServicesDTO,
    IPersonServicesDTO,
} from '@management/domain/management';
import { UtilsComponent } from '@management/ui';
import { selector } from '@management/base/management';

export abstract class ServiceRepository extends UtilsComponent {
    abstract GetServicesService(params: IServicesServicesDTO, loadService: boolean): Observable<IServicesDTO[]>;
    abstract GetCampusServiceCurrencyService(params: ICampusServiceCurrencyCampusServiceCurrencyDTO, loadService: boolean): Observable<ICampusServiceCurrencyDTO[]>;
    abstract GetCampusServiceNameService(params: ICampusServiceNameCampusServiceNameDTO, loadService: boolean): Observable<ICampusServiceNameDTO[]>;
    abstract GetCampusServiceService(params: ICampusServiceCampusServiceDTO, loadService: boolean): Observable<ICampusServiceDTO[]>;
    abstract GetServiceService(params: IServiceServiceDTO, loadService: boolean): Observable<IServiceDTO[]>;
}