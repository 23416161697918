
import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import { IAuthDTO, IAuthSingInDTO, ICampusByCompanyDTO, ICompanySelectedDTO, IInstallationTypeSaveInstallationTypeDTO, IInstallationTypesInstallationTypesDTO, IMenuDTO } from '../../dtos';
import { MenuRepository } from '@management/domain/management';
import { ICampusByCompanyRequestEntity } from '@management/data/management';


export class GetRecursiveMenuUseCase implements UseCase<IMenuDTO[], IMenuDTO[]> {

    constructor(private menuRepository: MenuRepository) { }
    execute(params: undefined, loadService: boolean): Observable<IMenuDTO[]> {
        return this.menuRepository.getRecursiveMenu(loadService);
    }
}

