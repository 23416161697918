import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    AssignmentRepository,
    IAssignmentDTO,
    IAssignmentAssignmentDTO
} from '@management/domain/management';

export class SetAssignmentServiceUseCase implements UseCase<IAssignmentAssignmentDTO, IAssignmentDTO> {

    private static instance: SetAssignmentServiceUseCase;

    constructor(private assignmentRepository: AssignmentRepository) { }

    public static getInstance(assignmentRepository: AssignmentRepository): SetAssignmentServiceUseCase {
        if (!SetAssignmentServiceUseCase.instance)
            SetAssignmentServiceUseCase.instance = new SetAssignmentServiceUseCase(assignmentRepository);
        return SetAssignmentServiceUseCase.instance;
    }

    execute(
        params: IAssignmentAssignmentDTO, loadService: boolean
    ): Observable<IAssignmentDTO> {
        return this.assignmentRepository.SetAssignmentService(params, loadService);
    }
}