import { Observable } from "rxjs";
import { IMenuDTO, MenuRepository, GetRecursiveMenuUseCase, SetRecursiveMenuReduxUseCase, ClearMenuReduxUseCase } from "@management/domain/management";
import { GetRecursiveMenuReduxUseCase } from "@management/domain/management";
import { Injectable } from "@angular/core";
import { selector } from "@management/base/management";


export class MenuFacade extends MenuRepository {


    constructor(
        private menuRepository: MenuRepository
    ) { super(); }

    getRecursiveMenu(loadService: boolean): Observable<IMenuDTO[]> {
        return new GetRecursiveMenuUseCase(this.menuRepository).execute(undefined, loadService);
    }

    getRecursiveMenuSelector(): Observable<selector<IMenuDTO[]>> {
        return new GetRecursiveMenuReduxUseCase(this.menuRepository).execute();
    }

    setRecursiveMenuRedux(menu: IMenuDTO[]): void {
        new SetRecursiveMenuReduxUseCase(this.menuRepository).execute(menu);
    }

    clearMenuRedux(): void {
        new ClearMenuReduxUseCase(this.menuRepository).execute();
    }

}