
import { Subscription, first } from 'rxjs';
import { selector } from '@management/base/management';
import { PopupColorTypeEnum } from 'libs/ui/src/lib/enum/global-enum';
import { MessagesGLobal, UiModule, UtilsComponent } from '@management/ui';
import { ICampusByCompanyEntity } from '@management/data/management';
import { DialogDataSede, DialogSelectSedeComponent } from './components/dialog-select-sede/dialog-select-sede.component';
import { ConfigProcessPopup } from 'libs/ui/src/lib/moleculas/process-popup/process-popup.interface';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthFacade, MenuFacade } from '@management/facade/management';
import { ICampusByCompanyDTO, ICompanyDTO, ICompanyFilterDTO, ICompanySelectedDTO, IPersonDTO, ICompanySelectedRequestDTO, IMenuDTO } from '@management/domain/management';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { NgxTranslateModule } from '@management/ngx-translate';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DevextremeLibModule } from '@management/devextreme-lib';
import { DialogDataCompany, DialogSelectCompanyComponent } from './components/dialog-select-company/dialog-select-company.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgxTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DevextremeLibModule,
    UiModule,
    DialogSelectCompanyComponent,
    DialogSelectSedeComponent
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends UtilsComponent implements OnInit, OnDestroy {


  configPopupCompany: ConfigProcessPopup = {
    width: '300',
    height: '190',
    dragEnabled: false,
    visible: false,
    popupColorType: PopupColorTypeEnum.NORMAL
  }

  configPopupCampus: ConfigProcessPopup = {
    width: '300',
    height: '190',
    dragEnabled: false,
    visible: false,
    popupColorType: PopupColorTypeEnum.NORMAL
  }

  popupDataCampus: DialogDataSede = {
    campusId: null,
    campusList: [],
    onSelected: (companyId) => this.popupSelectedItemCampus(companyId)
  }
  popupDataCompany: DialogDataCompany = {
    companyId: null,
    companyList: [],
    onSelected: (companyId) => this.popupSelectedItemCompany(companyId)

  }

  private subscriptions = new Subscription();
  person!: selector<IPersonDTO>;
  nameUser = '';

  constructor(
    private messagesGlobal: MessagesGLobal,
    private readonly _authFacade: AuthFacade,
    private readonly _menuFacade: MenuFacade
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.getListCompanies();
    this.getUser();
  }

  private getUser() {
    this.subscriptions.add(
      this._authFacade.getPersonSelector().subscribe((person: selector<IPersonDTO>) => {
        this.person = person;
        this.nameUser = person?.name || ''
      })
    )
  }

  private getListCompanies() {
    this.subscriptions.add(
      this._authFacade.getCompanySelectedSelector()
        .pipe(first(e => !this.isNotUndefinedOrNull(e)))
        .subscribe(_ => {
          this.getListCompaniesRedux();
        })
    )
  }

  private getListCompaniesRedux() {
    this.subscriptions.add(
      this._authFacade.getListCompaniesSelector()
        .pipe(first(e => this.isNotUndefinedOrNull(e)))
        .subscribe((res: selector<ICompanyDTO[]>) => {
          this.popupDataCompany.companyList = res?.map((x: ICompanyDTO) => {
            return { id: x.id, name: x.name }
          }) || [];
          this.initialRules(this.popupDataCompany.companyList);
        })
    )
  }

  private initialRules(companyList: ICompanyFilterDTO[]) {
    if (this.listHaveOnlyOneElement(companyList)) {
      let [company] = companyList;
      this._authFacade.getCampusByCompany({ companyId: company.id }, false, true)
        .pipe(first(e => this.isNotUndefinedOrNull(e)))
        .subscribe((res: ICampusByCompanyEntity[]) => {
          this._authFacade.setCampusByCompanyRedux(res);
          this.listCampusByCompany();
        });
    } else {
      this.openDialogCompany();
    }
  }

  private listCampusByCompany() {
    this.subscriptions.add(
      this._authFacade.getListCampusByCompanySelector()
        .pipe(first(e => this.isNotUndefinedOrNull(e)))
        .subscribe((campus: selector<ICampusByCompanyDTO[]>) => {
          if (!campus) return
          this.popupDataCampus.campusList =
            campus.map((x) => {
              return { id: x.id, name: x.name }
            });
          this.hasSingleCampus(campus);
        })
    )
  }

  private hasSingleCampus(campus: ICampusByCompanyDTO[]) {
    if (this.listHaveOnlyOneElement(campus)) {
      let [campusId] = campus;
      let companyId = this.popupDataCompany.companyId;
      let userId = this.person?.id;

      if (campusId.id && companyId && userId)
        this.getCompanySelected({ campusId: campusId.id, companyId: companyId, personId: userId })
      return
    }
    return this.openDialogCampus();
  }

  private getCompanySelected(companySelectedRequest: ICompanySelectedRequestDTO) {
    this._authFacade.getCompanySelected(companySelectedRequest, true, true)
      .pipe(first(e => this.isNotUndefinedOrNull(e)))
      .subscribe((companySelected: ICompanySelectedDTO) => {
        this._authFacade.setCompanySelectedRedux(companySelected);
        this.getRecursiveMenu();
      });
  }

  private openDialogCampus() {
    this.configPopupCampus = { ...this.configPopupCampus, visible: true };

  }

  private openDialogCompany() {
    this.configPopupCompany = { ...this.configPopupCompany, visible: true };
    console.log('this.configPopupCompany',this.configPopupCompany)
  }


  private popupSelectedItemCompany(companyId: number) {
    this.configPopupCompany = { ...this.configPopupCompany, visible: false };
    this.popupDataCompany.companyId = companyId;
    this._authFacade.getCampusByCompany({ companyId: companyId || 0 }, true, true)
      .pipe(first(e => this.isNotUndefinedOrNull(e)))
      .subscribe((data: ICampusByCompanyEntity[]) => {
        this._authFacade.setCampusByCompanyRedux(data);
        this.listCampusByCompany();
      });

  }

  private popupSelectedItemCampus(campusId: number) {
    this.configPopupCampus = { ...this.configPopupCampus, visible: false };
    let companyId = this.popupDataCompany.companyId;
    let userId = this.person?.id;
    if (campusId && companyId && userId) {
      this.getCompanySelected({ campusId: campusId, companyId: companyId, personId: userId });

    }
  }

  getRecursiveMenu() {
    this._menuFacade.getRecursiveMenu(true)
      .pipe(first(e => this.isNotUndefinedOrNull(e)))
      .subscribe((res: IMenuDTO[]) => {
        this._menuFacade.setRecursiveMenuRedux(res);
      })
  }

}
