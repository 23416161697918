<div class="container">
  <div class="container__a">
    <h3>Infraestructura</h3>
    <small>Organización de las instalaciones de tu sede.</small>
  </div>
  <div class="container__b">
    @if (form) {
    <form class="container__b__a" [formGroup]="form" (ngSubmit)="submitData()">

      <div class="container__b__a__a">

        <div class="container__b__a__a__b" *ngIf="!mainFacility">

          <dx-button stylingMode="contained" text="{{'Crear Instalación principal' | translate }}" type="default"
            width="200" [disabled]="false" (click)="createInstallation()">
          </dx-button>


          <div>
            <span id="help-tooltip" class="material-symbols-rounded icon-tooltip">
              help
            </span>
            <dx-tooltip target="#help-tooltip" showEvent="mouseenter" hideEvent="mouseleave"
              [hideOnOutsideClick]="false" position="right" width="200">
              <div class="dx-tooltip-text" *dxTemplate="let data = data; of: 'content'">{{'Una instalacion principal
                corresponde, al lugar donde se va a prestar los servicios. Puede ser mueble o inmueble.'}}</div>
            </dx-tooltip>

          </div>
        </div>

        <ng-container *ngIf="mainFacility">
          <dx-button stylingMode="contained" text="{{'Crear' | translate }}" type="default" width="100"
            [useSubmitBehavior]="true">
          </dx-button>

          <dx-button stylingMode="contained" text="{{'Cancelar' | translate }}" type="danger" width="100"
            [disabled]="false"  (click)="createInstallation()">
          </dx-button>
        </ng-container>

      </div>

      <div class="container__b__a__b" *ngIf="mainFacility">

        <div class="container__b__a__b">
          <dx-text-box class="input" formControlName="name" [showClearButton]="true"
            placeholder="{{ 'Nombre' | translate }}">
            <dx-validator>
              <dxi-validation-rule type="required" message="{{'LOGIN.mailIsRequired' | translate}}"
                [ignoreEmptyValue]="true"></dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [min]="3"
                message="{{'LOGIN.mailIsTooShort' | translate}}"></dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [max]="30"
                message="{{'LOGIN.veryLongMail' | translate}}"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>

          <div class="container__b__a__a">
            <div>Estado</div>
            <dx-check-box formControlName="state"></dx-check-box>
          </div>

          <dx-text-box class="input" formControlName="description" [showClearButton]="true"
            placeholder="{{ 'Description' | translate }}" [width]="400">
            <dx-validator>
              <dxi-validation-rule type="required" message="{{'LOGIN.mailIsRequired' | translate}}"
                [ignoreEmptyValue]="true"></dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [min]="7"
                message="{{'LOGIN.mailIsTooShort' | translate}}"></dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [max]="40"
                message="{{'LOGIN.veryLongMail' | translate}}"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>

        </div>

      </div>

    </form>
    }

  </div>
  @if (infrastructureTree.length) {
    <div class="container__d" *ngIf="!mainFacility">
      <dx-tree-view class="container__d__a" [items]="infrastructureTree" [width]="320" (onItemClick)="selectItem($event)" noDataText="">
      </dx-tree-view>
      <div class="container__d__b">
        <app-form-infrastructure [infrastructureSelected]="installationTypeSelected"
          (callServiceGetGetInfrastructureByCampusService)="getGetInfrastructureByCampusService($event)"></app-form-infrastructure>
      </div>
    </div>
  }
</div>
