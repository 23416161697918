
import { Mapper } from '@management/base/management';
import { IMenuEntity } from '@management/data/management';
import { IMenuDTO } from '@management/domain/management';


export class MenuImplementationRepositoryMapper extends Mapper<IMenuEntity, IMenuDTO> {
    mapFrom(param: IMenuEntity): IMenuDTO {
        return {
            id: param.id,
            top_id: param.top_id,
            nivel: param.nivel,
            name: param.name,
            state: param.state,
            description: param.description,
            company: param.company,
            route: param.route,
            active: param.active,
            label: param.label,
            roles: param.roles,
            icon: param.icon,
            items: param.items
        }
    }
    mapFromList(params: IMenuEntity[]): IMenuDTO[] {
        return params.map((param: IMenuEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IMenuDTO): IMenuEntity {
        return {
            id: param.id,
            top_id: param.top_id,
            nivel: param.nivel,
            name: param.name,
            state: param.state,
            description: param.description,
            company: param.company,
            route: param.route,
            active: param.active,
            label: param.label,
            roles: param.roles,
            icon: param.icon,
            items: param.items
        }
    }

    mapToList(params: IMenuDTO[]): IMenuEntity[] {
        return params.map((param: IMenuDTO) => {
            return this.mapTo(param);
        })
    }
}