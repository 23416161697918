<div class="container">
    @if (!infrastructureSelected) {
    <div>Crea algun tipo de instalación.</div>
    } @else {
    <div class="container__a">{{infrastructureSelected.name | titlecase}}</div>
    @if (isEdit()) {
    <div class="container__c">
        <dx-button  [focusStateEnabled]="false" stylingMode="contained" text="{{'Actualizar' | translate }}" type="default"
            [useSubmitBehavior]="false" [width]="80" [height]="20"  (onClick)="updateInfraestructure()">
        </dx-button>
        <dx-button [focusStateEnabled]="false" stylingMode="contained" text="{{'Eliminar' | translate }}" type="default" [useSubmitBehavior]="false"
            [width]="80" [height]="20"  (onClick)="deleteInfraestructure()">
        </dx-button>
    </div>
    }
    <form class="container__b" [formGroup]="form" (submit)="setSaveInfrastructureService($event)">
        <dx-validation-group #validationGroupInfrastructure>

            <div class="container__b__a">
                <dx-text-box class="input" formControlName="name" [showClearButton]="true"
                    placeholder="{{ 'Nombre' | translate }}">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="{{'LOGIN.mailIsRequired' | translate}}"
                            [ignoreEmptyValue]="true"></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" [min]="3"
                            message="{{'LOGIN.mailIsTooShort' | translate}}"></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" [max]="30"
                            message="{{'LOGIN.veryLongMail' | translate}}"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>

                <div class="container__b__a__a">
                    <div>Estado</div>
                    <dx-check-box formControlName="state"></dx-check-box>
                </div>

                <dx-text-box class="input" formControlName="description" [showClearButton]="true"
                    placeholder="{{ 'Descripcion' | translate }}" [width]="400">
                    <dx-validator>
                        <dxi-validation-rule type="required" message="{{'LOGIN.mailIsRequired' | translate}}"
                            [ignoreEmptyValue]="true"></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" [min]="7"
                            message="{{'LOGIN.mailIsTooShort' | translate}}"></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" [max]="40"
                            message="{{'LOGIN.veryLongMail' | translate}}"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>

            </div>

            @if (!isEdit()) {
            <dx-button stylingMode="contained" text="{{'Crear' | translate }}" type="default" width="100"
                [useSubmitBehavior]="true">
            </dx-button>
            }

        </dx-validation-group>
    </form>

    }
</div>