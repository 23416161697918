import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { NgxTranslateModule } from '@management/ngx-translate';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule, UtilsComponent, Filter as LibraryFilter, Message, } from '@management/ui';
import { DevextremeLibModule } from '@management/devextreme-lib';
import { AuthFacade, CurrencyFacade, ServiceCampusFacade, ServiceFacade } from '@management/facade/management';
import { ACTIONS, Actions, CONDITION, TYPE_VALUE, selector } from '@management/base/management';
import { take } from 'rxjs';
import { Filter, ICampusServiceDTO, ICompanySelectedDTO, ICurrencyDTO, IServiceCampusDeleteDTO, IServiceCampusPutDTO, IServiceCampusPutServiceCampusDTO, IServiceCampusServiceCampusDTO, IServiceDTO } from '@management/domain/management';
import { loadMessages, locale } from 'devextreme/localization';
import es from '../../../assets/dev-extreme-lan/es.json';
import { ServiceFormComponent } from './components/service-form/service-form.component';
import { IOnDemandPaginationDTO } from 'libs/ui/src/lib/moleculas/on-demand-pagination/on-demand-pagination.component';
import { ConfigProcessPopup, TYPE_MODAL } from 'libs/ui/src/lib/moleculas/process-popup/process-popup.interface';
import { PopupColorTypeEnum } from 'libs/ui/src/lib/enum/global-enum';

@Component({
  selector: 'app-service',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgxTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DevextremeLibModule,
    UiModule,
    ServiceFormComponent
  ],
  templateUrl: './service.component.html',
  styleUrl: './service.component.scss'
})
export class ServiceComponent extends UtilsComponent implements OnInit {

  public companySelectedDTO!: ICompanySelectedDTO;
  public services: IServiceDTO[] = [];
  public servicesByCampus: ICampusServiceDTO[] = [];
  public currencies: ICurrencyDTO[] = [];
  public baseCurrency: number = 0;
  public configPopupService: ConfigProcessPopup = {
    width: '400',
    height: '340',
    dragEnabled: false,
    visible: false,
    popupColorType: PopupColorTypeEnum.NORMAL,
    typeModal: TYPE_MODAL.ADD
  }
  public override onDemandPagination: IOnDemandPaginationDTO = {
    callbackService: (pagination: IOnDemandPaginationDTO) => {
      this.getCampusService(pagination.from)
    },
    from: 0,
    until: 6,
    currentPage: 1
  }
  public actions: Actions[] = [
    { id: 1, value: 'Editar', icon: 'edit' },
    { id: 3, value: 'Eliminar', icon: 'remove' }
  ];

  public _authFacade = inject(AuthFacade);
  public _serviceFacade = inject(ServiceFacade);
  public _currencyFacade = inject(CurrencyFacade);
  public _serviceCampusFacade = inject(ServiceCampusFacade);

  constructor() {
    super()
  }

  ngOnInit(): void {
    if (!this.hasUserActive()) return
    this.getCurrencyService();
    this.clearLastRequest();
    this.getCompanySelectedSelector();
    this.loadLocale();
    this.getCampusService(this.onDemandPagination.from);
  }

  public loadLocale(): void {
    loadMessages(es);
    locale('es');
  }

  public getCurrencyService(): void {
    this._currencyFacade
      .GetCurrencyService({ fullData: true }, false)
      .pipe(take(1))
      .subscribe((currencies: ICurrencyDTO[]) => {
        this.currencies = currencies;
        this.assingBaseCurrency();
        this.initFilters();
      })
  }

  public assingBaseCurrency(): void {
    this.baseCurrency = this.currencies[0].id;
  }

  public setServiceCampusService(model: IServiceCampusServiceCampusDTO): void {
    this._serviceCampusFacade
      .SetServiceCampusService(model, true)
      .pipe(take(1))
      .subscribe((campusService: IServiceCampusServiceCampusDTO) => {
        if (campusService) {
          this.clearLastRequest();
          this.isVisiblePopupService(false, TYPE_MODAL.NA);
          this.onClear();
        }
      })
  }


  public getServiceService(): void {
    const { id } = this.companySelectedDTO.campus;
    const filterBase = [{
      value: id,
      type_value: TYPE_VALUE.NUMBER,
      condition: CONDITION.EQUALS,
      field: "company"
    }];
    this._serviceFacade
      .GetServiceService({ fullData: true, filters: filterBase }, false)
      .pipe(take(1))
      .subscribe((services: IServiceDTO[]) => {
        this.services = services;
        //TODO: se debe hacer un servicio en la arq que compare lo que esta en la tabla y dejar lo que no esta. tu puedes!!!
      })
  }

  public getCompanySelectedSelector(): void {
    this._authFacade.getCompanySelectedSelector()
      .pipe(take(1))
      .subscribe((res: selector<ICompanySelectedDTO>) => {
        this.companySelectedDTO = res as ICompanySelectedDTO;
        this.getServiceService();
      })
  }

  public submitData(form: FormGroup): void {
    switch (this.configPopupService.typeModal) {
      case TYPE_MODAL.ADD:
        this.createServiceDynamic(form);
        break;
      case TYPE_MODAL.UPDATE:
        this.updateServiceDynamic(form);
        break;
    }
  }

  public createServiceDynamic(form: FormGroup): void {
    if (!this.isFormValidTS(form)) return
    const { service, currency, price } = form?.value;
    this.baseCurrency = currency;
    const { id } = this.companySelectedDTO.campus;
    const modelCampusService: IServiceCampusServiceCampusDTO = {
      service,
      currency,
      price,
      campus: id,
    }
    this.setServiceCampusService(modelCampusService);
  }

  public updateServiceDynamic(form: FormGroup): void {
    if (!this.isFormValidTS(form)) return
    const { service, currency, price } = form?.value;
    const { id } = this.companySelectedDTO.campus;
    const { service_campus_id } = this.configPopupService.dataSource as ICampusServiceDTO;
    const modelCampusService: IServiceCampusPutServiceCampusDTO = {
      id: service_campus_id,
      service: service,
      campus: id,
      price: price,
      currency: currency
    }
    this.updateServiceCampusService(modelCampusService);
  }

  public updateServiceCampusService(model: IServiceCampusPutServiceCampusDTO): void {
    this._serviceCampusFacade
      .UpdateServiceCampusService(model, true)
      .pipe(take(1))
      .subscribe((campusService: IServiceCampusPutDTO) => {
        if (campusService) {
          this.clearLastRequest();
          this.isVisiblePopupService(false, TYPE_MODAL.NA);
          this.onClear();
        }
      })
  }

  public initFilters(): void {
    const selectCurrecy =
      this.currencies.map((currency: ICurrencyDTO) => {
        return { id: currency.id, value: `${currency.code} - ${currency.name}` }
      })
    this.filters = [
      { id: 1, type_value: TYPE_VALUE.STRING, condition: CONDITION.LIKE, field: 'name', value: 'INSTALLATION_COMPONENT.name' },
      { id: 2, type_value: TYPE_VALUE.SELECT, condition: CONDITION.EQUALS, field: 'currency', value: 'Moneda', datasource: selectCurrecy }
    ];
  }

  public onFilter(filter?: LibraryFilter[]): void {
    if (filter?.length === 0) return
    this.clearLastRequest();
    this.currentFilter = filter as Filter[];
    const [getFilter] = this.currentFilter;

    switch (getFilter.field) {
      case 'name':
        this.getCampusServiceName(getFilter)
        break;
      case 'currency':
        this.getCampusServiceCurrencyService(getFilter)
        break;

    }
  }

  public onClear(event?: boolean, clearFilter?: boolean): void {
    if (this.listHasValues(this.currentFilter) && !clearFilter) {
      this.onFilter(this.currentFilter)
    } else {
      this.currentFilter = [];
      this.resetOnDemandPagination();
      this.getCampusService(this.onDemandPagination.from);
    }
  }

  public getCampusServiceName(getFilter: Filter): void {
    if (!getFilter?.value) return
    this._serviceFacade
      .GetCampusServiceNameService({ name: getFilter.value.toString() }, true)
      .pipe(take(1))
      .subscribe((services) => {
        if (this.listHasValues(services))
          return this.servicesByCampus = services;
        return this.servicesByCampus = [];
      })
  }

  public getCampusServiceCurrencyService(getFilter: Filter): void {
    if (!getFilter?.value) return
    this._serviceFacade
      .GetCampusServiceCurrencyService({ currency: parseInt(getFilter.value.toString()) }, true)
      .pipe(take(1))
      .subscribe((services) => {
        if (this.listHasValues(services))
          return this.servicesByCampus = services;
        return this.servicesByCampus = [];
      })
  }

  public getCampusService(from: number): void {
    this._serviceFacade
      .GetCampusServiceService({ from, until: this.onDemandPagination.until }, true)
      .pipe(take(1))
      .subscribe((services: ICampusServiceDTO[]) => {
        if (services.length === 0) {
          this.stopOnDemandPagination();
        } else {
          this.servicesByCampus = services;
        }

      })
  }

  public onActionClick(data: ICampusServiceDTO, event: Actions): void {
    switch (event.id) {
      case ACTIONS.EDIT:
        this.isVisiblePopupService(true, TYPE_MODAL.UPDATE, data);
        break;
      case ACTIONS.DELETE:
        this.deleteServiceCampusDeleteService(data)
        break;
    }
  }

  public createService(): void {
    this.isVisiblePopupService(true, TYPE_MODAL.ADD);
  }

  public isVisiblePopupService(visible: boolean, typeModal?: TYPE_MODAL, data?: any): void {
    this.configPopupService = {
      ...this.configPopupService,
      typeModal: typeModal,
      visible: visible,
      dataSource: data
    }
  }

  public deleteServiceCampusDeleteService(campusService: ICampusServiceDTO): void {
    let { service_campus_id, currency }: ICampusServiceDTO = campusService;
    const id = service_campus_id || 0;
    this._serviceCampusFacade
      .DeleteServiceCampusDeleteService({ id }, true)
      .pipe(take(1))
      .subscribe((campusService: IServiceCampusDeleteDTO) => {
        if (campusService) {
          this.baseCurrency = currency || 0;
          this.onClear();
        }

      })
  }




}
