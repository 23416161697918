import { Injectable } from '@angular/core';
import { Action, select, Store } from "@ngrx/store";

import { environment, HttpClient, map, Observable, of, ResolveRequest } from '@management/base/management';
import { IMenuDTO } from '@management/domain/management';
import { IMenuEntity, MenuImplementationRepositoryMapper } from '@management/data/management';

import { keysSessionStorangeEnum } from '@management/base/management';
import { MenuRepository } from 'apps/management/src/domain/repositories/menu/menu.repository';

import * as reducerMenu from './redux/menu.reducer';
import * as actionsMenu from './redux/menu.actions';
import * as selectorsMenu from './redux/menu.selectors';

@Injectable({
    providedIn: 'root',
})
export class MenuImplementationRepository extends MenuRepository {
   



    private readonly urlApiRest: string = environment.api.urlApiRest;
    private readonly menuMapper = new MenuImplementationRepositoryMapper();

    constructor(
        private http: HttpClient,
        private resolveRequest: ResolveRequest,
        private store: Store<reducerMenu.MenuModel>) {
        super();
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    getRecursiveMenu(loadService: boolean): Observable<IMenuDTO[]> {
        let data = this.getSessionStorange(keysSessionStorangeEnum.recursiveMenu) as IMenuDTO[];
        if (!data || loadService)
            return this.http.get(`${this.urlApiRest}menu/getRecursiveMenu`)
                .pipe(map((response: any) => {
                    const res = this.resolveRequest.resolve<IMenuEntity[]>(response);
                    this.setSessionStorange(keysSessionStorangeEnum.recursiveMenu, res);
                    return res;
                }))
                .pipe(map((entity: IMenuEntity[]) => entity.length && this.menuMapper.mapFromList(entity) || entity))
        return of(data);
    }

    getRecursiveMenuSelector(): Observable<IMenuDTO[]> {
        return this.store.pipe(select(selectorsMenu.getRecursiveMenu));
    }

    setRecursiveMenuRedux(menu: IMenuDTO[]): void {
        this.dispatch(actionsMenu.setRecursiveMenu({ menu: menu }));
    }

    clearMenuRedux(): void {
        this.dispatch(actionsMenu.cleanMenu());
    }


}