
import { Observable } from 'rxjs';
import { UseCase, selector } from '@management/base/management';
import { IMenuDTO } from '../../dtos';
import { MenuRepository } from '@management/domain/management';


export class GetRecursiveMenuReduxUseCase implements UseCase<void, selector<IMenuDTO[]>> {

    constructor(private menuRepository: MenuRepository) { }
    execute(): Observable<selector<IMenuDTO[]>> {
        return this.menuRepository.getRecursiveMenuSelector();
    }
}