
import { Mapper } from '@management/base/management';
import { ICompanyCampusNameDTO } from '@management/domain/management';
import { ICompanyCampusNameEntity } from '@management/data/management';

export class CompanyCampusNameMapper extends Mapper<ICompanyCampusNameEntity, ICompanyCampusNameDTO> {

    private static instance: CompanyCampusNameMapper;

    private constructor() { super(); }

    public static getInstance(): CompanyCampusNameMapper {
        if (!CompanyCampusNameMapper.instance)
            CompanyCampusNameMapper.instance = new CompanyCampusNameMapper();
        return CompanyCampusNameMapper.instance;
    }


    mapFrom(param: ICompanyCampusNameEntity): ICompanyCampusNameDTO {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number,
            currency_name: param.currency_name,
            language_name: param.language_name,
            rol: param.rol,
            rol_name: param.rol_name
        };
    }
    mapFromList(params: ICompanyCampusNameEntity[]): ICompanyCampusNameDTO[] {
        return params.map((param: ICompanyCampusNameEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: ICompanyCampusNameDTO): ICompanyCampusNameEntity {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number,
            currency_name: param.currency_name,
            language_name: param.language_name,
            rol: param.rol,
            rol_name: param.rol_name
        }
    }
    mapToList(params: ICompanyCampusNameDTO[]): ICompanyCampusNameEntity[] {
        return params.map((param: ICompanyCampusNameDTO) => {
            return this.mapTo(param);
        })
    }
}