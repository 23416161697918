import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    InstallationTypeRepository,
    IInstallationTypeDTO,
    IInstallationTypeSaveInstallationTypeDTO
} from '@management/domain/management';

    export class SetSaveInstallationTypeServiceUseCase implements UseCase<IInstallationTypeSaveInstallationTypeDTO, IInstallationTypeDTO> {

    private static instance: SetSaveInstallationTypeServiceUseCase;

    constructor(private installationTypeRepository: InstallationTypeRepository) { }

    public static getInstance(installationTypeRepository: InstallationTypeRepository): SetSaveInstallationTypeServiceUseCase {
        if (!SetSaveInstallationTypeServiceUseCase.instance)
            SetSaveInstallationTypeServiceUseCase.instance = new SetSaveInstallationTypeServiceUseCase(installationTypeRepository);
        return SetSaveInstallationTypeServiceUseCase.instance;
    }

    execute(
        params: IInstallationTypeSaveInstallationTypeDTO, loadService: boolean
    ): Observable<IInstallationTypeDTO> {
        return this.installationTypeRepository.SetSaveInstallationTypeService(params, loadService);
    }
}