<div class="container">
  <div class="container__a">
    <span class="material-symbols-outlined">
      {{icon}}
    </span>
  </div>
  <div class="container__b">
    {{data.message}}
  </div>
  <div class="container__c">
    <dx-button stylingMode="contained" text="{{'Entendido' | translate }}" type="default" width="90" [disabled]="false"
      (click)="closeMessage()" [height]="20">
    </dx-button>
  </div>
</div>