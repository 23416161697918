import { Observable } from "rxjs";
//domain
import {
    AssignmentRepository,
    IAssignmentDTO,
    IAssignmentAssignmentDTO,
    SetAssignmentServiceUseCase,
} from "@management/domain/management";


export class AssignmentFacade extends AssignmentRepository {

    private static instance: AssignmentFacade;

    constructor(private assignmentRepository: AssignmentRepository) {
        super();
    }

    public static getInstance(assignmentRepository: AssignmentRepository): AssignmentFacade {
        if (!AssignmentFacade.instance)
            AssignmentFacade.instance = new AssignmentFacade(assignmentRepository);
        return AssignmentFacade.instance;
    }

    SetAssignmentService(params: IAssignmentAssignmentDTO, loadService: boolean): Observable<IAssignmentDTO> {
        return SetAssignmentServiceUseCase.getInstance(this.assignmentRepository).execute(params, loadService);
    }

}