import { actionsAuth } from './index';
import { Injectable } from "@angular/core";
import { mergeMap, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';


@Injectable()
export class AuthEffects {

    constructor(
        private actions$: Actions
    ) { }


    // setUser$ = createEffect(
    //     () => this.actions$.pipe(
    //         ofType(actionsAuth.setUser),
    //         mergeMap(async (actions) => actionsAuth.setUser({ authDTO: actions.authDTO }))
    //     )
    // );

}