import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './moleculas/menu/menu.component';
import { DevextremeLibModule } from '@management/devextreme-lib';
import { ProcessPopupComponent } from './moleculas/process-popup/process-popup.component';
import { MenuVerticalComponent } from './moleculas/menu-vertical/menu-vertical.component';
import { NgxTranslateModule } from '@management/ngx-translate';
import { PaginationComponent } from './moleculas/pagination/pagination.component';
import { FilterComponent } from './moleculas/filter/filter.component';
import { FormsModule } from '@angular/forms';
import { OnDemandPaginationComponent } from './moleculas/on-demand-pagination/on-demand-pagination.component';
import { PopupMessagesComponent } from './moleculas/popup-messages/popup-messages.component';

const COMPONENTS = [
  MenuComponent,
  ProcessPopupComponent,
  PaginationComponent,
  FilterComponent,
  OnDemandPaginationComponent,
  PopupMessagesComponent
]
@NgModule({
  declarations: COMPONENTS,
  imports: [CommonModule, DevextremeLibModule, NgxTranslateModule, FormsModule],
  exports: COMPONENTS
})
export class UiModule { }