import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonRepository,
    IPersonTypeUserIdentificationDTO,
    IPersonTypeUserIdentificationPersonTypeUserIdentificationDTO
} from '@management/domain/management';

export class GetPersonTypeUserIdentificationServiceUseCase implements UseCase<IPersonTypeUserIdentificationPersonTypeUserIdentificationDTO, IPersonTypeUserIdentificationDTO[]> {

    private static instance: GetPersonTypeUserIdentificationServiceUseCase;

    constructor(private personTypeUserIdentificationRepository: PersonRepository) { }

    public static getInstance(personTypeUserIdentificationRepository: PersonRepository): GetPersonTypeUserIdentificationServiceUseCase {
        if (!GetPersonTypeUserIdentificationServiceUseCase.instance)
            GetPersonTypeUserIdentificationServiceUseCase.instance = new GetPersonTypeUserIdentificationServiceUseCase(personTypeUserIdentificationRepository);
        return GetPersonTypeUserIdentificationServiceUseCase.instance;
    }

    execute(
        params: IPersonTypeUserIdentificationPersonTypeUserIdentificationDTO, loadService: boolean
    ): Observable<IPersonTypeUserIdentificationDTO[]> {
        return this.personTypeUserIdentificationRepository.GetPersonTypeUserIdentificationService(params, loadService);
    }
}