import { actionsPersonCampus } from './index';
import { createReducer, on } from '@ngrx/store';
import {  } from "@management/domain/management";

export const PERSON_CAMPUS_KEY = 'personCampus'
export interface PersonCampusModel {
}

export const PersonCampusInitialState: PersonCampusModel = {
}

const _PersonCampusReducer = createReducer(PersonCampusInitialState,
);

export function PersonCampusReducer(state: PersonCampusModel, action: any) {
    return _PersonCampusReducer(state, action);
}