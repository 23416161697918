import { actionsServiceCampus } from './index';
import { createReducer, on } from '@ngrx/store';
import {  } from "@management/domain/management";

export const SERVICE_CAMPUS_KEY = 'serviceCampus'
export interface ServiceCampusModel {
}

export const ServiceCampusInitialState: ServiceCampusModel = {
}

const _ServiceCampusReducer = createReducer(ServiceCampusInitialState,
);

export function ServiceCampusReducer(state: ServiceCampusModel, action: any) {
    return _ServiceCampusReducer(state, action);
}