import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  DxButtonModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxValidationSummaryModule,
  DxPopupModule,
  DxTemplateModule,
  DxSelectBoxModule,
  DxTooltipModule,
  DxSwitchModule,
  DxCheckBoxModule,
  DxTreeViewModule,
  DxDataGridModule,
  DxChartModule,
  DxFilterBuilderModule,
  DxValidationGroupModule,
  DxNumberBoxModule,
  DxDateBoxModule,
  DxRangeSelectorModule,
  DxMenuModule,
  DxDropDownButtonModule,
  DxToolbarModule,
  DxSchedulerModule,
  DxTextAreaModule,
  DxTagBoxModule
} from 'devextreme-angular';


const DEVEXTREME_LIB = [
  DxButtonModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxValidationSummaryModule,
  DxPopupModule,
  DxTemplateModule,
  DxSelectBoxModule,
  DxTooltipModule,
  DxSwitchModule,
  DxCheckBoxModule,
  DxTreeViewModule,
  DxDataGridModule,
  DxChartModule,
  DxFilterBuilderModule,
  DxValidationGroupModule,
  DxNumberBoxModule,
  DxDateBoxModule,
  DxRangeSelectorModule,
  DxMenuModule,
  DxDropDownButtonModule,
  DxToolbarModule,
  DxSchedulerModule,
  DxTextAreaModule,
  DxTagBoxModule
]

@NgModule({
  imports: [CommonModule, DEVEXTREME_LIB],
  exports: DEVEXTREME_LIB
})
export class DevextremeLibModule { }
