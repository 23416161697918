import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Configuration, TYPE_VALUE, Filter, CONDITION } from './filter.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnChanges {
  public TYPE_VALUE_ENUM = TYPE_VALUE;
  public labelMode = 'floating';
  public now: Date = new Date();
  public companies: any[] = [{
    id: 1,
    value: 'Super Mart of the West'
  }];
  public values: { [key: number]: any } = {};
  public filterSelected: Configuration[] = [];
  public isUniqueFilterAndHasFilter: boolean = false;

  @Input({ required: true }) filters: Configuration[] = [];
  @Input() isUniqueFilter = false;
  @Input() currentPage = 0;
  @Output() onFilter: EventEmitter<Filter[]> = new EventEmitter<Filter[]>();
  @Output() onClear: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private translateService: TranslateService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["filters"]) {
      setTimeout(() => {
        this.filters = this.filters.map((item: Configuration) => {
          return { ...item, value: this.translateService.instant(item.value) }
        });
      }, 1000);
    }
  }

  public clearButton(): boolean {
    const isDisableClearButton = [this.filterSelected.length == 0, this.currentPage == 1];
    return !isDisableClearButton.includes(false);
  }


  public getCompanySelectorLabelMode() {
    return this.labelMode === 'outside'
      ? 'hidden'
      : this.labelMode;
  }


  public onItemClick(e: any) {
    const item = e.itemData as Configuration;
    const haveNotItem = !this.filterSelected.find(x => x.id === item.id);
    const isRange = item.type_value === TYPE_VALUE.RANGE_NUMBER || item.type_value === TYPE_VALUE.RANGE_DATE || item.type_value === TYPE_VALUE.RANGE_TIME;
    if (haveNotItem) {
      if (isRange) {
        this.values[item.id] = { rangeFrom: undefined, rangeUp: undefined };
      }
      this.filterSelected.push(item);
    }

  }

  public applyFilters() {
    if (!this.filterSelected.length) return
    const getValues = this.values;
    const keysValue = Object.keys(getValues);
    const haveMoreFromOne = Object.entries(getValues).length > 1;
    let Filter: Filter[] = [];
    keysValue.forEach((key: string) => {
      const auxKey = parseInt(key);
      const [search] = this.filterSelected.filter(x => x.id === auxKey);
      if (!search) return
      if (this.values[auxKey]?.rangeFrom && this.values[auxKey]?.rangeUp) {
        Filter.push({
          type_value: search.type_value,
          condition: search.condition,
          field: search.field,
          rangeFrom: this.values[auxKey].rangeFrom,
          rangeUp: this.values[auxKey].rangeUp,
          nextCondition: haveMoreFromOne ? CONDITION.AND : undefined
        });

      } else {
        Filter.push({
          value: this.values[auxKey],
          type_value: search.type_value,
          condition: search.condition,
          field: search.field,
          nextCondition: haveMoreFromOne ? CONDITION.AND : undefined
        })
      }
    });

    this.onFilter.emit(this.clearNextCondition(Filter));
  }

  public clearNextCondition(filter: Filter[]): Filter[] {
    if (filter.length) {
      delete filter[filter.length - 1]?.nextCondition
    }
    return filter;
  }

  public deleteItem(item: Configuration) {
    this.filterSelected = this.filterSelected.filter(x => x.id != item.id);
    delete this.values[item.id];
  }

  public clear() {
    this.filterSelected = [];
    this.values = []
    this.onClear.emit(true);
  }

  public getDisplayText(value: string) {
    return this.translateService.instant(value);
  }



}

