import { Mapper } from '@management/base/management';
import { IPersonServiceDTO } from '@management/domain/management';
import { IPersonServiceEntity } from '@management/data/management';

export class PersonServiceMapper extends Mapper<IPersonServiceEntity, IPersonServiceDTO> {

    private static instance: PersonServiceMapper;

    private constructor() { super(); }

    public static getInstance(): PersonServiceMapper {
        if (!PersonServiceMapper.instance)
            PersonServiceMapper.instance = new PersonServiceMapper();
        return PersonServiceMapper.instance;
    }


    mapFrom(param: IPersonServiceEntity): IPersonServiceDTO {
        return {
            id: param.id,
            person: param.person,
            campus: param.campus,
            service: param.service,
            state: param.state
        };
    }
    mapFromList(params: IPersonServiceEntity[]): IPersonServiceDTO[] {
        return params.map((param: IPersonServiceEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IPersonServiceDTO): IPersonServiceEntity {
        return {
            id: param.id,
            person: param.person,
            campus: param.campus,
            service: param.service,
            state: param.state
        }
    }
    mapToList(params: IPersonServiceDTO[]): IPersonServiceEntity[] {
        return params.map((param: IPersonServiceDTO) => {
            return this.mapTo(param);
        })
    }
}