@if (message) {
<lib-process-popup [config]="configPopupGlobal">
    <app-popup-messages [data]="message" component></app-popup-messages>
</lib-process-popup>
}


<div *ngIf="person?.state; else onlyRouter"
    [ngClass]="{ 'wrapper__collapse':configMenu.stateMenu, 'wrapper':!configMenu.stateMenu }">

    <div class="a">
        <lib-menu [config]="configMenu"></lib-menu>
    </div>

    <div class="b">
        <div class="b__a">
            <app-menu-vertical [data]="menu" [isCollapseMenu]="isCollapseMenu"
                (onClick)="goUrl($event)"></app-menu-vertical>
        </div>
    </div>

    <div class="c">
        <div class="c__a">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>


<lib-process-popup *ngIf="person?.state" [config]="configPopupUser">
    <app-popup-user [data]="appPopupUserData" component></app-popup-user>
</lib-process-popup>

<ng-template #onlyRouter>
    <router-outlet></router-outlet>
</ng-template>