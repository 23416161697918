import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonServiceRepository,
    IDeletePersonServiceDTO,
    IDeletePersonServicePersonServiceDTO
} from '@management/domain/management';

export class DeletePersonServiceServiceUseCase implements UseCase<IDeletePersonServicePersonServiceDTO, IDeletePersonServiceDTO> {

    private static instance: DeletePersonServiceServiceUseCase;

    constructor(private deletePersonServiceRepository: PersonServiceRepository) { }

    public static getInstance(deletePersonServiceRepository: PersonServiceRepository): DeletePersonServiceServiceUseCase {
        if (!DeletePersonServiceServiceUseCase.instance)
            DeletePersonServiceServiceUseCase.instance = new DeletePersonServiceServiceUseCase(deletePersonServiceRepository);
        return DeletePersonServiceServiceUseCase.instance;
    }

    execute(
        params: IDeletePersonServicePersonServiceDTO, loadService: boolean
    ): Observable<IDeletePersonServiceDTO> {
        return this.deletePersonServiceRepository.DeletePersonServiceService(params, loadService);
    }
}