
import { Mapper } from '@management/base/management';
import { IDeleteInstallationTypeDTO } from '@management/domain/management';
import { IDeleteInstallationTypeEntity } from '@management/data/management';

export class DeleteInstallationTypeMapper extends Mapper<IDeleteInstallationTypeEntity, IDeleteInstallationTypeDTO> {

    private static instance: DeleteInstallationTypeMapper;

    private constructor() { super(); }

    public static getInstance(): DeleteInstallationTypeMapper {
        if (!DeleteInstallationTypeMapper.instance)
            DeleteInstallationTypeMapper.instance = new DeleteInstallationTypeMapper();
        return DeleteInstallationTypeMapper.instance;
    }


    mapFrom(param: IDeleteInstallationTypeEntity): IDeleteInstallationTypeDTO {
        return {
            id: param.id,
            state: param.state,
            campus: param.campus,
            name: param.name,
            homologation: param.homologation,
            description: param.description,
        };
    }
    mapFromList(params: IDeleteInstallationTypeEntity[]): IDeleteInstallationTypeDTO[] {
        return params.map((param: IDeleteInstallationTypeEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IDeleteInstallationTypeDTO): IDeleteInstallationTypeEntity {
        return {
            id: param.id,
            state: param.state,
            campus: param.campus,
            name: param.name,
            homologation: param.homologation,
            description: param.description,
        }
    }
    mapToList(params: IDeleteInstallationTypeDTO[]): IDeleteInstallationTypeEntity[] {
        return params.map((param: IDeleteInstallationTypeDTO) => {
            return this.mapTo(param);
        })
    }
}