
import { Mapper } from '@management/base/management';
import { ISavePersonInfraestructureDTO } from '@management/domain/management';
import { ISavePersonInfraestructureEntity } from '@management/data/management';

export class SavePersonInfraestructureMapper extends Mapper<ISavePersonInfraestructureEntity, ISavePersonInfraestructureDTO> {

    private static instance: SavePersonInfraestructureMapper;

    private constructor() { super(); }

    public static getInstance(): SavePersonInfraestructureMapper {
        if (!SavePersonInfraestructureMapper.instance)
            SavePersonInfraestructureMapper.instance = new SavePersonInfraestructureMapper();
        return SavePersonInfraestructureMapper.instance;
    }


    mapFrom(param: ISavePersonInfraestructureEntity): ISavePersonInfraestructureDTO {
        return {
            id: param.id,
            person: param.person,
            infrastructure: param.infrastructure
        };
    }
    mapFromList(params: ISavePersonInfraestructureEntity[]): ISavePersonInfraestructureDTO[] {
        return params.map((param: ISavePersonInfraestructureEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: ISavePersonInfraestructureDTO): ISavePersonInfraestructureEntity {
        return {
            id: param.id,
            person: param.person,
            infrastructure: param.infrastructure
        }
    }
    mapToList(params: ISavePersonInfraestructureDTO[]): ISavePersonInfraestructureEntity[] {
        return params.map((param: ISavePersonInfraestructureDTO) => {
            return this.mapTo(param);
        })
    }
}