import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DevextremeLibModule } from '@management/devextreme-lib';
import { NgxTranslateModule } from '@management/ngx-translate';
import { UiModule, UtilsComponent } from '@management/ui';
import { SharedModule } from 'apps/management/src/app/shared/shared.module';
import { Subscription } from 'rxjs';

export interface DialogDataSede {
  campusId: null | number,
  campusList: any,
  onSelected: (campusId: number) => void
}

@Component({
  selector: 'app-dialog-select-sede',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgxTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DevextremeLibModule,
    UiModule
  ],
  templateUrl: './dialog-select-sede.component.html',
  styleUrls: ['./dialog-select-sede.component.scss']
})
export class DialogSelectSedeComponent extends UtilsComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  form!: FormGroup;

  @Input() data!: DialogDataSede;

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.initForm();
  }


  private initForm() {

    let buildForm = {
      'campusId': [
        this.data.campusId,
        Validators.compose([
          Validators.required
        ])
      ],
    };
    this.form = this.fb.group(buildForm);
  }



  submitData() {
    this.data.onSelected(this.form.value.campusId);
  }


}
