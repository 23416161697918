import { Observable } from "rxjs";
//domain
import {
    InstallationTypeRepository,
    IInstallationTypeDTO,
    IInstallationTypeSaveInstallationTypeDTO,
    SetSaveInstallationTypeServiceUseCase,
    IInstallationTypesDTO,
    IInstallationTypesInstallationTypesDTO,
    GetInstallationTypesServiceUseCase,
    IUpdateInstallationTypeDTO,
    IUpdateInstallationTypeInstallationTypeDTO,
    UpdateInstallationTypeServiceUseCase,
    IDeleteInstallationTypeDTO,
    IDeleteInstallationTypeInstallationTypeDTO,
    DeleteInstallationTypeServiceUseCase,
} from "@management/domain/management";
import { PagedService } from "@management/base/management";


export class InstallationTypeFacade extends InstallationTypeRepository {

    private static instance: InstallationTypeFacade;

    constructor(private installationTypeRepository: InstallationTypeRepository) {
        super();
    }

    public static getInstance(installationTypeRepository: InstallationTypeRepository): InstallationTypeFacade {
        if (!InstallationTypeFacade.instance)
            InstallationTypeFacade.instance = new InstallationTypeFacade(installationTypeRepository);
        return InstallationTypeFacade.instance;
    }

    SetSaveInstallationTypeService(params: IInstallationTypeSaveInstallationTypeDTO, loadService: boolean): Observable<IInstallationTypeDTO> {
        return SetSaveInstallationTypeServiceUseCase.getInstance(this.installationTypeRepository).execute(params, loadService);
    }

    GetInstallationTypesService(params: IInstallationTypesInstallationTypesDTO, loadService: boolean): Observable<PagedService<IInstallationTypesDTO[]>> {
        return GetInstallationTypesServiceUseCase.getInstance(this.installationTypeRepository).execute(params, loadService);
    }

    UpdateInstallationTypeService(params: IUpdateInstallationTypeInstallationTypeDTO, loadService: boolean): Observable<IUpdateInstallationTypeDTO> {
        return UpdateInstallationTypeServiceUseCase.getInstance(this.installationTypeRepository).execute(params, loadService);
    }

    DeleteInstallationTypeService(params: IDeleteInstallationTypeInstallationTypeDTO, loadService: boolean): Observable<IDeleteInstallationTypeDTO> {
        return DeleteInstallationTypeServiceUseCase.getInstance(this.installationTypeRepository).execute(params, loadService);
    }
    
}