
import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import { AuthRepository, ICompanySelectedDTO, ICompanySelectedRequestDTO } from '@management/domain/management';


export class GetCompanySelectedUsecase implements UseCase<ICompanySelectedRequestDTO, ICompanySelectedDTO> {
    private static instance: GetCompanySelectedUsecase;
    constructor(private authRepository: AuthRepository) { }
    public static getInstance(authRepository: AuthRepository): GetCompanySelectedUsecase {
        if (!GetCompanySelectedUsecase.instance)
            GetCompanySelectedUsecase.instance = new GetCompanySelectedUsecase(authRepository);
        return GetCompanySelectedUsecase.instance;
    }
    execute(
        params: ICompanySelectedRequestDTO, loadService: boolean, loadSessionStorange: boolean
    ): Observable<ICompanySelectedDTO> {
        return this.authRepository.getCompanySelected(params, loadService, loadSessionStorange)
    }
}