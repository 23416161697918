import { Injectable } from '@angular/core';
import { Action, Store } from "@ngrx/store";

import {
    environment,
    HttpClient,
    map,
    Observable,
    of,
    ResolveRequest,
    keysSessionStorangeEnum
} from '@management/base/management';
//domain
import {
    PersonCampusRepository,
    IPersonCampusDTO,
    IPersonCampusPersonCampusDTO,
} from '@management/domain/management';
//data
import {
    IPersonCampusEntity,
    PersonCampusMapper,
} from '@management/data/management';

import {
    actionsPersonCampus,
    reducerPersonCampus,
    selectorsPersonCampus
} from './redux';


@Injectable({
    providedIn: 'root',
})
export class PersonCampusImplementationRepository extends PersonCampusRepository {

    private readonly urlApiRest: string = environment.api.urlApiRest;
    private readonly personCampusMapper = PersonCampusMapper.getInstance();

    constructor(
        private http: HttpClient,
        private resolveRequest: ResolveRequest,
        private store: Store<reducerPersonCampus.PersonCampusModel>) {
        super();
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    SetPersonCampusService(params: IPersonCampusPersonCampusDTO, loadService: boolean): Observable<IPersonCampusDTO> {
        return this.http
            .post<IPersonCampusEntity>(`${this.urlApiRest}PersonCampus`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IPersonCampusEntity>(response);
                return res;
            }))
            .pipe(map((entity: IPersonCampusEntity) => entity && this.personCampusMapper.mapFrom(entity)))
    }

}