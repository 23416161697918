
import { Mapper } from '@management/base/management';
import { ICompanyCampusIdentificationDTO } from '@management/domain/management';
import { ICompanyCampusIdentificationEntity } from '@management/data/management';

export class CompanyCampusIdentificationMapper extends Mapper<ICompanyCampusIdentificationEntity, ICompanyCampusIdentificationDTO> {

    private static instance: CompanyCampusIdentificationMapper;

    private constructor() { super(); }

    public static getInstance(): CompanyCampusIdentificationMapper {
        if (!CompanyCampusIdentificationMapper.instance)
            CompanyCampusIdentificationMapper.instance = new CompanyCampusIdentificationMapper();
        return CompanyCampusIdentificationMapper.instance;
    }


    mapFrom(param: ICompanyCampusIdentificationEntity): ICompanyCampusIdentificationDTO {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number,
            currency_name: param.currency_name,
            language_name: param.language_name,
            rol: param.rol,
            rol_name: param.rol_name
        };
    }
    mapFromList(params: ICompanyCampusIdentificationEntity[]): ICompanyCampusIdentificationDTO[] {
        return params.map((param: ICompanyCampusIdentificationEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: ICompanyCampusIdentificationDTO): ICompanyCampusIdentificationEntity {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number,
            currency_name: param.currency_name,
            language_name: param.language_name,
            rol: param.rol,
            rol_name: param.rol_name
        }
    }
    mapToList(params: ICompanyCampusIdentificationDTO[]): ICompanyCampusIdentificationEntity[] {
        return params.map((param: ICompanyCampusIdentificationDTO) => {
            return this.mapTo(param);
        })
    }
}