import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
//Imports Generator
import { AssignmentRepository } from '@management/domain/management';
import { AssignmentImplementationRepository } from '@management/data/management';
import { AssignmentFacade } from '@management/facade/management';
import { AssignmentEffects, reducerAssignment } from './repositories/assignment/redux/index';

import { PersonInfraestructureRepository } from '@management/domain/management';
import { PersonInfraestructureImplementationRepository } from '@management/data/management';
import { PersonInfraestructureFacade } from '@management/facade/management';
import { PersonInfraestructureEffects, reducerPersonInfraestructure } from './repositories/person-infraestructure/redux/index';

import { PersonServiceRepository } from '@management/domain/management';
import { PersonServiceImplementationRepository } from '@management/data/management';
import { PersonServiceFacade } from '@management/facade/management';
import { PersonServiceEffects, reducerPersonService } from './repositories/person-service/redux/index';

import { ServiceCampusRepository } from '@management/domain/management';
import { ServiceCampusImplementationRepository } from '@management/data/management';
import { ServiceCampusFacade } from '@management/facade/management';
import { ServiceCampusEffects, reducerServiceCampus } from './repositories/service-campus/redux/index';

import { ServiceRepository } from '@management/domain/management';
import { ServiceImplementationRepository } from '@management/data/management';
import { ServiceFacade } from '@management/facade/management';
import { ServiceEffects, reducerService } from './repositories/service/redux/index';

import { ScheduleRepository } from '@management/domain/management';
import { ScheduleImplementationRepository } from '@management/data/management';
import { ScheduleFacade } from '@management/facade/management';
import { ScheduleEffects, reducerSchedule } from './repositories/schedule/redux/index';

import { RolRepository } from '@management/domain/management';
import { RolImplementationRepository } from '@management/data/management';
import { RolFacade } from '@management/facade/management';
import { RolEffects, reducerRol } from './repositories/rol/redux/index';

import { PersonCampusRepository } from '@management/domain/management';
import { PersonCampusImplementationRepository } from '@management/data/management';
import { PersonCampusFacade } from '@management/facade/management';
import { PersonCampusEffects, reducerPersonCampus } from './repositories/person-campus/redux/index';

import { PersonCompanyRepository } from '@management/domain/management';
import { PersonCompanyImplementationRepository } from '@management/data/management';
import { PersonCompanyFacade } from '@management/facade/management';
import { PersonCompanyEffects, reducerPersonCompany } from './repositories/person-company/redux/index';

import { LanguageRepository } from '@management/domain/management';
import { LanguageImplementationRepository } from '@management/data/management';
import { LanguageFacade } from '@management/facade/management';
import { LanguageEffects, reducerLanguage } from './repositories/language/redux/index';

import { CurrencyRepository } from '@management/domain/management';
import { CurrencyImplementationRepository } from '@management/data/management';
import { CurrencyFacade } from '@management/facade/management';
import { CurrencyEffects, reducerCurrency } from './repositories/currency/redux/index';

import { PersonRepository } from '@management/domain/management';
import { PersonImplementationRepository } from '@management/data/management';
import { PersonFacade } from '@management/facade/management';
import { PersonEffects, reducerPerson } from './repositories/person/redux/index';

import { InfrastructureRepository } from '@management/domain/management';
import { InfrastructureImplementationRepository } from '@management/data/management';
import { InfrastructureFacade } from '@management/facade/management';
import { InfrastructureEffects, reducerInfrastructure } from './repositories/infrastructure/redux/index';

import { AuthRepository, MenuRepository } from '@management/domain/management';
import {
  AuthImplementationRepository,
  MenuImplementationRepository,
} from '@management/data/management';
import { AuthFacade } from '@management/facade/management';
import { AuthEffects, reducerAuth } from './repositories/auth/redux/index';
import * as MenuReducer from './repositories/menu/redux/menu.reducer';
import { MenuFacade } from '../facade';
import { MenuEffects } from './repositories/menu/redux/menu.effects';
import { InstallationTypeRepository } from '@management/domain/management';
import { InstallationTypeImplementationRepository } from '@management/data/management';
import { InstallationTypeFacade } from '@management/facade/management';
import {
  InstallationTypeEffects,
  reducerInstallationType,
} from './repositories/installation-type/redux/index';

const AuthFacadeFactory = (authRepo: AuthRepository) =>
  AuthFacade.getInstance(authRepo);
export const AuthFacadeProvider = {
  provide: AuthFacade,
  useFactory: AuthFacadeFactory,
  deps: [AuthRepository],
};

const AssignmentFacadeFactory =
  (assignmentRepo: AssignmentRepository) => AssignmentFacade.getInstance(assignmentRepo);
export const AssignmentFacadeProvider = {
  provide: AssignmentFacade,
  useFactory: AssignmentFacadeFactory,
  deps: [AssignmentRepository]
};

const PersonInfraestructureFacadeFactory =
  (person_infraestructureRepo: PersonInfraestructureRepository) => PersonInfraestructureFacade.getInstance(person_infraestructureRepo);
export const PersonInfraestructureFacadeProvider = {
  provide: PersonInfraestructureFacade,
  useFactory: PersonInfraestructureFacadeFactory,
  deps: [PersonInfraestructureRepository]
};


const PersonServiceFacadeFactory =
  (person_serviceRepo: PersonServiceRepository) => PersonServiceFacade.getInstance(person_serviceRepo);
export const PersonServiceFacadeProvider = {
  provide: PersonServiceFacade,
  useFactory: PersonServiceFacadeFactory,
  deps: [PersonServiceRepository]
};

const ServiceCampusFacadeFactory =
  (service_campusRepo: ServiceCampusRepository) => ServiceCampusFacade.getInstance(service_campusRepo);
export const ServiceCampusFacadeProvider = {
  provide: ServiceCampusFacade,
  useFactory: ServiceCampusFacadeFactory,
  deps: [ServiceCampusRepository]
};

const ServiceFacadeFactory =
  (serviceRepo: ServiceRepository) => ServiceFacade.getInstance(serviceRepo);
export const ServiceFacadeProvider = {
  provide: ServiceFacade,
  useFactory: ServiceFacadeFactory,
  deps: [ServiceRepository]
};

const ScheduleFacadeFactory =
  (scheduleRepo: ScheduleRepository) => ScheduleFacade.getInstance(scheduleRepo);
export const ScheduleFacadeProvider = {
  provide: ScheduleFacade,
  useFactory: ScheduleFacadeFactory,
  deps: [ScheduleRepository]
};

const RolFacadeFactory =
  (rolRepo: RolRepository) => RolFacade.getInstance(rolRepo);
export const RolFacadeProvider = {
  provide: RolFacade,
  useFactory: RolFacadeFactory,
  deps: [RolRepository]
};

const PersonCampusFacadeFactory =
  (person_campusRepo: PersonCampusRepository) => PersonCampusFacade.getInstance(person_campusRepo);
export const PersonCampusFacadeProvider = {
  provide: PersonCampusFacade,
  useFactory: PersonCampusFacadeFactory,
  deps: [PersonCampusRepository]
};

const PersonCompanyFacadeFactory =
  (person_companyRepo: PersonCompanyRepository) => PersonCompanyFacade.getInstance(person_companyRepo);
export const PersonCompanyFacadeProvider = {
  provide: PersonCompanyFacade,
  useFactory: PersonCompanyFacadeFactory,
  deps: [PersonCompanyRepository]
};

const LanguageFacadeFactory =
  (languageRepo: LanguageRepository) => LanguageFacade.getInstance(languageRepo);
export const LanguageFacadeProvider = {
  provide: LanguageFacade,
  useFactory: LanguageFacadeFactory,
  deps: [LanguageRepository]
};

const CurrencyFacadeFactory =
  (currencyRepo: CurrencyRepository) => CurrencyFacade.getInstance(currencyRepo);
export const CurrencyFacadeProvider = {
  provide: CurrencyFacade,
  useFactory: CurrencyFacadeFactory,
  deps: [CurrencyRepository]
};

const PersonFacadeFactory =
  (personRepo: PersonRepository) => PersonFacade.getInstance(personRepo);
export const PersonFacadeProvider = {
  provide: PersonFacade,
  useFactory: PersonFacadeFactory,
  deps: [PersonRepository]
};

const InfrastructureFacadeFactory =
  (infrastructureRepo: InfrastructureRepository) => InfrastructureFacade.getInstance(infrastructureRepo);
export const InfrastructureFacadeProvider = {
  provide: InfrastructureFacade,
  useFactory: InfrastructureFacadeFactory,
  deps: [InfrastructureRepository]
};

const InstallationTypeFacadeFactory = (
  installation_typeRepo: InstallationTypeRepository
) => InstallationTypeFacade.getInstance(installation_typeRepo);
export const InstallationTypeFacadeProvider = {
  provide: InstallationTypeFacade,
  useFactory: InstallationTypeFacadeFactory,
  deps: [InstallationTypeRepository],
};

const menuFacadeFactory = (menuRepo: MenuRepository) =>
  new MenuFacade(menuRepo);
export const menuFacadeProvider = {
  provide: MenuFacade,
  useFactory: menuFacadeFactory,
  deps: [MenuRepository],
};

const PROVIDERS = [
  AuthFacadeProvider,
  { provide: AuthRepository, useClass: AuthImplementationRepository },
  AssignmentFacadeProvider,
  { provide: AssignmentRepository, useClass: AssignmentImplementationRepository },
  PersonInfraestructureFacadeProvider,
  { provide: PersonInfraestructureRepository, useClass: PersonInfraestructureImplementationRepository },
  PersonServiceFacadeProvider,
  { provide: PersonServiceRepository, useClass: PersonServiceImplementationRepository },
  ServiceCampusFacadeProvider,
  { provide: ServiceCampusRepository, useClass: ServiceCampusImplementationRepository },
  ServiceFacadeProvider,
  { provide: ServiceRepository, useClass: ServiceImplementationRepository },
  ScheduleFacadeProvider,
  { provide: ScheduleRepository, useClass: ScheduleImplementationRepository },
  RolFacadeProvider,
  { provide: RolRepository, useClass: RolImplementationRepository },
  PersonCampusFacadeProvider,
  { provide: PersonCampusRepository, useClass: PersonCampusImplementationRepository },
  PersonCompanyFacadeProvider,
  { provide: PersonCompanyRepository, useClass: PersonCompanyImplementationRepository },
  LanguageFacadeProvider,
  { provide: LanguageRepository, useClass: LanguageImplementationRepository },
  CurrencyFacadeProvider,
  { provide: CurrencyRepository, useClass: CurrencyImplementationRepository },
  PersonFacadeProvider,
  { provide: PersonRepository, useClass: PersonImplementationRepository },
  InfrastructureFacadeProvider,
  { provide: InfrastructureRepository, useClass: InfrastructureImplementationRepository },
  InstallationTypeFacadeProvider,
  { provide: InstallationTypeRepository, useClass: InstallationTypeImplementationRepository },
  menuFacadeProvider,
  { provide: MenuRepository, useClass: MenuImplementationRepository }
]


const IMPORTS = [
  CommonModule,
  HttpClientModule,
  StoreModule.forFeature(reducerAuth.AUTH_KEY, reducerAuth.AuthReducer),
  StoreModule.forFeature(MenuReducer.MENU_KEY, MenuReducer.MenuReducer),
  StoreModule.forFeature(
    reducerInstallationType.INSTALLATION_TYPE_KEY,
    reducerInstallationType.InstallationTypeReducer
  ),
  EffectsModule.forFeature([
    AuthEffects,
    AssignmentEffects,
    PersonInfraestructureEffects,
    PersonServiceEffects,
    ServiceCampusEffects,
    ServiceEffects,
    ScheduleEffects,
    RolEffects,
    PersonCampusEffects,
    PersonCompanyEffects,
    LanguageEffects,
    CurrencyEffects,
    PersonEffects,
    InstallationTypeEffects,
    MenuEffects,
    InfrastructureEffects
  ]),
  StoreModule.forFeature(
    reducerAssignment.ASSIGNMENT_KEY,
    reducerAssignment.AssignmentReducer
  ),
  StoreModule.forFeature(
    reducerPersonInfraestructure.PERSON_INFRAESTRUCTURE_KEY,
    reducerPersonInfraestructure.PersonInfraestructureReducer
  ),
  StoreModule.forFeature(
    reducerPersonService.PERSON_SERVICE_KEY,
    reducerPersonService.PersonServiceReducer
  ),
  StoreModule.forFeature(
    reducerServiceCampus.SERVICE_CAMPUS_KEY,
    reducerServiceCampus.ServiceCampusReducer
  ),
  StoreModule.forFeature(
    reducerService.SERVICE_KEY,
    reducerService.ServiceReducer
  ),
  StoreModule.forFeature(
    reducerSchedule.SCHEDULE_KEY,
    reducerSchedule.ScheduleReducer
  ),
  StoreModule.forFeature(
    reducerRol.ROL_KEY,
    reducerRol.RolReducer
  ),
  StoreModule.forFeature(
    reducerPersonCampus.PERSON_CAMPUS_KEY,
    reducerPersonCampus.PersonCampusReducer
  ),
  StoreModule.forFeature(
    reducerPersonCompany.PERSON_COMPANY_KEY,
    reducerPersonCompany.PersonCompanyReducer
  ),
  StoreModule.forFeature(
    reducerLanguage.LANGUAGE_KEY,
    reducerLanguage.LanguageReducer
  ),
  StoreModule.forFeature(
    reducerCurrency.CURRENCY_KEY,
    reducerCurrency.CurrencyReducer
  ),
  StoreModule.forFeature(
    reducerPerson.PERSON_KEY,
    reducerPerson.PersonReducer
  ),
  StoreModule.forFeature(
    reducerInfrastructure.INFRASTRUCTURE_KEY,
    reducerInfrastructure.InfrastructureReducer
  )
];

@NgModule({
  providers: PROVIDERS,
  imports: IMPORTS,
})
export class DataModule { }
