import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    InfrastructureRepository,
    IUpdateInfrastructureDTO,
    IUpdateInfrastructurePutEditInfrastructureDTO
} from '@management/domain/management';

export class UpdatePutEditInfrastructureServiceUseCase implements UseCase<IUpdateInfrastructurePutEditInfrastructureDTO, IUpdateInfrastructureDTO> {

    private static instance: UpdatePutEditInfrastructureServiceUseCase;

    constructor(private updateInfrastructureRepository: InfrastructureRepository) { }

    public static getInstance(updateInfrastructureRepository: InfrastructureRepository): UpdatePutEditInfrastructureServiceUseCase {
        if (!UpdatePutEditInfrastructureServiceUseCase.instance)
            UpdatePutEditInfrastructureServiceUseCase.instance = new UpdatePutEditInfrastructureServiceUseCase(updateInfrastructureRepository);
        return UpdatePutEditInfrastructureServiceUseCase.instance;
    }

    execute(
        params: IUpdateInfrastructurePutEditInfrastructureDTO, loadService: boolean
    ): Observable<IUpdateInfrastructureDTO> {
        return this.updateInfrastructureRepository.UpdatePutEditInfrastructureService(params, loadService);
    }
}