import { Mapper } from '@management/base/management';
import { IPersonDTO } from '@management/domain/management';
import { IPersonEntity } from '@management/data/management';

export class PersonMapper extends Mapper<IPersonEntity, IPersonDTO> {

    private static instance: PersonMapper;

    private constructor() { super(); }

    public static getInstance(): PersonMapper {
        if (!PersonMapper.instance)
            PersonMapper.instance = new PersonMapper();
        return PersonMapper.instance;
    }


    mapFrom(param: IPersonEntity): IPersonDTO {
        return {
            currency: param.currency,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            language: param.language,
            id: param.id,
            state: param.state,
            name: param.name,
            cellPhone: param.cellPhone,
            email: param.email,
            password: param.password,
            identification_number: param.identification_number
        };
    }
    mapFromList(params: IPersonEntity[]): IPersonDTO[] {
        return params.map((param: IPersonEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IPersonDTO): IPersonEntity {
        return {
            currency: param.currency,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            language: param.language,
            id: param.id,
            state: param.state,
            name: param.name,
            cellPhone: param.cellPhone,
            email: param.email,
            password: param.password,
            identification_number: param.identification_number
        }
    }
    mapToList(params: IPersonDTO[]): IPersonEntity[] {
        return params.map((param: IPersonDTO) => {
            return this.mapTo(param);
        })
    }
}