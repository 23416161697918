import { actionsCurrency } from './index';
import { createReducer, on } from '@ngrx/store';
import {  } from "@management/domain/management";

export const CURRENCY_KEY = 'currency'
export interface CurrencyModel {
}

export const CurrencyInitialState: CurrencyModel = {
}

const _CurrencyReducer = createReducer(CurrencyInitialState,
);

export function CurrencyReducer(state: CurrencyModel, action: any) {
    return _CurrencyReducer(state, action);
}