import { Observable } from "rxjs";
//domain
import {
    ServiceCampusRepository,
    IServiceCampusDTO,
    IServiceCampusServiceCampusDTO,
    SetServiceCampusServiceUseCase,
    IServiceCampusDeleteDTO,
    IServiceCampusDeleteServiceCampusDeleteDTO,
    DeleteServiceCampusDeleteServiceUseCase,
    IServiceCampusPutDTO,
    IServiceCampusPutServiceCampusDTO,
    UpdateServiceCampusServiceUseCase,
} from "@management/domain/management";


export class ServiceCampusFacade extends ServiceCampusRepository {

    private static instance: ServiceCampusFacade;

    constructor(private serviceCampusRepository: ServiceCampusRepository) {
        super();
    }

    public static getInstance(serviceCampusRepository: ServiceCampusRepository): ServiceCampusFacade {
        if (!ServiceCampusFacade.instance)
            ServiceCampusFacade.instance = new ServiceCampusFacade(serviceCampusRepository);
        return ServiceCampusFacade.instance;
    }

    SetServiceCampusService(params: IServiceCampusServiceCampusDTO, loadService: boolean): Observable<IServiceCampusDTO> {
        return SetServiceCampusServiceUseCase.getInstance(this.serviceCampusRepository).execute(params, loadService);
    }

    DeleteServiceCampusDeleteService(params: IServiceCampusDeleteServiceCampusDeleteDTO, loadService: boolean): Observable<IServiceCampusDeleteDTO> {
        return DeleteServiceCampusDeleteServiceUseCase.getInstance(this.serviceCampusRepository).execute(params, loadService);
    }
    
    UpdateServiceCampusService(params: IServiceCampusPutServiceCampusDTO, loadService: boolean): Observable<IServiceCampusPutDTO> {
        return UpdateServiceCampusServiceUseCase.getInstance(this.serviceCampusRepository).execute(params, loadService);
    }
}