import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonRepository,
    IPersonCampusRolDTO,
    IPersonCampusRolPersonCompanyCampusRolDTO
} from '@management/domain/management';

export class GetPersonCompanyCampusRolServiceUseCase implements UseCase<IPersonCampusRolPersonCompanyCampusRolDTO, IPersonCampusRolDTO[]> {

    private static instance: GetPersonCompanyCampusRolServiceUseCase;

    constructor(private personCampusRolRepository: PersonRepository) { }

    public static getInstance(personCampusRolRepository: PersonRepository): GetPersonCompanyCampusRolServiceUseCase {
        if (!GetPersonCompanyCampusRolServiceUseCase.instance)
            GetPersonCompanyCampusRolServiceUseCase.instance = new GetPersonCompanyCampusRolServiceUseCase(personCampusRolRepository);
        return GetPersonCompanyCampusRolServiceUseCase.instance;
    }

    execute(
        params: IPersonCampusRolPersonCompanyCampusRolDTO, loadService: boolean
    ): Observable<IPersonCampusRolDTO[]> {
        return this.personCampusRolRepository.GetPersonCompanyCampusRolService(params, loadService);
    }
}