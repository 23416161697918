import { UseCase } from '@management/base/management';
import { AuthRepository, ICampusByCompanyDTO } from '@management/domain/management';

export class SetCampusByCompanyReduxUseCase implements UseCase<ICampusByCompanyDTO[], void> {
    private static instance: SetCampusByCompanyReduxUseCase;
    constructor(private authRepository: AuthRepository) { }
    public static getInstance(authRepository: AuthRepository): SetCampusByCompanyReduxUseCase {
        if (!SetCampusByCompanyReduxUseCase.instance)
            SetCampusByCompanyReduxUseCase.instance = new SetCampusByCompanyReduxUseCase(authRepository);
        return SetCampusByCompanyReduxUseCase.instance;
    }
    execute(
        campusByCompany: ICampusByCompanyDTO[]
    ): void {
        return this.authRepository.setCampusByCompanyRedux(campusByCompany);
    }
}