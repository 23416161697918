
import { Observable } from 'rxjs';
//domain
import {
    IAssignmentAssignmentDTO,
    IAssignmentDTO,
} from '@management/domain/management';
import { UtilsComponent } from '@management/ui';
import { selector } from '@management/base/management';

export abstract class AssignmentRepository extends UtilsComponent {
    abstract SetAssignmentService(params: IAssignmentAssignmentDTO, loadService: boolean): Observable<IAssignmentDTO>;
}