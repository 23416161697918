
import { Observable } from 'rxjs';
//domain
import {
    IPersonInfraestructurePersonInfraestructuresDTO,
    IPersonInfraestructureDTO,
    ISavePersonInfraestructurePersonInfraestructureDTO,
    ISavePersonInfraestructureDTO,
    IPersonInfraestructureDeletePersonInfraestructureDTO,
    IPersonInfraestructureDeleteDTO,
} from '@management/domain/management';
import { UtilsComponent } from '@management/ui';
import { selector } from '@management/base/management';

export abstract class PersonInfraestructureRepository extends UtilsComponent {
    abstract DeletePersonInfraestructureService(params: IPersonInfraestructureDeletePersonInfraestructureDTO, loadService: boolean): Observable<IPersonInfraestructureDeleteDTO>;
    abstract SetPersonInfraestructureService(params: ISavePersonInfraestructurePersonInfraestructureDTO, loadService: boolean): Observable<ISavePersonInfraestructureDTO>;
    abstract GetPersonInfraestructuresService(params: IPersonInfraestructurePersonInfraestructuresDTO, loadService: boolean): Observable<IPersonInfraestructureDTO[]>;
}