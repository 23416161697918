import { ICampusByCompanyEntity } from '@management/data/management';
import { UseCase } from '@management/base/management';
import { AuthRepository } from '@management/domain/management';



export class SetCampusByCompanySS implements UseCase< ICampusByCompanyEntity[], void> {
    private static instance: SetCampusByCompanySS;
    
    constructor(private authRepository: AuthRepository) { }
    
    public static getInstance(authRepository: AuthRepository): SetCampusByCompanySS {
        if (!SetCampusByCompanySS.instance)
            SetCampusByCompanySS.instance = new SetCampusByCompanySS(authRepository);
        return SetCampusByCompanySS.instance;
    }
    
    execute(params:  ICampusByCompanyEntity[]): void {
        this.authRepository.setCampusByCompanySS(params);
    }
}