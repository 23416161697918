import { Observable } from "rxjs";
//domain
import {
    InfrastructureRepository,
    IInfrastructureDTO,
    IInfrastructureGetInfrastructureByCampusDTO,
    GetInfrastructureByCampusServiceUseCase,
    ISaveInfrastructureDTO,
    ISaveInfrastructureSaveInfrastructureDTO,
    SetSaveInfrastructureServiceUseCase,
    IUpdateInfrastructureDTO,
    IUpdateInfrastructurePutEditInfrastructureDTO,
    UpdatePutEditInfrastructureServiceUseCase,
    IDeleteInfrastructureDTO,
    IDeleteInfrastructureInfrastructureDTO,
    DeleteInfrastructureServiceUseCase,
    IInfrastructuresDTO,
    IInfrastructuresInfrastructuresDTO,
    GetInfrastructuresServiceUseCase,
} from "@management/domain/management";


export class InfrastructureFacade extends InfrastructureRepository {

    private static instance: InfrastructureFacade;

    constructor(private infrastructureRepository: InfrastructureRepository) {
        super();
    }

    public static getInstance(infrastructureRepository: InfrastructureRepository): InfrastructureFacade {
        if (!InfrastructureFacade.instance)
            InfrastructureFacade.instance = new InfrastructureFacade(infrastructureRepository);
        return InfrastructureFacade.instance;
    }

    GetInfrastructureByCampusService(params: IInfrastructureGetInfrastructureByCampusDTO, loadService: boolean): Observable<IInfrastructureDTO[]> {
        return GetInfrastructureByCampusServiceUseCase.getInstance(this.infrastructureRepository).execute(params, loadService);
    }

    SetSaveInfrastructureService(params: ISaveInfrastructureSaveInfrastructureDTO, loadService: boolean): Observable<ISaveInfrastructureDTO> {
        return SetSaveInfrastructureServiceUseCase.getInstance(this.infrastructureRepository).execute(params, loadService);
    }
    
    UpdatePutEditInfrastructureService(params: IUpdateInfrastructurePutEditInfrastructureDTO, loadService: boolean): Observable<IUpdateInfrastructureDTO> {
        return UpdatePutEditInfrastructureServiceUseCase.getInstance(this.infrastructureRepository).execute(params, loadService);
    }
    
    DeleteInfrastructureService(params: IDeleteInfrastructureInfrastructureDTO, loadService: boolean): Observable<IDeleteInfrastructureDTO> {
        return DeleteInfrastructureServiceUseCase.getInstance(this.infrastructureRepository).execute(params, loadService);
    }
    GetInfrastructuresService(params: IInfrastructuresInfrastructuresDTO, loadService: boolean): Observable<IInfrastructuresDTO[]> {
        return GetInfrastructuresServiceUseCase.getInstance(this.infrastructureRepository).execute(params, loadService);
    }
}