import { Observable } from 'rxjs';
                import { UseCase } from '@management/base/management';
                import {
                    ServiceRepository,
                    IServicesDTO,
                    IServicesServicesDTO
                } from '@management/domain/management';

export class GetServicesServiceUseCase implements UseCase<IServicesServicesDTO, IServicesDTO[]> {

    private static instance: GetServicesServiceUseCase;

    constructor(private servicesRepository: ServiceRepository) { }

    public static getInstance(servicesRepository: ServiceRepository): GetServicesServiceUseCase {
        if (!GetServicesServiceUseCase.instance)
            GetServicesServiceUseCase.instance = new GetServicesServiceUseCase(servicesRepository);
        return GetServicesServiceUseCase.instance;
    }

    execute(
        params: IServicesServicesDTO, loadService: boolean
    ): Observable<IServicesDTO[]> {
        return this.servicesRepository.GetServicesService(params, loadService);
    }
}