


import { UtilsComponent } from "@management/ui";
import { first, skip } from 'rxjs';
import { AuthFacade, MenuFacade } from "@management/facade/management";
import { keysSessionStorangeEnum } from "@management/base/management";


export const initDataPlatformRedux = (utilsComponent: UtilsComponent, _authFacade: AuthFacade): void => {
    // coreFacade.getListCountry();
}

export const initDataPlatformReduxReload = (utilsComponent: UtilsComponent, _authFacade: AuthFacade, _menuFacade: MenuFacade): void => {

    let userCompanySelected = utilsComponent.getSessionStorange(keysSessionStorangeEnum.userCompanySelected);
    let user = utilsComponent.getSessionStorange(keysSessionStorangeEnum.user);
    let listCampusByCompany = utilsComponent.getSessionStorange(keysSessionStorangeEnum.campusByCompany);
    let recursiveMenu = utilsComponent.getSessionStorange(keysSessionStorangeEnum.recursiveMenu);

    if (!user) return
    _authFacade.setUserReduxUseCase(user);
    if (userCompanySelected)
        _authFacade.setCompanySelectedRedux(userCompanySelected);
    if (listCampusByCompany)
        _authFacade.setCampusByCompanyRedux(listCampusByCompany);
    if (recursiveMenu)
        _menuFacade.setRecursiveMenuRedux(recursiveMenu);
}




