import { actionsPerson } from './index';
import { createReducer, on } from '@ngrx/store';
import {  } from "@management/domain/management";

export const PERSON_KEY = 'person'
export interface PersonModel {
}

export const PersonInitialState: PersonModel = {
}

const _PersonReducer = createReducer(PersonInitialState,
);

export function PersonReducer(state: PersonModel, action: any) {
    return _PersonReducer(state, action);
}