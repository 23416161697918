import { Observable } from 'rxjs';
                import { UseCase } from '@management/base/management';
                import {
                    RolRepository,
                    IRolDTO,
                    IRolRolDTO
                } from '@management/domain/management';

export class GetRolServiceUseCase implements UseCase<IRolRolDTO, IRolDTO[]> {

    private static instance: GetRolServiceUseCase;

    constructor(private rolRepository: RolRepository) { }

    public static getInstance(rolRepository: RolRepository): GetRolServiceUseCase {
        if (!GetRolServiceUseCase.instance)
            GetRolServiceUseCase.instance = new GetRolServiceUseCase(rolRepository);
        return GetRolServiceUseCase.instance;
    }

    execute(
        params: IRolRolDTO, loadService: boolean
    ): Observable<IRolDTO[]> {
        return this.rolRepository.GetRolService(params, loadService);
    }
}