<div class="container">
  <div>Bienvenido a tu espacio de trabajo {{nameUser}}</div>
</div>

<lib-process-popup [config]="configPopupCompany">
  <app-dialog-select-company [data]="popupDataCompany" component>
  </app-dialog-select-company>
</lib-process-popup>


<lib-process-popup [config]="configPopupCampus">
  <app-dialog-select-sede [data]="popupDataCampus" component>
  </app-dialog-select-sede>
</lib-process-popup>

