<div class="container">
    <div class="container__a">
        <h3>Personal</h3>
        <small>Organiza todo tu personal</small>
        <small>Tambien Puedes crear la cuenta a tus usuarios para que se unan a Connecion.</small>
    </div>
    <div class="container__b">
        <div class="container__b__a__a__b">

            <dx-button stylingMode="contained" text="{{'Añadir colaborador' | translate }}" type="default" width="200"
                [disabled]="false" (click)="createPerson()">
            </dx-button>

            <div>
                <span id="help-tooltip" class="material-symbols-rounded icon-tooltip">
                    help
                </span>
                <dx-tooltip target="#help-tooltip" showEvent="mouseenter" hideEvent="mouseleave"
                    [hideOnOutsideClick]="false" position="right" width="200">
                    <div class="dx-tooltip-text" *dxTemplate="let data = data; of: 'content'">{{'Un colaborador
                        lo prodras asignar a tu infraestructura y gestionar muchas cosas mas.'}}</div>
                </dx-tooltip>
            </div>
        </div>
    </div>

    <div class="container__d">
        <app-filter [filters]="filters" [isUniqueFilter]="true" [currentPage]="onDemandPagination.currentPage" (onFilter)="onFilter($event)"
            (onClear)="onClear($event, true)"></app-filter>
    </div>
    <div class="container__c">
        <dx-data-grid id="gridContainer" [dataSource]="persons" keyExpr="id" [showBorders]="true"
            [focusedRowEnabled]="false" [height]="300" [columnAutoWidth]="false" [columnHidingEnabled]="true"
            [showBorders]="true">
            

            <dxi-column dataField="id" caption="{{'Id' | translate}}" [width]="50"></dxi-column>
            <dxi-column dataField="name" caption="{{'Nombre' | translate}}" ></dxi-column>
            <dxi-column dataField="identification_number" caption="{{'Identificación' | translate}}"
                [width]="'auto'"></dxi-column>
            <dxi-column dataField="state" caption="{{'Estado' | translate}}" ></dxi-column>
            <dxi-column dataField="rol_name" caption="{{'Rol' | translate}}" ></dxi-column>
            <dxi-column dataField="email" caption="{{'Correo' | translate}}"></dxi-column>
            <dxi-column dataField="cellPhone" caption="{{'Celular' | translate}}" [width]="'auto'"></dxi-column>
            <dxi-column dataField="currency_name" caption="{{'Modena' | translate}}" [width]="150"></dxi-column>
            <dxi-column dataField="language_name" caption="{{'Idioma' | translate}}" [width]="150"></dxi-column>
            <dxi-column dataField="" cellTemplate="actionsTemplate" [width]="100"></dxi-column>

            <div class="dev-row container__c__a" *dxTemplate="let cell of 'actionsTemplate'">
                <dx-drop-down-button text="Acciones" [items]="actions" displayExpr="value" keyExpr="id" [width]="'100%'"
                    (onItemClick)="onActionClick(cell.data, $event.itemData)" [dropDownOptions]="{ width: 150 }">
                </dx-drop-down-button>
            </div>
        </dx-data-grid>


    </div>
    @if(currentFilter.length === 0){
    <app-on-demand-pagination [pagination]="onDemandPagination"></app-on-demand-pagination>
    }

</div>



<lib-process-popup [config]="configPopupPerson">
    <app-person-edit-popup [currencies]="currencies" [languages]="languages" [rols]="rols"
        [configPopupPerson]="configPopupPerson" (sendForm)="submitData($event)" (close)="isVisiblePopupPerson($event)"
        component></app-person-edit-popup>
</lib-process-popup>