<div class="container">
  <div class="title">
    <h3>Servicio</h3>
    <div>{{typeModal()}}</div>
  </div>

  <form [formGroup]="form" (ngSubmit)="submitData()">
    <dx-validation-group class="form__group" #validationGroupService>


      <dx-select-box formControlName="service" [searchEnabled]="true" [showClearButton]="true"
        [noDataText]="'No se encontro datos'" width="150" [dataSource]="services" displayExpr="name" valueExpr="id"
        placeholder="Seleccionar servicio" label="Service" width="100%" labelMode="floating">
        <dx-validator>
          <dxi-validation-rule type="required" message="{{'GLOBAL.obligatoryField' | translate}}"></dxi-validation-rule>
        </dx-validator>
      </dx-select-box>

      <dx-select-box formControlName="currency" [searchEnabled]="true" [showClearButton]="true"
        [noDataText]="'No se encontro datos'" width="150" [dataSource]="currencies" displayExpr="name" valueExpr="id"
        placeholder="Seleccionar moneda" label="Moneda" width="100%" labelMode="floating">
        <dx-validator>
          <dxi-validation-rule type="required" message="{{'GLOBAL.obligatoryField' | translate}}"></dxi-validation-rule>
        </dx-validator>
      </dx-select-box>

      <dx-number-box class="input" formControlName="price" [showClearButton]="true"
        placeholder="{{ 'Costo' | translate }}" label="Costo" width="100%" labelMode="floating">
      </dx-number-box>

      <div class="form__group__actions">
        <dx-button stylingMode="contained" text={{typeModal()}} type="default" width="100%" height="26"
          [useSubmitBehavior]="true" [disabled]="isFormValid(form)">
        </dx-button>
  
        <dx-button stylingMode="contained" text="{{'Limpiar' | translate }}" width="100%" height="26"
          [useSubmitBehavior]="false" [disabled]="isFormValid(form)" (click)="clear()">
        </dx-button>
  
        <dx-button stylingMode="contained" text="{{'Cerrar' | translate }}" width="100%" (click)="closePopup()">
        </dx-button>
      </div>


    </dx-validation-group>
  </form>

</div>