import { Observable } from 'rxjs';
                import { UseCase } from '@management/base/management';
                import {
                    PersonRepository,
                    IPersonCampusRolNameDTO,
                    IPersonCampusRolNamePersonCompanyCampusRolNameDTO
                } from '@management/domain/management';

export class GetPersonCompanyCampusRolNameServiceUseCase implements UseCase<IPersonCampusRolNamePersonCompanyCampusRolNameDTO, IPersonCampusRolNameDTO[]> {

    private static instance: GetPersonCompanyCampusRolNameServiceUseCase;

    constructor(private personCampusRolNameRepository: PersonRepository) { }

    public static getInstance(personCampusRolNameRepository: PersonRepository): GetPersonCompanyCampusRolNameServiceUseCase {
        if (!GetPersonCompanyCampusRolNameServiceUseCase.instance)
            GetPersonCompanyCampusRolNameServiceUseCase.instance = new GetPersonCompanyCampusRolNameServiceUseCase(personCampusRolNameRepository);
        return GetPersonCompanyCampusRolNameServiceUseCase.instance;
    }

    execute(
        params: IPersonCampusRolNamePersonCompanyCampusRolNameDTO, loadService: boolean
    ): Observable<IPersonCampusRolNameDTO[]> {
        return this.personCampusRolNameRepository.GetPersonCompanyCampusRolNameService(params, loadService);
    }
}