
import { Observable } from 'rxjs';
//domain
import {
    IScheduleScheduleDTO,
    IScheduleDTO,
    IScheduleSaveScheduleDTO,
    IScheduleSaveDTO,
} from '@management/domain/management';
import { UtilsComponent } from '@management/ui';
import { selector } from '@management/base/management';

export abstract class ScheduleRepository extends UtilsComponent {
    abstract SetScheduleService(params: IScheduleSaveScheduleDTO, loadService: boolean): Observable<IScheduleSaveDTO>;
    abstract GetScheduleService(params: IScheduleScheduleDTO, loadService: boolean): Observable<IScheduleDTO[]>;
}