import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonRepository,
    ICompanyCampusEmailDTO,
    ICompanyCampusEmailCompanyCampusEmailDTO
} from '@management/domain/management';

export class GetCompanyCampusEmailServiceUseCase implements UseCase<ICompanyCampusEmailCompanyCampusEmailDTO, ICompanyCampusEmailDTO[]> {

    private static instance: GetCompanyCampusEmailServiceUseCase;

    constructor(private companyCampusEmailRepository: PersonRepository) { }

    public static getInstance(companyCampusEmailRepository: PersonRepository): GetCompanyCampusEmailServiceUseCase {
        if (!GetCompanyCampusEmailServiceUseCase.instance)
            GetCompanyCampusEmailServiceUseCase.instance = new GetCompanyCampusEmailServiceUseCase(companyCampusEmailRepository);
        return GetCompanyCampusEmailServiceUseCase.instance;
    }

    execute(
        params: ICompanyCampusEmailCompanyCampusEmailDTO, loadService: boolean
    ): Observable<ICompanyCampusEmailDTO[]> {
        return this.companyCampusEmailRepository.GetCompanyCampusEmailService(params, loadService);
    }
}