import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonRepository,
    IPersonTimesDTO,
    IPersonTimesAvailableTimePersonDTO
} from '@management/domain/management';

export class SetAvailableTimePersonServiceUseCase implements UseCase<IPersonTimesAvailableTimePersonDTO, IPersonTimesDTO[]> {

    private static instance: SetAvailableTimePersonServiceUseCase;

    constructor(private personTimesRepository: PersonRepository) { }

    public static getInstance(personTimesRepository: PersonRepository): SetAvailableTimePersonServiceUseCase {
        if (!SetAvailableTimePersonServiceUseCase.instance)
            SetAvailableTimePersonServiceUseCase.instance = new SetAvailableTimePersonServiceUseCase(personTimesRepository);
        return SetAvailableTimePersonServiceUseCase.instance;
    }

    execute(
        params: IPersonTimesAvailableTimePersonDTO, loadService: boolean
    ): Observable<IPersonTimesDTO[]> {
        return this.personTimesRepository.SetAvailableTimePersonService(params, loadService);
    }
}