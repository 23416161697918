import { Mapper } from '@management/base/management';
import { IPersonCompanyDTO } from '@management/domain/management';
import { IPersonCompanyEntity } from '@management/data/management';

export class PersonCompanyMapper extends Mapper<IPersonCompanyEntity, IPersonCompanyDTO> {

    private static instance: PersonCompanyMapper;

    private constructor() { super(); }

    public static getInstance(): PersonCompanyMapper {
        if (!PersonCompanyMapper.instance)
            PersonCompanyMapper.instance = new PersonCompanyMapper();
        return PersonCompanyMapper.instance;
    }


    mapFrom(param: IPersonCompanyEntity): IPersonCompanyDTO {
        return {
            id: param.id,
            person: param.person,
            company: param.company,
            rol: param.rol,
            state: param.state
        };
    }
    mapFromList(params: IPersonCompanyEntity[]): IPersonCompanyDTO[] {
        return params.map((param: IPersonCompanyEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IPersonCompanyDTO): IPersonCompanyEntity {
        return {
            id: param.id,
            person: param.person,
            company: param.company,
            rol: param.rol,
            state: param.state
        }
    }
    mapToList(params: IPersonCompanyDTO[]): IPersonCompanyEntity[] {
        return params.map((param: IPersonCompanyDTO) => {
            return this.mapTo(param);
        })
    }
}