<dx-popup class="popup" [width]="config.width" [height]="config.height" [showTitle]="false"
  [dragEnabled]="config.dragEnabled" [hideOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport"
  [(visible)]="config.visible">
  <div class="popup__wrapper">
    <div class="popup__wrapper__a">
      <ng-content select="[component]"></ng-content>
    </div>
    <div class="popup__wrapper__b" [ngStyle]="{'background-color': config.popupColorType}"></div>
  </div>
</dx-popup>


