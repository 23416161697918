import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import { IMenuDTO } from '../../dtos';
import { MenuRepository } from '@management/domain/management';


export class SetRecursiveMenuReduxUseCase implements UseCase<IMenuDTO[], void> {
    constructor(private menuRepository: MenuRepository) { }
    execute(
        params: IMenuDTO[]
    ): void {
         this.menuRepository.setRecursiveMenuRedux(params);
    }
}