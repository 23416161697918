import { actionsPersonInfraestructure } from './index';
import { createReducer, on } from '@ngrx/store';
import {  } from "@management/domain/management";

export const PERSON_INFRAESTRUCTURE_KEY = 'personInfraestructure'
export interface PersonInfraestructureModel {
}

export const PersonInfraestructureInitialState: PersonInfraestructureModel = {
}

const _PersonInfraestructureReducer = createReducer(PersonInfraestructureInitialState,
);

export function PersonInfraestructureReducer(state: PersonInfraestructureModel, action: any) {
    return _PersonInfraestructureReducer(state, action);
}