import { Mapper } from '@management/base/management';
import { IAvailablePersonsDTO } from '@management/domain/management';
import { IAvailablePersonsEntity } from '@management/data/management';

export class AvailablePersonsMapper extends Mapper<
  IAvailablePersonsEntity,
  IAvailablePersonsDTO
> {
  private static instance: AvailablePersonsMapper;

  private constructor() {
    super();
  }

  public static getInstance(): AvailablePersonsMapper {
    if (!AvailablePersonsMapper.instance)
      AvailablePersonsMapper.instance = new AvailablePersonsMapper();
    return AvailablePersonsMapper.instance;
  }

  mapFrom(param: IAvailablePersonsEntity): IAvailablePersonsDTO {
    return {
      schedule_id: param.schedule_id,
      person_id: param.person_id,
      name_person: param.name_person,
      infrastructure_id: param.infrastructure_id,
    };
  }
  mapFromList(params: IAvailablePersonsEntity[]): IAvailablePersonsDTO[] {
    return params.map((param: IAvailablePersonsEntity) => {
      return this.mapFrom(param);
    });
  }
  mapTo(param: IAvailablePersonsDTO): IAvailablePersonsEntity {
    return {
      schedule_id: param.schedule_id,
      person_id: param.person_id,
      name_person: param.name_person,
      infrastructure_id: param.infrastructure_id,
    };
  }
  mapToList(params: IAvailablePersonsDTO[]): IAvailablePersonsEntity[] {
    return params.map((param: IAvailablePersonsDTO) => {
      return this.mapTo(param);
    });
  }
}
