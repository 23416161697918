import { Injectable } from '@angular/core';
import { Action, Store } from "@ngrx/store";

import {
    environment,
    HttpClient,
    map,
    Observable,
    of,
    ResolveRequest,
    keysSessionStorangeEnum
} from '@management/base/management';
//domain
import {
    ServiceCampusRepository,
    IServiceCampusDTO,
    IServiceCampusServiceCampusDTO,
    IServiceCampusDeleteDTO,
    IServiceCampusDeleteServiceCampusDeleteDTO,
    IServiceCampusPutDTO,
    IServiceCampusPutServiceCampusDTO,
} from '@management/domain/management';
//data
import {
    IServiceCampusEntity,
    ServiceCampusMapper,
    IServiceCampusDeleteEntity,
    ServiceCampusDeleteMapper,
    IServiceCampusPutEntity,
    ServiceCampusPutMapper,
} from '@management/data/management';

import {
    actionsServiceCampus,
    reducerServiceCampus,
    selectorsServiceCampus
} from './redux';


@Injectable({
    providedIn: 'root',
})
export class ServiceCampusImplementationRepository extends ServiceCampusRepository {

    private readonly urlApiRest: string = environment.api.urlApiRest;
    private readonly serviceCampusMapper = ServiceCampusMapper.getInstance();
    private readonly serviceCampusPutMapper = ServiceCampusPutMapper.getInstance();
    private readonly serviceCampusDeleteMapper = ServiceCampusDeleteMapper.getInstance();

    constructor(
        private http: HttpClient,
        private resolveRequest: ResolveRequest,
        private store: Store<reducerServiceCampus.ServiceCampusModel>) {
        super();
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    SetServiceCampusService(params: IServiceCampusServiceCampusDTO, loadService: boolean): Observable<IServiceCampusDTO> {
        return this.http
            .post<IServiceCampusEntity>(`${this.urlApiRest}serviceCampus`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IServiceCampusEntity>(response);
                return res;
            }))
            .pipe(map((entity: IServiceCampusEntity) => entity && this.serviceCampusMapper.mapFrom(entity)))
    }

    DeleteServiceCampusDeleteService(params: IServiceCampusDeleteServiceCampusDeleteDTO, loadService: boolean): Observable<IServiceCampusDeleteDTO> {
        const options = {
            body: {
                id: params.id
            }
        }
        return this.http
            .delete<IServiceCampusDeleteEntity>(`${this.urlApiRest}ServiceCampus`, options)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IServiceCampusDeleteEntity>(response);
                return res;
            }))
            .pipe(map((entity: IServiceCampusDeleteEntity) => entity && this.serviceCampusDeleteMapper.mapFrom(entity)))
    }
    
    UpdateServiceCampusService(params: IServiceCampusPutServiceCampusDTO, loadService: boolean): Observable<IServiceCampusPutDTO> {
        return this.http
            .put<IServiceCampusPutEntity>(`${this.urlApiRest}ServiceCampus`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IServiceCampusPutEntity>(response);
                return res;
            }))
            .pipe(map((entity: IServiceCampusPutEntity) => entity && this.serviceCampusPutMapper.mapFrom(entity)))
    }
}