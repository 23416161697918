
import { Mapper } from '@management/base/management';
import { IPersonCompanyCampusDTO } from '@management/domain/management';
import { IPersonCompanyCampusEntity } from '@management/data/management';

export class PersonCompanyCampusMapper extends Mapper<IPersonCompanyCampusEntity, IPersonCompanyCampusDTO> {

    private static instance: PersonCompanyCampusMapper;

    private constructor() { super(); }

    public static getInstance(): PersonCompanyCampusMapper {
        if (!PersonCompanyCampusMapper.instance)
            PersonCompanyCampusMapper.instance = new PersonCompanyCampusMapper();
        return PersonCompanyCampusMapper.instance;
    }


    mapFrom(param: IPersonCompanyCampusEntity): IPersonCompanyCampusDTO {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number,
            currency_name: param.currency_name,
            language_name: param.language_name,
            rol: param.rol,
            rol_name: param.rol_name
        };
    }
    mapFromList(params: IPersonCompanyCampusEntity[]): IPersonCompanyCampusDTO[] {
        return params.map((param: IPersonCompanyCampusEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IPersonCompanyCampusDTO): IPersonCompanyCampusEntity {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number,
            currency_name: param.currency_name,
            language_name: param.language_name,
            rol: param.rol,
            rol_name: param.rol_name
        }
    }
    mapToList(params: IPersonCompanyCampusDTO[]): IPersonCompanyCampusEntity[] {
        return params.map((param: IPersonCompanyCampusDTO) => {
            return this.mapTo(param);
        })
    }
}