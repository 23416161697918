import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    ServiceCampusRepository,
    IServiceCampusDeleteDTO,
    IServiceCampusDeleteServiceCampusDeleteDTO
} from '@management/domain/management';

export class DeleteServiceCampusDeleteServiceUseCase implements UseCase<IServiceCampusDeleteServiceCampusDeleteDTO, IServiceCampusDeleteDTO> {

    private static instance: DeleteServiceCampusDeleteServiceUseCase;

    constructor(private serviceCampusDeleteRepository: ServiceCampusRepository) { }

    public static getInstance(serviceCampusDeleteRepository: ServiceCampusRepository): DeleteServiceCampusDeleteServiceUseCase {
        if (!DeleteServiceCampusDeleteServiceUseCase.instance)
            DeleteServiceCampusDeleteServiceUseCase.instance = new DeleteServiceCampusDeleteServiceUseCase(serviceCampusDeleteRepository);
        return DeleteServiceCampusDeleteServiceUseCase.instance;
    }

    execute(
        params: IServiceCampusDeleteServiceCampusDeleteDTO, loadService: boolean
    ): Observable<IServiceCampusDeleteDTO> {
        return this.serviceCampusDeleteRepository.DeleteServiceCampusDeleteService(params, loadService);
    }
}