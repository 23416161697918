import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonCampusRepository,
    IPersonCampusDTO,
    IPersonCampusPersonCampusDTO
} from '@management/domain/management';

export class SetPersonCampusServiceUseCase implements UseCase<IPersonCampusPersonCampusDTO, IPersonCampusDTO> {

    private static instance: SetPersonCampusServiceUseCase;

    constructor(private personCampusRepository: PersonCampusRepository) { }

    public static getInstance(personCampusRepository: PersonCampusRepository): SetPersonCampusServiceUseCase {
        if (!SetPersonCampusServiceUseCase.instance)
            SetPersonCampusServiceUseCase.instance = new SetPersonCampusServiceUseCase(personCampusRepository);
        return SetPersonCampusServiceUseCase.instance;
    }

    execute(
        params: IPersonCampusPersonCampusDTO, loadService: boolean
    ): Observable<IPersonCampusDTO> {
        return this.personCampusRepository.SetPersonCampusService(params, loadService);
    }
}