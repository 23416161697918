import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';


export interface IOnDemandPaginationDTO {
  callbackService: (pagination: IOnDemandPaginationDTO) => void
  from: number
  until: number
  currentPage: number
}

@Component({
  selector: 'app-on-demand-pagination',
  templateUrl: './on-demand-pagination.component.html',
  styleUrl: './on-demand-pagination.component.scss'
})
export class OnDemandPaginationComponent {
  @Input({ required: true }) pagination!: IOnDemandPaginationDTO;

  public goBack(): void {
    if (this.pagination.currentPage > 1) {
      this.pagination.currentPage--;
      this.adjustRange(true)
    }
  }

  public goForward(): void {
    this.pagination.currentPage++;
    this.adjustRange(false)
  }


  private adjustRange(isBack: boolean) {
    if (isBack) {
      this.pagination.from -= this.pagination.until;
      this.pagination.callbackService(this.pagination);
    } else {
      this.pagination.from += this.pagination.until;
      this.pagination.callbackService(this.pagination);
    }
  }



}












