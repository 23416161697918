
import { Observable } from 'rxjs';
//domain
import {
    IInfrastructureGetInfrastructureByCampusDTO,
    IInfrastructureDTO,
    ISaveInfrastructureSaveInfrastructureDTO,
    ISaveInfrastructureDTO,
    IUpdateInfrastructurePutEditInfrastructureDTO,
    IUpdateInfrastructureDTO,
    IDeleteInfrastructureInfrastructureDTO,
    IDeleteInfrastructureDTO,
    IInfrastructuresInfrastructuresDTO,
    IInfrastructuresDTO,
} from '@management/domain/management';
import { UtilsComponent } from '@management/ui';
import { selector } from '@management/base/management';

export abstract class InfrastructureRepository extends UtilsComponent {
    abstract GetInfrastructuresService(params: IInfrastructuresInfrastructuresDTO, loadService: boolean): Observable<IInfrastructuresDTO[]>;
    abstract DeleteInfrastructureService(params: IDeleteInfrastructureInfrastructureDTO, loadService: boolean): Observable<IDeleteInfrastructureDTO>;
    abstract UpdatePutEditInfrastructureService(params: IUpdateInfrastructurePutEditInfrastructureDTO, loadService: boolean): Observable<IUpdateInfrastructureDTO>;
    abstract SetSaveInfrastructureService(params: ISaveInfrastructureSaveInfrastructureDTO, loadService: boolean): Observable<ISaveInfrastructureDTO>;
    abstract GetInfrastructureByCampusService(params: IInfrastructureGetInfrastructureByCampusDTO, loadService: boolean): Observable<IInfrastructureDTO[]>;
}