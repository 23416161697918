import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonRepository,
    ICompanyCampusIdentificationDTO,
    ICompanyCampusIdentificationCompanyCampusIdentificationDTO
} from '@management/domain/management';

export class GetCompanyCampusIdentificationServiceUseCase implements UseCase<ICompanyCampusIdentificationCompanyCampusIdentificationDTO, ICompanyCampusIdentificationDTO[]> {

    private static instance: GetCompanyCampusIdentificationServiceUseCase;

    constructor(private companyCampusIdentificationRepository: PersonRepository) { }

    public static getInstance(companyCampusIdentificationRepository: PersonRepository): GetCompanyCampusIdentificationServiceUseCase {
        if (!GetCompanyCampusIdentificationServiceUseCase.instance)
            GetCompanyCampusIdentificationServiceUseCase.instance = new GetCompanyCampusIdentificationServiceUseCase(companyCampusIdentificationRepository);
        return GetCompanyCampusIdentificationServiceUseCase.instance;
    }

    execute(
        params: ICompanyCampusIdentificationCompanyCampusIdentificationDTO, loadService: boolean
    ): Observable<ICompanyCampusIdentificationDTO[]> {
        return this.companyCampusIdentificationRepository.GetCompanyCampusIdentificationService(params, loadService);
    }
}