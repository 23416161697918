import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    InfrastructureRepository,
    ISaveInfrastructureDTO,
    ISaveInfrastructureSaveInfrastructureDTO
} from '@management/domain/management';

export class SetSaveInfrastructureServiceUseCase implements UseCase<ISaveInfrastructureSaveInfrastructureDTO, ISaveInfrastructureDTO> {

    private static instance: SetSaveInfrastructureServiceUseCase;

    constructor(private saveInfrastructureRepository: InfrastructureRepository) { }

    public static getInstance(saveInfrastructureRepository: InfrastructureRepository): SetSaveInfrastructureServiceUseCase {
        if (!SetSaveInfrastructureServiceUseCase.instance)
            SetSaveInfrastructureServiceUseCase.instance = new SetSaveInfrastructureServiceUseCase(saveInfrastructureRepository);
        return SetSaveInfrastructureServiceUseCase.instance;
    }

    execute(
        params: ISaveInfrastructureSaveInfrastructureDTO, loadService: boolean
    ): Observable<ISaveInfrastructureDTO> {
        return this.saveInfrastructureRepository.SetSaveInfrastructureService(params, loadService);
    }
}