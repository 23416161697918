
import { Mapper } from '@management/base/management';
import { IPersonDeleteAllDTO } from '@management/domain/management';
import { IPersonDeleteAllEntity } from '@management/data/management';

export class PersonDeleteAllMapper extends Mapper<IPersonDeleteAllEntity, IPersonDeleteAllDTO> {

    private static instance: PersonDeleteAllMapper;

    private constructor() { super(); }

    public static getInstance(): PersonDeleteAllMapper {
        if (!PersonDeleteAllMapper.instance)
            PersonDeleteAllMapper.instance = new PersonDeleteAllMapper();
        return PersonDeleteAllMapper.instance;
    }


    mapFrom(param: IPersonDeleteAllEntity): IPersonDeleteAllDTO {
        return {
  id: param.id
        };
    }
    mapFromList(params: IPersonDeleteAllEntity[]): IPersonDeleteAllDTO[] {
        return params.map((param: IPersonDeleteAllEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IPersonDeleteAllDTO): IPersonDeleteAllEntity {
        return {
            id: param.id
        }
    }
    mapToList(params: IPersonDeleteAllDTO[]): IPersonDeleteAllEntity[] {
        return params.map((param: IPersonDeleteAllDTO) => {
            return this.mapTo(param);
        })
    }
}