
import { Mapper } from '@management/base/management';
import { ICampusServiceDTO } from '@management/domain/management';
import { ICampusServiceEntity } from '@management/data/management';

export class CampusServiceMapper extends Mapper<ICampusServiceEntity, ICampusServiceDTO> {

    private static instance: CampusServiceMapper;

    private constructor() { super(); }

    public static getInstance(): CampusServiceMapper {
        if (!CampusServiceMapper.instance)
            CampusServiceMapper.instance = new CampusServiceMapper();
        return CampusServiceMapper.instance;
    }


    mapFrom(param: ICampusServiceEntity): ICampusServiceDTO {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            company: param.company,
            price: param.price,
            currency: param.currency,
            currency_name: param.currency_name,
            code: param.code,
            service_campus_id: param.service_campus_id
        };
    }
    mapFromList(params: ICampusServiceEntity[]): ICampusServiceDTO[] {
        return params.map((param: ICampusServiceEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: ICampusServiceDTO): ICampusServiceEntity {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            company: param.company,
            price: param.price,
            currency: param.currency,
            currency_name: param.currency_name,
            code: param.code,
            service_campus_id: param.service_campus_id
        }
    }
    mapToList(params: ICampusServiceDTO[]): ICampusServiceEntity[] {
        return params.map((param: ICampusServiceDTO) => {
            return this.mapTo(param);
        })
    }
}