import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { IPersonDTO } from '@management/domain/management';
import { keysSessionStorangeEnum } from '../keysSessionStorange';


export const PersonGuard = () => {
    const router = inject(Router);
    const data = sessionStorage.getItem(keysSessionStorangeEnum.user);
    // const person: IPersonDTO = JSON.parse(data);

    if (!data) {
        router.navigate(['login']);
        return false;
    }
    return true;
}