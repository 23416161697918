
import { Observable } from '@management/base/management';
import { ICampusByCompanyRequestEntity } from '@management/data/management';
import { AuthRepository, ICampusByCompanyDTO, ICampusByCompanyRequestDTO, UseCase } from '@management/domain/management';


export class GetCampusByCompany implements UseCase<ICampusByCompanyRequestDTO, ICampusByCompanyDTO[]> {
    private static instance: GetCampusByCompany;
    constructor(private authRepository: AuthRepository) { }
    public static getInstance(authRepository: AuthRepository): GetCampusByCompany {
        if (!GetCampusByCompany.instance)
            GetCampusByCompany.instance = new GetCampusByCompany(authRepository);
        return GetCampusByCompany.instance;
    }
    execute(
        params: ICampusByCompanyRequestEntity, loadService: boolean, loadSessionStorange: boolean
    ): Observable<ICampusByCompanyDTO[]> {
        return this.authRepository.getCampusByCompany(params, loadService, loadSessionStorange)
    }
}