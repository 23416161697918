import { Injectable } from '@angular/core';
import { Action, Store } from "@ngrx/store";

import {
    environment,
    HttpClient,
    map,
    Observable,
    of,
    ResolveRequest,
    keysSessionStorangeEnum
} from '@management/base/management';
//domain
import {
    PersonRepository,
    IPersonDTO,
    IPersonPersonDTO,
    IPersonTypeUserIdentificationDTO,
    IPersonTypeUserIdentificationPersonTypeUserIdentificationDTO,
    ITypeUserEmailDTO,
    ITypeUserEmailTypeUserByEmailDTO,
    IPersonCompanyCampusDTO,
    IPersonCompanyCampusPersonCompanyCampusDTO,
    ICompanyCampusNameDTO,
    ICompanyCampusNameCompanyCampusNameDTO,
    ICompanyCampusEmailDTO,
    ICompanyCampusEmailCompanyCampusEmailDTO,
    ICompanyCampusIdentificationDTO,
    ICompanyCampusIdentificationCompanyCampusIdentificationDTO,
    IDeletePersonCollaboratorDTO,
    IDeletePersonCollaboratorDeletePersonRoleCollaboratorDTO,
    IPersonDeleteAllDTO,
    IPersonDeleteAllDeleteAllPersonDTO,
    IPersonCampusRolDTO,
    IPersonCampusRolPersonCompanyCampusRolDTO,
    IPersonServicesDTO,
    IPersonServicesPersonServicesDTO,
    IPersonCampusRolNameDTO,
    IPersonCampusRolNamePersonCompanyCampusRolNameDTO,
    IPersonRolEmailDTO,
    IPersonRolEmailPersonCompanyCampusRolEmailDTO,
    IPersonRolIdenticationDTO,
    IPersonRolIdenticationPersonCompanyCampusRolIdenDTO,
    IAvailablePersonsDTO,
    IAvailablePersonsAvailablePersonsDTO,
    IPersonTimesDTO,
    IPersonTimesAvailableTimePersonDTO,
} from '@management/domain/management';
//data
import {
    IPersonEntity,
    PersonMapper,
    IPersonTypeUserIdentificationEntity,
    PersonTypeUserIdentificationMapper,
    ITypeUserEmailEntity,
    TypeUserEmailMapper,
    IPersonCompanyCampusEntity,
    PersonCompanyCampusMapper,
    ICompanyCampusNameEntity,
    CompanyCampusNameMapper,
    ICompanyCampusEmailEntity,
    CompanyCampusEmailMapper,
    ICompanyCampusIdentificationEntity,
    CompanyCampusIdentificationMapper,
    IDeletePersonCollaboratorEntity,
    DeletePersonCollaboratorMapper,
    IPersonDeleteAllEntity,
    PersonDeleteAllMapper,
    IPersonCampusRolEntity,
    PersonCampusRolMapper,
    IPersonServicesEntity,
    PersonServicesMapper,
    IPersonCampusRolNameEntity,
    PersonCampusRolNameMapper,
    IPersonRolEmailEntity,
    PersonRolEmailMapper,
    IPersonRolIdenticationEntity,
    PersonRolIdenticationMapper,
    IAvailablePersonsEntity,
    AvailablePersonsMapper,
    IPersonTimesEntity,
    PersonTimesMapper,
} from '@management/data/management';

import {
    actionsPerson,
    reducerPerson,
    selectorsPerson
} from './redux';


@Injectable({
    providedIn: 'root',
})
export class PersonImplementationRepository extends PersonRepository {

    private readonly urlApiRest: string = environment.api.urlApiRest;
    private readonly personMapper = PersonMapper.getInstance();
    private readonly personTimesMapper = PersonTimesMapper.getInstance();
    private readonly availablePersonsMapper = AvailablePersonsMapper.getInstance();
    private readonly personRolIdenticationMapper = PersonRolIdenticationMapper.getInstance();
    private readonly personRolEmailMapper = PersonRolEmailMapper.getInstance();
    private readonly personCampusRolNameMapper = PersonCampusRolNameMapper.getInstance();
    private readonly personServicesMapper = PersonServicesMapper.getInstance();
    private readonly personCampusRolMapper = PersonCampusRolMapper.getInstance();
    private readonly personDeleteAllMapper = PersonDeleteAllMapper.getInstance();
    private readonly deletePersonCollaboratorMapper = DeletePersonCollaboratorMapper.getInstance();
    private readonly companyCampusIdentificationMapper = CompanyCampusIdentificationMapper.getInstance();
    private readonly companyCampusEmailMapper = CompanyCampusEmailMapper.getInstance();
    private readonly companyCampusNameMapper = CompanyCampusNameMapper.getInstance();
    private readonly personCompanyCampusMapper = PersonCompanyCampusMapper.getInstance();
    private readonly typeUserEmailMapper = TypeUserEmailMapper.getInstance();
    private readonly personTypeUserIdentificationMapper = PersonTypeUserIdentificationMapper.getInstance();

    constructor(
        private http: HttpClient,
        private resolveRequest: ResolveRequest,
        private store: Store<reducerPerson.PersonModel>) {
        super();
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    SetPersonService(params: IPersonPersonDTO, loadService: boolean): Observable<IPersonDTO> {
        return this.http
            .post<IPersonEntity>(`${this.urlApiRest}person/loggedIn`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IPersonEntity>(response);
                console.log(res)
                return res;
            }))
            .pipe(map((entity: IPersonEntity) => entity && this.personMapper.mapFrom(entity)))
    }

    GetPersonTypeUserIdentificationService(params: IPersonTypeUserIdentificationPersonTypeUserIdentificationDTO, loadService: boolean): Observable<IPersonTypeUserIdentificationDTO[]> {
        return this.http
            .post<IPersonTypeUserIdentificationEntity[]>(`${this.urlApiRest}person/PersonTypeUserIdentification`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IPersonTypeUserIdentificationEntity[]>(response);
                return res;
            }))
            .pipe(map((entity: IPersonTypeUserIdentificationEntity[]) => entity && this.personTypeUserIdentificationMapper.mapFromList(entity)))
    }

    GetTypeUserByEmailService(params: ITypeUserEmailTypeUserByEmailDTO, loadService: boolean): Observable<ITypeUserEmailDTO[]> {
        return this.http
            .post<ITypeUserEmailEntity[]>(`${this.urlApiRest}person/PersonsOfTypeUserByEmail`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<ITypeUserEmailEntity[]>(response);
                this.setSessionStorange(keysSessionStorangeEnum.GetTypeUserByEmailService, res);
                return res;
            }))
            .pipe(map((entity: ITypeUserEmailEntity[]) => entity && this.typeUserEmailMapper.mapFromList(entity)))
    }

    GetPersonCompanyCampusService(params: IPersonCompanyCampusPersonCompanyCampusDTO, loadService: boolean): Observable<IPersonCompanyCampusDTO[]> {

        if (this.areEqualRequest<IPersonCompanyCampusPersonCompanyCampusDTO>({
            url: 'person/PersonCompanyCampus',
            body: params
        })) return of([])

        return this.http
            .post<IPersonCompanyCampusEntity[]>(`${this.urlApiRest}person/PersonCompanyCampus`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IPersonCompanyCampusEntity[]>(response);
                return res;
            }))
            .pipe(map((entity: IPersonCompanyCampusEntity[]) => entity && this.personCompanyCampusMapper.mapFromList(entity)))
    }

    GetCompanyCampusNameService(params: ICompanyCampusNameCompanyCampusNameDTO, loadService: boolean): Observable<ICompanyCampusNameDTO[]> {
        if (this.areEqualRequest<ICompanyCampusNameCompanyCampusNameDTO>({
            url: 'person/PersonCompanyCampusName',
            body: params
        })) return of([]);
        return this.http
            .post<ICompanyCampusNameEntity[]>(`${this.urlApiRest}person/PersonCompanyCampusName`, params)
            .pipe(map((response: any) => {
                const res = this.resolveRequest.resolve<ICompanyCampusNameEntity[]>(response);
                return res;
            }))
            .pipe(map((entity: ICompanyCampusNameEntity[]) => entity && this.companyCampusNameMapper.mapFromList(entity)))
    }

    GetCompanyCampusEmailService(params: ICompanyCampusEmailCompanyCampusEmailDTO, loadService: boolean): Observable<ICompanyCampusEmailDTO[]> {
        if (this.areEqualRequest<ICompanyCampusEmailCompanyCampusEmailDTO>({
            url: 'person/PersonCompanyCampusEmail',
            body: params
        })) return of([]);
        return this.http
            .post<ICompanyCampusEmailEntity[]>(`${this.urlApiRest}person/PersonCompanyCampusEmail`, params)
            .pipe(map((response: any) => {
                const res = this.resolveRequest.resolve<ICompanyCampusEmailEntity[]>(response);
                return res;
            }))
            .pipe(map((entity: ICompanyCampusEmailEntity[]) => entity && this.companyCampusEmailMapper.mapFromList(entity)))
    }
    GetCompanyCampusIdentificationService(params: ICompanyCampusIdentificationCompanyCampusIdentificationDTO, loadService: boolean): Observable<ICompanyCampusIdentificationDTO[]> {
        if (this.areEqualRequest<ICompanyCampusIdentificationCompanyCampusIdentificationDTO>({
            url: 'person/PersonCompanyCampusIdentifica',
            body: params
        })) return of([]);
        return this.http
            .post<ICompanyCampusIdentificationEntity[]>(`${this.urlApiRest}person/PersonCompanyCampusIdentifica`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<ICompanyCampusIdentificationEntity[]>(response);
                return res;
            }))
            .pipe(map((entity: ICompanyCampusIdentificationEntity[]) => entity && this.companyCampusIdentificationMapper.mapFromList(entity)))
    }

    DeleteDeletePersonRoleCollaboratorService(params: IDeletePersonCollaboratorDeletePersonRoleCollaboratorDTO, loadService: boolean): Observable<IDeletePersonCollaboratorDTO> {

        const options = {
            body: {
                id: params.id
            }
        }

        return this.http
            .delete<IDeletePersonCollaboratorEntity>(`${this.urlApiRest}person/DeletePersonRoleCollaborator`, options)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IDeletePersonCollaboratorEntity>(response);
                return res;
            }))
            .pipe(map((entity: IDeletePersonCollaboratorEntity) => entity && this.deletePersonCollaboratorMapper.mapFrom(entity)))
    }

    DeleteDeleteAllPersonService(params: IPersonDeleteAllDeleteAllPersonDTO, loadService: boolean): Observable<IPersonDeleteAllDTO> {
        const options = {
            body: {
                id: params.id
            }
        }

        return this.http
            .delete<IPersonDeleteAllEntity>(`${this.urlApiRest}person/DeleteAllPerson`, options)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IPersonDeleteAllEntity>(response);
                return res;
            }))
            .pipe(map((entity: IPersonDeleteAllEntity) => entity && this.personDeleteAllMapper.mapFrom(entity)))
    }

    GetPersonCompanyCampusRolService(params: IPersonCampusRolPersonCompanyCampusRolDTO, loadService: boolean): Observable<IPersonCampusRolDTO[]> {
        if (this.areEqualRequest<IPersonCampusRolPersonCompanyCampusRolDTO>({
            url: 'person/PersonCompanyCampusRol',
            body: params
        })) return of([]);
        //let data = this.getSessionStorange(keysSessionStorangeEnum.GetPersonCompanyCampusRolService);
        //if (!data || loadService)
        return this.http
            .post<IPersonCampusRolEntity[]>(`${this.urlApiRest}person/PersonCompanyCampusRol`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IPersonCampusRolEntity[]>(response);
                //this.setSessionStorange(keysSessionStorangeEnum.GetPersonCompanyCampusRolService, res);
                return res;
            }))
            .pipe(map((entity: IPersonCampusRolEntity[]) => entity && this.personCampusRolMapper.mapFromList(entity)))
        //return of(data);
    }
    GetPersonServicesService(params: IPersonServicesPersonServicesDTO, loadService: boolean): Observable<IPersonServicesDTO[]> {
        if (this.areEqualRequest<IPersonServicesPersonServicesDTO>({
            url: 'person/PersonServices',
            body: params
        })) return of([]);
        //let data = this.getSessionStorange(keysSessionStorangeEnum.GetPersonServicesService);
        //if (!data || loadService)
        return this.http
            .post<IPersonServicesEntity>(`${this.urlApiRest}person/PersonServices`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IPersonServicesEntity[]>(response);
                //this.setSessionStorange(keysSessionStorangeEnum.GetPersonServicesService, res);
                return res;
            }))
            .pipe(map((entity: IPersonServicesEntity[]) => entity && this.personServicesMapper.mapFromList(entity)))
        //return of(data);
    }
    GetPersonCompanyCampusRolNameService(params: IPersonCampusRolNamePersonCompanyCampusRolNameDTO, loadService: boolean): Observable<IPersonCampusRolNameDTO[]> {
        //let data = this.getSessionStorange(keysSessionStorangeEnum.GetPersonCompanyCampusRolNameService);
        //if (!data || loadService)
        return this.http
            .post<IPersonCampusRolNameEntity>(`${this.urlApiRest}person/PersonCompanyCampusRolName`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IPersonCampusRolNameEntity[]>(response);
                //this.setSessionStorange(keysSessionStorangeEnum.GetPersonCompanyCampusRolNameService, res);
                return res;
            }))
            .pipe(map((entity: IPersonCampusRolNameEntity[]) => entity && this.personCampusRolNameMapper.mapFromList(entity)))
        //return of(data);
    }
    GetPersonCompanyCampusRolEmailService(params: IPersonRolEmailPersonCompanyCampusRolEmailDTO, loadService: boolean): Observable<IPersonRolEmailDTO[]> {
        //let data = this.getSessionStorange(keysSessionStorangeEnum.GetPersonCompanyCampusRolEmailService);
        //if (!data || loadService)
        return this.http
            .post<IPersonRolEmailEntity>(`${this.urlApiRest}person/PersonCompanyCampusRolEmail`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IPersonRolEmailEntity[]>(response);
                //this.setSessionStorange(keysSessionStorangeEnum.GetPersonCompanyCampusRolEmailService, res);
                return res;
            }))
            .pipe(map((entity: IPersonRolEmailEntity[]) => entity && this.personRolEmailMapper.mapFromList(entity)))
        //return of(data);
    }
    GetPersonCompanyCampusRolIdenService(params: IPersonRolIdenticationPersonCompanyCampusRolIdenDTO, loadService: boolean): Observable<IPersonRolIdenticationDTO[]> {
        return this.http
            .post<IPersonRolIdenticationEntity>(`${this.urlApiRest}person/PersonCompanyCampusRolIden`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IPersonRolIdenticationEntity[]>(response);
                return res;
            }))
            .pipe(map((entity: IPersonRolIdenticationEntity[]) => entity && this.personRolIdenticationMapper.mapFromList(entity)))
    }
    GetAvailablePersonsService(params: IAvailablePersonsAvailablePersonsDTO, loadService: boolean): Observable<IAvailablePersonsDTO[]> {
        return this.http
            .post<IAvailablePersonsEntity>(`${this.urlApiRest}person/availablePersons`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IAvailablePersonsEntity[]>(response);
                return res;
            }))
            .pipe(map((entity: IAvailablePersonsEntity[]) => entity && this.availablePersonsMapper.mapFromList(entity)))
    }
    SetAvailableTimePersonService(params: IPersonTimesAvailableTimePersonDTO, loadService: boolean): Observable<IPersonTimesDTO[]> {
        return this.http
            .post<IPersonTimesEntity>(`${this.urlApiRest}person/availableTimePerson`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IPersonTimesEntity[]>(response);
                return res;
            }))
            .pipe(map((entity: IPersonTimesEntity[]) => entity && this.personTimesMapper.mapFromList(entity)))
    }
}