import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NotificationTypeEnum } from '@management/base/management';
import { Message, MessagesGLobal } from '@management/ui';

@Component({
  selector: 'app-popup-messages',
  templateUrl: './popup-messages.component.html',
  styleUrls: ['./popup-messages.component.scss']
})
export class PopupMessagesComponent implements OnChanges {

  @Input({ required: true }) data!: Message;
  public icon!: string;

  constructor(private readonly messagesGLobal: MessagesGLobal) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes["data"]) {
      this.typeMessage();
    }
  }


  public typeMessage(): void {
    const { typeMessage } = this.data;
    switch (typeMessage) {
      case NotificationTypeEnum.SUCCESSFUL:
        this.icon = 'check'
        break;
      case NotificationTypeEnum.WARNING:
        this.icon = 'exclamation'
        break;
      case NotificationTypeEnum.FAILED:
        this.icon = 'close'
        break;
    }
  }

  closeMessage() {
    this.messagesGLobal.isShowMessage(false);
    
  }

}
