
import { Mapper } from '@management/base/management';
import { ICampusServiceCurrencyDTO } from '@management/domain/management';
import { ICampusServiceCurrencyEntity } from '@management/data/management';

export class CampusServiceCurrencyMapper extends Mapper<ICampusServiceCurrencyEntity, ICampusServiceCurrencyDTO> {

    private static instance: CampusServiceCurrencyMapper;

    private constructor() { super(); }

    public static getInstance(): CampusServiceCurrencyMapper {
        if (!CampusServiceCurrencyMapper.instance)
            CampusServiceCurrencyMapper.instance = new CampusServiceCurrencyMapper();
        return CampusServiceCurrencyMapper.instance;
    }


    mapFrom(param: ICampusServiceCurrencyEntity): ICampusServiceCurrencyDTO {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            company: param.company,
            price: param.price,
            currency: param.currency,
            currency_name: param.currency_name,
            code: param.code,
            service_campus_id: param.service_campus_id
        };
    }
    mapFromList(params: ICampusServiceCurrencyEntity[]): ICampusServiceCurrencyDTO[] {
        return params.map((param: ICampusServiceCurrencyEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: ICampusServiceCurrencyDTO): ICampusServiceCurrencyEntity {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            company: param.company,
            price: param.price,
            currency: param.currency,
            currency_name: param.currency_name,
            code: param.code,
            service_campus_id: param.service_campus_id
        }
    }
    mapToList(params: ICampusServiceCurrencyDTO[]): ICampusServiceCurrencyEntity[] {
        return params.map((param: ICampusServiceCurrencyDTO) => {
            return this.mapTo(param);
        })
    }
}