import { Observable } from "rxjs";
//domain
import {
    PersonRepository,
    IPersonDTO,
    IPersonPersonDTO,
    SetPersonServiceUseCase,
    IPersonTypeUserIdentificationDTO,
    IPersonTypeUserIdentificationPersonTypeUserIdentificationDTO,
    GetPersonTypeUserIdentificationServiceUseCase,
    ITypeUserEmailDTO,
    ITypeUserEmailTypeUserByEmailDTO,
    GetTypeUserByEmailServiceUseCase,
    IPersonCompanyCampusDTO,
    IPersonCompanyCampusPersonCompanyCampusDTO,
    GetPersonCompanyCampusServiceUseCase,
    ICompanyCampusNameDTO,
    ICompanyCampusNameCompanyCampusNameDTO,
    GetCompanyCampusNameServiceUseCase,
    ICompanyCampusEmailDTO,
    ICompanyCampusEmailCompanyCampusEmailDTO,
    GetCompanyCampusEmailServiceUseCase,
    ICompanyCampusIdentificationDTO,
    ICompanyCampusIdentificationCompanyCampusIdentificationDTO,
    GetCompanyCampusIdentificationServiceUseCase,
    IDeletePersonCollaboratorDTO,
    IDeletePersonCollaboratorDeletePersonRoleCollaboratorDTO,
    DeleteDeletePersonRoleCollaboratorServiceUseCase,
    IPersonDeleteAllDTO,
    IPersonDeleteAllDeleteAllPersonDTO,
    DeleteDeleteAllPersonServiceUseCase,
    IPersonCampusRolDTO,
    IPersonCampusRolPersonCompanyCampusRolDTO,
    GetPersonCompanyCampusRolServiceUseCase,
    IPersonServicesDTO,
    IPersonServicesPersonServicesDTO,
    GetPersonServicesServiceUseCase,
    IPersonCampusRolNameDTO,
    IPersonCampusRolNamePersonCompanyCampusRolNameDTO,
    GetPersonCompanyCampusRolNameServiceUseCase,
    IPersonRolEmailDTO,
    IPersonRolEmailPersonCompanyCampusRolEmailDTO,
    GetPersonCompanyCampusRolEmailServiceUseCase,
    IPersonRolIdenticationDTO,
    IPersonRolIdenticationPersonCompanyCampusRolIdenDTO,
    GetPersonCompanyCampusRolIdenServiceUseCase,
    IAvailablePersonsDTO,
    IAvailablePersonsAvailablePersonsDTO,
    GetAvailablePersonsServiceUseCase,
    IPersonTimesDTO,
    IPersonTimesAvailableTimePersonDTO,
    SetAvailableTimePersonServiceUseCase,
} from "@management/domain/management";


export class PersonFacade extends PersonRepository {

    private static instance: PersonFacade;

    constructor(private personRepository: PersonRepository) {
        super();
    }

    public static getInstance(personRepository: PersonRepository): PersonFacade {
        if (!PersonFacade.instance)
            PersonFacade.instance = new PersonFacade(personRepository);
        return PersonFacade.instance;
    }

    SetPersonService(params: IPersonPersonDTO, loadService: boolean): Observable<IPersonDTO> {
        return SetPersonServiceUseCase.getInstance(this.personRepository).execute(params, loadService);
    }

    GetPersonTypeUserIdentificationService(params: IPersonTypeUserIdentificationPersonTypeUserIdentificationDTO, loadService: boolean): Observable<IPersonTypeUserIdentificationDTO[]> {
        return GetPersonTypeUserIdentificationServiceUseCase.getInstance(this.personRepository).execute(params, loadService);
    }

    GetTypeUserByEmailService(params: ITypeUserEmailTypeUserByEmailDTO, loadService: boolean): Observable<ITypeUserEmailDTO[]> {
        return GetTypeUserByEmailServiceUseCase.getInstance(this.personRepository).execute(params, loadService);
    }

    GetPersonCompanyCampusService(params: IPersonCompanyCampusPersonCompanyCampusDTO, loadService: boolean): Observable<IPersonCompanyCampusDTO[]> {
        return GetPersonCompanyCampusServiceUseCase.getInstance(this.personRepository).execute(params, loadService);
    }

    GetCompanyCampusNameService(params: ICompanyCampusNameCompanyCampusNameDTO, loadService: boolean): Observable<ICompanyCampusNameDTO[]> {
        return GetCompanyCampusNameServiceUseCase.getInstance(this.personRepository).execute(params, loadService);
    }
    GetCompanyCampusEmailService(params: ICompanyCampusEmailCompanyCampusEmailDTO, loadService: boolean): Observable<ICompanyCampusEmailDTO[]> {
        return GetCompanyCampusEmailServiceUseCase.getInstance(this.personRepository).execute(params, loadService);
    }
    GetCompanyCampusIdentificationService(params: ICompanyCampusIdentificationCompanyCampusIdentificationDTO, loadService: boolean): Observable<ICompanyCampusIdentificationDTO[]> {
        return GetCompanyCampusIdentificationServiceUseCase.getInstance(this.personRepository).execute(params, loadService);
    }
    DeleteDeletePersonRoleCollaboratorService(params: IDeletePersonCollaboratorDeletePersonRoleCollaboratorDTO, loadService: boolean): Observable<IDeletePersonCollaboratorDTO> {
        return DeleteDeletePersonRoleCollaboratorServiceUseCase.getInstance(this.personRepository).execute(params, loadService);
    }
    DeleteDeleteAllPersonService(params: IPersonDeleteAllDeleteAllPersonDTO, loadService: boolean): Observable<IPersonDeleteAllDTO> {
        return DeleteDeleteAllPersonServiceUseCase.getInstance(this.personRepository).execute(params, loadService);
    }
    GetPersonCompanyCampusRolService(params: IPersonCampusRolPersonCompanyCampusRolDTO, loadService: boolean): Observable<IPersonCampusRolDTO[]> {
        return GetPersonCompanyCampusRolServiceUseCase.getInstance(this.personRepository).execute(params, loadService);
    }
    GetPersonServicesService(params: IPersonServicesPersonServicesDTO, loadService: boolean): Observable<IPersonServicesDTO[]> {
        return GetPersonServicesServiceUseCase.getInstance(this.personRepository).execute(params, loadService);
    }
    GetPersonCompanyCampusRolNameService(params: IPersonCampusRolNamePersonCompanyCampusRolNameDTO, loadService: boolean): Observable<IPersonCampusRolNameDTO[]> {
        return GetPersonCompanyCampusRolNameServiceUseCase.getInstance(this.personRepository).execute(params, loadService);
    }
    GetPersonCompanyCampusRolEmailService(params: IPersonRolEmailPersonCompanyCampusRolEmailDTO, loadService: boolean): Observable<IPersonRolEmailDTO[]> {
        return GetPersonCompanyCampusRolEmailServiceUseCase.getInstance(this.personRepository).execute(params, loadService);
    }
    GetPersonCompanyCampusRolIdenService(params: IPersonRolIdenticationPersonCompanyCampusRolIdenDTO, loadService: boolean): Observable<IPersonRolIdenticationDTO[]> {
        return GetPersonCompanyCampusRolIdenServiceUseCase.getInstance(this.personRepository).execute(params, loadService);
    }
    GetAvailablePersonsService(params: IAvailablePersonsAvailablePersonsDTO, loadService: boolean): Observable<IAvailablePersonsDTO[]> {
        return GetAvailablePersonsServiceUseCase.getInstance(this.personRepository).execute(params, loadService);
    }
    SetAvailableTimePersonService(params: IPersonTimesAvailableTimePersonDTO, loadService: boolean): Observable<IPersonTimesDTO[]> {
        return SetAvailableTimePersonServiceUseCase.getInstance(this.personRepository).execute(params, loadService);
    }
}