
import { Mapper } from '@management/base/management';
import { IScheduleSaveDTO } from '@management/domain/management';
import { IScheduleSaveEntity } from '@management/data/management';

export class ScheduleSaveMapper extends Mapper<IScheduleSaveEntity, IScheduleSaveDTO> {

    private static instance: ScheduleSaveMapper;

    private constructor() { super(); }

    public static getInstance(): ScheduleSaveMapper {
        if (!ScheduleSaveMapper.instance)
            ScheduleSaveMapper.instance = new ScheduleSaveMapper();
        return ScheduleSaveMapper.instance;
    }


    mapFrom(param: IScheduleSaveEntity): IScheduleSaveDTO {
        return {
            id: param.id,
            person: param.person,
            campus: param.campus
        };
    }
    mapFromList(params: IScheduleSaveEntity[]): IScheduleSaveDTO[] {
        return params.map((param: IScheduleSaveEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IScheduleSaveDTO): IScheduleSaveEntity {
        return {
            id: param.id,
            person: param.person,
            campus: param.campus
        }
    }
    mapToList(params: IScheduleSaveDTO[]): IScheduleSaveEntity[] {
        return params.map((param: IScheduleSaveDTO) => {
            return this.mapTo(param);
        })
    }
}