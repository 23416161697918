
import { Mapper } from '@management/base/management';
import { ISaveInfrastructureDTO } from '@management/domain/management';
import { ISaveInfrastructureEntity } from '@management/data/management';

export class SaveInfrastructureMapper extends Mapper<ISaveInfrastructureEntity, ISaveInfrastructureDTO> {

    private static instance: SaveInfrastructureMapper;

    private constructor() { super(); }

    public static getInstance(): SaveInfrastructureMapper {
        if (!SaveInfrastructureMapper.instance)
            SaveInfrastructureMapper.instance = new SaveInfrastructureMapper();
        return SaveInfrastructureMapper.instance;
    }


    mapFrom(param: ISaveInfrastructureEntity): ISaveInfrastructureDTO {
        return {
            state: param.state,
            top_id: param.top_id,
            campus: param.campus,
            installation_type: param.installation_type,
            id: param.id,
            name: param.name,
            description: param.description
        };
    }
    mapFromList(params: ISaveInfrastructureEntity[]): ISaveInfrastructureDTO[] {
        return params.map((param: ISaveInfrastructureEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: ISaveInfrastructureDTO): ISaveInfrastructureEntity {
        return {
            state: param.state,
            top_id: param.top_id,
            campus: param.campus,
            installation_type: param.installation_type,
            id: param.id,
            name: param.name,
            description: param.description
        }
    }
    mapToList(params: ISaveInfrastructureDTO[]): ISaveInfrastructureEntity[] {
        return params.map((param: ISaveInfrastructureDTO) => {
            return this.mapTo(param);
        })
    }
}