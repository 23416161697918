
import { Mapper } from '@management/base/management';
import { IPersonRolEmailDTO } from '@management/domain/management';
import { IPersonRolEmailEntity } from '@management/data/management';

export class PersonRolEmailMapper extends Mapper<IPersonRolEmailEntity, IPersonRolEmailDTO> {

    private static instance: PersonRolEmailMapper;

    private constructor() { super(); }

    public static getInstance(): PersonRolEmailMapper {
        if (!PersonRolEmailMapper.instance)
            PersonRolEmailMapper.instance = new PersonRolEmailMapper();
        return PersonRolEmailMapper.instance;
    }


    mapFrom(param: IPersonRolEmailEntity): IPersonRolEmailDTO {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number,
            currency_name: param.currency_name,
            language_name: param.language_name,
            rol: param.rol,
            rol_name: param.rol_name
        };
    }
    mapFromList(params: IPersonRolEmailEntity[]): IPersonRolEmailDTO[] {
        return params.map((param: IPersonRolEmailEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IPersonRolEmailDTO): IPersonRolEmailEntity {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number,
            currency_name: param.currency_name,
            language_name: param.language_name,
            rol: param.rol,
            rol_name: param.rol_name
        }
    }
    mapToList(params: IPersonRolEmailDTO[]): IPersonRolEmailEntity[] {
        return params.map((param: IPersonRolEmailDTO) => {
            return this.mapTo(param);
        })
    }
}