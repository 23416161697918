import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { NgxTranslateModule } from '@management/ngx-translate';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DevextremeLibModule } from '@management/devextreme-lib';
import {
  CONDITION,
  TYPE_VALUE,
  UiModule,
  UtilsComponent,
} from '@management/ui';
import { loadMessages, locale } from 'devextreme/localization';
import es from '../../../assets/dev-extreme-lan/es.json';
import {
  IAssignmentAssignmentDTO,
  IAssignmentDTO,
  IAvailablePersonsDTO,
  ICompanySelectedDTO,
  IPersonTimesAvailableTimePersonDTO,
  IPersonTimesDTO,
  IServiceDTO,
} from '@management/domain/management';
import { AssignmentFacade, AuthFacade, PersonFacade, ServiceFacade } from '@management/facade/management';
import { take } from 'rxjs';
import { selector } from '@management/base/management';
import { DxValidationGroupComponent } from 'devextreme-angular';

@Component({
  selector: 'app-assignment',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgxTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DevextremeLibModule,
    UiModule,
  ],
  templateUrl: './assignment.component.html',
  styleUrl: './assignment.component.scss',
})
export class AssignmentComponent extends UtilsComponent implements OnInit {
  @ViewChild('validationGroupAssignment', { static: false })
  validationGroup!: DxValidationGroupComponent;
  public services: IServiceDTO[] = [];
  public persons: IAvailablePersonsDTO[] = [];
  public personTimes: IPersonTimesDTO[] = [];
  public companySelectedDTO!: ICompanySelectedDTO;
  public form!: FormGroup;
  public servicesDurationTotalInMinutes: number = 0;
  public height: number | undefined = 90;
  public now: Date = new Date();
  public hoursList: any[] = [];

  public _fb = inject(FormBuilder);
  public _authFacade = inject(AuthFacade);
  public _personFacade = inject(PersonFacade);
  public _serviceFacade = inject(ServiceFacade);
  public _assignmentFacade = inject(AssignmentFacade);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.loadLocale();
    this.getCompanySelectedSelector();
  }

  public loadLocale(): void {
    loadMessages(es);
    locale('es');
  }

  public getCompanySelectedSelector(): void {
    this._authFacade
      .getCompanySelectedSelector()
      .pipe(take(1))
      .subscribe((res: selector<ICompanySelectedDTO>) => {
        this.companySelectedDTO = res as ICompanySelectedDTO;
        this.getServiceService();
      });
  }

  public getServiceService(): void {
    const { id } = this.companySelectedDTO.campus;
    const filterBase = [
      {
        value: id,
        type_value: TYPE_VALUE.NUMBER,
        condition: CONDITION.EQUALS,
        field: 'company',
      },
    ];
    this._serviceFacade
      .GetServiceService({ fullData: true, filters: filterBase }, true)
      .pipe(take(1))
      .subscribe((services: IServiceDTO[]) => {
        this.services = services;
        console.log(this.services);
      });
  }

  public initForm(): void {
    this.form = this._fb.group({
      services: [, Validators.compose([Validators.required])],
      collaborator: [, Validators.compose([Validators.required])],
      fromDate: [, Validators.compose([Validators.required])],
      fromHour: [, Validators.compose([Validators.required])],
    });
  }

  public submitData(): void {
    const { collaborator, fromDate, fromHour, services } = this.form.value;
    const personData =
      this.persons.find(person => person.person_id === this.form.value.collaborator);
    const getStartTime =
      this.personTimes.find(time => time.order_num === fromHour)?.start_time;
    if (!this.isFormValidTS(this.form) && !personData && !getStartTime) return

    const payload: IAssignmentAssignmentDTO = {
      schedule: personData?.schedule_id || 0,
      person: collaborator,
      initial_day: fromDate,
      start_time: getStartTime || '',
      services: services
    }

    this._assignmentFacade
      .SetAssignmentService(payload, true)
      .pipe(take(1))
      .subscribe((response: IAssignmentDTO) => {
        if (response) {
          this.clear();
        }
      });
  }

  public clear(): void {
    this.form?.reset();
    this.validationGroup?.instance?.reset();
  }

  public getAvailablePersonsService(services: number[]): void {

    const data = {
      numberServices: services.length || 0,
      serviceIds: services,
      from: 0,
      until: 6
    }

    this._personFacade
      .GetAvailablePersonsService(data, true)
      .pipe(take(1))
      .subscribe((persons: IAvailablePersonsDTO[]) => {
        this.persons = persons;
      });

  }

  public onChangeServices(e: unknown): void {
    if (!this.form.value.services.length) return
    this.servicesDurationTotalInMinutes = 0;

    const servicesSelected =
      this.services
        .filter(x => this.form.value.services.includes(x.id));
    this.servicesDurationTotalInMinutes =
      servicesSelected
        .reduce((accumulator, currentValue) =>
          accumulator + currentValue.service_time, 0);

    this.getAvailablePersonsService(this.form.value.services);
  }

  public getAvailableTimePersonService(datee: Date, personId: number): void {

    const data: IPersonTimesAvailableTimePersonDTO = {
      date: datee,
      person: personId
    }

    this._personFacade
      .SetAvailableTimePersonService(data, true)
      .pipe(take(1))
      .subscribe((personTimes: IPersonTimesDTO[]) => {
        this.personTimes = personTimes;
      });
  }

  public onChangePersons(e: unknown): void {
    const personId = this.form.value.collaborator;
    const fromDate = this.form.value.fromDate;

    console.log(fromDate)


    if (!personId || !fromDate) return
    this.getAvailableTimePersonService(fromDate, personId);
  }


}





