
import { Observable } from 'rxjs';
//domain
import {
    IPersonCompanyPersonCompanyDTO,
    IPersonCompanyDTO,
} from '@management/domain/management';
import { UtilsComponent } from '@management/ui';
import { selector } from '@management/base/management';

export abstract class PersonCompanyRepository extends UtilsComponent {
    abstract SetPersonCompanyService(params: IPersonCompanyPersonCompanyDTO, loadService: boolean): Observable<IPersonCompanyDTO>;
}