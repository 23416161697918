import { Observable } from "rxjs";
//domain
import {
    ServiceRepository,
    IServiceDTO,
    IServiceServiceDTO,
    GetServiceServiceUseCase,
    ICampusServiceDTO,
    ICampusServiceCampusServiceDTO,
    GetCampusServiceServiceUseCase,
    ICampusServiceNameDTO,
    ICampusServiceNameCampusServiceNameDTO,
    GetCampusServiceNameServiceUseCase,
    ICampusServiceCurrencyDTO,
    ICampusServiceCurrencyCampusServiceCurrencyDTO,
    GetCampusServiceCurrencyServiceUseCase,
    IServicesDTO,
    IServicesServicesDTO,
    GetServicesServiceUseCase,
    IServicesByAssignInputDTO,
    ServicesByAssignLogicUseCase,
} from "@management/domain/management";


export class ServiceFacade extends ServiceRepository {

    private static instance: ServiceFacade;

    constructor(private serviceRepository: ServiceRepository) {
        super();
    }

    public static getInstance(serviceRepository: ServiceRepository): ServiceFacade {
        if (!ServiceFacade.instance)
            ServiceFacade.instance = new ServiceFacade(serviceRepository);
        return ServiceFacade.instance;
    }

    GetServiceService(params: IServiceServiceDTO, loadService: boolean): Observable<IServiceDTO[]> {
        return GetServiceServiceUseCase.getInstance(this.serviceRepository).execute(params, loadService);
    }

    GetCampusServiceService(params: ICampusServiceCampusServiceDTO, loadService: boolean): Observable<ICampusServiceDTO[]> {
        return GetCampusServiceServiceUseCase.getInstance(this.serviceRepository).execute(params, loadService);
    }

    GetCampusServiceNameService(params: ICampusServiceNameCampusServiceNameDTO, loadService: boolean): Observable<ICampusServiceNameDTO[]> {
        return GetCampusServiceNameServiceUseCase.getInstance(this.serviceRepository).execute(params, loadService);
    }

    GetCampusServiceCurrencyService(params: ICampusServiceCurrencyCampusServiceCurrencyDTO, loadService: boolean): Observable<ICampusServiceCurrencyDTO[]> {
        return GetCampusServiceCurrencyServiceUseCase.getInstance(this.serviceRepository).execute(params, loadService);
    }
    GetServicesService(params: IServicesServicesDTO, loadService: boolean): Observable<IServicesDTO[]> {
        return GetServicesServiceUseCase.getInstance(this.serviceRepository).execute(params, loadService);
    }

    ServicesByAssignLogicUseCase(params: IServicesByAssignInputDTO): IServicesDTO[] {
        return ServicesByAssignLogicUseCase.getInstance().execute(params);
    };
}