<div class="container">

    <div class="container__c">
        <h3>Servicio</h3>
        <small>Organiza todo los servicios de la sede</small>
    </div>

    <dx-button icon="add" stylingMode="contained" text="{{'Añadir servicio' | translate }}" type="default" width="200"
        [disabled]="false" (click)="createService()">
    </dx-button>

    <div class="container__a">
        <app-filter [filters]="filters" [isUniqueFilter]="true" [currentPage]="onDemandPagination.currentPage"
            (onFilter)="onFilter($event)" (onClear)="onClear($event, true)"></app-filter>
    </div>
    <div class="container__b">

        <dx-data-grid id="567" [dataSource]="servicesByCampus" keyExpr="service_campus_id" [showBorders]="true"
            [focusedRowEnabled]="false" [height]="300" [columnAutoWidth]="false" [columnHidingEnabled]="true">

            <dxi-column dataField="service_campus_id" caption="{{'Id' | translate}}" [width]="50"></dxi-column>
            <dxi-column dataField="name" caption="{{'Nombre' | translate}}"></dxi-column>
            <dxi-column dataField="code" caption="{{'Moneda' | translate}}"></dxi-column>
            <dxi-column dataField="price" caption="{{'Costo' | translate}}"></dxi-column>
            <dxi-column dataField="" cellTemplate="actionsTemplate" [width]="100"></dxi-column>

            <div class="dev-row container__b__a" *dxTemplate="let cell of 'actionsTemplate'">
                <dx-drop-down-button text="Acciones" [items]="actions" displayExpr="value" keyExpr="id" [width]="'100%'"
                    (onItemClick)="onActionClick(cell.data, $event.itemData)" [dropDownOptions]="{ width: 150 }">
                </dx-drop-down-button>
            </div>
        </dx-data-grid>


    </div>
    @if(currentFilter.length === 0){
    <app-on-demand-pagination [pagination]="onDemandPagination"></app-on-demand-pagination>
    }
</div>


<lib-process-popup [config]="configPopupService">
    <app-service-form [services]="services" [currencies]="currencies" [configPopup]="configPopupService" 
        (sendForm)="submitData($event)" (close)="isVisiblePopupService($event)" component></app-service-form>
</lib-process-popup>