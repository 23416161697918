

import { UseCase } from '@management/base/management';
import { AuthRepository, ICompanySelectedDTO } from '@management/domain/management';
export class SetCompanySelectedReduxUseCase implements UseCase<ICompanySelectedDTO, void> {
    private static instance: SetCompanySelectedReduxUseCase;
    constructor(private authRepository: AuthRepository) { }
    public static getInstance(authRepository: AuthRepository): SetCompanySelectedReduxUseCase {
        if (!SetCompanySelectedReduxUseCase.instance)
            SetCompanySelectedReduxUseCase.instance = new SetCompanySelectedReduxUseCase(authRepository);
        return SetCompanySelectedReduxUseCase.instance;
    }
    execute(
        companySelected: ICompanySelectedDTO
    ): void {
        return this.authRepository.setCompanySelectedRedux(companySelected);
    }
}