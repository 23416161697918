import { actionsAuth } from './index';
import { createReducer, on } from '@ngrx/store';
import { IAuthDTO, ICampusByCompanyDTO, ICompanySelectedDTO } from "@management/domain/management";

export const AUTH_KEY = 'auth';
export interface AuthModel {
    user?: IAuthDTO
    companySelected?: ICompanySelectedDTO
    listCampusByCompany?: ICampusByCompanyDTO[]
}

export const AuthInitialState: AuthModel = {
    user: undefined,
    companySelected: undefined,
    listCampusByCompany: undefined
}


const _AuthReducer = createReducer(AuthInitialState,
    on(actionsAuth.setUser, (state, { authDTO }) => ({
        ...state,
        user: authDTO
    })),
    on(actionsAuth.setCampusByCompany, (state, { campusByCompany }) => ({
        ...state,
        listCampusByCompany: campusByCompany
    })),
    on(actionsAuth.setCompanySelected, (state, { companySelected }) => ({
        ...state,
        companySelected: companySelected
    })),
    on(actionsAuth.cleanAuth, (state) => ({
        ...AuthInitialState
    })),
);

export function AuthReducer(state: AuthModel, action: any) {
    return _AuthReducer(state, action);
}