import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonRepository,
    IAvailablePersonsDTO,
    IAvailablePersonsAvailablePersonsDTO
} from '@management/domain/management';

export class GetAvailablePersonsServiceUseCase implements UseCase<IAvailablePersonsAvailablePersonsDTO, IAvailablePersonsDTO[]> {

    private static instance: GetAvailablePersonsServiceUseCase;

    constructor(private availablePersonsRepository: PersonRepository) { }

    public static getInstance(availablePersonsRepository: PersonRepository): GetAvailablePersonsServiceUseCase {
        if (!GetAvailablePersonsServiceUseCase.instance)
            GetAvailablePersonsServiceUseCase.instance = new GetAvailablePersonsServiceUseCase(availablePersonsRepository);
        return GetAvailablePersonsServiceUseCase.instance;
    }

    execute(
        params: IAvailablePersonsAvailablePersonsDTO, loadService: boolean
    ): Observable<IAvailablePersonsDTO[]> {
        return this.availablePersonsRepository.GetAvailablePersonsService(params, loadService);
    }
}