<div class="paginator">
    <div (click)="goBack()">
        <span class="material-symbols-outlined">
            chevron_left
        </span>
    </div>
    <span>{{ pagination.currentPage }}</span>


    <div (click)="goForward()">
        <span class="material-symbols-outlined">
            chevron_right
        </span>
    </div>


</div>