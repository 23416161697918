
import { Mapper } from '@management/base/management';
import { IPersonCampusRolDTO } from '@management/domain/management';
import { IPersonCampusRolEntity } from '@management/data/management';

export class PersonCampusRolMapper extends Mapper<IPersonCampusRolEntity, IPersonCampusRolDTO> {

    private static instance: PersonCampusRolMapper;

    private constructor() { super(); }

    public static getInstance(): PersonCampusRolMapper {
        if (!PersonCampusRolMapper.instance)
            PersonCampusRolMapper.instance = new PersonCampusRolMapper();
        return PersonCampusRolMapper.instance;
    }


    mapFrom(param: IPersonCampusRolEntity): IPersonCampusRolDTO {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number,
            currency_name: param.currency_name,
            language_name: param.language_name,
            rol: param.rol,
            rol_name: param.rol_name
        };
    }
    mapFromList(params: IPersonCampusRolEntity[]): IPersonCampusRolDTO[] {
        return params.map((param: IPersonCampusRolEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IPersonCampusRolDTO): IPersonCampusRolEntity {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number,
            currency_name: param.currency_name,
            language_name: param.language_name,
            rol: param.rol,
            rol_name: param.rol_name
        }
    }
    mapToList(params: IPersonCampusRolDTO[]): IPersonCampusRolEntity[] {
        return params.map((param: IPersonCampusRolDTO) => {
            return this.mapTo(param);
        })
    }
}