import { Injectable } from '@angular/core';
import { Action, Store } from "@ngrx/store";

import {
    environment,
    HttpClient,
    map,
    Observable,
    of,
    ResolveRequest,
    keysSessionStorangeEnum
} from '@management/base/management';
//domain
import {
    ServiceRepository,
    IServiceDTO,
    IServiceServiceDTO,
    ICampusServiceDTO,
    ICampusServiceCampusServiceDTO,
    ICampusServiceNameDTO,
    ICampusServiceNameCampusServiceNameDTO,
    ICampusServiceCurrencyDTO,
    ICampusServiceCurrencyCampusServiceCurrencyDTO,
    IServicesDTO,
    IServicesServicesDTO,
    IPersonServicesDTO,
} from '@management/domain/management';
//data
import {
    IServiceEntity,
    ServiceMapper,
    ICampusServiceEntity,
    CampusServiceMapper,
    ICampusServiceNameEntity,
    CampusServiceNameMapper,
    ICampusServiceCurrencyEntity,
    CampusServiceCurrencyMapper,
    IServicesEntity,
    ServicesMapper,
} from '@management/data/management';

import {
    actionsService,
    reducerService,
    selectorsService
} from './redux';


@Injectable({
    providedIn: 'root',
})
export class ServiceImplementationRepository extends ServiceRepository {


    private readonly urlApiRest: string = environment.api.urlApiRest;
    private readonly serviceMapper = ServiceMapper.getInstance();
    private readonly servicesMapper = ServicesMapper.getInstance();
    private readonly campusServiceCurrencyMapper = CampusServiceCurrencyMapper.getInstance();
    private readonly campusServiceNameMapper = CampusServiceNameMapper.getInstance();
    private readonly campusServiceMapper = CampusServiceMapper.getInstance();

    constructor(
        private http: HttpClient,
        private resolveRequest: ResolveRequest,
        private store: Store<reducerService.ServiceModel>) {
        super();
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }


    GetServiceService(params: IServiceServiceDTO, loadService: boolean): Observable<IServiceDTO[]> {
        let data = this.getSessionStorange(keysSessionStorangeEnum.GetServiceService);
        if (!data || loadService)
            return this.http
                .post<IServiceEntity[]>(`${this.urlApiRest}service/list`, params)
                .pipe(map((response: any) => {
                    let res = this.resolveRequest.resolve<IServiceEntity[]>(response);
                    this.setSessionStorange(keysSessionStorangeEnum.GetServiceService, res);
                    return res;
                }))
                .pipe(map((entity: IServiceEntity[]) => entity && this.serviceMapper.mapFromList(entity)))
        return of(data);
    }

    GetCampusServiceService(params: ICampusServiceCampusServiceDTO, loadService: boolean): Observable<ICampusServiceDTO[]> {
        if (this.areEqualRequest<ICampusServiceCampusServiceDTO>({
            url: 'service/CampusService',
            body: params
        })) return of([]);
        return this.http
            .post<ICampusServiceEntity[]>(`${this.urlApiRest}service/CampusService`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<ICampusServiceEntity[]>(response);
                return res;
            }))
            .pipe(map((entity: ICampusServiceEntity[]) => entity && this.campusServiceMapper.mapFromList(entity)))
    }

    GetCampusServiceNameService(params: ICampusServiceNameCampusServiceNameDTO, loadService: boolean): Observable<ICampusServiceNameDTO[]> {
        return this.http
            .post<ICampusServiceNameEntity[]>(`${this.urlApiRest}service/CampusServiceName`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<ICampusServiceNameEntity[]>(response);
                return res;
            }))
            .pipe(map((entity: ICampusServiceNameEntity[]) => entity && this.campusServiceNameMapper.mapFromList(entity)))
    }

    GetCampusServiceCurrencyService(params: ICampusServiceCurrencyCampusServiceCurrencyDTO, loadService: boolean): Observable<ICampusServiceCurrencyDTO[]> {
        return this.http
            .post<ICampusServiceCurrencyEntity[]>(`${this.urlApiRest}service/CampusServiceCurrency`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<ICampusServiceCurrencyEntity[]>(response);
                return res;
            }))
            .pipe(map((entity: ICampusServiceCurrencyEntity[]) => entity && this.campusServiceCurrencyMapper.mapFromList(entity)))
    }
    GetServicesService(params: IServicesServicesDTO, loadService: boolean): Observable<IServicesDTO[]> {
        //let data = this.getSessionStorange(keysSessionStorangeEnum.GetServicesService);
        //if (!data || loadService)
        return this.http
            .post<IServicesEntity>(`${this.urlApiRest}service/list`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IServicesEntity[]>(response);
                //this.setSessionStorange(keysSessionStorangeEnum.GetServicesService, res);
                return res;
            }))
            .pipe(map((entity: IServicesEntity[]) => entity && this.servicesMapper.mapFromList(entity)))
        //return of(data);
    }
}