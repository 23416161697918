import { MessageTypeEnum, NotificationTypeEnum, TypeCodeHttp } from "../enums/enums"


export interface Response<T> {
    result: T | null
    message?: string | string[] | null
    notificationType: NotificationTypeEnum
    isValid: boolean
    messageType: MessageTypeEnum
    codeHttp: TypeCodeHttp,
    pagination?: Pagination
}

export interface Pagination {
    numberOfRows: number,
    numberOfPages: number,
    items: ItemDescription[]
}

export interface ItemDescription {
    id: number,
    startPage: number,
    pageLimit: number
}

export interface FailedAnswer {
    message?: string | string[] | null
    notificationType: NotificationTypeEnum
    isValid: boolean
    messageType: MessageTypeEnum
    codeHttp: TypeCodeHttp,
    pagination?: Pagination
}

export interface Country {
    id: string
    nombre: string
    estado: boolean
    sigla: string
    fechaInicio: string
}

export enum HEADERS {
    TOKEN = 'x-token',
    LANG = 'x-lang',
    COMPANY = 'x-company',
    CAMPUS = 'x-campus'
}

export interface PagedService<T> {
    result: T
    pagination?: Pagination
}


export interface AreEqualRequest<T> {
    url: string
    body: T
}

export interface Actions {
    id: number,
    value: string,
    icon?: string
}
