import { Mapper } from '@management/base/management';
import { IPersonCampusDTO } from '@management/domain/management';
import { IPersonCampusEntity } from '@management/data/management';

export class PersonCampusMapper extends Mapper<IPersonCampusEntity, IPersonCampusDTO> {

    private static instance: PersonCampusMapper;

    private constructor() { super(); }

    public static getInstance(): PersonCampusMapper {
        if (!PersonCampusMapper.instance)
            PersonCampusMapper.instance = new PersonCampusMapper();
        return PersonCampusMapper.instance;
    }


    mapFrom(param: IPersonCampusEntity): IPersonCampusDTO {
        return {
            id: param.id,
            person: param.person,
            campus: param.campus,
            state: param.state
        };
    }
    mapFromList(params: IPersonCampusEntity[]): IPersonCampusDTO[] {
        return params.map((param: IPersonCampusEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IPersonCampusDTO): IPersonCampusEntity {
        return {
            id: param.id,
            person: param.person,
            campus: param.campus,
            state: param.state
        }
    }
    mapToList(params: IPersonCampusDTO[]): IPersonCampusEntity[] {
        return params.map((param: IPersonCampusDTO) => {
            return this.mapTo(param);
        })
    }
}