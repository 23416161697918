

import { Mapper } from '@management/base/management';
import { CampusByCompanyRepositoryMapper, IAuthEntity, ICompanyEntity } from '@management/data/management';
import { IAuthDTO, ICompanyDTO } from '@management/domain/management';


export class CompanyImplementationRepositoryMapper extends Mapper<ICompanyEntity, ICompanyDTO> {


    mapFrom(param: ICompanyEntity): ICompanyDTO {
        return {
            id: param.id,
            initialDate: param.initialDate,
            returnDate: param.returnDate,
            state: param.state,
            country: param.country,
            name: param.name,
            cellPhone: param.cellPhone,
            address: param.address,
            phone: param.phone,
            identificationOfTheCompany: param.identificationOfTheCompany
        };
    }
    mapFromList(params: ICompanyEntity[]): ICompanyDTO[] {
        return params.map((param: ICompanyEntity) => {
            return this.mapFrom(param);
        })
    }
    mapTo(param: ICompanyDTO): ICompanyEntity {
        return {
            id: param.id,
            initialDate: param.initialDate,
            returnDate: param.returnDate,
            state: param.state,
            country: param.country,
            name: param.name,
            cellPhone: param.cellPhone,
            address: param.address,
            phone: param.phone,
            identificationOfTheCompany: param.identificationOfTheCompany
        }
    }
    mapToList(params: ICompanyDTO[]): ICompanyEntity[] {
        return params.map((param: ICompanyDTO) => {
            return this.mapTo(param);
        })
    }
}

