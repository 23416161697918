import { Observable } from "rxjs";
//domain
import {
    RolRepository,
    IRolDTO,
    IRolRolDTO,
    GetRolServiceUseCase,
} from "@management/domain/management";


export class RolFacade extends RolRepository {

    private static instance: RolFacade;

    constructor(private rolRepository: RolRepository) {
        super();
    }

    public static getInstance(rolRepository: RolRepository): RolFacade {
        if (!RolFacade.instance)
            RolFacade.instance = new RolFacade(rolRepository);
        return RolFacade.instance;
    }

    GetRolService(params: IRolRolDTO, loadService: boolean): Observable<IRolDTO[]> {
        return GetRolServiceUseCase.getInstance(this.rolRepository).execute(params, loadService);
    }

}