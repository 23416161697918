import { Injectable } from "@angular/core";
import { MessagesGLobal } from "@management/ui";
import { PagedService, Response } from '../models/models';

@Injectable({
    providedIn: 'root'
})
export class ResolveRequest {

    constructor(private messagesGLobal: MessagesGLobal) {
    }

    resolve<T>(requestResult: Response<T>) {
        try {
            this.messagesGLobal.setMessageConfig(this.messagesGLobal.mapperResponseToMessage(requestResult));
            return requestResult.result as T;

        } catch (error: any) {
            this.messagesGLobal.setMessageConfig(this.messagesGLobal.error(error))
            return requestResult.result as T
        }
    }

    resolvePagination<T>(requestResult: Response<T>): PagedService<T> {
        try {
            this.messagesGLobal.setMessageConfig(this.messagesGLobal.mapperResponseToMessage(requestResult));
            const data: PagedService<T> = {
                result: requestResult.result as T,
                pagination: requestResult?.pagination
            }
            return data;
        } catch (error: any) {
            this.messagesGLobal.setMessageConfig(this.messagesGLobal.error(error))
            return requestResult.result as PagedService<T>
        }
    }

}