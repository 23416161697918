import { FormGroup } from "@angular/forms";
import { CssClassEnum, ValidatorsEnum } from "./enum/global-enum";
import { AreEqualRequest, Pagination, keysSessionStorangeEnum } from "@management/base/management";
import { Configuration } from "./moleculas/filter";
import { Filter } from "@management/domain/management";
import { IOnDemandPaginationDTO } from "./moleculas/on-demand-pagination/on-demand-pagination.component";
import { TYPE_MODAL } from "./moleculas/process-popup";

export class UtilsComponent {

    currentPage = 1;
    totalPages = 0;
    pageGroup = 5;
    pageInit = {
        startPage: 0,
        pageLimit: this.pageGroup,
        pageGroup: this.pageGroup
    }
    pagination!: Pagination;
    filters: Configuration[] = [];
    currentFilter: Filter[] = [];
    onDemandPagination!: IOnDemandPaginationDTO;

    stopOnDemandPagination() {
        this.onDemandPagination = {
            ...this.onDemandPagination,
            currentPage: this.onDemandPagination.currentPage != 1 ? this.onDemandPagination.currentPage - 1 : 1,
            from: this.onDemandPagination.from - this.onDemandPagination.until
        }
    }

    resetOnDemandPagination() {
        this.clearLastRequest();
        this.onDemandPagination = {
            ...this.onDemandPagination,
            currentPage: 1,
            from: 0
        }
    }

    clearLastRequest() {
        this.setSessionStorange(keysSessionStorangeEnum.lastRequest, '');
    }



    validatorForm = (formGroup: FormGroup, value: string, error: ValidatorsEnum): boolean => {
        if (!formGroup) return false

        if (formGroup.get(value)?.invalid &&
            formGroup.get(value)?.touched &&
            (formGroup.get(value)?.value == '' || formGroup.get(value)?.value == undefined || formGroup.get(value)?.value == null) &&
            formGroup.get(value)?.errors?.[error])
            return true;

        if (formGroup.get(value)?.invalid &&
            formGroup.get(value)?.errors?.[error] &&
            formGroup.get(value)?.dirty)
            return true;

        return false;
    }

    isFormValid = (formGroup: FormGroup): boolean => {
        return formGroup.invalid;
    }

    isFormValidTS = (formGroup: FormGroup): boolean => {
        return !formGroup.invalid;
    }

    listHaveOnlyOneElement(value: any[]): boolean {
        if (value && value?.length === 1)
            return true
        return false
    }

    listHasValues(value: any[] | null | undefined) {
        if (value === null || value === undefined)
            return false
        if (value.length != 0)
            return true
        return false
    }

    setPaginator(data: any) {
        this.pagination = data.pagination;
        this.totalPages = data.pagination.numberOfPages
    }

    isNotUndefinedOrNull(value: any) {
        if (Array.isArray(value))
            if (value === null || value === undefined || value.length === 0)
                return false

        if (value !== undefined && value !== null)
            return true;
        return false;
    }

    setSessionStorange(key: string, data: any) {
        sessionStorage.setItem(key, JSON.stringify(data));
    }

    getSessionStorange(key: string) {
        let data = sessionStorage.getItem(key);
        if (data)
            return JSON.parse(data);
        return
    }

    areEqualRequest<T>(request: AreEqualRequest<T>): boolean {
        const lastRequest = this.getSessionStorange(keysSessionStorangeEnum.lastRequest);
        if (lastRequest && lastRequest.url === request.url && JSON.stringify(lastRequest.body) === JSON.stringify(request.body)) {
            return true
        }
        this.setSessionStorange(keysSessionStorangeEnum.lastRequest, request);
        return false
    }

    clearSessionStorange() {
        sessionStorage.clear();
    }

    get ValidatorsEnum(): typeof ValidatorsEnum {
        return ValidatorsEnum;
    }

    get CssClassEnum(): typeof CssClassEnum {
        return CssClassEnum;
    }

    get TypeModal(): typeof TYPE_MODAL {
        return TYPE_MODAL
    }

    hasUserActive = (): boolean => {
        const keysSessionStorangeEnumUSER = 'c567aed6-a770-49ce-b501-9de1ccbee652'
        return !!sessionStorage.getItem(keysSessionStorangeEnumUSER);
    }

}

export const getGeolocation = () => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(hasGeolocation, notHaveGeolocation)
    } else {
        alert('la localizacion esta activa')
    }
}

const hasGeolocation = (geoLocationPosition: any) => {
    console.log(geoLocationPosition)
    console.log('idioma',)
}

const notHaveGeolocation = () => {
    console.log('No tiene permisos para acceder a la localizacion')
}

export const getLanguage = () => {
    return window.navigator.language;
}






