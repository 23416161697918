export enum NotificationTypeEnum {
    SUCCESSFUL = 1,
    WARNING = 2,
    FAILED = 3
}

export enum MessageTypeEnum {
    STATIC_MESSAGE = 1,
    TEMPORARY_MESSAGE = 2,
    NONE = 3
}

export enum TypeCodeHttp {
    OK = 200,
    INTERNAL_SERVER_ERROR = 500,
    AUTHORIZATION_REQUIRED = 401,
    MODEL_VALIDATION = 400
}

export enum CONDITION {
    AND = 'and',
    OR = 'or',
    BETWEEN = 'between',
    EQUALS = '=',
    LIKE = 'like',
    DIFFERENT = '<>'
}

export enum TYPE_VALUE {
    NUMBER = 'number',
    STRING = 'string',
    DATE = 'date',
    RANGE_DATE = 'range_date',
    RANGE_NUMBER = 'range_number',
    RANGE_TIME = 'range_time',
    SELECT = 'select'
}


export enum TYPE_USER_BASE {
    USER = 'user',
    COLABORADOR = 'colaborador'
}



export enum ACTIONS {
    EDIT = 1,
    SEE = 2,
    DELETE = 3,
    CREATE = 4
}

export enum TYPE_ROLES {
    ADMINISTRATOR = 1,
    COLLABORATOR = 4,
    USER = 3
}

