import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    InfrastructureRepository,
    IInfrastructureDTO,
    IInfrastructureGetInfrastructureByCampusDTO
} from '@management/domain/management';

export class GetInfrastructureByCampusServiceUseCase implements UseCase<IInfrastructureGetInfrastructureByCampusDTO, IInfrastructureDTO[]> {

    private static instance: GetInfrastructureByCampusServiceUseCase;

    constructor(private infrastructureRepository: InfrastructureRepository) { }

    public static getInstance(infrastructureRepository: InfrastructureRepository): GetInfrastructureByCampusServiceUseCase {
        if (!GetInfrastructureByCampusServiceUseCase.instance)
            GetInfrastructureByCampusServiceUseCase.instance = new GetInfrastructureByCampusServiceUseCase(infrastructureRepository);
        return GetInfrastructureByCampusServiceUseCase.instance;
    }

    execute(
        params: IInfrastructureGetInfrastructureByCampusDTO, loadService: boolean
    ): Observable<IInfrastructureDTO[]> {
        return this.infrastructureRepository.GetInfrastructureByCampusService(params, loadService);
    }
}