import { actionsService } from './index';
import { createReducer, on } from '@ngrx/store';
import {  } from "@management/domain/management";

export const SERVICE_KEY = 'service'
export interface ServiceModel {
}

export const ServiceInitialState: ServiceModel = {
}

const _ServiceReducer = createReducer(ServiceInitialState,
);

export function ServiceReducer(state: ServiceModel, action: any) {
    return _ServiceReducer(state, action);
}