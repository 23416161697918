import { actionsPersonService } from './index';
import { createReducer, on } from '@ngrx/store';
import {  } from "@management/domain/management";

export const PERSON_SERVICE_KEY = 'personService'
export interface PersonServiceModel {
}

export const PersonServiceInitialState: PersonServiceModel = {
}

const _PersonServiceReducer = createReducer(PersonServiceInitialState,
);

export function PersonServiceReducer(state: PersonServiceModel, action: any) {
    return _PersonServiceReducer(state, action);
}