import { Mapper } from '@management/base/management';
import { ICurrencyDTO } from '@management/domain/management';
import { ICurrencyEntity } from '@management/data/management';

export class CurrencyMapper extends Mapper<ICurrencyEntity, ICurrencyDTO> {

    private static instance: CurrencyMapper;

    private constructor() { super(); }

    public static getInstance(): CurrencyMapper {
        if (!CurrencyMapper.instance)
            CurrencyMapper.instance = new CurrencyMapper();
        return CurrencyMapper.instance;
    }


    mapFrom(param: ICurrencyEntity): ICurrencyDTO {
        return {
            id: param.id,
            state: param.state,
            name: param.name,
            code: param.code
        };
    }
    mapFromList(params: ICurrencyEntity[]): ICurrencyDTO[] {
        return params.map((param: ICurrencyEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: ICurrencyDTO): ICurrencyEntity {
        return {
            id: param.id,
            state: param.state,
            name: param.name,
            code: param.code
        }
    }
    mapToList(params: ICurrencyDTO[]): ICurrencyEntity[] {
        return params.map((param: ICurrencyDTO) => {
            return this.mapTo(param);
        })
    }
}