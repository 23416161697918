
import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import { AuthRepository, IAuthDTO, IAuthSingInDTO } from '@management/domain/management';

export class SingInUseCase implements UseCase<IAuthSingInDTO, IAuthDTO> {

    private static instance: SingInUseCase;

    constructor(private authRepository: AuthRepository) { }

    public static getInstance(authRepository: AuthRepository): SingInUseCase {
        if (!SingInUseCase.instance)
            SingInUseCase.instance = new SingInUseCase(authRepository);
        return SingInUseCase.instance;
    }

    execute(
        params: IAuthSingInDTO, loadService: boolean
    ): Observable<IAuthDTO> {
        return this.authRepository.singIn(params, loadService);
    }
}

