import { Injectable } from '@angular/core';
import { Action, Store } from "@ngrx/store";

import {
    environment,
    HttpClient,
    map,
    Observable,
    of,
    ResolveRequest,
    keysSessionStorangeEnum
} from '@management/base/management';
//domain
import {
    LanguageRepository,
    ILanguageDTO,
    ILanguageLanguageDTO,
} from '@management/domain/management';
//data
import {
    ILanguageEntity,
    LanguageMapper,
} from '@management/data/management';

import {
    actionsLanguage, 
    reducerLanguage,
    selectorsLanguage
} from './redux';


@Injectable({
    providedIn: 'root',
})
export class LanguageImplementationRepository extends LanguageRepository {

    private readonly urlApiRest: string = environment.api.urlApiRest;
    private readonly languageMapper = LanguageMapper.getInstance();

    constructor(
        private http: HttpClient,
        private resolveRequest: ResolveRequest,
        private store: Store<reducerLanguage.LanguageModel>) {
        super();
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    GetLanguageService(params: ILanguageLanguageDTO, loadService: boolean): Observable<ILanguageDTO[]> {
        let data = this.getSessionStorange(keysSessionStorangeEnum.GetLanguageService);
        if (!data || loadService)
            return this.http
                .post<ILanguageEntity[]>(`${this.urlApiRest}language/list`, params)
                .pipe(map((response: any) => {
                    let res = this.resolveRequest.resolve<ILanguageEntity[]>(response);
                    this.setSessionStorange(keysSessionStorangeEnum.GetLanguageService, res);
                    return res;
                }))
                .pipe(map((entity: ILanguageEntity[]) => entity && this.languageMapper.mapFromList(entity)))
        return of(data);
    }

}