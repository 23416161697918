import { Observable } from "rxjs";
//domain
import {
    CurrencyRepository,
    ICurrencyDTO,
    ICurrencyCurrencyDTO,
    GetCurrencyServiceUseCase,
} from "@management/domain/management";


export class CurrencyFacade extends CurrencyRepository {

    private static instance: CurrencyFacade;

    constructor(private currencyRepository: CurrencyRepository) {
        super();
    }

    public static getInstance(currencyRepository: CurrencyRepository): CurrencyFacade {
        if (!CurrencyFacade.instance)
            CurrencyFacade.instance = new CurrencyFacade(currencyRepository);
        return CurrencyFacade.instance;
    }

    GetCurrencyService(params: ICurrencyCurrencyDTO, loadService: boolean): Observable<ICurrencyDTO[]> {
        return GetCurrencyServiceUseCase.getInstance(this.currencyRepository).execute(params, loadService);
    }

}