import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    ServiceCampusRepository,
    IServiceCampusPutDTO,
    IServiceCampusPutServiceCampusDTO
} from '@management/domain/management';

export class UpdateServiceCampusServiceUseCase implements UseCase<IServiceCampusPutServiceCampusDTO, IServiceCampusPutDTO> {

    private static instance: UpdateServiceCampusServiceUseCase;

    constructor(private serviceCampusPutRepository: ServiceCampusRepository) { }

    public static getInstance(serviceCampusPutRepository: ServiceCampusRepository): UpdateServiceCampusServiceUseCase {
        if (!UpdateServiceCampusServiceUseCase.instance)
            UpdateServiceCampusServiceUseCase.instance = new UpdateServiceCampusServiceUseCase(serviceCampusPutRepository);
        return UpdateServiceCampusServiceUseCase.instance;
    }

    execute(
        params: IServiceCampusPutServiceCampusDTO, loadService: boolean
    ): Observable<IServiceCampusPutDTO> {
        return this.serviceCampusPutRepository.UpdateServiceCampusService(params, loadService);
    }
}