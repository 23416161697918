import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { PopupColorTypeEnum } from '../../enum/global-enum';
import { ConfigProcessPopup } from './process-popup.interface';

const DEFAULT_CONFIG: ConfigProcessPopup = {
  width: '300',
  height: '250',
  dragEnabled: false,
  visible: false,
  popupColorType: PopupColorTypeEnum.SUCCESS
}


@Component({
  selector: 'lib-process-popup',
  templateUrl: './process-popup.component.html',
  styleUrls: ['./process-popup.component.scss']
})
export class ProcessPopupComponent implements OnInit {

  @Input() config!: ConfigProcessPopup;

  constructor() { }

  ngOnInit() {
    this.config = {
      ...DEFAULT_CONFIG,
      ...this.config
    }
  }

}
