import { Mapper } from "@management/base/management";
import { ICampusByCompanyEntity } from "@management/data/management";
import { ICampusByCompanyDTO } from "@management/domain/management";


export class CampusByCompanyRepositoryMapper extends Mapper<ICampusByCompanyEntity, ICampusByCompanyDTO> {
    mapFrom(param: ICampusByCompanyEntity): ICampusByCompanyDTO {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            company: param.company,
            country: param.country,
            telephoneOne: param.telephoneOne,
            telephoneTwo: param.telephoneTwo,
            address: param.address
        };
    }
    mapFromList(params: ICampusByCompanyEntity[]): ICampusByCompanyDTO[] {
        return params.map((param: ICampusByCompanyEntity) => {
            return this.mapFrom(param)
        })
    }
   
    mapTo(param: ICampusByCompanyDTO): ICampusByCompanyEntity {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            company: param.company,
            country: param.country,
            telephoneOne: param.telephoneOne,
            telephoneTwo: param.telephoneTwo,
            address: param.address
        }
    }
    mapToList(params: ICampusByCompanyDTO[]): ICampusByCompanyEntity[] {
        return params.map((param: ICampusByCompanyDTO) => {
            return this.mapTo(param);
        })
    }
}
