import { Observable } from 'rxjs';
                import { UseCase } from '@management/base/management';
                import {
                    PersonRepository,
                    IPersonServicesDTO,
                    IPersonServicesPersonServicesDTO
                } from '@management/domain/management';

export class GetPersonServicesServiceUseCase implements UseCase<IPersonServicesPersonServicesDTO, IPersonServicesDTO[]> {

    private static instance: GetPersonServicesServiceUseCase;

    constructor(private personServicesRepository: PersonRepository) { }

    public static getInstance(personServicesRepository: PersonRepository): GetPersonServicesServiceUseCase {
        if (!GetPersonServicesServiceUseCase.instance)
            GetPersonServicesServiceUseCase.instance = new GetPersonServicesServiceUseCase(personServicesRepository);
        return GetPersonServicesServiceUseCase.instance;
    }

    execute(
        params: IPersonServicesPersonServicesDTO, loadService: boolean
    ): Observable<IPersonServicesDTO[]> {
        return this.personServicesRepository.GetPersonServicesService(params, loadService);
    }
}