import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ICampusByCompanyEntity } from '@management/data/management';
import { ICampusByCompanyDTO, ICompanyDTO, ICompanySelectedDTO, ICompanySelectedRequestDTO, IMenuDTO } from '@management/domain/management';
import { AuthFacade, MenuFacade } from '@management/facade/management';
import { UtilsComponent } from '@management/ui';
import { first } from 'rxjs';

export interface IAppPopupUserData {
  userId: number
  companyId: number
  campusId: number
  listCompanies: ICompanyDTO[]
  listCampusByCompanies: ICampusByCompanyDTO[]
  onClose: () => void
}

@Component({
  selector: 'app-popup-user',
  templateUrl: './popup-user.component.html',
  styleUrls: ['./popup-user.component.scss']
})
export class PopupUserComponent extends UtilsComponent implements OnChanges {

  @Input() data!: IAppPopupUserData;
  form!: FormGroup;
  campusByCompany!: ICampusByCompanyEntity[];
  popupCompanySelected!: ICompanySelectedDTO;

  constructor(
    private fb: FormBuilder,
    private readonly _authFacade: AuthFacade,
    private readonly router: Router,
    private readonly _menuFacade: MenuFacade) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["data"]) {
      this.initForm();
    }
  }

  private initForm(): void {
    let buildForm = {
      'companyId': [this.data?.companyId, Validators.compose([Validators.required])],
      'campusId': [this.data?.campusId, Validators.compose([Validators.required])]
    };
    this.form = this.fb.group(buildForm);
  }

  companySelected(data: any): void {
    const { previousValue, value } = data;
    if (value && (value != previousValue)) {
      this.form.controls['campusId'].setValue(null);
      this._authFacade.getCampusByCompany({ companyId: value || 0 }, true, false)
        .pipe(first(e => this.isNotUndefinedOrNull(e)))
        .subscribe((data: ICampusByCompanyEntity[]) => {
          this.data.listCampusByCompanies = data;
          this.campusByCompany = data;
        });
    }
  }

  campusSeleted(data: any): void {
    const { previousValue, value } = data;
    if (value && (value != previousValue)) {
      this.getCompanySelected(
        {
          campusId: value,
          companyId: this.form.value.companyId,
          personId: this.data.userId
        }
      )
    }
  }

  getCompanySelected(companySelectedRequest: ICompanySelectedRequestDTO): void {
    this._authFacade.getCompanySelected(companySelectedRequest, true, false)
      .pipe(first(e => this.isNotUndefinedOrNull(e)))
      .subscribe((companySelected: ICompanySelectedDTO) => {
        this.popupCompanySelected = companySelected;
      });
  }

  submitData(): void {
    this.setRepositories();
    this.data.onClose();
  }

  private setRepositories(): void {
    this.popupCompanySelected && this._authFacade.setCompanySelectedSS(this.popupCompanySelected);
    this.popupCompanySelected && this._authFacade.setCompanySelectedRedux(this.popupCompanySelected);
    this.campusByCompany && this._authFacade.setCampusByCompanySS(this.campusByCompany);
    this.campusByCompany && this._authFacade.setCampusByCompanyRedux(this.campusByCompany);
    this.getRecursiveMenu();
  }

  closeSession(): void {
    this._authFacade.cleanAuthRedux();
    this._menuFacade.clearMenuRedux();
    this.clearSessionStorange();
    this.data.onClose();
    this.router.navigateByUrl('/');
  }

  private getRecursiveMenu(): void {
    this._menuFacade.getRecursiveMenu(true)
      .pipe(first(e => this.isNotUndefinedOrNull(e)))
      .subscribe((res: IMenuDTO[]) => {
        this._menuFacade.setRecursiveMenuRedux(res);
      })
  }

}
