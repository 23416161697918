import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    ServiceRepository,
    IServiceDTO,
    IServiceServiceDTO
} from '@management/domain/management';

export class GetServiceServiceUseCase implements UseCase<IServiceServiceDTO, IServiceDTO[]> {

    private static instance: GetServiceServiceUseCase;

    constructor(private serviceRepository: ServiceRepository) { }

    public static getInstance(serviceRepository: ServiceRepository): GetServiceServiceUseCase {
        if (!GetServiceServiceUseCase.instance)
            GetServiceServiceUseCase.instance = new GetServiceServiceUseCase(serviceRepository);
        return GetServiceServiceUseCase.instance;
    }

    execute(
        params: IServiceServiceDTO, loadService: boolean
    ): Observable<IServiceDTO[]> {
        return this.serviceRepository.GetServiceService(params, loadService);
    }
}