export enum LangEnum {
    enUS = 'en-US',
    esCO = 'es-CO'
}

export enum ValidatorsEnum{
    minlength = 'minlength',
    maxLength = 'maxLength',
    pattern = 'pattern',
    required = 'required'
}

export enum CssClassEnum{
    col = 'col',
    row = 'row',
    searchInput = 'searchInput'
}

export enum PopupColorTypeEnum {
   'SUCCESS' = '#5DB85B',
   'ERROR' = '#D9534F',
   'WARNING' = '#CBD12D',
   'NORMAL' = '#000000'

}

export enum NotificationTypeEnum {
    SUCCESSFUL = 1,
    WARNING = 2,
    FAILED = 3
}

export enum MessageTypeEnum {
    STATIC_MESSAGE = 1,
    TEMPORARY_MESSAGE = 2,
    NONE = 3
}