<div class="pagination">
  <div class="pagination-btn-next" (click)="previousPage()">
    <span class="material-symbols-outlined">
      chevron_left
    </span>
  </div>
  <ng-container *ngFor="let page of getPages()">
    <div class="pagination-btn" [class.active]="currentPage === page" (click)="goToPage(page)">{{ page }}</div>
  </ng-container>
  <div class="pagination-btn-next" (click)="nextPage()">
    <span class="material-symbols-outlined">
      chevron_right
    </span>
  </div>
</div>