
import { Mapper } from '@management/base/management';
import { IServiceCampusDeleteDTO } from '@management/domain/management';
import { IServiceCampusDeleteEntity } from '@management/data/management';

export class ServiceCampusDeleteMapper extends Mapper<IServiceCampusDeleteEntity, IServiceCampusDeleteDTO> {

    private static instance: ServiceCampusDeleteMapper;

    private constructor() { super(); }

    public static getInstance(): ServiceCampusDeleteMapper {
        if (!ServiceCampusDeleteMapper.instance)
            ServiceCampusDeleteMapper.instance = new ServiceCampusDeleteMapper();
        return ServiceCampusDeleteMapper.instance;
    }


    mapFrom(param: IServiceCampusDeleteEntity): IServiceCampusDeleteDTO {
        return {
            id: param.id,
            service: param.service,
            campus: param.campus,
            price: param.price,
            currency: param.currency
        };
    }
    mapFromList(params: IServiceCampusDeleteEntity[]): IServiceCampusDeleteDTO[] {
        return params.map((param: IServiceCampusDeleteEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IServiceCampusDeleteDTO): IServiceCampusDeleteEntity {
        return {
            id: param.id,
            service: param.service,
            campus: param.campus,
            price: param.price,
            currency: param.currency
        }
    }
    mapToList(params: IServiceCampusDeleteDTO[]): IServiceCampusDeleteEntity[] {
        return params.map((param: IServiceCampusDeleteDTO) => {
            return this.mapTo(param);
        })
    }
}