import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonInfraestructureRepository,
    IPersonInfraestructureDeleteDTO,
    IPersonInfraestructureDeletePersonInfraestructureDTO
} from '@management/domain/management';

export class DeletePersonInfraestructureServiceUseCase implements UseCase<IPersonInfraestructureDeletePersonInfraestructureDTO, IPersonInfraestructureDeleteDTO> {

    private static instance: DeletePersonInfraestructureServiceUseCase;

    constructor(private personInfraestructureDeleteRepository: PersonInfraestructureRepository) { }

    public static getInstance(personInfraestructureDeleteRepository: PersonInfraestructureRepository): DeletePersonInfraestructureServiceUseCase {
        if (!DeletePersonInfraestructureServiceUseCase.instance)
            DeletePersonInfraestructureServiceUseCase.instance = new DeletePersonInfraestructureServiceUseCase(personInfraestructureDeleteRepository);
        return DeletePersonInfraestructureServiceUseCase.instance;
    }

    execute(
        params: IPersonInfraestructureDeletePersonInfraestructureDTO, loadService: boolean
    ): Observable<IPersonInfraestructureDeleteDTO> {
        return this.personInfraestructureDeleteRepository.DeletePersonInfraestructureService(params, loadService);
    }
}