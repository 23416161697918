import { Mapper } from '@management/base/management';
import { ILanguageDTO } from '@management/domain/management';
import { ILanguageEntity } from '@management/data/management';

export class LanguageMapper extends Mapper<ILanguageEntity, ILanguageDTO> {

    private static instance: LanguageMapper;

    private constructor() { super(); }

    public static getInstance(): LanguageMapper {
        if (!LanguageMapper.instance)
            LanguageMapper.instance = new LanguageMapper();
        return LanguageMapper.instance;
    }


    mapFrom(param: ILanguageEntity): ILanguageDTO {
        return {
            id: param.id,
            state: param.state,
            name: param.name,
            code: param.code
        };
    }
    mapFromList(params: ILanguageEntity[]): ILanguageDTO[] {
        return params.map((param: ILanguageEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: ILanguageDTO): ILanguageEntity {
        return {
            id: param.id,
            state: param.state,
            name: param.name,
            code: param.code
        }
    }
    mapToList(params: ILanguageDTO[]): ILanguageEntity[] {
        return params.map((param: ILanguageDTO) => {
            return this.mapTo(param);
        })
    }
}