import { Injectable } from '@angular/core';
import { Action, Store } from "@ngrx/store";

import {
    environment,
    HttpClient,
    map,
    Observable,
    of,
    ResolveRequest,
    keysSessionStorangeEnum
} from '@management/base/management';
//domain
import {
    PersonServiceRepository,
    IPersonServiceDTO,
    IPersonServiceGetListPersonServiceDTO,
    ISavePersonServiceDTO,
    ISavePersonServiceSavePersonServiceDTO,
    IDeletePersonServiceDTO,
    IDeletePersonServicePersonServiceDTO,
} from '@management/domain/management';
//data
import {
    IPersonServiceEntity,
    PersonServiceMapper,
    ISavePersonServiceEntity,
    SavePersonServiceMapper,
    IDeletePersonServiceEntity,
    DeletePersonServiceMapper,
} from '@management/data/management';

import {
    actionsPersonService,
    reducerPersonService,
    selectorsPersonService
} from './redux';


@Injectable({
    providedIn: 'root',
})
export class PersonServiceImplementationRepository extends PersonServiceRepository {

    private readonly urlApiRest: string = environment.api.urlApiRest;
    private readonly personServiceMapper = PersonServiceMapper.getInstance();
    private readonly deletePersonServiceMapper = DeletePersonServiceMapper.getInstance();
    private readonly savePersonServiceMapper = SavePersonServiceMapper.getInstance();

    constructor(
        private http: HttpClient,
        private resolveRequest: ResolveRequest,
        private store: Store<reducerPersonService.PersonServiceModel>) {
        super();
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    GetGetListPersonServiceService(params: IPersonServiceGetListPersonServiceDTO, loadService: boolean): Observable<IPersonServiceDTO[]> {

        return this.http
            .post<IPersonServiceEntity>(`${this.urlApiRest}personService/list`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IPersonServiceEntity[]>(response);

                return res;
            }))
            .pipe(map((entity: IPersonServiceEntity[]) => entity && this.personServiceMapper.mapFromList(entity)))

    }

    SetSavePersonServiceService(params: ISavePersonServiceSavePersonServiceDTO, loadService: boolean): Observable<ISavePersonServiceDTO> {

        return this.http
            .post<ISavePersonServiceEntity>(`${this.urlApiRest}personService`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<ISavePersonServiceEntity>(response);

                return res;
            }))
            .pipe(map((entity: ISavePersonServiceEntity) => entity && this.savePersonServiceMapper.mapFrom(entity)))

    }
    DeletePersonServiceService(params: IDeletePersonServicePersonServiceDTO, loadService: boolean): Observable<IDeletePersonServiceDTO> {

        const options = {
            body: {
                id: params.id
            }
        }

        return this.http
            .delete<IDeletePersonServiceEntity>(`${this.urlApiRest}personService`, options)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IDeletePersonServiceEntity>(response);
                return res;
            }))
            .pipe(map((entity: IDeletePersonServiceEntity) => entity && this.deletePersonServiceMapper.mapFrom(entity)))

    }
}