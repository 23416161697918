import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    ServiceCampusRepository,
    IServiceCampusDTO,
    IServiceCampusServiceCampusDTO
} from '@management/domain/management';

export class SetServiceCampusServiceUseCase implements UseCase<IServiceCampusServiceCampusDTO, IServiceCampusDTO> {

    private static instance: SetServiceCampusServiceUseCase;

    constructor(private serviceCampusRepository: ServiceCampusRepository) { }

    public static getInstance(serviceCampusRepository: ServiceCampusRepository): SetServiceCampusServiceUseCase {
        if (!SetServiceCampusServiceUseCase.instance)
            SetServiceCampusServiceUseCase.instance = new SetServiceCampusServiceUseCase(serviceCampusRepository);
        return SetServiceCampusServiceUseCase.instance;
    }

    execute(
        params: IServiceCampusServiceCampusDTO, loadService: boolean
    ): Observable<IServiceCampusDTO> {
        return this.serviceCampusRepository.SetServiceCampusService(params, loadService);
    }
}