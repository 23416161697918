import { actionsAssignment } from './index';
import { createReducer, on } from '@ngrx/store';
import {  } from "@management/domain/management";

export const ASSIGNMENT_KEY = 'assignment'
export interface AssignmentModel {
}

export const AssignmentInitialState: AssignmentModel = {
}

const _AssignmentReducer = createReducer(AssignmentInitialState,
);

export function AssignmentReducer(state: AssignmentModel, action: any) {
    return _AssignmentReducer(state, action);
}