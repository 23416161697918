

import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import { AuthRepository, ICompanyDTO } from '@management/domain/management';
import { selector } from '@management/base/management';


export class GetListCompaniesReduxUseCase implements UseCase<selector<ICompanyDTO[]>, selector<ICompanyDTO[]>> {
    private static instance: GetListCompaniesReduxUseCase;
    constructor(private authRepository: AuthRepository) { }
    public static getInstance(authRepository: AuthRepository): GetListCompaniesReduxUseCase {
        if (!GetListCompaniesReduxUseCase.instance)
            GetListCompaniesReduxUseCase.instance = new GetListCompaniesReduxUseCase(authRepository);
        return GetListCompaniesReduxUseCase.instance;
    }
    execute(): Observable<selector<ICompanyDTO[]>> {
        return this.authRepository.getListCompaniesSelector()
    }
}