import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonInfraestructureRepository,
    ISavePersonInfraestructureDTO,
    ISavePersonInfraestructurePersonInfraestructureDTO
} from '@management/domain/management';

export class SetPersonInfraestructureServiceUseCase implements UseCase<ISavePersonInfraestructurePersonInfraestructureDTO, ISavePersonInfraestructureDTO> {

    private static instance: SetPersonInfraestructureServiceUseCase;

    constructor(private savePersonInfraestructureRepository: PersonInfraestructureRepository) { }

    public static getInstance(savePersonInfraestructureRepository: PersonInfraestructureRepository): SetPersonInfraestructureServiceUseCase {
        if (!SetPersonInfraestructureServiceUseCase.instance)
            SetPersonInfraestructureServiceUseCase.instance = new SetPersonInfraestructureServiceUseCase(savePersonInfraestructureRepository);
        return SetPersonInfraestructureServiceUseCase.instance;
    }

    execute(
        params: ISavePersonInfraestructurePersonInfraestructureDTO, loadService: boolean
    ): Observable<ISavePersonInfraestructureDTO> {
        return this.savePersonInfraestructureRepository.SetPersonInfraestructureService(params, loadService);
    }
}