import { PopupColorTypeEnum } from '../../enum/global-enum';

export interface ConfigProcessPopup {
    width: string
    height: string
    dragEnabled: boolean
    visible: boolean
    popupColorType: PopupColorTypeEnum
    typeModal?: TYPE_MODAL
    dataSource?: any
}

export enum TYPE_MODAL {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE',
    ADD = 'ADD',
    NA = 'NA'
}