import { Observable } from 'rxjs';
                import { UseCase } from '@management/base/management';
                import {
                    InfrastructureRepository,
                    IInfrastructuresDTO,
                    IInfrastructuresInfrastructuresDTO
                } from '@management/domain/management';

export class GetInfrastructuresServiceUseCase implements UseCase<IInfrastructuresInfrastructuresDTO, IInfrastructuresDTO[]> {

    private static instance: GetInfrastructuresServiceUseCase;

    constructor(private infrastructuresRepository: InfrastructureRepository) { }

    public static getInstance(infrastructuresRepository: InfrastructureRepository): GetInfrastructuresServiceUseCase {
        if (!GetInfrastructuresServiceUseCase.instance)
            GetInfrastructuresServiceUseCase.instance = new GetInfrastructuresServiceUseCase(infrastructuresRepository);
        return GetInfrastructuresServiceUseCase.instance;
    }

    execute(
        params: IInfrastructuresInfrastructuresDTO, loadService: boolean
    ): Observable<IInfrastructuresDTO[]> {
        return this.infrastructuresRepository.GetInfrastructuresService(params, loadService);
    }
}