import { ModuleWithProviders, NgModule } from '@angular/core';


import { HttpLoaderFactory, NgxTranslateModule } from '@management/ngx-translate';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BackComponent } from './components/buttons/back/back.component';
import { TitlePageComponent } from './components/titles/title-page/title-page.component';
import { LangComponent } from './components/buttons/lang/lang.component';
import { CredentialsComponent } from './components/titles/credentials/credentials.component';
import { ErrorComponent } from './components/titles/error/error.component';
import { SedeComponent } from './components/buttons/sede/sede.component';
import { UserComponent } from './components/buttons/user/user.component';
import { CloseComponent } from './components/buttons/close/close.component';
import { MessagesComponent } from './components/messages/messages.component';
import { PopupUserComponent } from './components/popup-user/popup-user.component';
import { DevextremeLibModule } from '@management/devextreme-lib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgxTranslateModule,
        DevextremeLibModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: false
        })],
    declarations: [
        BackComponent,
        TitlePageComponent,
        LangComponent,
        CredentialsComponent,
        ErrorComponent,
        SedeComponent,
        UserComponent,
        CloseComponent,
        MessagesComponent,
        PopupUserComponent
    ],
    exports: [
        BackComponent,
        TitlePageComponent,
        LangComponent,
        CredentialsComponent,
        ErrorComponent,
        SedeComponent,
        UserComponent,
        CloseComponent,
        MessagesComponent,
        PopupUserComponent
    ],
    providers: [],
})

export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: []
        };
    }
}
