
import { Observable } from 'rxjs'; import { UseCase } from '@management/base/management';

import { MenuRepository } from '@management/domain/management';


export class ClearMenuReduxUseCase implements UseCase<void, void> {

    constructor(private menuRepository: MenuRepository) { }
    execute(): void {
        return this.menuRepository.clearMenuRedux();
    }
}