<div class="container" *ngIf="hasUserActive()">
  <div class="container__a">
    <h3>{{'INSTALLATION_COMPONENT.typesInstallation' | translate }}</h3>
    <small>{{'INSTALLATION_COMPONENT.typesInstallationDescription' | translate}}</small>
  </div>
  <div class="container__b">
    <form class="container__b__a" [formGroup]="form" (ngSubmit)="submitData()">
      <dx-validation-group #myValidationGroup>

        <div class="container__b__a__a">
          <dx-button icon="add" stylingMode="contained" text="{{'INSTALLATION_COMPONENT.create' | translate }}"
            type="default" width="100" [useSubmitBehavior]="true">
          </dx-button>
        </div>

        <div class="container__b__a__b">

          <div class="container__b__a__b__a">
            <div>{{'INSTALLATION_COMPONENT.state' | translate}}</div>
            <dx-check-box formControlName="state"></dx-check-box>
          </div>

          <dx-text-box class="input container__b__a__b__b" formControlName="name" [showClearButton]="true"
            placeholder="{{ 'INSTALLATION_COMPONENT.name' | translate }}">
            <dx-validator>
              <dxi-validation-rule type="required"
                message="{{'LOGIN.mailIsRequired' | translate}}"></dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [min]="3"
                message="{{'LOGIN.mailIsTooShort' | translate}}"></dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [max]="30"
                message="{{'LOGIN.veryLongMail' | translate}}"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>

          <dx-text-box class="input container__b__a__b__c" formControlName="description" [showClearButton]="true"
            placeholder="{{ 'INSTALLATION_COMPONENT.description' | translate }}">
            <dx-validator>
              <dxi-validation-rule type="required"
                message="{{'LOGIN.mailIsRequired' | translate}}"></dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [min]="3"
                message="{{'LOGIN.mailIsTooShort' | translate}}"></dxi-validation-rule>
              <dxi-validation-rule type="stringLength" [max]="30"
                message="{{'LOGIN.veryLongMail' | translate}}"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>

        </div>
      </dx-validation-group>
    </form>
  </div>
  @if (installationTypes.length) {
  <div class="container__c">
    <div>{{'INSTALLATION_COMPONENT.installation' | translate}}</div>
    <small>{{'INSTALLATION_COMPONENT.theseAreTheFacilities' | translate}}</small>
  </div>

  <div class="container__d">
    <app-filter [filters]="filters" (onFilter)="onFilter($event)" (onClear)="onClear($event)"></app-filter>
  </div>
  <div class="container__e">

    <dx-data-grid  id="gridContainer" [dataSource]="installationTypes" keyExpr="id" [showBorders]="true"
      [focusedRowEnabled]="true" [height]="300" [columnAutoWidth]="false">

      <dxo-scrolling rowRenderingMode="virtual"> </dxo-scrolling>
      <dxi-column dataField="id" caption="{{'INSTALLATION_COMPONENT.id' | translate}}" [width]="50"></dxi-column>
      <dxi-column dataField="name" caption="{{'INSTALLATION_COMPONENT.name' | translate}}"></dxi-column>
      <dxi-column dataField="state" caption="{{'INSTALLATION_COMPONENT.state' | translate}}" [width]="70"></dxi-column>
      <dxi-column dataField="description" caption="{{'INSTALLATION_COMPONENT.description' | translate}}"
        cellTemplate="descriptionTemplate"></dxi-column>
      <dxi-column dataField="" cellTemplate="actionsTemplate" [width]="100"></dxi-column>

      <div class="dev-row" *dxTemplate="let cell of 'descriptionTemplate'">
        {{cell.data.description }}
      </div>
      <div class="dev-row container__e__a" *dxTemplate="let cell of 'actionsTemplate'">

        <dx-button icon="edit" [focusStateEnabled]="false" stylingMode="contained" type="default"
          [useSubmitBehavior]="false" [height]="20" (onClick)="popupEditInstallation(cell.data)">
        </dx-button>
        <dx-button icon="trash" [focusStateEnabled]="false" stylingMode="contained" type="default"
          [useSubmitBehavior]="false" [height]="20" (onClick)="deleteInstallation(cell.data)">
        </dx-button>

      </div>
    </dx-data-grid>
  </div>
  }
</div>

@if (installationTypes.length) {
<app-pagination [currentPage]="currentPage" [totalPages]="totalPages"
  (pageChange)="pageChange($event)"></app-pagination>
}

<!-- Popus -->
@if (popupEditData) {
<lib-process-popup [config]="configPopupEdit">
  <app-popup-edit-installation [data]="popupEditData" component></app-popup-edit-installation>
</lib-process-popup>
}



