import { UseCase } from '@management/base/management';
import {
    ServiceRepository,
    IServicesDTO,
    IServicesByAssignInputDTO
} from '@management/domain/management';

export class ServicesByAssignLogicUseCase implements UseCase<IServicesByAssignInputDTO, IServicesDTO[]> {

    private static instance: ServicesByAssignLogicUseCase;

    constructor() { }

    public static getInstance(): ServicesByAssignLogicUseCase {
        if (!ServicesByAssignLogicUseCase.instance)
            ServicesByAssignLogicUseCase.instance = new ServicesByAssignLogicUseCase();
        return ServicesByAssignLogicUseCase.instance;
    }

    execute(
        params: IServicesByAssignInputDTO
    ): IServicesDTO[] {
        let { personServices, servicesAvailable } = params;

        const result = servicesAvailable.filter((service: IServicesDTO) => {
            return !personServices.some(personService => personService.service === service.id);
        });

        return result;
    }
}





