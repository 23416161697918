import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UiModule, UtilsComponent } from '@management/ui';
import { AuthFacade, InfrastructureFacade, InstallationTypeFacade } from '@management/facade/management';
import { ICompanySelectedDTO, IInfrastructureDTO, IInstallationTypesDTO, ISaveInfrastructureDTO, ISaveInfrastructureSaveInfrastructureDTO, IStatesCrudInfraestructureDTO, IUpdateInfrastructurePutEditInfrastructureDTO } from '@management/domain/management';
import { first, take } from 'rxjs';
import { CONDITION, PagedService, TYPE_VALUE, selector } from '@management/base/management';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { NgxTranslateModule } from '@management/ngx-translate';
import { DevextremeLibModule } from '@management/devextreme-lib';
import { FormInfrastructureComponent } from './components/form-infrastructure/form-infrastructure.component';
import { InstallationComponent } from './components/installation/installation.component';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-infrastructure',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgxTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DevextremeLibModule,
    UiModule,
    FormInfrastructureComponent,
    RouterOutlet
  ],
  templateUrl: './infrastructure.component.html',
  styleUrls: ['./infrastructure.component.scss']
})
export class InfrastructureComponent extends UtilsComponent implements OnInit {

  public form!: FormGroup;
  public mainFacility = false;
  public campus: Campus[];
  public infrastructure: IInfrastructureDTO[] = [];
  public infrastructureTree: IInfrastructureDTO[] = [];
  public installationTypes: IInstallationTypesDTO[] = [];
  public companySelected!: ICompanySelectedDTO;
  public installationTypeSelected!: IInfrastructureDTO | undefined;
  public _fb = inject(FormBuilder);
  public _infrastructureFacade = inject(InfrastructureFacade);
  public _installationTypeFacade = inject(InstallationTypeFacade);
  public _authFacade = inject(AuthFacade);
  public readonly infraestructura = 'infraestructura';


  constructor() {
    super();
    this.campus = campus;
  }

  ngOnInit() {
    if (!this.hasUserActive()) return
    this.getCompanySelected();
    this.initForm();
    this.getGetInfrastructureByCampusService({});

  }

  public selectItem(e: any): void {
    if (!e.itemData.installation_type) return
    this.installationTypeSelected = e.itemData;
  }

  public initForm(): void {
    let buildForm = {
      "name": [,
        Validators.compose([
          Validators.required
        ])
      ],
      "state": [
        true,
        Validators.compose([
          Validators.required
        ])
      ],
      "description": [
        ,
        Validators.compose([
          Validators.required
        ])
      ]
    };
    this.form = this._fb.group(buildForm);
  }

  public submitData(): void {
    if (!this.isFormValidTS(this.form)) return
    const { state, description, name } = this.form.value;
    const { campus } = this.companySelected;
    const installation_type = this.installationTypes.find(installation => installation.homologation === this.infraestructura)?.id || 0;
    this.saveInfrastructureService({
      state,
      campus: campus.id,
      installation_type,
      name,
      description
    })
  }

  public saveInfrastructureService(saveInfrastructureDTO: ISaveInfrastructureSaveInfrastructureDTO): void {
    this._infrastructureFacade
      .SetSaveInfrastructureService(saveInfrastructureDTO, true)
      .pipe(take(1))
      .subscribe((mainInfrastructure: ISaveInfrastructureDTO) => {
        mainInfrastructure = { ...mainInfrastructure, top_id: mainInfrastructure.id };
        const { campus, description, id, installation_type, name, state, top_id } = mainInfrastructure;
        const stateToNumber: number = state ? 1 : 0;
        this.updatePutEditInfrastructureService({ campus, description, id, installation_type, name, state: stateToNumber, top_id })
      })
  }

  public updatePutEditInfrastructureService(infrastructureDTO: IUpdateInfrastructurePutEditInfrastructureDTO): void {
    this._infrastructureFacade
      .UpdatePutEditInfrastructureService(
        infrastructureDTO, true)
      .pipe(take(1))
      .subscribe((data) => {
        if (!data) return
        this.createInstallation();
        this.getGetInfrastructureByCampusService({});
      })
  }

  public createInstallation(): void {
    this.mainFacility = !this.mainFacility;
  }

  public getGetInfrastructureByCampusService(event: IStatesCrudInfraestructureDTO): void {
    this._infrastructureFacade.GetInfrastructureByCampusService({ fullData: true }, true)
      .pipe(first(infrastructure => !!infrastructure?.length))
      .subscribe((infrastructure: IInfrastructureDTO[]) => {
        this.infrastructure = infrastructure;
        this.installationTypeSelected = undefined;
        this.getInstallationTypes();
      })
  }

  private getCompanySelected(): void {
    this._authFacade.getCompanySelectedSelector()
      .pipe(first(e => this.isNotUndefinedOrNull(e)))
      .subscribe((companySelected: selector<ICompanySelectedDTO>) => {
        this.companySelected = companySelected as ICompanySelectedDTO;
      })
  }

  public getInstallationTypes(): void {
    this._installationTypeFacade.GetInstallationTypesService(
      {
        fullData: true,
        filters: [
          {
            field: 'campus',
            condition: CONDITION.EQUALS,
            type_value: TYPE_VALUE.NUMBER,
            value: this.companySelected.campus.id
          }
        ]
      }, true)
      .pipe(first((e) => !!e))
      .subscribe((installationTypes: PagedService<IInstallationTypesDTO[]>) => {
        this.installationTypes = installationTypes.result;
        this.buildTree(this.infrastructure);
      })
  }

  private buildTree(infrastructure: IInfrastructureDTO[]): void {
    this.infrastructureTree = [];
    infrastructure.forEach((element: IInfrastructureDTO, index: number) => {
      let architectureElements: IInfrastructureDTO[] = [];
      if (element.items.length >= 1) {
        architectureElements = this.buildTreeChildren(element.items, `${index + 1}`, element)
      } else {
        architectureElements = this.buildTreeChildren(element.items, `${index + 1}`, element)
      }
      this.infrastructureTree.push({
        id: `${index + 1}`,
        text: element.name,
        expanded: false,
        top_id: element.top_id,
        campus: element.campus,
        description: element.description,
        installation_type: element.installation_type,
        name: element.name,
        state: element.state,
        id_item: element.id_item,
        isEdit: true,
        items: architectureElements
      });
    });
  }

  private buildTreeChildren(infrastructure: IInfrastructureDTO[], acumulado: string, itemDad: IInfrastructureDTO): IInfrastructureDTO[] {
    let architectureElements: IInfrastructureDTO[] = []
    architectureElements.push({
      id: this.getLevelTree(acumulado, 0),
      text: '+ Tipos de instalación',
      expanded: false,
      top_id: 0,
      campus: 0,
      description: '',
      installation_type: 0,
      name: '',
      state: true,
      id_item: 1,
      items: this.typesOfHospitalFacility(this.getLevelTree(acumulado, 0), itemDad)
    });

    infrastructure.forEach((element: IInfrastructureDTO, index: number) => {
      architectureElements.push({
        id: this.getLevelTree(acumulado, index + 1),
        text: element.name,
        expanded: false,
        top_id: element.top_id,
        campus: element.campus,
        description: element.description,
        installation_type: element.installation_type,
        name: element.name,
        state: element.state,
        id_item: element.id_item,
        isEdit: true,
        items: element.items.length >= 1 ? this.buildTreeChildren(element.items, this.getLevelTree(acumulado, index + 1), element) :
          this.buildTreeChildren(element.items, this.getLevelTree(acumulado, index + 1), element)
      })
    });
    return architectureElements;
  }

  private getLevelTree(numberBase: string, index: number): string {
    let treePosition: string[] = []
    let splitAux: string[] = numberBase.split('_')
    treePosition = treePosition.concat(splitAux)
    treePosition.push(`${index}`)
    return treePosition.join('_').toString()
  }

  private typesOfHospitalFacility(idItemTree: string, infrastructure: IInfrastructureDTO): IInfrastructureDTO[] {
    let infrastructureDTO: IInfrastructureDTO[] = [];
    this.installationTypes.map((installationType: IInstallationTypesDTO) => {
      if (installationType.homologation != this.infraestructura)
        infrastructureDTO.push({
          id: `${idItemTree}_${installationType.id}`,
          text: `+ ${installationType.name}`,
          expanded: false,
          top_id: parseInt(infrastructure.id),
          campus: installationType.campus,
          description: '',
          installation_type: installationType.id,
          name: installationType.name,
          state: true,
          id_item: installationType.id,
          isEdit: false,
          items: []
        })
    })
    return infrastructureDTO;
  }
}


export class Campus {
  ID!: number
  Name!: string
}



const campus: Campus[] = [
  {
    ID: 1,
    Name: 'Colombia',
  },
  {
    ID: 2,
    Name: 'Mexico'
  }
]
