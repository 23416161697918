
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { IPersonDTO } from '@management/domain/management';
import { keysSessionStorangeEnum } from '../keysSessionStorange';

export const ShowLogin = () => {

    const router = inject(Router);
    const person = sessionStorage.getItem(keysSessionStorangeEnum.user);

    if (!person)
        return true;

    router.navigate(['home']);
    return false;

}