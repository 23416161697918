import { actionsRol } from './index';
import { createReducer, on } from '@ngrx/store';
import {  } from "@management/domain/management";

export const ROL_KEY = 'rol'
export interface RolModel {
}

export const RolInitialState: RolModel = {
}

const _RolReducer = createReducer(RolInitialState,
);

export function RolReducer(state: RolModel, action: any) {
    return _RolReducer(state, action);
}