
import { Mapper } from '@management/base/management';
import { IDeleteInfrastructureDTO } from '@management/domain/management';
import { IDeleteInfrastructureEntity } from '@management/data/management';

export class DeleteInfrastructureMapper extends Mapper<IDeleteInfrastructureEntity, IDeleteInfrastructureDTO> {

    private static instance: DeleteInfrastructureMapper;

    private constructor() { super(); }

    public static getInstance(): DeleteInfrastructureMapper {
        if (!DeleteInfrastructureMapper.instance)
            DeleteInfrastructureMapper.instance = new DeleteInfrastructureMapper();
        return DeleteInfrastructureMapper.instance;
    }


    mapFrom(param: IDeleteInfrastructureEntity): IDeleteInfrastructureDTO {
        return {
            state: param.state,
            top_id: param.top_id,
            campus: param.campus,
            installation_type: param.installation_type,
            id: param.id,
            name: param.name,
            description: param.description
        };
    }
    mapFromList(params: IDeleteInfrastructureEntity[]): IDeleteInfrastructureDTO[] {
        return params.map((param: IDeleteInfrastructureEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IDeleteInfrastructureDTO): IDeleteInfrastructureEntity {
        return {
            state: param.state,
            top_id: param.top_id,
            campus: param.campus,
            installation_type: param.installation_type,
            id: param.id,
            name: param.name,
            description: param.description
        }
    }
    mapToList(params: IDeleteInfrastructureDTO[]): IDeleteInfrastructureEntity[] {
        return params.map((param: IDeleteInfrastructureDTO) => {
            return this.mapTo(param);
        })
    }
}