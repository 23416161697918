import { UiModule, UtilsComponent } from '@management/ui';
import { Subscription } from 'rxjs';
import { ICompanyFilterDTO } from '@management/domain/management';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AuthFacade } from '@management/facade/management';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'apps/management/src/app/shared/shared.module';
import { NgxTranslateModule } from '@management/ngx-translate';
import { DevextremeLibModule } from '@management/devextreme-lib';



export interface DialogDataCompany {
  companyId: number | null;
  companyList: ICompanyFilterDTO[];
  onSelected: (companyId: number) => void
}

@Component({
  selector: 'app-dialog-select-company',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgxTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DevextremeLibModule,
    UiModule
  ],
  templateUrl: './dialog-select-company.component.html',
  styleUrls: ['./dialog-select-company.component.scss']
})
export class DialogSelectCompanyComponent extends UtilsComponent implements OnInit, OnDestroy {

  form!: FormGroup;
  private subscriptions = new Subscription();
  @Input() data!: DialogDataCompany;

  constructor(private fb: FormBuilder,
    public readonly _authFacade: AuthFacade) {
    super();
  }

  ngOnInit() {
    this.initForm();
    this.getListCompanies();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getListCompanies() {
    this.subscriptions.add(
      this._authFacade.getListCompaniesSelector().subscribe((res) => {
        if (!res) return
        this.data.companyList = res.map((x) => {
          return { id: x.id, name: x.name }
        })
      })
    )
  }


  private initForm() {

    let buildForm = {
      'companyId': [this.data.companyId,
      Validators.compose([
        Validators.required
      ])
      ],
    };
    this.form = this.fb.group(buildForm);
  }

  search(event: any) {
    if (event && event.target) {
      console.log(event.target.value)
    }
  }

  submitData() {
    this.data.onSelected(this.form.value.companyId);
  }

}
