import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonRepository,
    ICompanyCampusNameDTO,
    ICompanyCampusNameCompanyCampusNameDTO
} from '@management/domain/management';

export class GetCompanyCampusNameServiceUseCase implements UseCase<ICompanyCampusNameCompanyCampusNameDTO, ICompanyCampusNameDTO[]> {

    private static instance: GetCompanyCampusNameServiceUseCase;

    constructor(private companyCampusNameRepository: PersonRepository) { }

    public static getInstance(companyCampusNameRepository: PersonRepository): GetCompanyCampusNameServiceUseCase {
        if (!GetCompanyCampusNameServiceUseCase.instance)
            GetCompanyCampusNameServiceUseCase.instance = new GetCompanyCampusNameServiceUseCase(companyCampusNameRepository);
        return GetCompanyCampusNameServiceUseCase.instance;
    }

    execute(
        params: ICompanyCampusNameCompanyCampusNameDTO, loadService: boolean
    ): Observable<ICompanyCampusNameDTO[]> {
        return this.companyCampusNameRepository.GetCompanyCampusNameService(params, loadService);
    }
}