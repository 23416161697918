import { Mapper } from '@management/base/management';
import { IPersonInfraestructureDTO } from '@management/domain/management';
import { IPersonInfraestructureEntity } from '@management/data/management';

export class PersonInfraestructureMapper extends Mapper<IPersonInfraestructureEntity, IPersonInfraestructureDTO> {

    private static instance: PersonInfraestructureMapper;

    private constructor() { super(); }

    public static getInstance(): PersonInfraestructureMapper {
        if (!PersonInfraestructureMapper.instance)
            PersonInfraestructureMapper.instance = new PersonInfraestructureMapper();
        return PersonInfraestructureMapper.instance;
    }


    mapFrom(param: IPersonInfraestructureEntity): IPersonInfraestructureDTO {
        return {
            id: param.id,
            person: param.person,
            infrastructure: param.infrastructure,
            name_infrastructure: param.name_infrastructure
        };
    }
    mapFromList(params: IPersonInfraestructureEntity[]): IPersonInfraestructureDTO[] {
        return params.map((param: IPersonInfraestructureEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IPersonInfraestructureDTO): IPersonInfraestructureEntity {
        return {
            id: param.id,
            person: param.person,
            infrastructure: param.infrastructure,
            name_infrastructure: param.name_infrastructure
        }
    }
    mapToList(params: IPersonInfraestructureDTO[]): IPersonInfraestructureEntity[] {
        return params.map((param: IPersonInfraestructureDTO) => {
            return this.mapTo(param);
        })
    }
}