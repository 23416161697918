import { Injectable } from '@angular/core';
import { Action, select, Store } from "@ngrx/store";

import {
    environment,
    HttpClient,
    map,
    Observable,
    of,
    ResolveRequest,
    selector,
    keysSessionStorangeEnum
} from '@management/base/management';

import {
    AuthRepository,
    IAuthDTO,
    IAuthSingInDTO,
    ICampusByCompanyDTO,
    ICompanyDTO,
    ICompanySelectedDTO,
    ICompanySelectedRequestDTO,
    IPersonDTO
} from '@management/domain/management';

import {
    CampusByCompanyRepositoryMapper,
    CompanySelectedImplementationRepositoryMapper,
    IAuthEntity,
    ICampusByCompanyEntity,
    ICampusByCompanyRequestEntity,
    ICompanySelectedEntity,
    AuthMapper
} from '@management/data/management';

import {
    actionsAuth, 
    reducerAuth,
    selectorsAuth
} from './redux';


@Injectable({
    providedIn: 'root',
})
export class AuthImplementationRepository extends AuthRepository {

    private readonly urlApiRest: string = environment.api.urlApiRest;
    private readonly authMapper = AuthMapper.getInstance();
    private readonly campusByCompanyMapper = new CampusByCompanyRepositoryMapper();
    private readonly companySelectedMapper = new CompanySelectedImplementationRepositoryMapper();

    constructor(
        private http: HttpClient,
        private resolveRequest: ResolveRequest,
        private store: Store<reducerAuth.AuthModel>) {
        super();
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    singIn(params: IAuthSingInDTO, loadService: boolean): Observable<IAuthDTO> {
        let data = this.getSessionStorange(keysSessionStorangeEnum.user);
        if (!data || loadService)
            return this.http
                .post<IAuthEntity>(`${this.urlApiRest}auth/singIn`, params)
                .pipe(map((response: any) => {
                    let res = this.resolveRequest.resolve<IAuthEntity>(response);
                    this.setSessionStorange(keysSessionStorangeEnum.user, res);
                    return res;
                }))
                .pipe(map((entity: IAuthEntity) => entity && this.authMapper.mapFrom(entity)))
        return of(data);
    }

    setUserReduxUseCase(authDTO: IAuthDTO): void {
        this.dispatch(actionsAuth.setUser({ authDTO }));
    }

    getPersonSelector(): Observable<selector<IPersonDTO>> {
        return this.store.pipe(select(selectorsAuth.getPerson));
    }

    getListCompaniesSelector(): Observable<selector<ICompanyDTO[]>> {
        return this.store.pipe(select(selectorsAuth.getListCompanies));
    }

    getCampusByCompany(params: ICampusByCompanyRequestEntity, loadService: boolean, loadSessionStorange: boolean): Observable<ICampusByCompanyEntity[]> {
        let data = this.getSessionStorange(keysSessionStorangeEnum.campusByCompany);
        if (!data || loadService)
            return this.http.post(`${this.urlApiRest}auth/campusByCompany`, params)
                .pipe(map((response: any) => {
                    let res = this.resolveRequest.resolve<ICampusByCompanyEntity[]>(response);
                    if (loadSessionStorange)
                        this.setSessionStorange(keysSessionStorangeEnum.campusByCompany, res);
                    return res;
                }))
                .pipe(map((entity: ICampusByCompanyEntity[]) => {
                    return entity && this.campusByCompanyMapper.mapFromList(entity)
                }))

        return of(data);
    }

    setCampusByCompanySS(params: ICampusByCompanyEntity[]): void {
        this.setSessionStorange(keysSessionStorangeEnum.campusByCompany, params);
    }

    setCampusByCompanyRedux(campusByCompany: ICampusByCompanyDTO[]): void {
        this.dispatch(actionsAuth.setCampusByCompany({ campusByCompany }));
    }

    setCompanySelectedRedux(companySelected: ICompanySelectedDTO): void {
        this.dispatch(actionsAuth.setCompanySelected({ companySelected }));
    }

    getCompanySelectedSelector(): Observable<selector<ICompanySelectedDTO>> {
        return this.store.pipe(select(selectorsAuth.getCompanySelected));
    }

    getListCampusByCompanySelector(): Observable<selector<ICampusByCompanyDTO[]>> {
        return this.store.pipe(select(selectorsAuth.getListCampusByCompany));
    }


    getCompanySelected(params: ICompanySelectedRequestDTO, loadService: boolean, loadSessionStorange: boolean): Observable<ICompanySelectedDTO> {
        let data = this.getSessionStorange(keysSessionStorangeEnum.userCompanySelected);
        if (!data || loadService)
            return this.http.post(`${this.urlApiRest}auth/companySelected`, params)
                .pipe(map((response: any) => {
                    let res = this.resolveRequest.resolve<ICompanySelectedEntity>(response);
                    if (loadSessionStorange)
                        this.setSessionStorange(keysSessionStorangeEnum.userCompanySelected, res);
                    return res;
                }))
                .pipe(map((entity: ICompanySelectedEntity) => {
                    return entity && this.companySelectedMapper.mapFrom(entity);
                }))
        return of(data);
    }

    setCompanySelectedSS(params: ICompanySelectedDTO): void {
        this.setSessionStorange(keysSessionStorangeEnum.userCompanySelected, this.companySelectedMapper.mapTo(params));
    }

    cleanAuthRedux(): void {
        this.dispatch(actionsAuth.cleanAuth());
    }


}