import { Observable } from "rxjs";
import {
    AuthRepository,
    IAuthDTO,
    IAuthSingInDTO,
    IPersonDTO,
    SetUserReduxUseCase,
    SingInUseCase,
    GetPersonReduxUseCase,
    ICampusByCompanyDTO,
    ICompanyDTO,
    ICompanySelectedDTO,
    GetListCompaniesReduxUseCase,
    GetCampusByCompany,
    SetCampusByCompanyReduxUseCase,
    SetCompanySelectedReduxUseCase,
    GetCompanySelectedReduxUseCase,
    GetListCampusByCompanySelector,
    ICompanySelectedRequestDTO,
    GetCompanySelectedUsecase,
    CleanAuthReduxUseCase,
    SetCompanySeletedSS,
    SetCampusByCompanySS
} from "@management/domain/management";

import { selector } from '@management/base/management';
import { ICampusByCompanyEntity, ICampusByCompanyRequestEntity } from "@management/data/management";

export class AuthFacade extends AuthRepository {

    private static instance: AuthFacade;

    constructor(private authRepository: AuthRepository) {
        super();
    }

    public static getInstance(authRepository: AuthRepository): AuthFacade {
        if (!AuthFacade.instance)
            AuthFacade.instance = new AuthFacade(authRepository);
        return AuthFacade.instance;
    }

    /**
     Realiza una solicitud de inicio de sesión de autenticación utilizando los parámetros proporcionados y devuelve un Observable con la respuesta de la solicitud.
     @param params Los parámetros necesarios para realizar la solicitud de inicio de sesión.
     @param loadService Booleano que indica si se debe cargar el servicio al realizar la solicitud de inicio de sesión.
     @returns Un Observable que emite una respuesta de la solicitud de inicio de sesión.
     */
    singIn(params: IAuthSingInDTO, loadService: boolean): Observable<IAuthDTO> {
        return SingInUseCase.getInstance(this.authRepository).execute(params, loadService);
    }

    setUserReduxUseCase(authDTO: IAuthDTO): void {
        return SetUserReduxUseCase.getInstance(this.authRepository).execute(authDTO);
    }

    getListCompaniesSelector(): Observable<selector<ICompanyDTO[]>> {
        return GetListCompaniesReduxUseCase.getInstance(this.authRepository).execute();
    }

    getCampusByCompany(params: ICampusByCompanyRequestEntity, loadService: boolean, loadSessionStorange: boolean): Observable<ICampusByCompanyEntity[]> {
        return GetCampusByCompany.getInstance(this.authRepository).execute(params, loadService, loadSessionStorange);
    }

    setCampusByCompanySS(params: ICampusByCompanyEntity[]): void {
        SetCampusByCompanySS.getInstance(this.authRepository).execute(params);
    }

    setCampusByCompanyRedux(campusByCompany: ICampusByCompanyDTO[]): void {
        return SetCampusByCompanyReduxUseCase.getInstance(this.authRepository).execute(campusByCompany);
    }

    setCompanySelectedRedux(companySelected: ICompanySelectedDTO): void {
        return SetCompanySelectedReduxUseCase.getInstance(this.authRepository).execute(companySelected);
    }

    getPersonSelector(): Observable<selector<IPersonDTO>> {
        return GetPersonReduxUseCase.getInstance(this.authRepository).execute()
    }

    getCompanySelectedSelector(): Observable<selector<ICompanySelectedDTO>> {
        return GetCompanySelectedReduxUseCase.getInstance(this.authRepository).execute();
    }

    getListCampusByCompanySelector(): Observable<selector<ICampusByCompanyDTO[]>> {
        return GetListCampusByCompanySelector.getInstance(this.authRepository).execute();
    }

    getCompanySelected(params: ICompanySelectedRequestDTO, loadService: boolean, loadSessionStorange: boolean): Observable<ICompanySelectedDTO> {
        return GetCompanySelectedUsecase.getInstance(this.authRepository).execute(params, loadService, loadSessionStorange);
    }

    setCompanySelectedSS(params: ICompanySelectedDTO): void {
        SetCompanySeletedSS.getInstance(this.authRepository).execute(params);
    }

    cleanAuthRedux(): void {
        return CleanAuthReduxUseCase.getInstance(this.authRepository).execute();
    }


}

