
import { UseCase } from '@management/base/management';
import { AuthRepository, ICompanySelectedDTO } from '@management/domain/management';

export class SetCompanySeletedSS implements UseCase<ICompanySelectedDTO, void> {
    
    private static instance: SetCompanySeletedSS;

    constructor(private authRepository: AuthRepository) { }

    public static getInstance(authRepository: AuthRepository): SetCompanySeletedSS {
        if (!SetCompanySeletedSS.instance)
            SetCompanySeletedSS.instance = new SetCompanySeletedSS(authRepository);
        return SetCompanySeletedSS.instance;
    }

    execute(params: ICompanySelectedDTO): void {
        this.authRepository.setCompanySelectedSS(params);
    }
}