import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    InstallationTypeRepository,
    IUpdateInstallationTypeDTO,
    IUpdateInstallationTypeInstallationTypeDTO
} from '@management/domain/management';

export class UpdateInstallationTypeServiceUseCase implements UseCase<IUpdateInstallationTypeInstallationTypeDTO, IUpdateInstallationTypeDTO> {

    private static instance: UpdateInstallationTypeServiceUseCase;

    constructor(private updateInstallationTypeRepository: InstallationTypeRepository) { }

    public static getInstance(updateInstallationTypeRepository: InstallationTypeRepository): UpdateInstallationTypeServiceUseCase {
        if (!UpdateInstallationTypeServiceUseCase.instance)
            UpdateInstallationTypeServiceUseCase.instance = new UpdateInstallationTypeServiceUseCase(updateInstallationTypeRepository);
        return UpdateInstallationTypeServiceUseCase.instance;
    }

    execute(
        params: IUpdateInstallationTypeInstallationTypeDTO, loadService: boolean
    ): Observable<IUpdateInstallationTypeDTO> {
        return this.updateInstallationTypeRepository.UpdateInstallationTypeService(params, loadService);
    }
}