
import { UseCase } from '@management/base/management';
import { AuthRepository, IAuthDTO } from '@management/domain/management';

export class SetUserReduxUseCase implements UseCase<IAuthDTO, IAuthDTO> {

    private static instance: SetUserReduxUseCase;

    constructor(private authRepository: AuthRepository) { }

    public static getInstance(authRepository: AuthRepository): SetUserReduxUseCase {
        if (!SetUserReduxUseCase.instance)
            SetUserReduxUseCase.instance = new SetUserReduxUseCase(authRepository);
        return SetUserReduxUseCase.instance;
    }

    execute(
        authDTO: IAuthDTO
    ): void {
        return this.authRepository.setUserReduxUseCase(authDTO)
    }
}