import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { NgxTranslateModule } from '@management/ngx-translate';
import { DevextremeLibModule } from '@management/devextreme-lib';
import {
  AuthFacade,
  CurrencyFacade,
  LanguageFacade,
  PersonCampusFacade,
  PersonCompanyFacade,
  PersonFacade,
  RolFacade,
  ScheduleFacade
} from '@management/facade/management';
import {
  Filter,
  ICompanyCampusNameDTO,
  ICompanySelectedDTO,
  ICurrencyDTO,
  ILanguageDTO,
  IPersonCompanyCampusDTO,
  IPersonDTO,
  IPersonPersonDTO,
  IRolDTO
} from '@management/domain/management';
import { filter, take } from 'rxjs';
import { ACTIONS, Actions, TYPE_ROLES, TYPE_USER_BASE, selector } from '@management/base/management';
import { IOnDemandPaginationDTO } from 'libs/ui/src/lib/moleculas/on-demand-pagination/on-demand-pagination.component';
import { CONDITION, Filter as LibraryFilter, TYPE_VALUE, UiModule, UtilsComponent } from '@management/ui';
import { loadMessages, locale } from 'devextreme/localization';
import es from '../../../assets/dev-extreme-lan/es.json';
import { ConfigProcessPopup, TYPE_MODAL } from 'libs/ui/src/lib/moleculas/process-popup/process-popup.interface';
import { PopupColorTypeEnum } from 'libs/ui/src/lib/enum/global-enum';
import { PersonEditPopupComponent } from './components/person-edit-popup/person-edit-popup.component';


@Component({
  selector: 'app-staff',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgxTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DevextremeLibModule,
    UiModule,
    PersonEditPopupComponent
  ],
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent extends UtilsComponent implements OnInit {

  public override onDemandPagination: IOnDemandPaginationDTO = {
    callbackService: (pagination: IOnDemandPaginationDTO) => {
      this.getPersonCompanyCampusService(pagination.from)
    },
    from: 0,
    until: 6,
    currentPage: 1
  }
  public actions: Actions[] = [
    { id: 1, value: 'Editar', icon: 'edit' },
    { id: 3, value: 'Eliminar', icon: 'remove' }
  ];

  public configPopupPerson: ConfigProcessPopup = {
    width: '400',
    height: '500',
    dragEnabled: false,
    visible: false,
    popupColorType: PopupColorTypeEnum.NORMAL,
    typeModal: TYPE_MODAL.CREATE
  }
  public person!: IPersonPersonDTO;
  public persons: IPersonCompanyCampusDTO[] = [];
  public currencies: ICurrencyDTO[] = []
  public companySelectedDTO!: ICompanySelectedDTO
  public languages: ILanguageDTO[] = []
  public rols: IRolDTO[] = []


  public _personFacade = inject(PersonFacade);
  public _authFacade = inject(AuthFacade);
  public _currencyFacade = inject(CurrencyFacade);
  public _languageFacade = inject(LanguageFacade);
  public _personCompanyFacade = inject(PersonCompanyFacade);
  public _personCampusFacade = inject(PersonCampusFacade);
  public _rolFacade = inject(RolFacade);
  public _scheduleFacade = inject(ScheduleFacade);

  constructor() {
    super()
  }

  ngOnInit() {
    if (!this.hasUserActive()) return
    this.getCompanySelectedSelector();
    this.clearLastRequest();
    this.getPersonCompanyCampusService(this.onDemandPagination.from);
    this.initFilters();
    this.loadLocale();
  }

  public loadLocale(): void {
    loadMessages(es);
    locale('es');
  }

  public initFilters(): void {
    this.filters = [
      { id: 1, type_value: TYPE_VALUE.STRING, condition: CONDITION.LIKE, field: 'name', value: 'INSTALLATION_COMPONENT.name' },
      { id: 2, type_value: TYPE_VALUE.STRING, condition: CONDITION.LIKE, field: 'email', value: 'Correo' },
      { id: 3, type_value: TYPE_VALUE.STRING, condition: CONDITION.LIKE, field: 'identificationNumber', value: 'Identificacion' }
    ];
  }

  public onFilter(filter?: LibraryFilter[]): void {
    if (filter?.length === 0) return
    this.clearLastRequest();
    this.currentFilter = filter as Filter[];
    const [getFilter] = this.currentFilter;

    switch (getFilter.field) {
      case 'name':
        this.getCompanyCampusNameService(getFilter)
        break;
      case 'email':
        this.getCompanyCampusEmailService(getFilter)
        break;
      case 'identificationNumber':
        this.getCompanyCampusIdentificationService(getFilter)
        break;

    }
  }

  public getCompanyCampusNameService(getFilter: Filter): void {
    if (!getFilter?.value) return
    this._personFacade
      .GetCompanyCampusNameService({ name: getFilter.value.toString() }, true)
      .pipe(take(1))
      .subscribe((person: ICompanyCampusNameDTO[]) => {
        if (this.listHasValues(person))
          return this.persons = person;
        return this.persons = [];
      })
  }

  public getCompanyCampusEmailService(getFilter: Filter): void {
    if (!getFilter?.value) return
    this._personFacade
      .GetCompanyCampusEmailService({ email: getFilter.value.toString() }, true)
      .pipe(take(1))
      .subscribe((person: ICompanyCampusNameDTO[]) => {
        if (this.listHasValues(person))
          return this.persons = person;
        return this.persons = [];
      })
  }

  public getCompanyCampusIdentificationService(getFilter: Filter): void {
    if (!getFilter?.value) return
    this._personFacade
      .GetCompanyCampusIdentificationService({ identificationNumber: getFilter.value.toString() }, true)
      .pipe(take(1))
      .subscribe((person: ICompanyCampusNameDTO[]) => {
        if (this.listHasValues(person))
          return this.persons = person;
        return this.persons = [];
      })
  }

  public onClear(event?: boolean, clearFilter?: boolean): void {
    if (this.listHasValues(this.currentFilter) && !clearFilter) {
      this.onFilter(this.currentFilter)
    } else {
      this.currentFilter = [];
      this.resetOnDemandPagination();
      this.getPersonCompanyCampusService(this.onDemandPagination.from);
    }
  }

  public getPersonCompanyCampusService(from: number): void {
    this._personFacade
      .GetPersonCompanyCampusService({ from, until: this.onDemandPagination.until }, true)
      .pipe(take(1))
      .subscribe((persons: IPersonCompanyCampusDTO[]) => {
        if (persons.length === 0) {
          this.stopOnDemandPagination();
        } else {
          this.persons = persons;
        }
      })
  }


  public createPerson(): void {
    this.isVisiblePopupPerson(true, TYPE_MODAL.CREATE);
  }

  public submitData(form: FormGroup): void {
    if (!this.isFormValidTS(form)) return
    switch (this.configPopupPerson.typeModal) {
      case TYPE_MODAL.CREATE:
        this.createServiceDynamic(form);
        break;
      case TYPE_MODAL.UPDATE:
        this.updateServiceDynamic(form);
        break;
    }
  }

  public createServiceDynamic(form: FormGroup): void {
    const { rol, ...person } = form.value;
    const rolSeleted = this.rols.find((rol) => rol.id === form.value.rol)
    const initialDate = new Date();
    this._personFacade.SetPersonService({
      ...person,
      code_rol: rolSeleted?.name === TYPE_USER_BASE.USER ? TYPE_USER_BASE.USER : '',
      initialDate
    }, true)
      .pipe(take(1))
      .subscribe({
        next: (person: IPersonDTO) => {
          person = { ...person, rol: rol };
          this.createPerson();
          this.setPersonCompanyService(rol, person);
          this.createSchedule(rol, person);
          this.isVisiblePopupPerson(false);
        }
      })
  }

  public updateServiceDynamic(form: FormGroup): void {
    console.log(form);

  }


  public createSchedule(idRol: number, person: IPersonDTO): void {
    const { id } = this.companySelectedDTO.campus;
    const rolSeleted = this.rols.find((rol) => rol.id === idRol)
    //TODO cuando este configurando las keys de traducciones OJO con el colaborador...
    if (rolSeleted?.name === TYPE_USER_BASE.COLABORADOR)
      this._scheduleFacade
        .SetScheduleService({ person: person.id, campus: id }, true)
        .pipe(take(1))
        .subscribe(() => {

        })
  }

  public isVisiblePopupPerson(visible: boolean, typeModal?: TYPE_MODAL, data?: any): void {
    this.configPopupPerson = {
      ...this.configPopupPerson,
      typeModal,
      dataSource: data,
      visible: visible
    }
  }

  public getCurrencyService(): void {
    this._currencyFacade
      .GetCurrencyService({ fullData: true }, false)
      .pipe(take(1))
      .subscribe((currencies: ICurrencyDTO[]) => this.currencies = currencies)
  }

  public getLanguageService(): void {
    this._languageFacade
      .GetLanguageService({ fullData: true }, false)
      .pipe(take(1))
      .subscribe((language: ILanguageDTO[]) => this.languages = language);
  }

  public getRolService(): void {
    const { id } = this.companySelectedDTO.campus;
    const filterBase = [{
      value: id,
      type_value: TYPE_VALUE.NUMBER,
      condition: CONDITION.EQUALS,
      field: "company"
    }];
    this._rolFacade
      .GetRolService({ fullData: true, filters: filterBase }, false)
      .pipe(take(1))
      .subscribe((rols: IRolDTO[]) => this.rols = rols);
  }

  public setPersonCompanyService(idRol: number, person: IPersonDTO): void {
    const { id } = this.companySelectedDTO.company;
    const rolSeleted = this.rols.find((rol) => rol.id === idRol)
    //TODO cuando este configurando las keys de traducciones OJO con el colaborador...
    if (!(rolSeleted?.name != TYPE_USER_BASE.USER)) return

    this._personCompanyFacade.SetPersonCompanyService({
      person: person.id,
      company: id,
      rol: person.rol || 0,
      state: true
    }, true)
      .pipe(take(1))
      .subscribe(() => {
        this.setPersonCampusService(person);
      })
  }

  public getCompanySelectedSelector(): void {
    this._authFacade.getCompanySelectedSelector()
      .pipe(take(1))
      .subscribe((res: selector<ICompanySelectedDTO>) => {
        this.companySelectedDTO = res as ICompanySelectedDTO;
        this.getRolService();
        this.getLanguageService();
        this.getCurrencyService();
      })
  }

  public setPersonCampusService(person: IPersonDTO): void {
    const { id } = this.companySelectedDTO.campus;
    this._personCampusFacade
      .SetPersonCampusService({
        person: person.id,
        campus: id,
        state: true
      }, true)
      .pipe(take(1))
      .subscribe(() => {
        this.clearLastRequest();
        this.onClear();
      })
  }



  public onActionClick(person: IPersonCompanyCampusDTO, event: Actions): void {
    console.log(person)
    console.log(event)
    switch (event.id) {
      case ACTIONS.EDIT:
        this.isVisiblePopupPerson(true, TYPE_MODAL.UPDATE, person);
        break;
      case ACTIONS.DELETE:
        this.deletePersonRoleCollaboratorService(person);
        this.deleteAllPersonService(person);
        break;
    }
  }

  public deletePersonRoleCollaboratorService(person: IPersonCompanyCampusDTO): void {
    let rol = this.rols.find(rol => rol.id === person.rol);
    if (rol?.code === TYPE_ROLES.COLLABORATOR)
      this._personFacade
        .DeleteDeletePersonRoleCollaboratorService({ id: person.id || 0 }, true)
        .pipe(take(1))
        .pipe(filter(person => !!person?.id))
        .subscribe(() => {
          this.clearLastRequest();
          this.onClear();
        })
  }

  public deleteAllPersonService(person: IPersonCompanyCampusDTO): void {
    let rol = this.rols.find(rol => rol.id === person.rol);
    if (rol?.code !== TYPE_ROLES.COLLABORATOR)
      this._personFacade
        .DeleteDeleteAllPersonService({ id: person.id || 0 }, true)
        .pipe(take(1))
        .pipe(filter(person => !!person?.id))
        .subscribe(() => {
          this.clearLastRequest();
          this.onClear();
        })
  }



}













