import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonInfraestructureRepository,
    IPersonInfraestructureDTO,
    IPersonInfraestructurePersonInfraestructuresDTO
} from '@management/domain/management';

export class GetPersonInfraestructuresServiceUseCase implements UseCase<IPersonInfraestructurePersonInfraestructuresDTO, IPersonInfraestructureDTO[]> {

    private static instance: GetPersonInfraestructuresServiceUseCase;

    constructor(private personInfraestructureRepository: PersonInfraestructureRepository) { }

    public static getInstance(personInfraestructureRepository: PersonInfraestructureRepository): GetPersonInfraestructuresServiceUseCase {
        if (!GetPersonInfraestructuresServiceUseCase.instance)
            GetPersonInfraestructuresServiceUseCase.instance = new GetPersonInfraestructuresServiceUseCase(personInfraestructureRepository);
        return GetPersonInfraestructuresServiceUseCase.instance;
    }

    execute(
        params: IPersonInfraestructurePersonInfraestructuresDTO, loadService: boolean
    ): Observable<IPersonInfraestructureDTO[]> {
        return this.personInfraestructureRepository.GetPersonInfraestructuresService(params, loadService);
    }
}