import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonRepository,
    ITypeUserEmailDTO,
    ITypeUserEmailTypeUserByEmailDTO
} from '@management/domain/management';

export class GetTypeUserByEmailServiceUseCase implements UseCase<ITypeUserEmailTypeUserByEmailDTO, ITypeUserEmailDTO[]> {

    private static instance: GetTypeUserByEmailServiceUseCase;

    constructor(private typeUserEmailRepository: PersonRepository) { }

    public static getInstance(typeUserEmailRepository: PersonRepository): GetTypeUserByEmailServiceUseCase {
        if (!GetTypeUserByEmailServiceUseCase.instance)
            GetTypeUserByEmailServiceUseCase.instance = new GetTypeUserByEmailServiceUseCase(typeUserEmailRepository);
        return GetTypeUserByEmailServiceUseCase.instance;
    }

    execute(
        params: ITypeUserEmailTypeUserByEmailDTO, loadService: boolean
    ): Observable<ITypeUserEmailDTO[]> {
        return this.typeUserEmailRepository.GetTypeUserByEmailService(params, loadService);
    }
}