
import { Mapper } from '@management/base/management';
import { ISavePersonServiceDTO } from '@management/domain/management';
import { ISavePersonServiceEntity } from '@management/data/management';

export class SavePersonServiceMapper extends Mapper<ISavePersonServiceEntity, ISavePersonServiceDTO> {

    private static instance: SavePersonServiceMapper;

    private constructor() { super(); }

    public static getInstance(): SavePersonServiceMapper {
        if (!SavePersonServiceMapper.instance)
            SavePersonServiceMapper.instance = new SavePersonServiceMapper();
        return SavePersonServiceMapper.instance;
    }


    mapFrom(param: ISavePersonServiceEntity): ISavePersonServiceDTO {
        return {
            id: param.id,
            person: param.person,
            campus: param.campus,
            service: param.service,
            state: param.state
        };
    }
    mapFromList(params: ISavePersonServiceEntity[]): ISavePersonServiceDTO[] {
        return params.map((param: ISavePersonServiceEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: ISavePersonServiceDTO): ISavePersonServiceEntity {
        return {
            id: param.id,
            person: param.person,
            campus: param.campus,
            service: param.service,
            state: param.state
        }
    }
    mapToList(params: ISavePersonServiceDTO[]): ISavePersonServiceEntity[] {
        return params.map((param: ISavePersonServiceDTO) => {
            return this.mapTo(param);
        })
    }
}