import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonRepository,
    IPersonDeleteAllDTO,
    IPersonDeleteAllDeleteAllPersonDTO
} from '@management/domain/management';

export class DeleteDeleteAllPersonServiceUseCase implements UseCase<IPersonDeleteAllDeleteAllPersonDTO, IPersonDeleteAllDTO> {

    private static instance: DeleteDeleteAllPersonServiceUseCase;

    constructor(private personDeleteAllRepository: PersonRepository) { }

    public static getInstance(personDeleteAllRepository: PersonRepository): DeleteDeleteAllPersonServiceUseCase {
        if (!DeleteDeleteAllPersonServiceUseCase.instance)
            DeleteDeleteAllPersonServiceUseCase.instance = new DeleteDeleteAllPersonServiceUseCase(personDeleteAllRepository);
        return DeleteDeleteAllPersonServiceUseCase.instance;
    }

    execute(
        params: IPersonDeleteAllDeleteAllPersonDTO, loadService: boolean
    ): Observable<IPersonDeleteAllDTO> {
        return this.personDeleteAllRepository.DeleteDeleteAllPersonService(params, loadService);
    }
}