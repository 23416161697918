import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DevextremeLibModule } from '@management/devextreme-lib';
import { ICurrencyDTO, IServiceDTO } from '@management/domain/management';
import { NgxTranslateModule } from '@management/ngx-translate';
import { UiModule, UtilsComponent } from '@management/ui';
import { SharedModule } from 'apps/management/src/app/shared/shared.module';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { ConfigProcessPopup, TYPE_MODAL } from 'libs/ui/src/lib/moleculas/process-popup/process-popup.interface';

@Component({
  selector: 'app-service-form',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgxTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DevextremeLibModule,
    UiModule
  ],
  templateUrl: './service-form.component.html',
  styleUrls: ['./service-form.component.scss']
})
export class ServiceFormComponent extends UtilsComponent implements  OnChanges {
  @ViewChild('validationGroupService', { static: false }) validationGroup!: DxValidationGroupComponent;
  @Input({ required: true }) services: IServiceDTO[] = [];
  @Input({ required: true }) currencies: ICurrencyDTO[] = [];
  @Input({ required: true }) configPopup!: ConfigProcessPopup;
  @Output() close = new EventEmitter<boolean>();
  @Output() sendForm = new EventEmitter<FormGroup>();

  public form!: FormGroup;

  public _fb = inject(FormBuilder);


  constructor() {
    super();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (!this.hasUserActive()) return
    const hasToResetForm = changes['configPopup'] && !this.configPopup.visible;
    if (hasToResetForm)
      this.clear();
    if (changes["configPopup"]) {
      this.initForm();
    }

  }

  public initForm(): void {
    switch (this.configPopup.typeModal) {
      case TYPE_MODAL.ADD:
        this.form = this._fb.group(this.initFormAdd());
        break;
      case TYPE_MODAL.UPDATE:
        this.form = this._fb.group(this.inintFormUpdate());
        break;
    }
  }

  public initFormAdd() {
    return {
      "service": [,
        Validators.compose([
          Validators.required
        ])
      ],
      "currency": [
        ,
        Validators.compose([
          Validators.required
        ])
      ],
      "price": [
        ,
        Validators.compose([
          Validators.required
        ])
      ],
    };
  }

  public inintFormUpdate() {
    const { id, currency, price } = this.configPopup.dataSource;
    return {
      "service": [id,
        Validators.compose([
          Validators.required
        ])
      ],
      "currency": [currency,
        Validators.compose([
          Validators.required
        ])
      ],
      "price": [price,
        Validators.compose([
          Validators.required
        ])
      ],
    };
  }

  public submitData(): void {
    if (!this.isFormValidTS(this.form)) return
    this.sendForm.emit(this.form)
  }

  public clear(): void {
    this.form?.reset();
    this.validationGroup?.instance?.reset();
  }

  public closePopup(): void {
    this.clear();
    this.close.emit(false);
  }

  public typeModal(): string {
    switch (this.configPopup.typeModal) {
      case TYPE_MODAL.ADD:
        return 'Asignar'
      case TYPE_MODAL.CREATE:
        return 'Crear'
      case TYPE_MODAL.UPDATE:
        return 'Editar'
    }
    return '';
  }


}
