import { Injectable } from '@angular/core';
import { Action, Store } from "@ngrx/store";

import {
    environment,
    HttpClient,
    map,
    Observable,
    of,
    ResolveRequest,
    keysSessionStorangeEnum
} from '@management/base/management';
//domain
import {
    ScheduleRepository,
    IScheduleDTO,
    IScheduleScheduleDTO,
    IScheduleSaveDTO,
    IScheduleSaveScheduleDTO,
} from '@management/domain/management';
//data
import {
    IScheduleEntity,
    ScheduleMapper,
    IScheduleSaveEntity,
    ScheduleSaveMapper,
} from '@management/data/management';

import {
    actionsSchedule,
    reducerSchedule,
    selectorsSchedule
} from './redux';


@Injectable({
    providedIn: 'root',
})
export class ScheduleImplementationRepository extends ScheduleRepository {

    private readonly urlApiRest: string = environment.api.urlApiRest;
    private readonly scheduleMapper = ScheduleMapper.getInstance();
    private readonly scheduleSaveMapper = ScheduleSaveMapper.getInstance();

    constructor(
        private http: HttpClient,
        private resolveRequest: ResolveRequest,
        private store: Store<reducerSchedule.ScheduleModel>) {
        super();
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    GetScheduleService(params: IScheduleScheduleDTO, loadService: boolean): Observable<IScheduleDTO[]> {
        return this.http
            .post<IScheduleEntity>(`${this.urlApiRest}schedule/list`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IScheduleEntity[]>(response);
                return res;
            }))
            .pipe(map((entity: IScheduleEntity[]) => entity && this.scheduleMapper.mapFromList(entity)))
    }

    SetScheduleService(params: IScheduleSaveScheduleDTO, loadService: boolean): Observable<IScheduleSaveDTO> {
        return this.http
            .post<IScheduleSaveEntity>(`${this.urlApiRest}schedule`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IScheduleSaveEntity>(response);
                return res;
            }))
            .pipe(map((entity: IScheduleSaveEntity) => entity && this.scheduleSaveMapper.mapFrom(entity)))
    }
}