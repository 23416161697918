
import { Mapper } from '@management/base/management';
import { IInstallationTypesDTO } from '@management/domain/management';
import { IInstallationTypesEntity } from '@management/data/management';

export class InstallationTypesMapper extends Mapper<IInstallationTypesEntity, IInstallationTypesDTO> {

    private static instance: InstallationTypesMapper;

    private constructor() { super(); }

    public static getInstance(): InstallationTypesMapper {
        if (!InstallationTypesMapper.instance)
            InstallationTypesMapper.instance = new InstallationTypesMapper();
        return InstallationTypesMapper.instance;
    }


    mapFrom(param: IInstallationTypesEntity): IInstallationTypesDTO {
        return {
            id: param.id,
            state: param.state,
            campus: param.campus,
            name: param.name,
            homologation: param.homologation,
            description: param.description,

        };
    }
    mapFromList(params: IInstallationTypesEntity[]): IInstallationTypesDTO[] {
        return params.map((param: IInstallationTypesEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IInstallationTypesDTO): IInstallationTypesEntity {
        return {
            id: param.id,
            state: param.state,
            campus: param.campus,
            name: param.name,
            homologation: param.homologation,
            description: param.description,

        }
    }
    mapToList(params: IInstallationTypesDTO[]): IInstallationTypesEntity[] {
        return params.map((param: IInstallationTypesDTO) => {
            return this.mapTo(param);
        })
    }
}