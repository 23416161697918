<div class="container" *ngIf="form && data">
  <div class="container__title">Usuario</div>
  <div class="container__description">Seleccione la configuración que prefiera. </div>

  <form class="container__form" [formGroup]="form" (ngSubmit)="submitData()">
    <div class="container__form__label">Compañia</div>
    <dx-select-box formControlName="companyId" [searchEnabled]="true" [showClearButton]="true"
      [noDataText]="'No se encontro datos'" width="100%" [dataSource]="data.listCompanies" displayExpr="name"
      valueExpr="id"  (onValueChanged)="companySelected($event)" >
      <dx-validator>
        <dxi-validation-rule type="required" message="{{'GLOBAL.obligatoryField' | translate}}"></dxi-validation-rule>
      </dx-validator>
    </dx-select-box>
    <br>
    <div class="container__form__label">Sede</div>
    <dx-select-box formControlName="campusId" [searchEnabled]="true" [showClearButton]="true"
      [noDataText]="'No se encontro datos'" width="100%" [dataSource]="data.listCampusByCompanies" displayExpr="name"
      valueExpr="id"  (onValueChanged)="campusSeleted($event)">
      <dx-validator>
        <dxi-validation-rule type="required" message="{{'GLOBAL.obligatoryField' | translate}}"></dxi-validation-rule>
      </dx-validator>
    </dx-select-box>

    <dx-button class="container__form__sesion" stylingMode="outlined" text="{{'Cerrar sesión' | translate }}" type="normal"
      width="100%" (onClick)="closeSession()">
    </dx-button>

    <dx-button class="container__form__button" stylingMode="outlined" text="{{'Aceptar' | translate }}" type="default"
      width="100%" [disabled]="isFormValid(form)" [useSubmitBehavior]="true">
    </dx-button>

  </form>

</div>