import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonRepository,
    IPersonCompanyCampusDTO,
    IPersonCompanyCampusPersonCompanyCampusDTO
} from '@management/domain/management';

export class GetPersonCompanyCampusServiceUseCase implements UseCase<IPersonCompanyCampusPersonCompanyCampusDTO, IPersonCompanyCampusDTO[]> {

    private static instance: GetPersonCompanyCampusServiceUseCase;

    constructor(private personCompanyCampusRepository: PersonRepository) { }

    public static getInstance(personCompanyCampusRepository: PersonRepository): GetPersonCompanyCampusServiceUseCase {
        if (!GetPersonCompanyCampusServiceUseCase.instance)
            GetPersonCompanyCampusServiceUseCase.instance = new GetPersonCompanyCampusServiceUseCase(personCompanyCampusRepository);
        return GetPersonCompanyCampusServiceUseCase.instance;
    }

    execute(
        params: IPersonCompanyCampusPersonCompanyCampusDTO, loadService: boolean
    ): Observable<IPersonCompanyCampusDTO[]> {
        return this.personCompanyCampusRepository.GetPersonCompanyCampusService(params, loadService);
    }
}