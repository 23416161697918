import { Observable } from 'rxjs';
                import { UseCase } from '@management/base/management';
                import {
                    PersonRepository,
                    IPersonRolIdenticationDTO,
                    IPersonRolIdenticationPersonCompanyCampusRolIdenDTO
                } from '@management/domain/management';

export class GetPersonCompanyCampusRolIdenServiceUseCase implements UseCase<IPersonRolIdenticationPersonCompanyCampusRolIdenDTO, IPersonRolIdenticationDTO[]> {

    private static instance: GetPersonCompanyCampusRolIdenServiceUseCase;

    constructor(private personRolIdenticationRepository: PersonRepository) { }

    public static getInstance(personRolIdenticationRepository: PersonRepository): GetPersonCompanyCampusRolIdenServiceUseCase {
        if (!GetPersonCompanyCampusRolIdenServiceUseCase.instance)
            GetPersonCompanyCampusRolIdenServiceUseCase.instance = new GetPersonCompanyCampusRolIdenServiceUseCase(personRolIdenticationRepository);
        return GetPersonCompanyCampusRolIdenServiceUseCase.instance;
    }

    execute(
        params: IPersonRolIdenticationPersonCompanyCampusRolIdenDTO, loadService: boolean
    ): Observable<IPersonRolIdenticationDTO[]> {
        return this.personRolIdenticationRepository.GetPersonCompanyCampusRolIdenService(params, loadService);
    }
}