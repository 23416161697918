
import { Mapper } from '@management/base/management';
import { IUpdateInstallationTypeDTO } from '@management/domain/management';
import { IUpdateInstallationTypeEntity } from '@management/data/management';

export class UpdateInstallationTypeMapper extends Mapper<IUpdateInstallationTypeEntity, IUpdateInstallationTypeDTO> {

    private static instance: UpdateInstallationTypeMapper;

    private constructor() { super(); }

    public static getInstance(): UpdateInstallationTypeMapper {
        if (!UpdateInstallationTypeMapper.instance)
            UpdateInstallationTypeMapper.instance = new UpdateInstallationTypeMapper();
        return UpdateInstallationTypeMapper.instance;
    }


    mapFrom(param: IUpdateInstallationTypeEntity): IUpdateInstallationTypeDTO {
        return {
            id: param.id,
            state: param.state,
            campus: param.campus,
            name: param.name,
            homologation: param.homologation,
            description: param.description,
        };
    }
    mapFromList(params: IUpdateInstallationTypeEntity[]): IUpdateInstallationTypeDTO[] {
        return params.map((param: IUpdateInstallationTypeEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IUpdateInstallationTypeDTO): IUpdateInstallationTypeEntity {
        return {
            id: param.id,
            state: param.state,
            campus: param.campus,
            name: param.name,
            homologation: param.homologation,
            description: param.description,
        }
    }
    mapToList(params: IUpdateInstallationTypeDTO[]): IUpdateInstallationTypeEntity[] {
        return params.map((param: IUpdateInstallationTypeDTO) => {
            return this.mapTo(param);
        })
    }
}