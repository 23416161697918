import { actionsServiceCampus } from './index';
import { Injectable } from "@angular/core";
import { mergeMap, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

@Injectable()
export class ServiceCampusEffects {

    constructor(
        private actions$: Actions
    ) { }

}