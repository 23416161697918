
import { PersonGuard, ShowLogin } from '@management/base/management';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';
import { AssignmentComponent } from './modules/assignment/assignment.component';
import { StaffComponent } from './modules/staff/staff.component';
import { InfrastructureComponent } from './modules/infrastructure/infrastructure.component';
import { InstallationComponent } from './modules/infrastructure/components/installation/installation.component';
import { HomeComponent } from './modules/home/home.component';
import { Routes } from '@angular/router';
import { ServiceComponent } from './modules/service/service.component';
import { AssignPersonServiceComponent } from './modules/staff/components/assign-person-service/assign-person-service.component';
import { AssignPersonInfrastructureComponent } from './modules/staff/components/assign-person-infrastructure/assign-person-infrastructure.component';


export const routes: Routes = [
  {
    path: '', loadChildren: () =>
      import('./modules/login/login.module').then(m => m.LoginModule),
    canActivate: [ShowLogin]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [PersonGuard]
  },
  {
    path: 'infrastructure',
    component: InfrastructureComponent,
    canActivate: [PersonGuard]
  },
  {
    path: 'infrastructure/installation',
    component: InstallationComponent,
    canActivate: [PersonGuard]
  },
  {
    path: 'staff',
    component: StaffComponent,
    canActivate: [PersonGuard]
  },
  {
    path: 'staff/assign-person-service',
    component: AssignPersonServiceComponent,
    canActivate: [PersonGuard]
  },
  {
    path: 'staff/assign-person-infrastructure',
    component: AssignPersonInfrastructureComponent,
    canActivate: [PersonGuard]
  },
  {
    path: 'service',
    component: ServiceComponent,
    canActivate: [PersonGuard]
  },
  {
    path: 'assignment',
    component: AssignmentComponent,
    canActivate: [PersonGuard]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

export const appRoutes = () => {

  // let companies = ['agrosoil', 'barbeer', 'odontologo'];
  // let addRoutes: Routes = [];

  // routes.forEach((route) => {
  //   addRoutes.push(route);
  //   for (let i = 0; i < companies.length; i++) {
  //     if (route.path === '**') {
  //       // addRoutes.push({ ...route, redirectTo: `${companies[i]}/login` });
  //     } else {
  //       addRoutes.push({ ...route, path: `${companies[i]}/${route.path}` });
  //     }
  //   }
  // });

  // console.log(routes)

  return [...routes];
}









