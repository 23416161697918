import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonRepository,
    IPersonDTO,
    IPersonPersonDTO
} from '@management/domain/management';

export class SetPersonServiceUseCase implements UseCase<IPersonPersonDTO, IPersonDTO> {

    private static instance: SetPersonServiceUseCase;

    constructor(private personRepository: PersonRepository) { }

    public static getInstance(personRepository: PersonRepository): SetPersonServiceUseCase {
        if (!SetPersonServiceUseCase.instance)
            SetPersonServiceUseCase.instance = new SetPersonServiceUseCase(personRepository);
        return SetPersonServiceUseCase.instance;
    }

    execute(
        params: IPersonPersonDTO, loadService: boolean
    ): Observable<IPersonDTO> {
        return this.personRepository.SetPersonService(params, loadService);
    }
}