
import { Mapper } from '@management/base/management';
import { IPersonRolIdenticationDTO } from '@management/domain/management';
import { IPersonRolIdenticationEntity } from '@management/data/management';

export class PersonRolIdenticationMapper extends Mapper<IPersonRolIdenticationEntity, IPersonRolIdenticationDTO> {

    private static instance: PersonRolIdenticationMapper;

    private constructor() { super(); }

    public static getInstance(): PersonRolIdenticationMapper {
        if (!PersonRolIdenticationMapper.instance)
            PersonRolIdenticationMapper.instance = new PersonRolIdenticationMapper();
        return PersonRolIdenticationMapper.instance;
    }


    mapFrom(param: IPersonRolIdenticationEntity): IPersonRolIdenticationDTO {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number,
            currency_name: param.currency_name,
            language_name: param.language_name,
            rol: param.rol,
            rol_name: param.rol_name
        };
    }
    mapFromList(params: IPersonRolIdenticationEntity[]): IPersonRolIdenticationDTO[] {
        return params.map((param: IPersonRolIdenticationEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IPersonRolIdenticationDTO): IPersonRolIdenticationEntity {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number,
            currency_name: param.currency_name,
            language_name: param.language_name,
            rol: param.rol,
            rol_name: param.rol_name
        }
    }
    mapToList(params: IPersonRolIdenticationDTO[]): IPersonRolIdenticationEntity[] {
        return params.map((param: IPersonRolIdenticationDTO) => {
            return this.mapTo(param);
        })
    }
}