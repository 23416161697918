<div class="title">
    <h3>Persona</h3>
    <div>{{typeModal()}}</div>
</div>

<form [formGroup]="form" (ngSubmit)="submitData()">
    <dx-validation-group class="form" #validationGroupPersonPopup>
        <dx-text-box class="input" formControlName="name" [showClearButton]="true"
            placeholder="{{ 'Nombre' | translate }}" label="Nombre" width="100%" labelMode="floating">
            <dx-validator>
                <dxi-validation-rule type="required" message="{{'LOGIN.mailIsRequired' | translate}}"
                    [ignoreEmptyValue]="true"></dxi-validation-rule>
                <dxi-validation-rule type="stringLength" [min]="3"
                    message="{{'LOGIN.mailIsTooShort' | translate}}"></dxi-validation-rule>
                <dxi-validation-rule type="stringLength" [max]="30"
                    message="{{'LOGIN.veryLongMail' | translate}}"></dxi-validation-rule>
                <dxi-validation-rule type="pattern" [pattern]="namePattern"
                    message="Do not use digits in the Name"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>

        <dx-select-box formControlName="rol" [searchEnabled]="true" [showClearButton]="true"
            [noDataText]="'No se encontro datos'" [dataSource]="rols" displayExpr="name" valueExpr="id"
            placeholder="Seleccionar rol" label="Rol" width="100%" labelMode="floating">
            <dx-validator>
                <dxi-validation-rule type="required"
                    message="{{'GLOBAL.obligatoryField' | translate}}"></dxi-validation-rule>
            </dx-validator>
        </dx-select-box>

        @if (form.value.rol) {
        <dx-text-box class="input" formControlName="identification_number" [showClearButton]="true"
            placeholder="{{ 'Numero de identificación' | translate }}" [disabled]="false" label="Identificacion"
            width="100%" labelMode="floating">
            <dx-validator>
                <dxi-validation-rule type="required" message="{{'LOGIN.mailIsRequired' | translate}}"
                    [ignoreEmptyValue]="true"></dxi-validation-rule>
                <dxi-validation-rule type="stringLength" [min]="3"
                    message="{{'LOGIN.mailIsTooShort' | translate}}"></dxi-validation-rule>
                <dxi-validation-rule type="stringLength" [max]="30"
                    message="{{'LOGIN.veryLongMail' | translate}}"></dxi-validation-rule>
                <dxi-validation-rule type="async" message="Este numero de identificacion ya existe"
                    [validationCallback]="asyncValidationIdentification"></dxi-validation-rule>

            </dx-validator>
        </dx-text-box>




        <dx-number-box class="input" formControlName="cellPhone" [showClearButton]="true"
            placeholder="{{ 'Celular' | translate }}" label="Celular" width="100%" labelMode="floating">
            <dx-validator>
                <dxi-validation-rule type="required" message="{{'LOGIN.mailIsRequired' | translate}}"
                    [ignoreEmptyValue]="true"></dxi-validation-rule>
                <dxi-validation-rule type="stringLength" [min]="7"
                    message="{{'LOGIN.mailIsTooShort' | translate}}"></dxi-validation-rule>
                <dxi-validation-rule type="stringLength" [max]="40"
                    message="{{'LOGIN.veryLongMail' | translate}}"></dxi-validation-rule>
            </dx-validator>
        </dx-number-box>

        <dx-text-box class="input" formControlName="email" [showClearButton]="true"
            placeholder="{{ 'Correo' | translate }}" label="Correo" width="100%" labelMode="floating">
            <dx-validator>
                <dxi-validation-rule type="required" message="{{'LOGIN.mailIsRequired' | translate}}"
                    [ignoreEmptyValue]="true"></dxi-validation-rule>
                <dxi-validation-rule type="stringLength" [min]="7"
                    message="{{'LOGIN.mailIsTooShort' | translate}}"></dxi-validation-rule>
                <dxi-validation-rule type="stringLength" [max]="40"
                    message="{{'LOGIN.veryLongMail' | translate}}"></dxi-validation-rule>
                <dxi-validation-rule type="email" message="Email is invalid"></dxi-validation-rule>
                <dxi-validation-rule type="async" message="Este correo ya esta registrado"
                    [validationCallback]="asyncValidationEmail"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>

        <dx-select-box formControlName="currency" [searchEnabled]="true" [showClearButton]="true"
            [noDataText]="'No se encontro datos'" width="150" [dataSource]="currencies" displayExpr="name"
            valueExpr="id" placeholder="Seleccionar moneda" label="Moneda" width="100%" labelMode="floating">
            <dx-validator>
                <dxi-validation-rule type="required"
                    message="{{'GLOBAL.obligatoryField' | translate}}"></dxi-validation-rule>
            </dx-validator>
        </dx-select-box>

        <dx-select-box formControlName="language" [searchEnabled]="true" [showClearButton]="true"
            [noDataText]="'No se encontro datos'" [dataSource]="languages" displayExpr="name" valueExpr="id"
            placeholder="Seleccionar idioma" label="Idioma" width="100%" labelMode="floating">
            <dx-validator>
                <dxi-validation-rule type="required"
                    message="{{'GLOBAL.obligatoryField' | translate}}"></dxi-validation-rule>
            </dx-validator>
        </dx-select-box>



        <dx-text-box [mode]="passwordMode" maxLength="15" class="input" formControlName="password"
            [showClearButton]="true" placeholder="{{ 'Contraseña' | translate }}" label="Contraseña" width="100%"
            labelMode="floating">
            <dxi-button name="password" location="after" [options]="passwordButton"></dxi-button>
            <dx-validator>
                <dxi-validation-rule type="required" message="{{'LOGIN.mailIsRequired' | translate}}"
                    [ignoreEmptyValue]="true"></dxi-validation-rule>
                <dxi-validation-rule type="stringLength" [min]="7"
                    message="{{'LOGIN.mailIsTooShort' | translate}}"></dxi-validation-rule>
                <dxi-validation-rule type="stringLength" [max]="15"
                    message="{{'LOGIN.veryLongMail' | translate}}"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>

        <div class="status">
            <div>Estado</div>
            <dx-check-box formControlName="state"></dx-check-box>
        </div>
        }
        <dx-button stylingMode="contained" text={{typeModal()}} width="100%"
            [useSubmitBehavior]="true" [disabled]="isFormValid(form)">
        </dx-button>
        <dx-button stylingMode="contained" text="{{'Cerrar' | translate }}"  width="100%"
            (click)="closePopup()">
        </dx-button>
    </dx-validation-group>
</form>