import { createReducer, on } from '@ngrx/store';
import { IMenuDTO } from "@management/domain/management";
import * as MenuActions from './menu.actions';

export const MENU_KEY = 'menu';

export interface MenuModel {
    menu: IMenuDTO[]
}

export const MenuInitialState: MenuModel = {
    menu: []
}


const _MenuReducer = createReducer(MenuInitialState,
    on(MenuActions.setRecursiveMenu, (state, { menu }) => ({
        ...state,
        menu: menu
    })),
    on(MenuActions.cleanMenu, (state) => ({
        ...MenuInitialState
    })),
);

export function MenuReducer(state: MenuModel, action: any) {
    return _MenuReducer(state, action);
}