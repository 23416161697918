
import { Mapper } from '@management/base/management';
import { IServicesDTO } from '@management/domain/management';
import { IServicesEntity } from '@management/data/management';

export class ServicesMapper extends Mapper<IServicesEntity, IServicesDTO> {

    private static instance: ServicesMapper;

    private constructor() { super(); }

    public static getInstance(): ServicesMapper {
        if (!ServicesMapper.instance)
            ServicesMapper.instance = new ServicesMapper();
        return ServicesMapper.instance;
    }


    mapFrom(param: IServicesEntity): IServicesDTO {
        return {
            id: param.id,
            company: param.company,
            name: param.name,
            state: param.state,
            price: param.price
        };
    }
    mapFromList(params: IServicesEntity[]): IServicesDTO[] {
        return params.map((param: IServicesEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IServicesDTO): IServicesEntity {
        return {
            id: param.id,
            company: param.company,
            name: param.name,
            state: param.state,
            price: param.price
        }
    }
    mapToList(params: IServicesDTO[]): IServicesEntity[] {
        return params.map((param: IServicesDTO) => {
            return this.mapTo(param);
        })
    }
}