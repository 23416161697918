
import { Mapper } from '@management/base/management';
import { IUpdateInfrastructureDTO } from '@management/domain/management';
import { IUpdateInfrastructureEntity } from '@management/data/management';

export class UpdateInfrastructureMapper extends Mapper<IUpdateInfrastructureEntity, IUpdateInfrastructureDTO> {

    private static instance: UpdateInfrastructureMapper;

    private constructor() { super(); }

    public static getInstance(): UpdateInfrastructureMapper {
        if (!UpdateInfrastructureMapper.instance)
            UpdateInfrastructureMapper.instance = new UpdateInfrastructureMapper();
        return UpdateInfrastructureMapper.instance;
    }


    mapFrom(param: IUpdateInfrastructureEntity): IUpdateInfrastructureDTO {
        return {
            state: param.state,
            top_id: param.top_id,
            campus: param.campus,
            installation_type: param.installation_type,
            id: param.id,
            name: param.name,
            description: param.description
        };
    }
    mapFromList(params: IUpdateInfrastructureEntity[]): IUpdateInfrastructureDTO[] {
        return params.map((param: IUpdateInfrastructureEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IUpdateInfrastructureDTO): IUpdateInfrastructureEntity {
        return {
            state: param.state,
            top_id: param.top_id,
            campus: param.campus,
            installation_type: param.installation_type,
            id: param.id,
            name: param.name,
            description: param.description
        }
    }
    mapToList(params: IUpdateInfrastructureDTO[]): IUpdateInfrastructureEntity[] {
        return params.map((param: IUpdateInfrastructureDTO) => {
            return this.mapTo(param);
        })
    }
}