import { actionsInfrastructure } from './index';
import { createReducer, on } from '@ngrx/store';
import {  } from "@management/domain/management";

export const INFRASTRUCTURE_KEY = 'infrastructure'
export interface InfrastructureModel {
}

export const InfrastructureInitialState: InfrastructureModel = {
}

const _InfrastructureReducer = createReducer(InfrastructureInitialState,
);

export function InfrastructureReducer(state: InfrastructureModel, action: any) {
    return _InfrastructureReducer(state, action);
}