export enum CONDITION {
    AND = 'and',
    OR = 'or',
    BETWEEN = 'between',
    EQUALS = '=',
    LIKE = 'like',
    DIFFERENT = '<>'
  }
  
  export enum TYPE_VALUE {
    NUMBER = 'number',
    STRING = 'string',
    DATE = 'date',
    RANGE_DATE = 'range_date',
    RANGE_NUMBER = 'range_number',
    RANGE_TIME = 'range_time',
    SELECT = 'select'
  }
  
  export interface Filter {
    value?: string | number
    type_value: TYPE_VALUE
    rangeFrom?: string | number
    rangeUp?: string | number
    condition: CONDITION
    field: string
    nextCondition?: CONDITION
  }
  
  export interface Configuration extends Filter {
    id: number;
    icon?: string;
    badge?: string;
    datasource?: any;
    value: string;
  }