<div class="container">

  <div class="container__a">

    <div class="container__a__menu" (click)="config.onClickMenu()" >
      <img src="../../../assets/img/menu.svg" alt="menu">
    </div>

    <div class="container__a__logo">
      <img src="../../../assets/img/logo_white.svg" alt="logo white">
    </div>
    <img src="../../../assets/img/separador.svg" alt="separator">

    <img src="../../../assets/img/logo_company_register.svg" alt="logo company register">


  </div>

  <div class="container__b">
    <div class="container__b__a"> {{config.companySelectedDTO?.company?.name}}</div>
    <div class="container__b__b"> {{config.companySelectedDTO?.campus?.name}}</div>
   
  </div>

  <div class="container__c">

    <div class="container__c__notification" (click)="config.onClickNotification()">
      <img src="../../../assets/img/notifications_white.svg" alt="notifications">
      <div class="container__c__notification__count">5</div>
    </div>

    <div class="container__c__user" (click)="config.onClickUser()">
      <img src="../../../assets/img/person_white.svg" alt="person">
      <div class="container__c__user--name">{{nameUser}}</div>
    </div>

  </div>

</div>
