import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    InstallationTypeRepository,
    IDeleteInstallationTypeDTO,
    IDeleteInstallationTypeInstallationTypeDTO
} from '@management/domain/management';

export class DeleteInstallationTypeServiceUseCase implements UseCase<IDeleteInstallationTypeInstallationTypeDTO, IDeleteInstallationTypeDTO> {

    private static instance: DeleteInstallationTypeServiceUseCase;

    constructor(private deleteInstallationTypeRepository: InstallationTypeRepository) { }

    public static getInstance(deleteInstallationTypeRepository: InstallationTypeRepository): DeleteInstallationTypeServiceUseCase {
        if (!DeleteInstallationTypeServiceUseCase.instance)
            DeleteInstallationTypeServiceUseCase.instance = new DeleteInstallationTypeServiceUseCase(deleteInstallationTypeRepository);
        return DeleteInstallationTypeServiceUseCase.instance;
    }

    execute(
        params: IDeleteInstallationTypeInstallationTypeDTO, loadService: boolean
    ): Observable<IDeleteInstallationTypeDTO> {
        return this.deleteInstallationTypeRepository.DeleteInstallationTypeService(params, loadService);
    }
}