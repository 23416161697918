import { Mapper } from '@management/base/management';
import { IScheduleDTO } from '@management/domain/management';
import { IScheduleEntity } from '@management/data/management';

export class ScheduleMapper extends Mapper<IScheduleEntity, IScheduleDTO> {

    private static instance: ScheduleMapper;

    private constructor() { super(); }

    public static getInstance(): ScheduleMapper {
        if (!ScheduleMapper.instance)
            ScheduleMapper.instance = new ScheduleMapper();
        return ScheduleMapper.instance;
    }


    mapFrom(param: IScheduleEntity): IScheduleDTO {
        return {
            id: param.id,
            person: param.person,
            campus: param.campus
        };
    }
    mapFromList(params: IScheduleEntity[]): IScheduleDTO[] {
        return params.map((param: IScheduleEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IScheduleDTO): IScheduleEntity {
        return {
            id: param.id,
            person: param.person,
            campus: param.campus
        }
    }
    mapToList(params: IScheduleDTO[]): IScheduleEntity[] {
        return params.map((param: IScheduleDTO) => {
            return this.mapTo(param);
        })
    }
}