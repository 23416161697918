import { actionsSchedule } from './index';
import { createReducer, on } from '@ngrx/store';
import {  } from "@management/domain/management";

export const SCHEDULE_KEY = 'schedule'
export interface ScheduleModel {
}

export const ScheduleInitialState: ScheduleModel = {
}

const _ScheduleReducer = createReducer(ScheduleInitialState,
);

export function ScheduleReducer(state: ScheduleModel, action: any) {
    return _ScheduleReducer(state, action);
}