
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UtilsComponent } from '../../utils';
import { IMenuDTO } from '@management/domain/management';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgxTranslateModule } from '@management/ngx-translate';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DevextremeLibModule } from '@management/devextreme-lib';



@Component({
  selector: 'app-menu-vertical',
  standalone: true,
  imports: [
    CommonModule,
    NgxTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DevextremeLibModule
  ],
  templateUrl: './menu-vertical.component.html',
  styleUrls: ['./menu-vertical.component.scss']
})
export class MenuVerticalComponent extends UtilsComponent implements OnDestroy, OnChanges {

  @Input() data: IMenuDTO[] = [];
  @Input() isCollapseMenu!: boolean;
  @Output() onClick = new EventEmitter<IMenuDTO>();
  private subscriptions = new Subscription();
  showItemsMenu: IMenuDTO[] = [];
  previousMenu!: IMenuDTO[] | undefined;

  constructor(private router: Router) {
    super();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.isNotUndefinedOrNull(this.data) && changes["data"]) {
      this.initItems();
    }
  }

  onClickItem(item: IMenuDTO): void {
    if (item?.items.length) {
      this.previousMenu = this.showItemsMenu;
    }

    if (item?.items.length && Array.isArray(item?.items)) {
      this.showItemsMenu = item.items;
    }
    this.onClick.emit(item);
  }

  initItems(): void {
    this.previousMenu = undefined;
    this.showItemsMenu = this.data.filter(x => x.id === x.top_id);
  }

  back() {
    this.showItemsMenu = this.previousMenu || [];
  }

  getTarget(item: IMenuDTO) {
    return `#item${item.id}`;
  }

  setId(item: IMenuDTO) {
    return `item${item.id}`
  }



}
