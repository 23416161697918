import { Observable } from 'rxjs';
                import { UseCase } from '@management/base/management';
                import {
                    LanguageRepository,
                    ILanguageDTO,
                    ILanguageLanguageDTO
                } from '@management/domain/management';

export class GetLanguageServiceUseCase implements UseCase<ILanguageLanguageDTO, ILanguageDTO[]> {

    private static instance: GetLanguageServiceUseCase;

    constructor(private languageRepository: LanguageRepository) { }

    public static getInstance(languageRepository: LanguageRepository): GetLanguageServiceUseCase {
        if (!GetLanguageServiceUseCase.instance)
            GetLanguageServiceUseCase.instance = new GetLanguageServiceUseCase(languageRepository);
        return GetLanguageServiceUseCase.instance;
    }

    execute(
        params: ILanguageLanguageDTO, loadService: boolean
    ): Observable<ILanguageDTO[]> {
        return this.languageRepository.GetLanguageService(params, loadService);
    }
}