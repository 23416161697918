<section class="actions">
  <dx-drop-down-button text="Filtrar" [dropDownOptions]="{ width:'auto' }" [items]="filters"
    (onItemClick)="onItemClick($event)" displayExpr="value" keyExpr="id" [width]="80"  [dropDownOptions]="{ width: 150 }" [disabled]="!!filterSelected.length && isUniqueFilter">
  </dx-drop-down-button>

  <dx-button stylingMode="contained" text="Aplicar" type="normal" [width]="70" (onClick)="applyFilters()">
  </dx-button>
  <dx-button  stylingMode="contained" text="Borrar" type="normal" [width]="70" (onClick)="clear()" [disabled]="clearButton()">
  </dx-button>
</section>

<div class="filters">
  <div *ngFor="let item of filterSelected">
    <div [ngSwitch]="item.type_value">
      <div class="row" *ngSwitchCase="TYPE_VALUE_ENUM.STRING">
        <dx-text-box [width]="150" mode="text" [showClearButton]="true" placeholder="{{ 'text' | translate }}"
          [(ngModel)]="values[item.id]"></dx-text-box>
        <span class="material-symbols-outlined icon" (click)="deleteItem(item)">
          close
        </span>
      </div>
      <div class="row" *ngSwitchCase="TYPE_VALUE_ENUM.NUMBER">
        <dx-number-box [width]="150" [showClearButton]="true" placeholder="{{ 'number' | translate }}"
          [(ngModel)]="values[item.id]"></dx-number-box>
        <span class="material-symbols-outlined icon" (click)="deleteItem(item)">
          close
        </span>
      </div>
      <div class="row" *ngSwitchCase="TYPE_VALUE_ENUM.DATE">
        <dx-date-box [width]="150" [(ngModel)]="values[item.id]" type="date" [showClearButton]="true"
          displayFormat="dd/MM/yyyy">
        </dx-date-box>
        <span class="material-symbols-outlined icon" (click)="deleteItem(item)">
          close
        </span>
      </div>
      <div class="row" *ngSwitchCase="TYPE_VALUE_ENUM.SELECT">
        <dx-select-box [width]="150" [searchEnabled]="true" [showClearButton]="true"
          [noDataText]="'No se encontro datos'" width="100%" [dataSource]="item.datasource"
           valueExpr="id" displayExpr="value"  placeholder="Seleccionar"
          [(ngModel)]="values[item.id]">
        </dx-select-box>
        <span class="material-symbols-outlined icon" (click)="deleteItem(item)">
          close
        </span>
      </div>
      <div class="row" *ngSwitchCase="TYPE_VALUE_ENUM.RANGE_NUMBER">
        <dx-number-box [width]="150" [showClearButton]="true" placeholder="{{ 'number' | translate }}"
          [(ngModel)]="values[item.id].rangeFrom"></dx-number-box>
        <dx-number-box [width]="150" [showClearButton]="true" placeholder="{{ 'number' | translate }}"
          [(ngModel)]="values[item.id].rangeUp"></dx-number-box>
        <span class="material-symbols-outlined icon" (click)="deleteItem(item)">
          close
        </span>
      </div>
      <div class="row" *ngSwitchCase="TYPE_VALUE_ENUM.RANGE_DATE">
        <dx-date-box [width]="150" [(ngModel)]="values[item.id].rangeFrom" type="date" [showClearButton]="true">
        </dx-date-box>
        <dx-date-box [width]="150" [(ngModel)]="values[item.id].rangeUp" type="date" [showClearButton]="true">
        </dx-date-box>
        <span class="material-symbols-outlined icon" (click)="deleteItem(item)">
          close
        </span>
      </div>
      <div class="row" *ngSwitchCase="TYPE_VALUE_ENUM.RANGE_TIME">
        <dx-date-box [width]="150" [(ngModel)]="values[item.id].rangeFrom" type="time" [showClearButton]="true">
        </dx-date-box>
        <dx-date-box [width]="150" [(ngModel)]="values[item.id].rangeUp" type="time" [showClearButton]="true">
        </dx-date-box>
        <span class="material-symbols-outlined icon" (click)="deleteItem(item)">
          close
        </span>
      </div>
    </div>
  </div>
</div>