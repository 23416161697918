import { Injectable } from '@angular/core';
import { Action, Store } from "@ngrx/store";

import {
    environment,
    HttpClient,
    map,
    Observable,
    of,
    ResolveRequest,
    keysSessionStorangeEnum
} from '@management/base/management';
//domain
import {
    PersonCompanyRepository,
    IPersonCompanyDTO,
    IPersonCompanyPersonCompanyDTO,
} from '@management/domain/management';
//data
import {
    IPersonCompanyEntity,
    PersonCompanyMapper,
} from '@management/data/management';

import {
    actionsPersonCompany,
    reducerPersonCompany,
    selectorsPersonCompany
} from './redux';


@Injectable({
    providedIn: 'root',
})
export class PersonCompanyImplementationRepository extends PersonCompanyRepository {

    private readonly urlApiRest: string = environment.api.urlApiRest;
    private readonly personCompanyMapper = PersonCompanyMapper.getInstance();

    constructor(
        private http: HttpClient,
        private resolveRequest: ResolveRequest,
        private store: Store<reducerPersonCompany.PersonCompanyModel>) {
        super();
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    SetPersonCompanyService(params: IPersonCompanyPersonCompanyDTO, loadService: boolean): Observable<IPersonCompanyDTO> {
        return this.http
            .post<IPersonCompanyEntity>(`${this.urlApiRest}PersonCompany`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IPersonCompanyEntity>(response);
                return res;
            }))
            .pipe(map((entity: IPersonCompanyEntity) => entity && this.personCompanyMapper.mapFrom(entity)))
    }

}