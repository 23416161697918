

import { Observable } from 'rxjs';
import { AuthRepository, ICompanySelectedDTO } from '@management/domain/management';
import { selector, UseCase } from '@management/base/management';


export class GetCompanySelectedReduxUseCase implements UseCase<null, selector<ICompanySelectedDTO>> {
    
    private static instance: GetCompanySelectedReduxUseCase;
    
    constructor(private authRepository: AuthRepository) { }
    
    public static getInstance(authRepository: AuthRepository): GetCompanySelectedReduxUseCase {
        if (!GetCompanySelectedReduxUseCase.instance)
            GetCompanySelectedReduxUseCase.instance = new GetCompanySelectedReduxUseCase(authRepository);
        return GetCompanySelectedReduxUseCase.instance;
    }

    execute(): Observable<selector<ICompanySelectedDTO>> {
        return this.authRepository.getCompanySelectedSelector();
    }
}