import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ACTIONS, Actions, TYPE_ROLES, selector } from '@management/base/management';
import { DevextremeLibModule } from '@management/devextreme-lib';
import { ICompanySelectedDTO, IDeletePersonServiceDTO, IPersonCampusRolDTO, IPersonServiceDTO, IPersonServicesDTO, ISavePersonServiceDTO, ISavePersonServiceSavePersonServiceDTO, IServiceDTO, IServicesDTO } from '@management/domain/management';
import { AuthFacade, PersonFacade, PersonServiceFacade, ServiceFacade } from '@management/facade/management';
import { NgxTranslateModule } from '@management/ngx-translate';
import { CONDITION, TYPE_VALUE, UiModule, UtilsComponent, Filter as LibraryFilter, Filter } from '@management/ui';
import { SharedModule } from 'apps/management/src/app/shared/shared.module';
import { loadMessages, locale } from 'devextreme/localization';
import es from '../../../../../assets/dev-extreme-lan/es.json';
import { filter, take } from 'rxjs';
import { IOnDemandPaginationDTO } from 'libs/ui/src/lib/moleculas/on-demand-pagination/on-demand-pagination.component';
import { ConfigProcessPopup, TYPE_MODAL } from 'libs/ui/src/lib/moleculas/process-popup';
import { PopupColorTypeEnum } from 'libs/ui/src/lib/enum/global-enum';
import { AssignServiceFormComponent } from './components/assign-service-form/assign-service-form.component';

@Component({
  selector: 'app-assign-person-service',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgxTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DevextremeLibModule,
    UiModule,
    AssignServiceFormComponent
  ],
  templateUrl: './assign-person-service.component.html',
  styleUrls: ['./assign-person-service.component.scss']
})
export class AssignPersonServiceComponent extends UtilsComponent implements OnInit {

  public override onDemandPagination: IOnDemandPaginationDTO = {
    callbackService: (pagination: IOnDemandPaginationDTO) => {
      this.getPersonCompanyCampusRolService(pagination.from)
    },
    from: 0,
    until: 6,
    currentPage: 1
  }
  public configPopupService: ConfigProcessPopup = {
    width: 'auto',
    height: 'auto',
    dragEnabled: false,
    visible: false,
    popupColorType: PopupColorTypeEnum.ERROR,
    typeModal: TYPE_MODAL.CREATE
  }
  public actions: Actions[] = [
    { id: 4, value: 'Asignar servicios', icon: 'bookmark' },
    { id: 3, value: 'Eliminar servicios', icon: 'remove' }
  ];
  public companySelectedDTO!: ICompanySelectedDTO;
  public persons: IPersonCampusRolDTO[] = [];
  public services: IServicesDTO[] = [];

  public _authFacade = inject(AuthFacade);
  public _personFacade = inject(PersonFacade);
  public _personServiceFacade = inject(PersonServiceFacade);
  public _serviceFacade = inject(ServiceFacade);

  constructor() {
    super();
  }

  ngOnInit() {
    if (!this.hasUserActive()) return
    this.getCompanySelectedSelector();
    this.clearLastRequest();
    this.getPersonCompanyCampusRolService(this.onDemandPagination.from);
    this.loadLocale();
    this.initFilters();
  }

  public loadLocale(): void {
    loadMessages(es);
    locale('es');
  }

  public initFilters(): void {
    this.filters = [
      { id: 1, type_value: TYPE_VALUE.STRING, condition: CONDITION.LIKE, field: 'name', value: 'INSTALLATION_COMPONENT.name' },
      { id: 2, type_value: TYPE_VALUE.STRING, condition: CONDITION.LIKE, field: 'email', value: 'Correo' },
      { id: 3, type_value: TYPE_VALUE.STRING, condition: CONDITION.LIKE, field: 'identificationNumber', value: 'Identificacion' }
    ];
  }

  public onFilter(filter?: LibraryFilter[]): void {
    if (filter?.length === 0) return
    this.clearLastRequest();
    this.currentFilter = filter as Filter[];
    const [getFilter] = this.currentFilter;

    switch (getFilter.field) {
      case 'name':
        this.getPersonCompanyCampusRolNameService(getFilter)
        break;
      case 'email':
        this.getPersonCompanyCampusRolEmailService(getFilter)
        break;
      case 'identificationNumber':
        this.getPersonCompanyCampusRolIdenService(getFilter)
        break;

    }
  }

  public getPersonCompanyCampusRolNameService(getFilter: Filter): void {
    if (!getFilter?.value) return
    this._personFacade
      .GetPersonCompanyCampusRolNameService({ name: getFilter.value.toString(), codeRol: TYPE_ROLES.COLLABORATOR }, true)
      .pipe(take(1))
      .subscribe((person: IPersonCampusRolDTO[]) => {
        if (this.listHasValues(person))
          return this.persons = person;
        return this.persons = [];
      })
  }

  public getPersonCompanyCampusRolEmailService(getFilter: Filter): void {
    if (!getFilter?.value) return
    this._personFacade
      .GetPersonCompanyCampusRolEmailService({ email: getFilter.value.toString(), codeRol: TYPE_ROLES.COLLABORATOR }, true)
      .pipe(take(1))
      .subscribe((person: IPersonCampusRolDTO[]) => {
        if (this.listHasValues(person))
          return this.persons = person;
        return this.persons = [];
      })
  }

  public getPersonCompanyCampusRolIdenService(getFilter: Filter): void {
    if (!getFilter?.value) return
    this._personFacade
      .GetPersonCompanyCampusRolIdenService({ identificationNumber: getFilter.value.toString(), codeRol: TYPE_ROLES.COLLABORATOR }, true)
      .pipe(take(1))
      .subscribe((person: IPersonCampusRolDTO[]) => {
        if (this.listHasValues(person))
          return this.persons = person;
        return this.persons = [];
      })
  }

  public onClear(event?: boolean, clearFilter?: boolean): void {
    if (this.listHasValues(this.currentFilter) && !clearFilter) {
      this.onFilter(this.currentFilter)
    } else {
      this.currentFilter = [];
      this.resetOnDemandPagination();
      this.getPersonCompanyCampusRolService(this.onDemandPagination.from);
    }
  }

  public getCompanySelectedSelector(): void {
    this._authFacade.getCompanySelectedSelector()
      .pipe(take(1))
      .subscribe((res: selector<ICompanySelectedDTO>) => {
        this.companySelectedDTO = res as ICompanySelectedDTO;
        this.getServiceService();
      })
  }

  public getPersonCompanyCampusRolService(from: number): void {
    this._personFacade
      .GetPersonCompanyCampusRolService({ codeRol: TYPE_ROLES.COLLABORATOR, from, until: this.onDemandPagination.until }, true)
      .pipe(take(1))
      .subscribe((persons: IPersonCampusRolDTO[]) => {
        if (persons.length === 0) {
          this.stopOnDemandPagination();
        } else {
          this.persons = persons;
        }
      })
  }

  public onActionClick(person: IPersonCampusRolDTO, event: Actions): void {
    switch (event.id) {
      case ACTIONS.CREATE:
        this.isVisiblePopupService(true, TYPE_MODAL.CREATE, { services: this.services, person });
        break;
      case ACTIONS.DELETE:

        break;
    }
  }

  public getServiceService(): void {
    const { id } = this.companySelectedDTO.campus;
    const filterBase = [{
      value: id,
      type_value: TYPE_VALUE.NUMBER,
      condition: CONDITION.EQUALS,
      field: "company"
    }];
    this._serviceFacade
      .GetServicesService({ fullData: true, filters: filterBase }, true)
      .pipe(take(1))
      .subscribe((services: IServicesDTO[]) => {
        this.services = services;
      })
  }

  public isVisiblePopupService(visible: boolean, typeModal?: TYPE_MODAL, data?: any): void {
    this.configPopupService = {
      ...this.configPopupService,
      typeModal: typeModal,
      visible: visible,
      dataSource: data
    }
  }

  public submitData(form: FormGroup): void {
    switch (this.configPopupService.typeModal) {
      case TYPE_MODAL.CREATE:
        this.createPersonServiceDynamic(form)
        break;
    }
  }

  public createPersonServiceDynamic(form: FormGroup) {

    const services: number[] = form.value.assingServices;
    const { id } = this.companySelectedDTO.campus;
    const { person } = this.configPopupService.dataSource;

    services.forEach(service => {

      const serviceBuild: ISavePersonServiceDTO = {
        person: person.id,
        campus: id,
        service: service,
        state: true
      }

      this._personServiceFacade
        .SetSavePersonServiceService(serviceBuild, true)
        .pipe(take(1))
        .subscribe((personService: ISavePersonServiceDTO) => {
          if (personService) {
            this.getServiceService();
            this.isVisiblePopupService(true, TYPE_MODAL.CREATE, { services: this.services, person });
          }
        });

    });

  }

  public deletePersonServiceDynamic(deletePersonServices: IPersonServicesDTO) {

    const { person } = this.configPopupService.dataSource;
    const { service } = deletePersonServices;
    const { id } = this.companySelectedDTO.campus;

    const filters = {
      fullData: true,
      filters: [
        {
          field: 'service',
          condition: CONDITION.EQUALS,
          type_value: TYPE_VALUE.NUMBER,
          value: service,
          nextCondition: CONDITION.AND
        },
        {
          field: 'campus',
          condition: CONDITION.EQUALS,
          type_value: TYPE_VALUE.NUMBER,
          value: id,
          nextCondition: CONDITION.AND
        },
        {
          field: 'person',
          condition: CONDITION.EQUALS,
          type_value: TYPE_VALUE.NUMBER,
          value: person.id
        }
      ]
    }

    this._personServiceFacade
      .GetGetListPersonServiceService(filters, true)
      .pipe(filter(personService => !!personService.length))
      .pipe(take(1))
      .subscribe((personService: IPersonServiceDTO[]) => {
        this.deletePersonService(personService)
      })

  }

  public deletePersonService(personServiceResult: IPersonServiceDTO[]) {
    const [personService] = personServiceResult;
    const { person } = this.configPopupService.dataSource;

    if (!personService?.id) return

    this._personServiceFacade
      .DeletePersonServiceService({ id: personService.id }, true)
      .pipe(filter(personService => !!personService))
      .pipe(take(1))
      .subscribe(() => {
        this.getServiceService();
        this.isVisiblePopupService(true, TYPE_MODAL.CREATE, { services: this.services, person });
      })
  }




}
