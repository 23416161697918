import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'apps/management/src/app/shared/shared.module';
import { NgxTranslateModule } from '@management/ngx-translate';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DevextremeLibModule } from '@management/devextreme-lib';
import { UiModule, UtilsComponent } from '@management/ui';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { IInstallationTypesDTO } from '@management/domain/management';

export interface IPopupEditInstallationData {
  installationTypesDTO: IInstallationTypesDTO
  onClose: () => void
  onSubmit: (installationTypesDTO: IInstallationTypesDTO) => void
}

@Component({
  selector: 'app-popup-edit-installation',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgxTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DevextremeLibModule,
    UiModule
  ],
  templateUrl: './popup-edit-installation.component.html',
  styleUrl: './popup-edit-installation.component.scss'
})
export class PopupEditInstallationComponent extends UtilsComponent implements OnChanges {
  @ViewChild('validationGroupPopupEditInstallation', { static: false })
  validationGroup!: DxValidationGroupComponent;
  @Input({ required: true }) data!: IPopupEditInstallationData;
  public form!: FormGroup;
  public fb = inject(FormBuilder);

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["data"]) {
      this.initForm();
    }
  }

  public initForm(): void {
    let { name, state, description } = this.data.installationTypesDTO;
    let buildForm = {
      name: [name, Validators.compose([Validators.required])],
      state: [state, Validators.compose([Validators.required])],
      description: [description, Validators.compose([Validators.required])],
    };
    this.form = this.fb.group(buildForm);
  }

  public submitData(): void {
    if (!this.isFormValidTS(this.form)) return
    let { name, state, description } = this.form.value;
    let { id, campus, homologation } = this.data.installationTypesDTO;
    this.data.onSubmit({ id, campus, name, state, description, homologation })
  }

  public close(event: any): void {
    this.data.onClose();
  }



}
