
import { Mapper } from '@management/base/management';
import { IServiceCampusPutDTO } from '@management/domain/management';
import { IServiceCampusPutEntity } from '@management/data/management';

export class ServiceCampusPutMapper extends Mapper<IServiceCampusPutEntity, IServiceCampusPutDTO> {

    private static instance: ServiceCampusPutMapper;

    private constructor() { super(); }

    public static getInstance(): ServiceCampusPutMapper {
        if (!ServiceCampusPutMapper.instance)
            ServiceCampusPutMapper.instance = new ServiceCampusPutMapper();
        return ServiceCampusPutMapper.instance;
    }


    mapFrom(param: IServiceCampusPutEntity): IServiceCampusPutDTO {
        return {
            id: param.id,
            service: param.service,
            campus: param.campus,
            price: param.price,
            currency: param.currency
        };
    }
    mapFromList(params: IServiceCampusPutEntity[]): IServiceCampusPutDTO[] {
        return params.map((param: IServiceCampusPutEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IServiceCampusPutDTO): IServiceCampusPutEntity {
        return {
            id: param.id,
            service: param.service,
            campus: param.campus,
            price: param.price,
            currency: param.currency
        }
    }
    mapToList(params: IServiceCampusPutDTO[]): IServiceCampusPutEntity[] {
        return params.map((param: IServiceCampusPutDTO) => {
            return this.mapTo(param);
        })
    }
}