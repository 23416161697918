<div class="container">

    <div class="container__a">
        <div>Editar</div>
    </div>
    @if (form) {
    <form class="container__b" [formGroup]="form" (ngSubmit)="submitData()">
        <dx-validation-group #validationGroupPopupEditInstallation>
            <div class="container__b__a">

                <div class="container__b__a__a">
                    <div>{{'INSTALLATION_COMPONENT.state' | translate}}</div>
                    <dx-check-box formControlName="state"></dx-check-box>
                </div>

                <dx-text-box class="input" formControlName="name" [showClearButton]="true"
                    placeholder="{{ 'INSTALLATION_COMPONENT.name' | translate }}">
                    <dx-validator>
                        <dxi-validation-rule type="required"
                            message="{{'LOGIN.mailIsRequired' | translate}}"></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" [min]="3"
                            message="{{'LOGIN.mailIsTooShort' | translate}}"></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" [max]="30"
                            message="{{'LOGIN.veryLongMail' | translate}}"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>

                <dx-text-box class="input" formControlName="description" [showClearButton]="true"
                    placeholder="{{ 'INSTALLATION_COMPONENT.description' | translate }}">
                    <dx-validator>
                        <dxi-validation-rule type="required"
                            message="{{'LOGIN.mailIsRequired' | translate}}"></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" [min]="3"
                            message="{{'LOGIN.mailIsTooShort' | translate}}"></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" [max]="30"
                            message="{{'LOGIN.veryLongMail' | translate}}"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>

            </div>

            <div class="container__b__b">
                <dx-button icon="edit" stylingMode="contained" text="{{'Editar' | translate }}" type="default"
                    width="100%" [useSubmitBehavior]="true">
                </dx-button>
            </div>
        </dx-validation-group>

        <dx-button stylingMode="outlined" text="{{'Cancelar' | translate }}" type="default" width="100%"
            [useSubmitBehavior]="false"  (onClick)="close($event)">
        </dx-button>
    </form>
    }
</div>