import { Mapper } from '@management/base/management';
import { IServiceDTO } from '@management/domain/management';
import { IServiceEntity } from '@management/data/management';

export class ServiceMapper extends Mapper<IServiceEntity, IServiceDTO> {

    private static instance: ServiceMapper;

    private constructor() { super(); }

    public static getInstance(): ServiceMapper {
        if (!ServiceMapper.instance)
            ServiceMapper.instance = new ServiceMapper();
        return ServiceMapper.instance;
    }


    mapFrom(param: IServiceEntity): IServiceDTO {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            company: param.company,
            price: param.price,
            service_time: param.service_time
        };
    }
    mapFromList(params: IServiceEntity[]): IServiceDTO[] {
        return params.map((param: IServiceEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IServiceDTO): IServiceEntity {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            company: param.company,
            price: param.price,
            service_time: param.service_time
        }
    }
    mapToList(params: IServiceDTO[]): IServiceEntity[] {
        return params.map((param: IServiceDTO) => {
            return this.mapTo(param);
        })
    }
}