
import { Observable } from 'rxjs';
//domain
import {
    ILanguageLanguageDTO,
    ILanguageDTO,
} from '@management/domain/management';
import { UtilsComponent } from '@management/ui';
import { selector } from '@management/base/management';

export abstract class LanguageRepository extends UtilsComponent {
    abstract GetLanguageService(params: ILanguageLanguageDTO, loadService: boolean): Observable<ILanguageDTO[]>;
}