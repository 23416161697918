import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    ServiceRepository,
    ICampusServiceCurrencyDTO,
    ICampusServiceCurrencyCampusServiceCurrencyDTO
} from '@management/domain/management';

export class GetCampusServiceCurrencyServiceUseCase implements UseCase<ICampusServiceCurrencyCampusServiceCurrencyDTO, ICampusServiceCurrencyDTO[]> {

    private static instance: GetCampusServiceCurrencyServiceUseCase;

    constructor(private campusServiceCurrencyRepository: ServiceRepository) { }

    public static getInstance(campusServiceCurrencyRepository: ServiceRepository): GetCampusServiceCurrencyServiceUseCase {
        if (!GetCampusServiceCurrencyServiceUseCase.instance)
            GetCampusServiceCurrencyServiceUseCase.instance = new GetCampusServiceCurrencyServiceUseCase(campusServiceCurrencyRepository);
        return GetCampusServiceCurrencyServiceUseCase.instance;
    }

    execute(
        params: ICampusServiceCurrencyCampusServiceCurrencyDTO, loadService: boolean
    ): Observable<ICampusServiceCurrencyDTO[]> {
        return this.campusServiceCurrencyRepository.GetCampusServiceCurrencyService(params, loadService);
    }
}