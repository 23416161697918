
import { Observable } from 'rxjs';
//domain
import {
    IServiceCampusServiceCampusDTO,
    IServiceCampusDTO,
    IServiceCampusDeleteServiceCampusDeleteDTO,
    IServiceCampusDeleteDTO,
    IServiceCampusPutServiceCampusDTO,
    IServiceCampusPutDTO,
} from '@management/domain/management';
import { UtilsComponent } from '@management/ui';
import { selector } from '@management/base/management';

export abstract class ServiceCampusRepository extends UtilsComponent {
    abstract UpdateServiceCampusService(params: IServiceCampusPutServiceCampusDTO, loadService: boolean): Observable<IServiceCampusPutDTO>;
    abstract DeleteServiceCampusDeleteService(params: IServiceCampusDeleteServiceCampusDeleteDTO, loadService: boolean): Observable<IServiceCampusDeleteDTO>;
    abstract SetServiceCampusService(params: IServiceCampusServiceCampusDTO, loadService: boolean): Observable<IServiceCampusDTO>;
}