import { Component, OnInit } from '@angular/core';
import { AuthFacade, MenuFacade } from '@management/facade/management';
import { UtilsComponent } from '@management/ui';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent extends UtilsComponent implements OnInit {

  constructor(
    private readonly _authFacade: AuthFacade,
    private readonly _menuFacade: MenuFacade,
    private readonly _router: Router,
    private readonly _activateRouter: ActivatedRoute
  ) { super(); }

  ngOnInit() {
    this.redirectTo();
  }

  private redirectTo(): void {
    console.log(this._activateRouter.url)
    this._router.navigateByUrl('home');

    console.error('Page Not Found.')
  }

}
