
import { Mapper } from '@management/base/management';
import { IDeletePersonServiceDTO } from '@management/domain/management';
import { IDeletePersonServiceEntity } from '@management/data/management';

export class DeletePersonServiceMapper extends Mapper<IDeletePersonServiceEntity, IDeletePersonServiceDTO> {

    private static instance: DeletePersonServiceMapper;

    private constructor() { super(); }

    public static getInstance(): DeletePersonServiceMapper {
        if (!DeletePersonServiceMapper.instance)
            DeletePersonServiceMapper.instance = new DeletePersonServiceMapper();
        return DeletePersonServiceMapper.instance;
    }


    mapFrom(param: IDeletePersonServiceEntity): IDeletePersonServiceDTO {
        return {
            id: param.id,
            person: param.person,
            campus: param.campus,
            service: param.service,
            state: param.state
        };
    }
    mapFromList(params: IDeletePersonServiceEntity[]): IDeletePersonServiceDTO[] {
        return params.map((param: IDeletePersonServiceEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IDeletePersonServiceDTO): IDeletePersonServiceEntity {
        return {
            id: param.id,
            person: param.person,
            campus: param.campus,
            service: param.service,
            state: param.state
        }
    }
    mapToList(params: IDeletePersonServiceDTO[]): IDeletePersonServiceEntity[] {
        return params.map((param: IDeletePersonServiceDTO) => {
            return this.mapTo(param);
        })
    }
}