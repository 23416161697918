
import { Observable } from 'rxjs';
//domain
import {
    IInstallationTypeSaveInstallationTypeDTO,
    IInstallationTypeDTO,
    IInstallationTypesInstallationTypesDTO,
    IInstallationTypesDTO,
    IUpdateInstallationTypeInstallationTypeDTO,
    IUpdateInstallationTypeDTO,
    IDeleteInstallationTypeInstallationTypeDTO,
    IDeleteInstallationTypeDTO,
} from '@management/domain/management';
import { UtilsComponent } from '@management/ui';
import { PagedService, selector } from '@management/base/management';

export abstract class InstallationTypeRepository extends UtilsComponent {
    abstract DeleteInstallationTypeService(params: IDeleteInstallationTypeInstallationTypeDTO, loadService: boolean): Observable<IDeleteInstallationTypeDTO>;
    abstract UpdateInstallationTypeService(params: IUpdateInstallationTypeInstallationTypeDTO, loadService: boolean): Observable<IUpdateInstallationTypeDTO>;
    abstract SetSaveInstallationTypeService(params: IInstallationTypeSaveInstallationTypeDTO, loadService: boolean): Observable<IInstallationTypeDTO>;
    abstract GetInstallationTypesService(params: IInstallationTypesInstallationTypesDTO, loadService: boolean): Observable<PagedService<IInstallationTypesDTO[]>>;
} 