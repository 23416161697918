import { Injectable } from '@angular/core';
import { Action, Store } from "@ngrx/store";

import {
    environment,
    HttpClient,
    map,
    Observable,
    of,
    ResolveRequest,
    keysSessionStorangeEnum
} from '@management/base/management';
//domain
import {
    InfrastructureRepository,
    IInfrastructureDTO,
    IInfrastructureGetInfrastructureByCampusDTO,
    ISaveInfrastructureDTO,
    ISaveInfrastructureSaveInfrastructureDTO,
    IUpdateInfrastructureDTO,
    IUpdateInfrastructurePutEditInfrastructureDTO,
    IDeleteInfrastructureDTO,
    IDeleteInfrastructureInfrastructureDTO,
    IInfrastructuresDTO,
    IInfrastructuresInfrastructuresDTO,
} from '@management/domain/management';
//data
import {
    IInfrastructureEntity,
    InfrastructureMapper,
    ISaveInfrastructureEntity,
    SaveInfrastructureMapper,
    IUpdateInfrastructureEntity,
    UpdateInfrastructureMapper,
    IDeleteInfrastructureEntity,
    DeleteInfrastructureMapper,
    IInfrastructuresEntity,
    InfrastructuresMapper,
} from '@management/data/management';

import {
    actionsInfrastructure,
    reducerInfrastructure,
    selectorsInfrastructure
} from './redux';


@Injectable({
    providedIn: 'root',
})
export class InfrastructureImplementationRepository extends InfrastructureRepository {

    private readonly urlApiRest: string = environment.api.urlApiRest;
    private readonly infrastructureMapper = InfrastructureMapper.getInstance();
    private readonly infrastructuresMapper = InfrastructuresMapper.getInstance();
    private readonly deleteInfrastructureMapper = DeleteInfrastructureMapper.getInstance();
    private readonly updateInfrastructureMapper = UpdateInfrastructureMapper.getInstance();
    private readonly saveInfrastructureMapper = SaveInfrastructureMapper.getInstance();

    constructor(
        private http: HttpClient,
        private resolveRequest: ResolveRequest,
        private store: Store<reducerInfrastructure.InfrastructureModel>) {
        super();
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    GetInfrastructureByCampusService(params: IInfrastructureGetInfrastructureByCampusDTO, loadService: boolean): Observable<IInfrastructureDTO[]> {
        let data = this.getSessionStorange(keysSessionStorangeEnum.GetGetInfrastructureByCampusService);
        if (!data || loadService)
            return this.http
                .get<IInfrastructureEntity>(`${this.urlApiRest}infrastructure/getInfrastructureByCampus`)
                .pipe(map((response: any) => {
                    let res = this.resolveRequest.resolve<IInfrastructureEntity[]>(response);
                    this.setSessionStorange(keysSessionStorangeEnum.GetGetInfrastructureByCampusService, res);
                    return res;
                }))
                .pipe(map((entity: IInfrastructureEntity[]) => entity && this.infrastructureMapper.mapFromList(entity)))
        return of(data);
    }

    SetSaveInfrastructureService(params: ISaveInfrastructureSaveInfrastructureDTO, loadService: boolean): Observable<ISaveInfrastructureDTO> {
        let data = this.getSessionStorange(keysSessionStorangeEnum.SetSaveInfrastructureService);
        if (!data || loadService)
            return this.http
                .post<ISaveInfrastructureEntity>(`${this.urlApiRest}infrastructure`, params)
                .pipe(map((response: any) => {
                    let res = this.resolveRequest.resolve<ISaveInfrastructureEntity>(response);
                    this.setSessionStorange(keysSessionStorangeEnum.SetSaveInfrastructureService, res);
                    return res;
                }))
                .pipe(map((entity: ISaveInfrastructureEntity) => entity && this.saveInfrastructureMapper.mapFrom(entity)))
        return of(data);
    }

    UpdatePutEditInfrastructureService(params: IUpdateInfrastructurePutEditInfrastructureDTO, loadService: boolean): Observable<IUpdateInfrastructureDTO> {
        let data = this.getSessionStorange(keysSessionStorangeEnum.UpdatePutEditInfrastructureService);
        if (!data || loadService)
            return this.http
                .put<IUpdateInfrastructureEntity>(`${this.urlApiRest}infrastructure`, params)
                .pipe(map((response: any) => {
                    let res = this.resolveRequest.resolve<IUpdateInfrastructureEntity>(response);
                    this.setSessionStorange(keysSessionStorangeEnum.UpdatePutEditInfrastructureService, res);
                    return res;
                }))
                .pipe(map((entity: IUpdateInfrastructureEntity) => entity && this.updateInfrastructureMapper.mapFrom(entity)))
        return of(data);
    }
    DeleteInfrastructureService(params: IDeleteInfrastructureInfrastructureDTO, loadService: boolean): Observable<IDeleteInfrastructureDTO> {

        const options = {
            body: {
                id: params.id
            }
        }

        return this.http
            .delete<IDeleteInfrastructureEntity>(`${this.urlApiRest}infrastructure`, options)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IDeleteInfrastructureEntity>(response);
                return res;
            }))
            .pipe(map((entity: IDeleteInfrastructureEntity) => {
                return entity && this.deleteInfrastructureMapper.mapFrom(entity)
            }))
    }
    GetInfrastructuresService(params: IInfrastructuresInfrastructuresDTO, loadService: boolean): Observable<IInfrastructuresDTO[]> {

        //let data = this.getSessionStorange(keysSessionStorangeEnum.GetInfrastructuresService);
        //if (!data || loadService)
            return this.http
                .post<IInfrastructuresEntity>(`${this.urlApiRest}infrastructure/list`, params)
                .pipe(map((response: any) => {
                    let res = this.resolveRequest.resolve<IInfrastructuresEntity[]>(response);
                    //this.setSessionStorange(keysSessionStorangeEnum.GetInfrastructuresService, res);
                    return res;
                }))
                .pipe(map((entity: IInfrastructuresEntity[]) => entity && this.infrastructuresMapper.mapFromList(entity)))
        //return of(data);
    }
}