import { Mapper } from '@management/base/management';
import { IInfrastructureDTO } from '@management/domain/management';
import { IInfrastructureEntity } from '@management/data/management';

export class InfrastructureMapper extends Mapper<IInfrastructureEntity, IInfrastructureDTO> {

    private static instance: InfrastructureMapper;

    private constructor() { super(); }

    public static getInstance(): InfrastructureMapper {
        if (!InfrastructureMapper.instance)
            InfrastructureMapper.instance = new InfrastructureMapper();
        return InfrastructureMapper.instance;
    }


    mapFrom(param: IInfrastructureEntity): IInfrastructureDTO {
        return {
            id: param.id.toString(),
            top_id: param.top_id,
            name: param.name,
            state: param.state,
            description: param.description,
            campus: param.campus,
            installation_type: param.installation_type,
            items: param?.items ? this.mapFromList(param.items): [],
            id_item: param.id
        };
    }
    mapFromList(params: IInfrastructureEntity[]): IInfrastructureDTO[] {
        return params.map((param: IInfrastructureEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IInfrastructureDTO): IInfrastructureEntity {
        return {
            id: parseInt(param.id),
            top_id: param.top_id,
            name: param.name,
            state: param.state,
            description: param.description,
            campus: param.campus,
            installation_type: param.installation_type,
            items: param?.items ? this.mapToList(param.items) : [],
        }
    }
    mapToList(params: IInfrastructureDTO[]): IInfrastructureEntity[] {
        return params.map((param: IInfrastructureDTO) => {
            return this.mapTo(param);
        })
    }
}