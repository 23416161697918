import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ConfigMenu } from './menu.interface';


const DEFAULT_CONFIG: ConfigMenu = {
  stateMenu: false,
  stateNotification: false,
  stateUser: false,
  onClickMenu: () => { },
  onClickUser: () => { },
  onClickNotification: () => { }
}

@Component({
  selector: 'lib-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnChanges {


  @Input() config!: ConfigMenu;
  public nameUser!: string

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["config"]) {
      this.config = {
        ...DEFAULT_CONFIG,
        ...this.config
      }
      this.getNameUser()
    }
  }


  public getNameUser(): void {
    const [name] = this.config.companySelectedDTO?.person?.name.toString().split(' ') || [];
    this.nameUser = name;
  }

}
