import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonCompanyRepository,
    IPersonCompanyDTO,
    IPersonCompanyPersonCompanyDTO
} from '@management/domain/management';

export class SetPersonCompanyServiceUseCase implements UseCase<IPersonCompanyPersonCompanyDTO, IPersonCompanyDTO> {

    private static instance: SetPersonCompanyServiceUseCase;

    constructor(private personCompanyRepository: PersonCompanyRepository) { }

    public static getInstance(personCompanyRepository: PersonCompanyRepository): SetPersonCompanyServiceUseCase {
        if (!SetPersonCompanyServiceUseCase.instance)
            SetPersonCompanyServiceUseCase.instance = new SetPersonCompanyServiceUseCase(personCompanyRepository);
        return SetPersonCompanyServiceUseCase.instance;
    }

    execute(
        params: IPersonCompanyPersonCompanyDTO, loadService: boolean
    ): Observable<IPersonCompanyDTO> {
        return this.personCompanyRepository.SetPersonCompanyService(params, loadService);
    }
}