
import { Observable } from 'rxjs';
//domain
import {
    ICurrencyCurrencyDTO,
    ICurrencyDTO,
} from '@management/domain/management';
import { UtilsComponent } from '@management/ui';
import { selector } from '@management/base/management';

export abstract class CurrencyRepository extends UtilsComponent {
    abstract GetCurrencyService(params: ICurrencyCurrencyDTO, loadService: boolean): Observable<ICurrencyDTO[]>;
}