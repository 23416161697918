import { Injectable } from '@angular/core';
import { Action, Store } from "@ngrx/store";

import {
    environment,
    HttpClient,
    map,
    Observable,
    of,
    ResolveRequest,
    keysSessionStorangeEnum
} from '@management/base/management';
//domain
import {
    CurrencyRepository,
    ICurrencyDTO,
    ICurrencyCurrencyDTO,
} from '@management/domain/management';
//data
import {
    ICurrencyEntity,
    CurrencyMapper,
} from '@management/data/management';

import {
    actionsCurrency,
    reducerCurrency,
    selectorsCurrency
} from './redux';


@Injectable({
    providedIn: 'root',
})
export class CurrencyImplementationRepository extends CurrencyRepository {

    private readonly urlApiRest: string = environment.api.urlApiRest;
    private readonly currencyMapper = CurrencyMapper.getInstance();

    constructor(
        private http: HttpClient,
        private resolveRequest: ResolveRequest,
        private store: Store<reducerCurrency.CurrencyModel>) {
        super();
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    GetCurrencyService(params: ICurrencyCurrencyDTO, loadService: boolean): Observable<ICurrencyDTO[]> {
        let data = this.getSessionStorange(keysSessionStorangeEnum.GetCurrencyService);
        if (!data || loadService)
            return this.http
                .post<ICurrencyEntity>(`${this.urlApiRest}currency/list`, params)
                .pipe(map((response: any) => {
                    let res = this.resolveRequest.resolve<ICurrencyEntity[]>(response);
                    this.setSessionStorange(keysSessionStorangeEnum.GetCurrencyService, res);
                    return res;
                }))
                .pipe(map((entity: ICurrencyEntity[]) => entity && this.currencyMapper.mapFromList(entity)))
        return of(data);
    }

}