import { Component, Input, inject, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule, UtilsComponent } from '@management/ui';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedModule } from 'apps/management/src/app/shared/shared.module';
import { NgxTranslateModule } from '@management/ngx-translate';
import { DevextremeLibModule } from '@management/devextreme-lib';
import { InfrastructureFacade } from '@management/facade/management';
import { IDeleteInfrastructureDTO, IInfrastructureDTO, IStatesCrudInfraestructureDTO } from '@management/domain/management';
import { DxValidationGroupComponent } from 'devextreme-angular';

@Component({
  selector: 'app-form-infrastructure',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgxTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DevextremeLibModule,
    UiModule,
  ],
  templateUrl: './form-infrastructure.component.html',
  styleUrl: './form-infrastructure.component.scss'
})
export class FormInfrastructureComponent extends UtilsComponent implements OnChanges {

  @Input({ required: true }) infrastructureSelected!: IInfrastructureDTO | undefined;
  @ViewChild('validationGroupInfrastructure', { static: false }) validationGroup!: DxValidationGroupComponent;
  @Output() callServiceGetGetInfrastructureByCampusService: EventEmitter<IStatesCrudInfraestructureDTO> = new EventEmitter<{}>();
  public form!: FormGroup;
  public _fb = inject(FormBuilder);
  public readonly _infrastructureFacade = inject(InfrastructureFacade);
  public isEdit: WritableSignal<boolean> = signal(false);


  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.hasUserActive()) return
    if (changes["infrastructureSelected"]) {
      this.initForm();
    }
  }

  public initForm(): void {
    if (!this.infrastructureSelected) return
    this.isEdit.set(this.infrastructureSelected?.isEdit || false);
    if (this.isEdit()) {
      return this.initFormEdit();
    } else {
      this.initFormEmpty();
    }

  }

  public initFormEmpty(): void {
    this.form?.reset();
    this.validationGroup?.instance?.reset();
    let buildForm = {
      "name": ['',
        Validators.compose([
          Validators.required
        ])
      ],
      "state": [
        true,
        Validators.compose([
          Validators.required
        ])
      ],
      "description": [''
        ,
        Validators.compose([
          Validators.required
        ])
      ]
    };
    this.form = this._fb.group(buildForm);
  }

  public initFormEdit(): void {
    if (!this.infrastructureSelected) return
    let { name, state, description } = this.infrastructureSelected;
    let buildForm = {
      "name": [name,
        Validators.compose([
          Validators.required
        ])
      ],
      "state": [
        state,
        Validators.compose([
          Validators.required
        ])
      ],
      "description": [
        description,
        Validators.compose([
          Validators.required
        ])
      ]
    };
    this.form = this._fb.group(buildForm);
  }

  public setSaveInfrastructureService(e: any): void {
    if (!this.isFormValidTS(this.form)) return
    if (!this.infrastructureSelected) return
    const { top_id, campus, installation_type } = this.infrastructureSelected;
    const { name, state, description } = this.form.value;
    this._infrastructureFacade.SetSaveInfrastructureService({
      state,
      top_id,
      campus,
      installation_type,
      name,
      description
    }, true).subscribe(() => {
      this.resetForm();
      this.callServiceGetGetInfrastructureByCampusService.emit({});
    })
  }

  public resetForm(): void {
    this.form.reset();
    this.validationGroup.instance.reset();
    this.form?.get('state')?.setValue(true);
  }

  public updateInfraestructure(): void {
    if (!this.isFormValidTS(this.form)) return
    if (!this.infrastructureSelected) return
    const { top_id, campus, installation_type, id_item } = this.infrastructureSelected;
    const { name, state, description } = this.form.value;
    this._infrastructureFacade.UpdatePutEditInfrastructureService({
      state: state ? 1 : 0,
      top_id,
      campus,
      installation_type,
      name,
      id: id_item || 0,
      description
    }, true).subscribe(() => {
      if (!this.infrastructureSelected) return
      this.infrastructureSelected = { ...this.infrastructureSelected, name, state, description }
      this.initForm();
      this.callServiceGetGetInfrastructureByCampusService.emit({});
    })
  }

  public deleteInfraestructure(): void {
    if (!this.infrastructureSelected) return
    const { id_item } = this.infrastructureSelected;
    this._infrastructureFacade
      .DeleteInfrastructureService({ id: id_item || 0 }, true)
      .subscribe({
        next: (data: IDeleteInfrastructureDTO) => {
          if (!data) return
          this.initForm();
          this.callServiceGetGetInfrastructureByCampusService.emit({ delete: true });
        },
        error: () => {
          console.log('Se debe eliminar llaves foraneas para poder eliminar el elemento')
        }
      })
  }


}









