
import { Observable } from 'rxjs';
//domain
import {
    IPersonCampusPersonCampusDTO,
    IPersonCampusDTO,
} from '@management/domain/management';
import { UtilsComponent } from '@management/ui';
import { selector } from '@management/base/management';

export abstract class PersonCampusRepository extends UtilsComponent {
    abstract SetPersonCampusService(params: IPersonCampusPersonCampusDTO, loadService: boolean): Observable<IPersonCampusDTO>;
}