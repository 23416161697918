
import { Mapper } from '@management/base/management';
import { IPersonServicesDTO } from '@management/domain/management';
import { IPersonServicesEntity } from '@management/data/management';

export class PersonServicesMapper extends Mapper<IPersonServicesEntity, IPersonServicesDTO> {

    private static instance: PersonServicesMapper;

    private constructor() { super(); }

    public static getInstance(): PersonServicesMapper {
        if (!PersonServicesMapper.instance)
            PersonServicesMapper.instance = new PersonServicesMapper();
        return PersonServicesMapper.instance;
    }


    mapFrom(param: IPersonServicesEntity): IPersonServicesDTO {
        return {
            id: param.id,
            service: param.service,
            service_name: param.service_name,
            state: param.state
        };
    }
    mapFromList(params: IPersonServicesEntity[]): IPersonServicesDTO[] {
        return params.map((param: IPersonServicesEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IPersonServicesDTO): IPersonServicesEntity {
        return {
            id: param.id,
            service: param.service,
            service_name: param.service_name,
            state: param.state
        }
    }
    mapToList(params: IPersonServicesDTO[]): IPersonServicesEntity[] {
        return params.map((param: IPersonServicesDTO) => {
            return this.mapTo(param);
        })
    }
}