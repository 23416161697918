import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from '@angular/core';
import { Router } from "@angular/router";
import { IAuthDTO, ICompanySelectedDTO } from "@management/domain/management";
import { AuthFacade } from "@management/facade/management";
import { HEADERS } from "@management/base/management";
import { MessagesGLobal } from "@management/ui";
import { catchError, Observable, retry, Subscription, throwError } from "rxjs";
import { keysSessionStorangeEnum } from "../keysSessionStorange";

function getSessionStorange(key: string) {
    let data = sessionStorage.getItem(key);
    if (data)
        return JSON.parse(data);
    return
}

function clearSessionStorange() {
    sessionStorage.clear();
}


export function InterceptorService(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    let subscriptions = new Subscription();
    let user: IAuthDTO | null;
    let companySelected: ICompanySelectedDTO;
    let _authFacade = inject(AuthFacade)
    let router = inject(Router)
    let messagesGlobal = inject(MessagesGLobal)

    user = getSessionStorange(keysSessionStorangeEnum.user);
    companySelected = getSessionStorange(keysSessionStorangeEnum.userCompanySelected);

    req = req.clone({ headers: req.headers.set(HEADERS.LANG, `en`) });
    req = req.clone({ headers: req.headers.set(HEADERS.TOKEN, `${user?.token}`) });
    req = req.clone({ headers: req.headers.set(HEADERS.COMPANY, `${companySelected?.company.id}`) });
    req = req.clone({ headers: req.headers.set(HEADERS.CAMPUS, `${companySelected?.campus.id}`) });

    return next(req).pipe(
        retry(0),
        catchError((error: HttpErrorResponse) => {
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
                // client-side error
                errorMessage = `Error: ${error.error.message}`;
                messagesGlobal.setMessageConfig(messagesGlobal.error(errorMessage));
            } else {
                // server-side error
                errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
                if (error.status === 0 || error.status === 401 || error.status === 402 || error.status === 403) {
                    messagesGlobal.setMessageConfig(messagesGlobal.error('No tiene autorizacion.'));
                    clearSessionStorange();
                    _authFacade.cleanAuthRedux();
                    router.navigateByUrl('login');
                }

                if (error.status === 404) {
                    messagesGlobal.setMessageConfig(messagesGlobal.error(errorMessage));
                }

                if (error.status === 500 || error.status === 400) {
                    messagesGlobal.setMessageConfig(messagesGlobal.error(error?.error?.message));
                }
            }
            return throwError(() => new Error(errorMessage));
        })
    );
}
