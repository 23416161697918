
import { Observable } from 'rxjs';
//domain
import {
    IPersonPersonDTO,
    IPersonDTO,
    IPersonTypeUserIdentificationPersonTypeUserIdentificationDTO,
    IPersonTypeUserIdentificationDTO,
    ITypeUserEmailTypeUserByEmailDTO,
    ITypeUserEmailDTO,
    IPersonCompanyCampusPersonCompanyCampusDTO,
    IPersonCompanyCampusDTO,
    ICompanyCampusNameCompanyCampusNameDTO,
    ICompanyCampusNameDTO,
    ICompanyCampusEmailCompanyCampusEmailDTO,
    ICompanyCampusEmailDTO,
    ICompanyCampusIdentificationCompanyCampusIdentificationDTO,
    ICompanyCampusIdentificationDTO,
    IDeletePersonCollaboratorDeletePersonRoleCollaboratorDTO,
    IDeletePersonCollaboratorDTO,
    IPersonDeleteAllDeleteAllPersonDTO,
    IPersonDeleteAllDTO,
    IPersonCampusRolPersonCompanyCampusRolDTO,
    IPersonCampusRolDTO,
    IPersonServicesPersonServicesDTO,
    IPersonServicesDTO,
    IPersonCampusRolNamePersonCompanyCampusRolNameDTO,
    IPersonCampusRolNameDTO,
    IPersonRolEmailPersonCompanyCampusRolEmailDTO,
    IPersonRolEmailDTO,
    IPersonRolIdenticationPersonCompanyCampusRolIdenDTO,
    IPersonRolIdenticationDTO,
    IAvailablePersonsAvailablePersonsDTO,
    IAvailablePersonsDTO,
    IPersonTimesAvailableTimePersonDTO,
    IPersonTimesDTO,
} from '@management/domain/management';
import { UtilsComponent } from '@management/ui';
import { selector } from '@management/base/management';

export abstract class PersonRepository extends UtilsComponent {
    abstract SetAvailableTimePersonService(params: IPersonTimesAvailableTimePersonDTO, loadService: boolean): Observable<IPersonTimesDTO[]>;
    abstract GetAvailablePersonsService(params: IAvailablePersonsAvailablePersonsDTO, loadService: boolean): Observable<IAvailablePersonsDTO[]>;
    abstract GetPersonCompanyCampusRolIdenService(params: IPersonRolIdenticationPersonCompanyCampusRolIdenDTO, loadService: boolean): Observable<IPersonRolIdenticationDTO[]>;
    abstract GetPersonCompanyCampusRolEmailService(params: IPersonRolEmailPersonCompanyCampusRolEmailDTO, loadService: boolean): Observable<IPersonRolEmailDTO[]>;
    abstract GetPersonCompanyCampusRolNameService(params: IPersonCampusRolNamePersonCompanyCampusRolNameDTO, loadService: boolean): Observable<IPersonCampusRolNameDTO[]>;
    abstract GetPersonServicesService(params: IPersonServicesPersonServicesDTO, loadService: boolean): Observable<IPersonServicesDTO[]>;
    abstract GetPersonCompanyCampusRolService(params: IPersonCampusRolPersonCompanyCampusRolDTO, loadService: boolean): Observable<IPersonCampusRolDTO[]>;
    abstract DeleteDeleteAllPersonService(params: IPersonDeleteAllDeleteAllPersonDTO, loadService: boolean): Observable<IPersonDeleteAllDTO>;
    abstract DeleteDeletePersonRoleCollaboratorService(params: IDeletePersonCollaboratorDeletePersonRoleCollaboratorDTO, loadService: boolean): Observable<IDeletePersonCollaboratorDTO>;
    abstract GetCompanyCampusIdentificationService(params: ICompanyCampusIdentificationCompanyCampusIdentificationDTO, loadService: boolean): Observable<ICompanyCampusIdentificationDTO[]>;
    abstract GetCompanyCampusEmailService(params: ICompanyCampusEmailCompanyCampusEmailDTO, loadService: boolean): Observable<ICompanyCampusEmailDTO[]>;
    abstract GetCompanyCampusNameService(params: ICompanyCampusNameCompanyCampusNameDTO, loadService: boolean): Observable<ICompanyCampusNameDTO[]>;
    abstract GetPersonCompanyCampusService(params: IPersonCompanyCampusPersonCompanyCampusDTO, loadService: boolean): Observable<IPersonCompanyCampusDTO[]>;
    abstract GetTypeUserByEmailService(params: ITypeUserEmailTypeUserByEmailDTO, loadService: boolean): Observable<ITypeUserEmailDTO[]>;
    abstract GetPersonTypeUserIdentificationService(params: IPersonTypeUserIdentificationPersonTypeUserIdentificationDTO, loadService: boolean): Observable<IPersonTypeUserIdentificationDTO[]>;
    abstract SetPersonService(params: IPersonPersonDTO, loadService: boolean): Observable<IPersonDTO>;
}