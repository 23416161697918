import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    InfrastructureRepository,
    IDeleteInfrastructureDTO,
    IDeleteInfrastructureInfrastructureDTO
} from '@management/domain/management';

export class DeleteInfrastructureServiceUseCase implements UseCase<IDeleteInfrastructureInfrastructureDTO, IDeleteInfrastructureDTO> {

    private static instance: DeleteInfrastructureServiceUseCase;

    constructor(private deleteInfrastructureRepository: InfrastructureRepository) { }

    public static getInstance(deleteInfrastructureRepository: InfrastructureRepository): DeleteInfrastructureServiceUseCase {
        if (!DeleteInfrastructureServiceUseCase.instance)
            DeleteInfrastructureServiceUseCase.instance = new DeleteInfrastructureServiceUseCase(deleteInfrastructureRepository);
        return DeleteInfrastructureServiceUseCase.instance;
    }

    execute(
        params: IDeleteInfrastructureInfrastructureDTO, loadService: boolean
    ): Observable<IDeleteInfrastructureDTO> {
        return this.deleteInfrastructureRepository.DeleteInfrastructureService(params, loadService);
    }
}