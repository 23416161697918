import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    CurrencyRepository,
    ICurrencyDTO,
    ICurrencyCurrencyDTO
} from '@management/domain/management';

export class GetCurrencyServiceUseCase implements UseCase<ICurrencyCurrencyDTO, ICurrencyDTO[]> {

    private static instance: GetCurrencyServiceUseCase;

    constructor(private currencyRepository: CurrencyRepository) { }

    public static getInstance(currencyRepository: CurrencyRepository): GetCurrencyServiceUseCase {
        if (!GetCurrencyServiceUseCase.instance)
            GetCurrencyServiceUseCase.instance = new GetCurrencyServiceUseCase(currencyRepository);
        return GetCurrencyServiceUseCase.instance;
    }

    execute(
        params: ICurrencyCurrencyDTO, loadService: boolean
    ): Observable<ICurrencyDTO[]> {
        return this.currencyRepository.GetCurrencyService(params, loadService);
    }
}