
import { Mapper } from '@management/base/management';
import { ICompanyCampusEmailDTO } from '@management/domain/management';
import { ICompanyCampusEmailEntity } from '@management/data/management';

export class CompanyCampusEmailMapper extends Mapper<ICompanyCampusEmailEntity, ICompanyCampusEmailDTO> {

    private static instance: CompanyCampusEmailMapper;

    private constructor() { super(); }

    public static getInstance(): CompanyCampusEmailMapper {
        if (!CompanyCampusEmailMapper.instance)
            CompanyCampusEmailMapper.instance = new CompanyCampusEmailMapper();
        return CompanyCampusEmailMapper.instance;
    }


    mapFrom(param: ICompanyCampusEmailEntity): ICompanyCampusEmailDTO {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number,
            currency_name: param.currency_name,
            language_name: param.language_name,
            rol: param.rol,
            rol_name: param.rol_name
        };
    }
    mapFromList(params: ICompanyCampusEmailEntity[]): ICompanyCampusEmailDTO[] {
        return params.map((param: ICompanyCampusEmailEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: ICompanyCampusEmailDTO): ICompanyCampusEmailEntity {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number,
            currency_name: param.currency_name,
            language_name: param.language_name,
            rol: param.rol,
            rol_name: param.rol_name
        }
    }
    mapToList(params: ICompanyCampusEmailDTO[]): ICompanyCampusEmailEntity[] {
        return params.map((param: ICompanyCampusEmailDTO) => {
            return this.mapTo(param);
        })
    }
}