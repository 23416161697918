import { Mapper } from '@management/base/management';
import { IAssignmentDTO } from '@management/domain/management';
import { IAssignmentEntity } from '@management/data/management';

export class AssignmentMapper extends Mapper<IAssignmentEntity, IAssignmentDTO> {

    private static instance: AssignmentMapper;

    private constructor() { super(); }

    public static getInstance(): AssignmentMapper {
        if (!AssignmentMapper.instance)
            AssignmentMapper.instance = new AssignmentMapper();
        return AssignmentMapper.instance;
    }


    mapFrom(param: IAssignmentEntity): IAssignmentDTO {
        return {
            id: param.id,
            schedule: param.schedule,
            person: param.person,
            assignment_status: param.assignment_status,
            initial_day: param.initial_day,
            final_day: param.final_day,
            start_time: param.start_time,
            final_hour: param.final_hour
        };
    }
    mapFromList(params: IAssignmentEntity[]): IAssignmentDTO[] {
        return params.map((param: IAssignmentEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IAssignmentDTO): IAssignmentEntity {
        return {
            id: param.id,
            schedule: param.schedule,
            person: param.person,
            assignment_status: param.assignment_status,
            initial_day: param.initial_day,
            final_day: param.final_day,
            start_time: param.start_time,
            final_hour: param.final_hour
        }
    }
    mapToList(params: IAssignmentDTO[]): IAssignmentEntity[] {
        return params.map((param: IAssignmentDTO) => {
            return this.mapTo(param);
        })
    }
}