import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Message } from '../models/models';

export enum NotificationType {
  Exitoso = 1,
  Advertencia = 2,
  Fallida = 3,
  Informacion = 4,
}

export enum TypeMessage {
  StaticMessage = 1,
  TemporaryMessage = 2,
  none = 3,
}

@Injectable({
  providedIn: 'root',
})
export class MessagesGLobal {
  private messageInit: Message = {
    typeNotification: 1,
    typeMessage: 1,
    isShow: false,
    message: '',
  };
  message!: Message;

  private time: number = 5000;

  constructor() {}

  public messageConfig: BehaviorSubject<Message> = new BehaviorSubject<Message>(
    this.messageInit,
  );

  isShowMessage(isShow: boolean = false) {
    this.messageConfig.next({ ...this.messageConfig.value, isShow: isShow });
  }

  setMessageConfig(message: Message) {
    this.typeMessageTimer(message);
  }

  getMessageConfig() {
    return this.messageInit;
  }

  private typeMessageTimer(message: Message) {
    switch (message.typeMessage) {
      case TypeMessage.StaticMessage:
        this.messageConfig.next(message);
        this.isShowMessage(true);
        break;
      case TypeMessage.none:
        //TODO: this.isShowMessage(); no de debe colocar llega la siguiente peticion y limpia todo

        break;
      case TypeMessage.TemporaryMessage:
        this.messageConfig.next(message);
        setTimeout(() => {
          this.isShowMessage();
        }, this.time);
        break;
      default:
        this.isShowMessage();
        break;
    }
  }

  mapperResponseToMessage(requestResult: any) {
    const isArrayMessage = Array.isArray(requestResult?.message);

    return {
      isShow: true,
      message: isArrayMessage
        ? requestResult?.message[0]
        : requestResult?.message,
      typeMessage: requestResult.messageType,
      typeNotification: requestResult.notificationType,
    };
  }

  error(error: string) {
    return {
      isShow: true,
      message: error,
      typeMessage: TypeMessage.TemporaryMessage,
      typeNotification: NotificationType.Fallida,
    };
  }
}
