import { Injectable } from '@angular/core';
import { Action, Store } from "@ngrx/store";

import {
    environment,
    HttpClient,
    map,
    Observable,
    of,
    ResolveRequest,
    keysSessionStorangeEnum,
    PagedService
} from '@management/base/management';
//domain
import {
    InstallationTypeRepository,
    IInstallationTypeDTO,
    IInstallationTypeSaveInstallationTypeDTO,
    IInstallationTypesDTO,
    IInstallationTypesInstallationTypesDTO,
    IUpdateInstallationTypeDTO,
    IUpdateInstallationTypeInstallationTypeDTO,
    IDeleteInstallationTypeDTO,
    IDeleteInstallationTypeInstallationTypeDTO,
} from '@management/domain/management';
//data
import {
    IInstallationTypeEntity,
    InstallationTypeMapper,
    IInstallationTypesEntity,
    InstallationTypesMapper,
    IUpdateInstallationTypeEntity,
    UpdateInstallationTypeMapper,
    IDeleteInstallationTypeEntity,
    DeleteInstallationTypeMapper,
} from '@management/data/management';

import {
    actionsInstallationType,
    reducerInstallationType,
    selectorsInstallationType
} from './redux';


@Injectable({
    providedIn: 'root',
})
export class InstallationTypeImplementationRepository extends InstallationTypeRepository {

    private readonly urlApiRest: string = environment.api.urlApiRest;
    private readonly installationTypeMapper = InstallationTypeMapper.getInstance();
    private readonly deleteInstallationTypeMapper = DeleteInstallationTypeMapper.getInstance();
    private readonly updateInstallationTypeMapper = UpdateInstallationTypeMapper.getInstance();
    private readonly installationTypesMapper = InstallationTypesMapper.getInstance();

    constructor(
        private http: HttpClient,
        private resolveRequest: ResolveRequest,
        private store: Store<reducerInstallationType.InstallationTypeModel>) {
        super();
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    SetSaveInstallationTypeService(params: IInstallationTypeSaveInstallationTypeDTO, loadService: boolean): Observable<IInstallationTypeDTO> {
        return this.http
            .post<IInstallationTypeEntity>(`${this.urlApiRest}installationType`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IInstallationTypeEntity>(response);
                return res;
            }))
            .pipe(map((entity: IInstallationTypeEntity) => entity && this.installationTypeMapper.mapFrom(entity)))
    }

    GetInstallationTypesService(params: IInstallationTypesInstallationTypesDTO, loadService: boolean): Observable<PagedService<IInstallationTypesDTO[]>> {
        return this.http
            .post<IInstallationTypesEntity[]>(`${this.urlApiRest}installationType/list`, params)
            .pipe(map((response: any) => {
                const resolvePagination = this.resolveRequest.resolvePagination<IInstallationTypesEntity[]>(response);
                const result = this.installationTypesMapper.mapFromList(resolvePagination.result);
                const pagination = {
                    result: result,
                    pagination: resolvePagination.pagination
                }
                return pagination;
            }))
    }
    UpdateInstallationTypeService(params: IUpdateInstallationTypeInstallationTypeDTO, loadService: boolean): Observable<IUpdateInstallationTypeDTO> {
        return this.http
            .put<IUpdateInstallationTypeEntity>(`${this.urlApiRest}installationType`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IUpdateInstallationTypeEntity>(response);
                return res;
            }))
            .pipe(map((entity: IUpdateInstallationTypeEntity) => entity && this.updateInstallationTypeMapper.mapFrom(entity)))
    }

    DeleteInstallationTypeService(params: IDeleteInstallationTypeInstallationTypeDTO, loadService: boolean): Observable<IDeleteInstallationTypeDTO> {
        const options = {
            body: {
                id: params.id
            }
        }
        return this.http
            .delete<IDeleteInstallationTypeEntity>(`${this.urlApiRest}installationType`, options)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IDeleteInstallationTypeEntity>(response);
                return res;
            }))
            .pipe(map((entity: IDeleteInstallationTypeEntity) => entity && this.deleteInstallationTypeMapper.mapFrom(entity)))

    }
}