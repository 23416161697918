import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getCountry } from '@management/ngx-translate';
import { LangEnum, PopupColorTypeEnum } from 'libs/ui/src/lib/enum/global-enum';
import { initDataPlatformRedux, initDataPlatformReduxReload } from './shared/utils/utils';
import { Message, MessagesGLobal, UtilsComponent, getLanguage, ConfigMenu, UiModule, MenuVerticalComponent } from '@management/ui';
import { AuthFacade, MenuFacade } from '@management/facade/management';
import { selector } from '@management/base/management';
import { ICampusByCompanyDTO, ICompanyDTO, ICompanySelectedDTO, IMenuDTO, IPersonDTO } from '@management/domain/management';
import { ConfigProcessPopup } from 'libs/ui/src/lib/moleculas/process-popup/process-popup.interface';
import { first } from 'rxjs';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { IAppPopupUserData } from './shared/components/popup-user/popup-user.component';
import { inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DevextremeLibModule } from '@management/devextreme-lib';
import { appRoutes } from './app.routes';

@Component({
  selector: 'management-root',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    DevextremeLibModule,
    UiModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    MenuVerticalComponent
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends UtilsComponent implements OnInit {

  configMenu: ConfigMenu = {
    stateMenu: true,
    stateNotification: false,
    stateUser: false,
    onClickMenu: () => this.onClickMenu(),
    onClickUser: () => this.onClickUser(),
    onClickNotification: () => this.onClickNotification()
  }

  configPopupUser: ConfigProcessPopup = {
    width: '400',
    height: '370',
    dragEnabled: false,
    visible: false,
    popupColorType: PopupColorTypeEnum.NORMAL
  }

  configPopupGlobal: ConfigProcessPopup = {
    width: '300',
    height: '190',
    dragEnabled: false,
    visible: true,
    popupColorType: PopupColorTypeEnum.NORMAL
  }

  appPopupUserData!: IAppPopupUserData;

  message!: Message;
  isValidUser = false;
  person!: selector<IPersonDTO>;
  menu!: IMenuDTO[];
  isCollapseMenu = this.configMenu.stateMenu;
  private _router = inject(Router)
  private _activatedRoute = inject(ActivatedRoute)
  private translate = inject(TranslateService)
  private _authFacade = inject(AuthFacade)
  private _menuFacade = inject(MenuFacade)
  private messagesGLobal = inject(MessagesGLobal)
  private readonly notReloadthisUrl = ['/']


  constructor() {
    super();
    appRoutes()
  }


  ngOnInit(): void {
    this.setTranslate();
    initDataPlatformRedux(new UtilsComponent, this._authFacade);
    initDataPlatformReduxReload(new UtilsComponent, this._authFacade, this._menuFacade);
    this.getMessage();
    this.getUser();
    setTimeout(() => {
      //TODO: tomar el nombre de la compañia para enrutar todo con ese nombre.
      this._router.url.toString()
    }, 1000);

  }

  public setConfigMenu(): void {
    this._authFacade.getCompanySelectedSelector()
      .pipe(first(e => this.isNotUndefinedOrNull(e)))
      .subscribe((res: selector<ICompanySelectedDTO>) => {
        if (res && this.person)
          this.configMenu = { ...this.configMenu, companySelectedDTO: res }
      })


  }

  setTranslate() {
    // si quitas la validacion tendras problemas con las traducciones NO se te van a traducir si refrescas la paguina.
    if (!this.translate.getDefaultLang()) {
      getCountry(this.translate, LangEnum.esCO, getLanguage());
    }
  }

  public getMessage(): void {
    this.messagesGLobal.messageConfig
      .subscribe((data: Message) => {
        if (!data) return
        this.configPopupGlobal = { ...this.configPopupGlobal, visible: data.isShow || false }
        this.message = data;
      }
      );
  }

  getUser(): void {
    this._authFacade.getPersonSelector()
      .subscribe((person: selector<IPersonDTO>) => {
        this.person = person;
        this.isValidUser = person?.state || false;
        this.getRecursiveMenu();
        this.setConfigMenu();
      })
  }

  //** This method works like the onDestroy */
  closeApp(): void {
    this.configPopupUser = { ...this.configPopupUser, visible: false }
    this.configMenu = {
      ...this.configMenu, stateMenu: true,
      stateNotification: false,
      stateUser: false,
    }
    this.isCollapseMenu = this.configMenu.stateMenu;
    this.notReloadthisUrls();
  }

  private notReloadthisUrls(): void {
    setTimeout(() => {
      if (!this.notReloadthisUrl.includes(this._router.url.toString())) {
        location.reload();
      }
    }, 500);
  }

  onClickMenu() {
    this.configMenu =
    {
      ...this.configMenu,
      stateMenu: !this.configMenu.stateMenu,
      stateUser: false,
      stateNotification: false
    }

    this.isCollapseMenu = this.configMenu.stateMenu;
  }

  onClickUser() {

    this.initDataPopupUser();
    this.configMenu =
    {
      ...this.configMenu,
      stateUser: !this.configMenu.stateUser,
      stateMenu: false,
      stateNotification: false
    }

    this.configPopupUser = { ...this.configPopupUser, visible: true }

  }

  private initDataPopupUser() {
    this.getListCompaniesRedux();
    this.buildCurrentDataPopupUserRedux();
    this.getCurrentListCampusByCompanyRedux();
  }


  private getListCompaniesRedux() {
    this._authFacade.getListCompaniesSelector()
      .pipe(first(e => this.isNotUndefinedOrNull(e)))
      .subscribe((res: selector<ICompanyDTO[]>) => {
        if (res)
          this.appPopupUserData = { ...this.appPopupUserData, listCompanies: res }
      })
  }

  private buildCurrentDataPopupUserRedux() {
    this._authFacade.getCompanySelectedSelector()
      .pipe(first(e => this.isNotUndefinedOrNull(e)))
      .subscribe((res: selector<ICompanySelectedDTO>) => {
        if (res && this.person)
          this.appPopupUserData =
          {
            ...this.appPopupUserData,
            companyId: res.company.id,
            campusId: res.campus.id,
            userId: this.person.id,
            onClose: () => this.closeApp()
          }
      })
  }

  private getCurrentListCampusByCompanyRedux() {
    this._authFacade.getListCampusByCompanySelector()
      .pipe(first(e => this.isNotUndefinedOrNull(e)))
      .subscribe((res: selector<ICampusByCompanyDTO[]>) => {
        if (res)
          this.appPopupUserData =
          {
            ...this.appPopupUserData,
            listCampusByCompanies: res
          }
      })
  }



  onClickNotification() {
    this.configMenu = {
      ...this.configMenu,
      stateNotification: !this.configMenu.stateNotification,
      stateUser: false,
      stateMenu: false
    }
  }


  private getRecursiveMenu(): void {
    if (!this.person) return
    this._menuFacade.getRecursiveMenuSelector()
      .subscribe((res: selector<IMenuDTO[]>) => {
        if (res)
          this.menu = res;
      })

  }

  goUrl(event: IMenuDTO): void {
    this._router.navigate([event.route]);
  }



}
