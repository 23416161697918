import { Observable } from 'rxjs';
                import { UseCase } from '@management/base/management';
                import {
                    PersonServiceRepository,
                    IPersonServiceDTO,
                    IPersonServiceGetListPersonServiceDTO
                } from '@management/domain/management';

export class GetGetListPersonServiceServiceUseCase implements UseCase<IPersonServiceGetListPersonServiceDTO, IPersonServiceDTO[]> {

    private static instance: GetGetListPersonServiceServiceUseCase;

    constructor(private personServiceRepository: PersonServiceRepository) { }

    public static getInstance(personServiceRepository: PersonServiceRepository): GetGetListPersonServiceServiceUseCase {
        if (!GetGetListPersonServiceServiceUseCase.instance)
            GetGetListPersonServiceServiceUseCase.instance = new GetGetListPersonServiceServiceUseCase(personServiceRepository);
        return GetGetListPersonServiceServiceUseCase.instance;
    }

    execute(
        params: IPersonServiceGetListPersonServiceDTO, loadService: boolean
    ): Observable<IPersonServiceDTO[]> {
        return this.personServiceRepository.GetGetListPersonServiceService(params, loadService);
    }
}