import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'apps/management/src/app/shared/shared.module';
import { NgxTranslateModule } from '@management/ngx-translate';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DevextremeLibModule } from '@management/devextreme-lib';
import { UiModule, UtilsComponent } from '@management/ui';
import { ICurrencyDTO, ILanguageDTO, IPersonCompanyCampusDTO, IRolDTO, ITypeUserEmailDTO } from '@management/domain/management';
import { of, take } from 'rxjs';
import { PersonFacade } from '@management/facade/management';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { TYPE_USER_BASE } from '@management/base/management';
import { ConfigProcessPopup, TYPE_MODAL } from 'libs/ui/src/lib/moleculas/process-popup/process-popup.interface';
import { ButtonType } from 'devextreme/common';
import { TextBoxType } from 'devextreme/ui/text_box';

@Component({
  selector: 'app-person-edit-popup',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgxTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DevextremeLibModule,
    UiModule
  ],
  templateUrl: './person-edit-popup.component.html',
  styleUrl: './person-edit-popup.component.scss'
})
export class PersonEditPopupComponent extends UtilsComponent implements OnChanges {
  @ViewChild('validationGroupPersonPopup', { static: false }) validationGroup!: DxValidationGroupComponent;
  @Input({ required: true }) currencies: ICurrencyDTO[] = [];
  @Input({ required: true }) languages: ILanguageDTO[] = [];
  @Input({ required: true }) rols: IRolDTO[] = [];
  @Input({ required: true }) configPopupPerson!: ConfigProcessPopup;
  @Output() sendForm = new EventEmitter<FormGroup>();
  @Output() close = new EventEmitter<boolean>();

  public form!: FormGroup;
  public readonly namePattern: RegExp = /^[^0-9]+$/;
  public passwordMode: TextBoxType = 'password';
  public passwordButton: any = {
    icon: './assets/img/eye.png',
    type: 'default',
    onClick: () => {
      this.passwordMode = this.passwordMode === 'text' ? 'password' : 'text';
    },
  };
  public _fb = inject(FormBuilder);
  public _personFacade = inject(PersonFacade);

  constructor() {
    super();
  }



  ngOnChanges(changes: SimpleChanges): void {
    if (!this.hasUserActive()) return
    const hasToResetForm = changes['configPopup'] && !this.configPopupPerson.visible;
    if (hasToResetForm)
      this.resetForm();
    if (changes["configPopupPerson"]) {
      this.initForm();
      this.validateInputsRules();
    }
  }


  public initForm(): void {
    switch (this.configPopupPerson.typeModal) {
      case TYPE_MODAL.CREATE:
        this.form = this._fb.group(this.initFormCreate());
        break;
      case TYPE_MODAL.UPDATE:
        this.form = this._fb.group(this.inintFormUpdate());
        break;
    }
  }

  public initFormCreate() {
    return {
      "name": ['',
        Validators.compose([
          Validators.required
        ])
      ],
      "identification_number": [,
        Validators.compose([
          Validators.required
        ])
      ],
      "state": [
        true,
        Validators.compose([
          Validators.required
        ])
      ],
      "cellPhone": [
        ,
        Validators.compose([
          Validators.required
        ])
      ],
      "email": [
        ,
        Validators.compose([
          Validators.required
        ])
      ],
      "password": [
        ,
        Validators.compose([
          Validators.required
        ])
      ],
      "currency": [
        ,
        Validators.compose([
          Validators.required
        ])
      ],
      "language": [
        ,
        Validators.compose([
          Validators.required
        ])
      ],
      "rol": [
        ,
        Validators.compose([
          Validators.required
        ])
      ]
    };
  }

  public inintFormUpdate() {
    //TODO no tenemos el rol de la persona como paso ?
    const { name, identification_number, state, cellPhone, email, password, currency, language, rol } = this.configPopupPerson.dataSource as IPersonCompanyCampusDTO;
    return {
      "name": [name,
        Validators.compose([
          Validators.required
        ])
      ],
      "identification_number": [identification_number,
        Validators.compose([
          Validators.required
        ])
      ],
      "state": [
        state,
        Validators.compose([
          Validators.required
        ])
      ],
      "cellPhone": [cellPhone
        ,
        Validators.compose([
          Validators.required
        ])
      ],
      "email": [email
        ,
        Validators.compose([
          Validators.required
        ])
      ],
      "password": [password
        ,
        Validators.compose([
          Validators.required
        ])
      ],
      "currency": [currency
        ,
        Validators.compose([
          Validators.required
        ])
      ],
      "language": [language
        ,
        Validators.compose([
          Validators.required
        ])
      ],
      "rol": [rol
        ,
        Validators.compose([
          Validators.required
        ])
      ]
    };
  }

  public closePopup(): void {
    this.resetForm();
    this.close.emit(false);
  }
  public validateInputsRules(): void {
    this.form.get('rol')?.valueChanges.subscribe(() => {
      let identificationNumberCurrentValue = this.form.value?.identification_number;
      let emailNumberCurrentValue = this.form.value?.email;
      this.form.get('identification_number')?.patchValue('');
      this.form.get('identification_number')?.patchValue(identificationNumberCurrentValue);
      this.form.get('email')?.patchValue('');
      this.form.get('email')?.patchValue(emailNumberCurrentValue);
    })
  }

  public submitData(): void {
    if (!this.isFormValidTS(this.form)) return
    this.sendForm.emit(this.form)
  }

  public resetForm(): void {
    this.form?.reset();
      this.validationGroup?.instance?.reset();
      console.log(this.validationGroup?.instance)

    this.form?.get('state')?.setValue(true);
  }

  public asyncValidationIdentification = (identificationNumber: any): Promise<boolean> => {
    const rolSeleted = this.rols.find((rol) => rol.id === this.form.value.rol)
    //TODO cuando este configurando las keys de traducciones OJO con el colaborador...
    if (rolSeleted?.name === TYPE_USER_BASE.USER)
      return new Promise((resolve) => {
        this._personFacade
          .GetPersonTypeUserIdentificationService({ identificationNumber: identificationNumber.value }, true)
          .pipe(take(1))
          .subscribe((person: ITypeUserEmailDTO[]) => {
            if (person.length === 0)
              return resolve(true);
            return resolve(false);
          })
      })

    return new Promise((resolve) => {
      return resolve(true);
    })
  }

  public asyncValidationEmail = (email: any): Promise<boolean> => {
    const rolSeleted = this.rols.find((rol) => rol.id === this.form.value.rol)
    //TODO cuando este configurando las keys de traducciones OJO con el colaborador...
    if (rolSeleted?.name === TYPE_USER_BASE.USER)
      return new Promise((resolve) => {
        this._personFacade
          .GetTypeUserByEmailService({ email: email.value }, true)
          .pipe(take(1))
          .subscribe((person: ITypeUserEmailDTO[]) => {
            if (person.length === 0)
              return resolve(true);
            return resolve(false);
          })
      })

    return new Promise((resolve) => {
      return resolve(true);
    })
  }

  public typeModal(): string {
    switch (this.configPopupPerson.typeModal) {
      case TYPE_MODAL.ADD:
        return 'Asignar'
      case TYPE_MODAL.CREATE:
        return 'Crear'
      case TYPE_MODAL.UPDATE:
        return 'Editar'
    }
    return '';
  }

}
