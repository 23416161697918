import { Observable } from "rxjs";
//domain
import {
    PersonCampusRepository,
    IPersonCampusDTO,
    IPersonCampusPersonCampusDTO,
    SetPersonCampusServiceUseCase,
} from "@management/domain/management";


export class PersonCampusFacade extends PersonCampusRepository {

    private static instance: PersonCampusFacade;

    constructor(private personCampusRepository: PersonCampusRepository) {
        super();
    }

    public static getInstance(personCampusRepository: PersonCampusRepository): PersonCampusFacade {
        if (!PersonCampusFacade.instance)
            PersonCampusFacade.instance = new PersonCampusFacade(personCampusRepository);
        return PersonCampusFacade.instance;
    }

    SetPersonCampusService(params: IPersonCampusPersonCampusDTO, loadService: boolean): Observable<IPersonCampusDTO> {
        return SetPersonCampusServiceUseCase.getInstance(this.personCampusRepository).execute(params, loadService);
    }

}