import { UseCase } from '@management/base/management';
import {
    IInfrastructuresDTO,
    IInfraestructuresByAssignInputDTO
} from '@management/domain/management';

export class InfraestructuresByAssignLogicUseCase implements UseCase<IInfraestructuresByAssignInputDTO, IInfrastructuresDTO[]> {

    private static instance: InfraestructuresByAssignLogicUseCase;

    constructor() { }

    public static getInstance(): InfraestructuresByAssignLogicUseCase {
        if (!InfraestructuresByAssignLogicUseCase.instance)
            InfraestructuresByAssignLogicUseCase.instance = new InfraestructuresByAssignLogicUseCase();
        return InfraestructuresByAssignLogicUseCase.instance;
    }

    execute(
        params: IInfraestructuresByAssignInputDTO
    ): IInfrastructuresDTO[] {
        let { personInfraestructures, infraestructuresAvailable } = params;

        const result = infraestructuresAvailable.filter((infrastructure: IInfrastructuresDTO) => {
            return !personInfraestructures.some(personInfraestructure => personInfraestructure.infrastructure === infrastructure.id);
        });

        return result;
    }
}