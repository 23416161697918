import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonRepository,
    IDeletePersonCollaboratorDTO,
    IDeletePersonCollaboratorDeletePersonRoleCollaboratorDTO
} from '@management/domain/management';

export class DeleteDeletePersonRoleCollaboratorServiceUseCase implements UseCase<IDeletePersonCollaboratorDeletePersonRoleCollaboratorDTO, IDeletePersonCollaboratorDTO> {

    private static instance: DeleteDeletePersonRoleCollaboratorServiceUseCase;

    constructor(private deletePersonCollaboratorRepository: PersonRepository) { }

    public static getInstance(deletePersonCollaboratorRepository: PersonRepository): DeleteDeletePersonRoleCollaboratorServiceUseCase {
        if (!DeleteDeletePersonRoleCollaboratorServiceUseCase.instance)
            DeleteDeletePersonRoleCollaboratorServiceUseCase.instance = new DeleteDeletePersonRoleCollaboratorServiceUseCase(deletePersonCollaboratorRepository);
        return DeleteDeletePersonRoleCollaboratorServiceUseCase.instance;
    }

    execute(
        params: IDeletePersonCollaboratorDeletePersonRoleCollaboratorDTO, loadService: boolean
    ): Observable<IDeletePersonCollaboratorDTO> {
        return this.deletePersonCollaboratorRepository.DeleteDeletePersonRoleCollaboratorService(params, loadService);
    }
}