import { Observable } from "rxjs";
//domain
import {
    ScheduleRepository,
    IScheduleDTO,
    IScheduleScheduleDTO,
    GetScheduleServiceUseCase,
    IScheduleSaveDTO,
    IScheduleSaveScheduleDTO,
    SetScheduleServiceUseCase,
} from "@management/domain/management";


export class ScheduleFacade extends ScheduleRepository {

    private static instance: ScheduleFacade;

    constructor(private scheduleRepository: ScheduleRepository) {
        super();
    }

    public static getInstance(scheduleRepository: ScheduleRepository): ScheduleFacade {
        if (!ScheduleFacade.instance)
            ScheduleFacade.instance = new ScheduleFacade(scheduleRepository);
        return ScheduleFacade.instance;
    }

    GetScheduleService(params: IScheduleScheduleDTO, loadService: boolean): Observable<IScheduleDTO[]> {
        return GetScheduleServiceUseCase.getInstance(this.scheduleRepository).execute(params, loadService);
    }

    SetScheduleService(params: IScheduleSaveScheduleDTO, loadService: boolean): Observable<IScheduleSaveDTO> {
        return SetScheduleServiceUseCase.getInstance(this.scheduleRepository).execute(params, loadService);
    }
}