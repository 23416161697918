import { actionsInstallationType } from './index';
import { createReducer, on } from '@ngrx/store';
import {  } from "@management/domain/management";

export const INSTALLATION_TYPE_KEY = 'installationType'
export interface InstallationTypeModel {
}

export const InstallationTypeInitialState: InstallationTypeModel = {
}

const _InstallationTypeReducer = createReducer(InstallationTypeInitialState,
);

export function InstallationTypeReducer(state: InstallationTypeModel, action: any) {
    return _InstallationTypeReducer(state, action);
}