import { actionsLanguage } from './index';
import { createReducer, on } from '@ngrx/store';
import {  } from "@management/domain/management";

export const LANGUAGE_KEY = 'language'
export interface LanguageModel {
}

export const LanguageInitialState: LanguageModel = {
}

const _LanguageReducer = createReducer(LanguageInitialState,
);

export function LanguageReducer(state: LanguageModel, action: any) {
    return _LanguageReducer(state, action);
}