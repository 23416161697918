import { Injectable } from '@angular/core';
import { Action, Store } from "@ngrx/store";

import {
    environment,
    HttpClient,
    map,
    Observable,
    of,
    ResolveRequest,
    keysSessionStorangeEnum
} from '@management/base/management';
//domain
import {
    AssignmentRepository,
    IAssignmentDTO,
    IAssignmentAssignmentDTO,
} from '@management/domain/management';
//data
import {
    IAssignmentEntity,
    AssignmentMapper,
} from '@management/data/management';

import {
    actionsAssignment,
    reducerAssignment,
    selectorsAssignment
} from './redux';


@Injectable({
    providedIn: 'root',
})
export class AssignmentImplementationRepository extends AssignmentRepository {

    private readonly urlApiRest: string = environment.api.urlApiRest;
    private readonly assignmentMapper = AssignmentMapper.getInstance();

    constructor(
        private http: HttpClient,
        private resolveRequest: ResolveRequest,
        private store: Store<reducerAssignment.AssignmentModel>) {
        super();
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    SetAssignmentService(params: IAssignmentAssignmentDTO, loadService: boolean): Observable<IAssignmentDTO> {
        return this.http
            .post<IAssignmentEntity>(`${this.urlApiRest}assignment`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IAssignmentEntity>(response);
                return res;
            }))
            .pipe(map((entity: IAssignmentEntity) => entity && this.assignmentMapper.mapFrom(entity)))
    }

}