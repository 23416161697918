import { Mapper } from '@management/base/management';
import { IRolDTO } from '@management/domain/management';
import { IRolEntity } from '@management/data/management';

export class RolMapper extends Mapper<IRolEntity, IRolDTO> {

    private static instance: RolMapper;

    private constructor() { super(); }

    public static getInstance(): RolMapper {
        if (!RolMapper.instance)
            RolMapper.instance = new RolMapper();
        return RolMapper.instance;
    }


    mapFrom(param: IRolEntity): IRolDTO {
        return {
            id: param.id,
            company: param.company,
            code: param.code,
            state: param.state,
            name: param.name
        };
    }
    mapFromList(params: IRolEntity[]): IRolDTO[] {
        return params.map((param: IRolEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IRolDTO): IRolEntity {
        return {
            id: param.id,
            company: param.company,
            code: param.code,
            state: param.state,
            name: param.name
        }
    }
    mapToList(params: IRolDTO[]): IRolEntity[] {
        return params.map((param: IRolDTO) => {
            return this.mapTo(param);
        })
    }
}