import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map } from 'rxjs/operators';

import * as authActions from "./menu.actions";


@Injectable()
export class MenuEffects {

    constructor(
        private actions$: Actions
    ) { }


    // setUser$ = createEffect(
    //     () => this.actions$.pipe(
    //         ofType(authActions.setUser),
    //         mergeMap(async (actions) => authActions.setUser({ authDTO: actions.authDTO }))
    //     )
    // );

}