

import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import { AuthRepository, IPersonDTO } from '@management/domain/management';
import { selector } from '@management/base/management';

export class GetPersonReduxUseCase implements UseCase<selector<IPersonDTO>, selector<IPersonDTO>> {
    private static instance: GetPersonReduxUseCase;
    constructor(private authRepository: AuthRepository) { }
    public static getInstance(authRepository: AuthRepository): GetPersonReduxUseCase {
        if (!GetPersonReduxUseCase.instance)
            GetPersonReduxUseCase.instance = new GetPersonReduxUseCase(authRepository);
        return GetPersonReduxUseCase.instance;
    }
    execute(): Observable<selector<IPersonDTO>> {
        return this.authRepository.getPersonSelector()
    }
}