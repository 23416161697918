import { Observable } from 'rxjs';
import { PagedService, UseCase } from '@management/base/management';
import {
    InstallationTypeRepository,
    IInstallationTypesDTO,
    IInstallationTypesInstallationTypesDTO
} from '@management/domain/management';

export class GetInstallationTypesServiceUseCase implements UseCase<IInstallationTypesInstallationTypesDTO, PagedService<IInstallationTypesDTO[]>> {

    private static instance: GetInstallationTypesServiceUseCase;

    constructor(private installationTypesRepository: InstallationTypeRepository) { }

    public static getInstance(installationTypesRepository: InstallationTypeRepository): GetInstallationTypesServiceUseCase {
        if (!GetInstallationTypesServiceUseCase.instance)
            GetInstallationTypesServiceUseCase.instance = new GetInstallationTypesServiceUseCase(installationTypesRepository);
        return GetInstallationTypesServiceUseCase.instance;
    }

    execute(
        params: IInstallationTypesInstallationTypesDTO, loadService: boolean
    ): Observable<PagedService<IInstallationTypesDTO[]>> {
        return this.installationTypesRepository.GetInstallationTypesService(params, loadService);
    }
}