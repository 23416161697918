import { Observable } from 'rxjs';
                import { UseCase } from '@management/base/management';
                import {
                    PersonRepository,
                    IPersonRolEmailDTO,
                    IPersonRolEmailPersonCompanyCampusRolEmailDTO
                } from '@management/domain/management';

export class GetPersonCompanyCampusRolEmailServiceUseCase implements UseCase<IPersonRolEmailPersonCompanyCampusRolEmailDTO, IPersonRolEmailDTO[]> {

    private static instance: GetPersonCompanyCampusRolEmailServiceUseCase;

    constructor(private personRolEmailRepository: PersonRepository) { }

    public static getInstance(personRolEmailRepository: PersonRepository): GetPersonCompanyCampusRolEmailServiceUseCase {
        if (!GetPersonCompanyCampusRolEmailServiceUseCase.instance)
            GetPersonCompanyCampusRolEmailServiceUseCase.instance = new GetPersonCompanyCampusRolEmailServiceUseCase(personRolEmailRepository);
        return GetPersonCompanyCampusRolEmailServiceUseCase.instance;
    }

    execute(
        params: IPersonRolEmailPersonCompanyCampusRolEmailDTO, loadService: boolean
    ): Observable<IPersonRolEmailDTO[]> {
        return this.personRolEmailRepository.GetPersonCompanyCampusRolEmailService(params, loadService);
    }
}