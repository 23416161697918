import { CommonModule } from '@angular/common';
import {
  Component,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { PagedService, selector } from '@management/base/management';
import { DevextremeLibModule } from '@management/devextreme-lib';
import { Filter, ICompanySelectedDTO, IInstallationTypeSaveInstallationTypeDTO, IInstallationTypesDTO, IInstallationTypesInstallationTypesDTO } from '@management/domain/management';
import { AuthFacade, InstallationTypeFacade } from '@management/facade/management';
import { NgxTranslateModule } from '@management/ngx-translate';
import { CONDITION, Filter as LibraryFilter, TYPE_VALUE, UiModule, UtilsComponent } from '@management/ui';
import { SharedModule } from 'apps/management/src/app/shared/shared.module';
import {
  DxValidationGroupComponent,
} from 'devextreme-angular';
import { PopupColorTypeEnum } from 'libs/ui/src/lib/enum/global-enum';
import { ConfigProcessPopup } from 'libs/ui/src/lib/moleculas/process-popup/process-popup.interface';
import { first, take } from 'rxjs';
import { IPopupEditInstallationData, PopupEditInstallationComponent } from './components/popup-edit-installation/popup-edit-installation.component';

@Component({
  selector: 'app-installation',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgxTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DevextremeLibModule,
    UiModule,
    PopupEditInstallationComponent
  ],
  templateUrl: './installation.component.html',
  styleUrls: ['./installation.component.scss'],
})
export class InstallationComponent extends UtilsComponent implements OnInit {
  @ViewChild('myValidationGroup', { static: false })
  validationGroup!: DxValidationGroupComponent;
  public installationTypes: IInstallationTypesDTO[] = [];
  public installationTypeSaveInstallationTypeDTO!: IInstallationTypeSaveInstallationTypeDTO;
  public form!: FormGroup;
  public companySelected!: ICompanySelectedDTO;
  public configPopupEdit: ConfigProcessPopup = {
    width: '300',
    height: '265',
    dragEnabled: false,
    visible: false,
    popupColorType: PopupColorTypeEnum.NORMAL
  };
  public popupEditData!: IPopupEditInstallationData;
  public fb = inject(FormBuilder);
  public _authFacade = inject(AuthFacade);
  public _installationTypeFacade = inject(InstallationTypeFacade);


  constructor() {
    super();
  }

  ngOnInit() {
    if (!this.hasUserActive()) return
    this.getCompanySelected();
    this.initForm();
    this.getInstallationTypes();
    this.initFilters();
  }

  public initForm(): void {
    let buildForm = {
      name: [``, Validators.compose([Validators.required])],
      state: [true, Validators.compose([Validators.required])],
      description: [``, Validators.compose([Validators.required])],
    };
    this.form = this.fb.group(buildForm);
  }

  initFilters(): void {
    this.filters = [
      { id: 1, type_value: TYPE_VALUE.NUMBER, condition: CONDITION.EQUALS, field: 'id', value: 'INSTALLATION_COMPONENT.id' },
      { id: 2, type_value: TYPE_VALUE.STRING, condition: CONDITION.LIKE, field: 'name', value: 'INSTALLATION_COMPONENT.name' },
      {
        id: 3, type_value: TYPE_VALUE.SELECT, condition: CONDITION.EQUALS, field: 'state', value: 'INSTALLATION_COMPONENT.state',
        datasource: [{ id: "true", value: 'INSTALLATION_COMPONENT.active' }, { id: "false", value: 'INSTALLATION_COMPONENT.inactive' }]
      },
      { id: 4, type_value: TYPE_VALUE.STRING, condition: CONDITION.LIKE, field: 'description', value: 'INSTALLATION_COMPONENT.description' }
    ];
  }

  public onFilter(filter?: LibraryFilter[]): void {
    this.currentFilter = filter as Filter[];
    const { campus } = this.companySelected;
    const filterBase = {
      value: campus.id,
      type_value: TYPE_VALUE.NUMBER,
      condition: CONDITION.EQUALS,
      field: "campus",
      nextCondition: CONDITION.AND
    }
    this.currentFilter.push(filterBase)
    this.currentFilter.reverse()
    const build = { ...this.pageInit, filters: this.currentFilter };
    this.configPaginator(build);
  }

  public onClear(event?: boolean): void {
    this.currentFilter = [];
    this.pageChange(1);
  }

  public pageChange(page: number): void {
    this.currentPage = page;
    const { pageLimit, startPage } = this.pagination.items[page - 1];
    this.configPaginator({ startPage, pageLimit, pageGroup: this.pageGroup, filters: this.currentFilter });
  }

  public getInstallationTypes(): void {
    this.configPaginator(this.pageInit);
  }

  private getCompanySelected(): void {
    this._authFacade.getCompanySelectedSelector()
      .pipe(first(e => this.isNotUndefinedOrNull(e)))
      .subscribe((companySelected: selector<ICompanySelectedDTO>) => {
        this.companySelected = companySelected as ICompanySelectedDTO;
      })
  }

  public configPaginator(params: IInstallationTypesInstallationTypesDTO): void {
    const { campus } = this.companySelected;
    const filterBase = {
      value: campus.id,
      type_value: TYPE_VALUE.NUMBER,
      condition: CONDITION.EQUALS,
      field: "campus"
    }
    if (!params?.filters?.length) {
      params = { ...params, filters: [filterBase] }
    }
    this._installationTypeFacade.GetInstallationTypesService(params, true)
      .pipe(first((e) => !!e))
      .subscribe((installationTypes: PagedService<IInstallationTypesDTO[]>) => {
        this.installationTypes = installationTypes.result;
        this.setPaginator(installationTypes);
      })
  }

  public submitData(): void {
    if (this.isFormValidTS(this.form)) {
      this._installationTypeFacade
        .SetSaveInstallationTypeService(
          this.form.value as IInstallationTypeSaveInstallationTypeDTO,
          true
        )
        .pipe(first((e) => !!e))
        .subscribe((rest) => {
          this.form.reset();
          this.validationGroup.instance.reset();
          this.form?.get('state')?.setValue(true);
          this.getInstallationTypes();
        });
    }
  }

  public popupEditInstallation(installationTypesDTO: IInstallationTypesDTO): void {
    this.configPopupEdit = { ...this.configPopupEdit, visible: true }
    this.popupEditData = {
      ...this.popupEditData,
      onClose: () => this.closePopupEdit(),
      onSubmit: (data: IInstallationTypesDTO) => this.editInstallation(data),
      installationTypesDTO
    }
  }

  public closePopupEdit(): void {
    this.configPopupEdit = { ...this.configPopupEdit, visible: false };
  }

  public editInstallation(installationTypesDTO: IInstallationTypesDTO) {
    this._installationTypeFacade
      .UpdateInstallationTypeService(installationTypesDTO, true)
      .pipe(take(1))
      .subscribe((data) => {
        if (!data) return
        this.closePopupEdit()
        this.onClear()
      })
  }

  public deleteInstallation(installationTypesDTO: IInstallationTypesDTO): void {
    let { id } = installationTypesDTO;
    this._installationTypeFacade
      .DeleteInstallationTypeService({ id }, true)
      .pipe(take(1))
      .subscribe((data) => {
        if (!data) return
        this.onClear();
      })
  }

}
