import { Injectable } from '@angular/core';
import { Action, Store } from "@ngrx/store";

import {
    environment,
    HttpClient,
    map,
    Observable,
    of,
    ResolveRequest,
    keysSessionStorangeEnum
} from '@management/base/management';
//domain
import {
    PersonInfraestructureRepository,
    IPersonInfraestructureDTO,
    IPersonInfraestructurePersonInfraestructuresDTO,
    ISavePersonInfraestructureDTO,
    ISavePersonInfraestructurePersonInfraestructureDTO,
    IPersonInfraestructureDeleteDTO,
    IPersonInfraestructureDeletePersonInfraestructureDTO,
} from '@management/domain/management';
//data
import {
    IPersonInfraestructureEntity,
    PersonInfraestructureMapper,
    ISavePersonInfraestructureEntity,
    SavePersonInfraestructureMapper,
    IPersonInfraestructureDeleteEntity,
    PersonInfraestructureDeleteMapper,
} from '@management/data/management';

import {
    actionsPersonInfraestructure,
    reducerPersonInfraestructure,
    selectorsPersonInfraestructure
} from './redux';


@Injectable({
    providedIn: 'root',
})
export class PersonInfraestructureImplementationRepository extends PersonInfraestructureRepository {

    private readonly urlApiRest: string = environment.api.urlApiRest;
    private readonly personInfraestructureMapper = PersonInfraestructureMapper.getInstance();
    private readonly personInfraestructureDeleteMapper = PersonInfraestructureDeleteMapper.getInstance();
    private readonly savePersonInfraestructureMapper = SavePersonInfraestructureMapper.getInstance();

    constructor(
        private http: HttpClient,
        private resolveRequest: ResolveRequest,
        private store: Store<reducerPersonInfraestructure.PersonInfraestructureModel>) {
        super();
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    GetPersonInfraestructuresService(params: IPersonInfraestructurePersonInfraestructuresDTO, loadService: boolean): Observable<IPersonInfraestructureDTO[]> {

        if (this.areEqualRequest<IPersonInfraestructurePersonInfraestructuresDTO>({
            url: 'personInfrastructure/PersonInfrastructure',
            body: params
        })) return of([]);

        return this.http
            .post<IPersonInfraestructureEntity>(`${this.urlApiRest}personInfrastructure/PersonInfrastructure`, params)
            .pipe(map((response: any) => {
                const res = this.resolveRequest.resolve<IPersonInfraestructureEntity[]>(response);
                return res;
            }))
            .pipe(map((entity: IPersonInfraestructureEntity[]) => entity && this.personInfraestructureMapper.mapFromList(entity)))

    }

    SetPersonInfraestructureService(params: ISavePersonInfraestructurePersonInfraestructureDTO, loadService: boolean): Observable<ISavePersonInfraestructureDTO> {
        return this.http
            .post<ISavePersonInfraestructureEntity>(`${this.urlApiRest}personInfrastructure`, params)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<ISavePersonInfraestructureEntity>(response);
                return res;
            }))
            .pipe(map((entity: ISavePersonInfraestructureEntity) => entity && this.savePersonInfraestructureMapper.mapTo(entity)))
    }
    DeletePersonInfraestructureService(params: IPersonInfraestructureDeletePersonInfraestructureDTO, loadService: boolean): Observable<IPersonInfraestructureDeleteDTO> {

        const options = {
            body: {
                id: params.id
            }
        }

        return this.http
            .delete<IPersonInfraestructureDeleteEntity>(`${this.urlApiRest}personInfrastructure`, options)
            .pipe(map((response: any) => {
                let res = this.resolveRequest.resolve<IPersonInfraestructureDeleteEntity>(response);
                return res;
            }))
            .pipe(map((entity: IPersonInfraestructureDeleteEntity) => entity && this.personInfraestructureDeleteMapper.mapTo(entity)))

    }
}