
import { Mapper } from '@management/base/management';
import { IInfrastructuresDTO } from '@management/domain/management';
import { IInfrastructuresEntity } from '@management/data/management';

export class InfrastructuresMapper extends Mapper<IInfrastructuresEntity, IInfrastructuresDTO> {

    private static instance: InfrastructuresMapper;

    private constructor() { super(); }

    public static getInstance(): InfrastructuresMapper {
        if (!InfrastructuresMapper.instance)
            InfrastructuresMapper.instance = new InfrastructuresMapper();
        return InfrastructuresMapper.instance;
    }


    mapFrom(param: IInfrastructuresEntity): IInfrastructuresDTO {
        return {
            id: param.id,
            top_id: param.top_id,
            name: param.name,
            state: param.state,
            description: param.description,
            campus: param.campus,
            installation_type: param.installation_type
        };
    }
    mapFromList(params: IInfrastructuresEntity[]): IInfrastructuresDTO[] {
        return params.map((param: IInfrastructuresEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IInfrastructuresDTO): IInfrastructuresEntity {
        return {
            id: param.id,
            top_id: param.top_id,
            name: param.name,
            state: param.state,
            description: param.description,
            campus: param.campus,
            installation_type: param.installation_type
        }
    }
    mapToList(params: IInfrastructuresDTO[]): IInfrastructuresEntity[] {
        return params.map((param: IInfrastructuresDTO) => {
            return this.mapTo(param);
        })
    }
}