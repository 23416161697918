import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    PersonServiceRepository,
    ISavePersonServiceDTO,
    ISavePersonServiceSavePersonServiceDTO
} from '@management/domain/management';

export class SetSavePersonServiceServiceUseCase implements UseCase<ISavePersonServiceSavePersonServiceDTO, ISavePersonServiceDTO> {

    private static instance: SetSavePersonServiceServiceUseCase;

    constructor(private savePersonServiceRepository: PersonServiceRepository) { }

    public static getInstance(savePersonServiceRepository: PersonServiceRepository): SetSavePersonServiceServiceUseCase {
        if (!SetSavePersonServiceServiceUseCase.instance)
            SetSavePersonServiceServiceUseCase.instance = new SetSavePersonServiceServiceUseCase(savePersonServiceRepository);
        return SetSavePersonServiceServiceUseCase.instance;
    }

    execute(
        params: ISavePersonServiceSavePersonServiceDTO, loadService: boolean
    ): Observable<ISavePersonServiceDTO> {
        return this.savePersonServiceRepository.SetSavePersonServiceService(params, loadService);
    }
}