
import { Mapper } from '@management/base/management';
import { IPersonInfraestructureDeleteDTO } from '@management/domain/management';
import { IPersonInfraestructureDeleteEntity } from '@management/data/management';

export class PersonInfraestructureDeleteMapper extends Mapper<IPersonInfraestructureDeleteEntity, IPersonInfraestructureDeleteDTO> {

    private static instance: PersonInfraestructureDeleteMapper;

    private constructor() { super(); }

    public static getInstance(): PersonInfraestructureDeleteMapper {
        if (!PersonInfraestructureDeleteMapper.instance)
            PersonInfraestructureDeleteMapper.instance = new PersonInfraestructureDeleteMapper();
        return PersonInfraestructureDeleteMapper.instance;
    }


    mapFrom(param: IPersonInfraestructureDeleteEntity): IPersonInfraestructureDeleteDTO {
        return {
            id: param.id,
            person: param.person,
            infrastructure: param.infrastructure
        };
    }
    mapFromList(params: IPersonInfraestructureDeleteEntity[]): IPersonInfraestructureDeleteDTO[] {
        return params.map((param: IPersonInfraestructureDeleteEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IPersonInfraestructureDeleteDTO): IPersonInfraestructureDeleteEntity {
        return {
            id: param.id,
            person: param.person,
            infrastructure: param.infrastructure
        }
    }
    mapToList(params: IPersonInfraestructureDeleteDTO[]): IPersonInfraestructureDeleteEntity[] {
        return params.map((param: IPersonInfraestructureDeleteDTO) => {
            return this.mapTo(param);
        })
    }
}