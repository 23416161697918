
import { Mapper } from '@management/base/management';
import { IDeletePersonCollaboratorDTO } from '@management/domain/management';
import { IDeletePersonCollaboratorEntity } from '@management/data/management';

export class DeletePersonCollaboratorMapper extends Mapper<IDeletePersonCollaboratorEntity, IDeletePersonCollaboratorDTO> {

    private static instance: DeletePersonCollaboratorMapper;

    private constructor() { super(); }

    public static getInstance(): DeletePersonCollaboratorMapper {
        if (!DeletePersonCollaboratorMapper.instance)
            DeletePersonCollaboratorMapper.instance = new DeletePersonCollaboratorMapper();
        return DeletePersonCollaboratorMapper.instance;
    }


    mapFrom(param: IDeletePersonCollaboratorEntity): IDeletePersonCollaboratorDTO {
        return {
            id: param.id
        };
    }
    mapFromList(params: IDeletePersonCollaboratorEntity[]): IDeletePersonCollaboratorDTO[] {
        return params.map((param: IDeletePersonCollaboratorEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IDeletePersonCollaboratorDTO): IDeletePersonCollaboratorEntity {
        return {
            id: param.id
        }
    }
    mapToList(params: IDeletePersonCollaboratorDTO[]): IDeletePersonCollaboratorEntity[] {
        return params.map((param: IDeletePersonCollaboratorDTO) => {
            return this.mapTo(param);
        })
    }
}