
import { TranslateService } from '@ngx-translate/core';
import { LangEnum } from '../../../ui/src/lib/enum/global-enum';


export const getCountry = (translate: TranslateService, lang: LangEnum, langNavigator?: string) => {
    translate.addLangs([LangEnum.enUS, LangEnum.esCO]);
    if (langNavigator) {
        const langs = translate.getLangs();
        const cleanLangNavigator = langNavigator.toLocaleLowerCase().substring(0, 2);
        const selectLanguage = langs.find(x => x.toLowerCase().includes(cleanLangNavigator)) || lang;
        translate.setDefaultLang(selectLanguage || lang);
        return
    }

    translate.setDefaultLang(lang);
    return
};