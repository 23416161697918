import { Observable } from "rxjs";
//domain
import {
    PersonCompanyRepository,
    IPersonCompanyDTO,
    IPersonCompanyPersonCompanyDTO,
    SetPersonCompanyServiceUseCase,
} from "@management/domain/management";


export class PersonCompanyFacade extends PersonCompanyRepository {

    private static instance: PersonCompanyFacade;

    constructor(private personCompanyRepository: PersonCompanyRepository) {
        super();
    }

    public static getInstance(personCompanyRepository: PersonCompanyRepository): PersonCompanyFacade {
        if (!PersonCompanyFacade.instance)
            PersonCompanyFacade.instance = new PersonCompanyFacade(personCompanyRepository);
        return PersonCompanyFacade.instance;
    }

    SetPersonCompanyService(params: IPersonCompanyPersonCompanyDTO, loadService: boolean): Observable<IPersonCompanyDTO> {
        return SetPersonCompanyServiceUseCase.getInstance(this.personCompanyRepository).execute(params, loadService);
    }

}