import { Injectable } from '@angular/core';
import { Action, Store } from "@ngrx/store";

import {
    environment,
    HttpClient,
    map,
    Observable,
    of,
    ResolveRequest,
    keysSessionStorangeEnum
} from '@management/base/management';
//domain
import {
    RolRepository,
    IRolDTO,
    IRolRolDTO,
} from '@management/domain/management';
//data
import {
    IRolEntity,
    RolMapper,
} from '@management/data/management';

import {
    actionsRol,
    reducerRol,
    selectorsRol
} from './redux';


@Injectable({
    providedIn: 'root',
})
export class RolImplementationRepository extends RolRepository {

    private readonly urlApiRest: string = environment.api.urlApiRest;
    private readonly rolMapper = RolMapper.getInstance();

    constructor(
        private http: HttpClient,
        private resolveRequest: ResolveRequest,
        private store: Store<reducerRol.RolModel>) {
        super();
    }

    private dispatch(action: Action) {
        this.store.dispatch(action);
    }

    GetRolService(params: IRolRolDTO, loadService: boolean): Observable<IRolDTO[]> {
        let data = this.getSessionStorange(keysSessionStorangeEnum.GetRolService);
        if (!data || loadService)
            return this.http
                .post<IRolEntity[]>(`${this.urlApiRest}rol/list`, params)
                .pipe(map((response: any) => {
                    let res = this.resolveRequest.resolve<IRolEntity[]>(response);
                    this.setSessionStorange(keysSessionStorangeEnum.GetRolService, res);
                    return res;
                }))
                .pipe(map((entity: IRolEntity[]) => entity && this.rolMapper.mapFromList(entity)))
        return of(data);
    }

}