import { Observable } from "rxjs";
//domain
import {
    LanguageRepository,
    ILanguageDTO,
    ILanguageLanguageDTO,
    GetLanguageServiceUseCase,
} from "@management/domain/management";


export class LanguageFacade extends LanguageRepository {

    private static instance: LanguageFacade;

    constructor(private languageRepository: LanguageRepository) {
        super();
    }

    public static getInstance(languageRepository: LanguageRepository): LanguageFacade {
        if (!LanguageFacade.instance)
            LanguageFacade.instance = new LanguageFacade(languageRepository);
        return LanguageFacade.instance;
    }

    GetLanguageService(params: ILanguageLanguageDTO, loadService: boolean): Observable<ILanguageDTO[]> {
        return GetLanguageServiceUseCase.getInstance(this.languageRepository).execute(params, loadService);
    }

}