import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    ServiceRepository,
    ICampusServiceDTO,
    ICampusServiceCampusServiceDTO
} from '@management/domain/management';

export class GetCampusServiceServiceUseCase implements UseCase<ICampusServiceCampusServiceDTO, ICampusServiceDTO[]> {

    private static instance: GetCampusServiceServiceUseCase;

    constructor(private campusServiceRepository: ServiceRepository) { }

    public static getInstance(campusServiceRepository: ServiceRepository): GetCampusServiceServiceUseCase {
        if (!GetCampusServiceServiceUseCase.instance)
            GetCampusServiceServiceUseCase.instance = new GetCampusServiceServiceUseCase(campusServiceRepository);
        return GetCampusServiceServiceUseCase.instance;
    }

    execute(
        params: ICampusServiceCampusServiceDTO, loadService: boolean
    ): Observable<ICampusServiceDTO[]> {
        return this.campusServiceRepository.GetCampusServiceService(params, loadService);
    }
}