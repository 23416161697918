import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    ScheduleRepository,
    IScheduleSaveDTO,
    IScheduleSaveScheduleDTO
} from '@management/domain/management';

export class SetScheduleServiceUseCase implements UseCase<IScheduleSaveScheduleDTO, IScheduleSaveDTO> {

    private static instance: SetScheduleServiceUseCase;

    constructor(private scheduleSaveRepository: ScheduleRepository) { }

    public static getInstance(scheduleSaveRepository: ScheduleRepository): SetScheduleServiceUseCase {
        if (!SetScheduleServiceUseCase.instance)
            SetScheduleServiceUseCase.instance = new SetScheduleServiceUseCase(scheduleSaveRepository);
        return SetScheduleServiceUseCase.instance;
    }

    execute(
        params: IScheduleSaveScheduleDTO, loadService: boolean
    ): Observable<IScheduleSaveDTO> {
        return this.scheduleSaveRepository.SetScheduleService(params, loadService);
    }
}