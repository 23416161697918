import {
    ICampusByCompanyEntity,
    ICampusByCompanyRequestEntity
} from '@management/data/management';
import { Observable } from 'rxjs';
import {
    IAuthDTO,
    IAuthSingInDTO,
    ICampusByCompanyDTO,
    ICompanyDTO,
    ICompanySelectedDTO,
    ICompanySelectedRequestDTO,
    IPersonDTO
} from '@management/domain/management';
import { UtilsComponent } from '@management/ui';
import { selector } from '@management/base/management';

export abstract class AuthRepository extends UtilsComponent {
    abstract singIn(params: IAuthSingInDTO, loadService: boolean): Observable<IAuthDTO>;
    abstract setUserReduxUseCase(authDTO: IAuthDTO): void;
    abstract getPersonSelector(): Observable<selector<IPersonDTO>>
    abstract getListCompaniesSelector(): Observable<selector<ICompanyDTO[]>>
    abstract getCampusByCompany(params: ICampusByCompanyRequestEntity, loadService: boolean, loadSessionStorange: boolean): Observable<ICampusByCompanyEntity[]>
    abstract setCampusByCompanySS(params: ICampusByCompanyEntity[]): void
    abstract setCampusByCompanyRedux(campusByCompany: ICampusByCompanyDTO[]): void
    abstract setCompanySelectedRedux(companySelected: ICompanySelectedDTO): void
    abstract getCompanySelectedSelector(): Observable<selector<ICompanySelectedDTO>>
    abstract getListCampusByCompanySelector(): Observable<selector<ICampusByCompanyDTO[]>>
    abstract getCompanySelected(params: ICompanySelectedRequestDTO, loadService: boolean, loadSessionStorange: boolean): Observable<ICompanySelectedDTO>
    abstract setCompanySelectedSS(params: ICompanySelectedDTO): void
    abstract cleanAuthRedux(): void;
}
