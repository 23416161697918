<div class="container" *ngIf="data">

  <div class="container__control" *ngIf="previousMenu && showItemsMenu[0].nivel != 0">

    <div class="container__control__a" (click)="back()">
      <span class="material-symbols-outlined">
        arrow_back
      </span>
      <div class="container__control__a--text" *ngIf="!isCollapseMenu"> Atras</div>
    </div>

  </div>

  <div class="container__hr" *ngIf="previousMenu && showItemsMenu[0].nivel != 0"></div>

  <div class="container__items">

    <div class="container__items__item" *ngFor="let menuItem of showItemsMenu" (click)="onClickItem(menuItem)">
      <span id={{setId(menuItem)}} class="material-symbols-outlined">
        {{menuItem.icon}}
      </span>
      <div class="container__items__item__text" *ngIf="!isCollapseMenu">{{menuItem.name | translate}}</div>
      <dx-tooltip *ngIf="isCollapseMenu" target={{getTarget(menuItem)}} showEvent="mouseenter" hideEvent="mouseleave"
        [hideOnOutsideClick]="false" position="right">
        <div *dxTemplate="let data = data; of: 'content'"> {{menuItem.name | translate}}</div>
      </dx-tooltip>
    </div>

  </div>

</div>