export enum keysSessionStorangeEnum {
    user = "c567aed6-a770-49ce-b501-9de1ccbee652",
    userCompanySelected = "c567aed6-a770-49ce-b501-9de1ccbee653",
    campusByCompany = "c567aed6-a770-49ce-b501-9de1ccbee654",
    recursiveMenu = "c567aed6-a770-49ce-b501-9de1ccbee655",
    GetGetInfrastructureByCampusService = "c567aed6-a770-49ce-b501-9de1ccbee656",
    SetSaveInfrastructureService = "c567aed6-a770-49ce-b501-9de1ccbee657",
    UpdatePutEditInfrastructureService = "c567aed6-a770-49ce-b501-9de1ccbee658",
    UpdateInfrastructureService = "c567aed6-a770-49ce-b501-9de1ccbee659",
    GetCurrencyService = "c567aed6-a770-49ce-b501-9de1ccbee610",
    GetLanguageService = "c567aed6-a770-49ce-b501-9de1ccbee611",
    GetRolService = "c567aed6-a770-49ce-b501-9de1ccbee612",
    GetTypeUserByEmailService = "GetTypeUserByEmailService",
    lastRequest = 'c567aed6-a770-49ce-b501-9de1ccbee613',
    GetServiceService = 'c567aed6-a770-49ce-b501-9de1ccbee614'
}