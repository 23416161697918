
import { Mapper } from '@management/base/management';
import { ITypeUserEmailDTO } from '@management/domain/management';
import { ITypeUserEmailEntity } from '@management/data/management';

export class TypeUserEmailMapper extends Mapper<ITypeUserEmailEntity, ITypeUserEmailDTO> {

    private static instance: TypeUserEmailMapper;

    private constructor() { super(); }

    public static getInstance(): TypeUserEmailMapper {
        if (!TypeUserEmailMapper.instance)
            TypeUserEmailMapper.instance = new TypeUserEmailMapper();
        return TypeUserEmailMapper.instance;
    }


    mapFrom(param: ITypeUserEmailEntity): ITypeUserEmailDTO {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number
        };
    }
    mapFromList(params: ITypeUserEmailEntity[]): ITypeUserEmailDTO[] {
        return params.map((param: ITypeUserEmailEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: ITypeUserEmailDTO): ITypeUserEmailEntity {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number
        }
    }
    mapToList(params: ITypeUserEmailDTO[]): ITypeUserEmailEntity[] {
        return params.map((param: ITypeUserEmailDTO) => {
            return this.mapTo(param);
        })
    }
}