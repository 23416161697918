import { Mapper } from '@management/base/management';
import { IServiceCampusDTO } from '@management/domain/management';
import { IServiceCampusEntity } from '@management/data/management';

export class ServiceCampusMapper extends Mapper<IServiceCampusEntity, IServiceCampusDTO> {

    private static instance: ServiceCampusMapper;

    private constructor() { super(); }

    public static getInstance(): ServiceCampusMapper {
        if (!ServiceCampusMapper.instance)
            ServiceCampusMapper.instance = new ServiceCampusMapper();
        return ServiceCampusMapper.instance;
    }


    mapFrom(param: IServiceCampusEntity): IServiceCampusDTO {
        return {
            id: param.id,
            service: param.service,
            campus: param.campus,
            price: param.price,
            currency: param.currency
        };
    }
    mapFromList(params: IServiceCampusEntity[]): IServiceCampusDTO[] {
        return params.map((param: IServiceCampusEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IServiceCampusDTO): IServiceCampusEntity {
        return {
            id: param.id,
            service: param.service,
            campus: param.campus,
            price: param.price,
            currency: param.currency
        }
    }
    mapToList(params: IServiceCampusDTO[]): IServiceCampusEntity[] {
        return params.map((param: IServiceCampusDTO) => {
            return this.mapTo(param);
        })
    }
}