
import { Mapper } from '@management/base/management';
import { IPersonTypeUserIdentificationDTO } from '@management/domain/management';
import { IPersonTypeUserIdentificationEntity } from '@management/data/management';

export class PersonTypeUserIdentificationMapper extends Mapper<IPersonTypeUserIdentificationEntity, IPersonTypeUserIdentificationDTO> {

    private static instance: PersonTypeUserIdentificationMapper;

    private constructor() { super(); }

    public static getInstance(): PersonTypeUserIdentificationMapper {
        if (!PersonTypeUserIdentificationMapper.instance)
            PersonTypeUserIdentificationMapper.instance = new PersonTypeUserIdentificationMapper();
        return PersonTypeUserIdentificationMapper.instance;
    }


    mapFrom(param: IPersonTypeUserIdentificationEntity): IPersonTypeUserIdentificationDTO {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number
        };
    }
    mapFromList(params: IPersonTypeUserIdentificationEntity[]): IPersonTypeUserIdentificationDTO[] {
        return params.map((param: IPersonTypeUserIdentificationEntity) => {
            return this.mapFrom(param)
        })
    }
    mapTo(param: IPersonTypeUserIdentificationDTO): IPersonTypeUserIdentificationEntity {
        return {
            id: param.id,
            name: param.name,
            state: param.state,
            email: param.email,
            password: param.password,
            initialDate: param.initialDate,
            finalDate: param.finalDate,
            cellPhone: param.cellPhone,
            language: param.language,
            currency: param.currency,
            identification_number: param.identification_number
        }
    }
    mapToList(params: IPersonTypeUserIdentificationDTO[]): IPersonTypeUserIdentificationEntity[] {
        return params.map((param: IPersonTypeUserIdentificationDTO) => {
            return this.mapTo(param);
        })
    }
}