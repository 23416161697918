
import { UtilsComponent } from '@management/ui';
import { Observable } from 'rxjs';
import { IMenuDTO } from '../../dtos';
import { selector } from '@management/base/management';


export abstract class MenuRepository extends UtilsComponent {
    abstract getRecursiveMenu(loadService: boolean): Observable<IMenuDTO[]>;
    abstract getRecursiveMenuSelector(): Observable<selector<IMenuDTO[]>>;
    abstract setRecursiveMenuRedux(menu: IMenuDTO[]): void;
    abstract clearMenuRedux(): void;
}