import { Observable } from "rxjs";
//domain
import {
    PersonInfraestructureRepository,
    IPersonInfraestructureDTO,
    IPersonInfraestructurePersonInfraestructuresDTO,
    GetPersonInfraestructuresServiceUseCase,
    ISavePersonInfraestructureDTO,
    ISavePersonInfraestructurePersonInfraestructureDTO,
    SetPersonInfraestructureServiceUseCase,
    IInfraestructuresByAssignInputDTO,
    IInfrastructuresDTO,
    InfraestructuresByAssignLogicUseCase,
    IPersonInfraestructureDeleteDTO,
    IPersonInfraestructureDeletePersonInfraestructureDTO,
    DeletePersonInfraestructureServiceUseCase,
} from "@management/domain/management";


export class PersonInfraestructureFacade extends PersonInfraestructureRepository {

    private static instance: PersonInfraestructureFacade;

    constructor(private personInfraestructureRepository: PersonInfraestructureRepository) {
        super();
    }

    public static getInstance(personInfraestructureRepository: PersonInfraestructureRepository): PersonInfraestructureFacade {
        if (!PersonInfraestructureFacade.instance)
            PersonInfraestructureFacade.instance = new PersonInfraestructureFacade(personInfraestructureRepository);
        return PersonInfraestructureFacade.instance;
    }

    GetPersonInfraestructuresService(params: IPersonInfraestructurePersonInfraestructuresDTO, loadService: boolean): Observable<IPersonInfraestructureDTO[]> {
        return GetPersonInfraestructuresServiceUseCase.getInstance(this.personInfraestructureRepository).execute(params, loadService);
    }

    SetPersonInfraestructureService(params: ISavePersonInfraestructurePersonInfraestructureDTO, loadService: boolean): Observable<ISavePersonInfraestructureDTO> {
        return SetPersonInfraestructureServiceUseCase.getInstance(this.personInfraestructureRepository).execute(params, loadService);
    }

    InfraestructuresByAssignLogicUseCase(params: IInfraestructuresByAssignInputDTO): IInfrastructuresDTO[] {
        return InfraestructuresByAssignLogicUseCase.getInstance().execute(params);
    }
    DeletePersonInfraestructureService(params: IPersonInfraestructureDeletePersonInfraestructureDTO, loadService: boolean): Observable<IPersonInfraestructureDeleteDTO> {
        return DeletePersonInfraestructureServiceUseCase.getInstance(this.personInfraestructureRepository).execute(params, loadService);
    }
}