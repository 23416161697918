import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    ScheduleRepository,
    IScheduleDTO,
    IScheduleScheduleDTO
} from '@management/domain/management';

export class GetScheduleServiceUseCase implements UseCase<IScheduleScheduleDTO, IScheduleDTO[]> {

    private static instance: GetScheduleServiceUseCase;

    constructor(private scheduleRepository: ScheduleRepository) { }

    public static getInstance(scheduleRepository: ScheduleRepository): GetScheduleServiceUseCase {
        if (!GetScheduleServiceUseCase.instance)
            GetScheduleServiceUseCase.instance = new GetScheduleServiceUseCase(scheduleRepository);
        return GetScheduleServiceUseCase.instance;
    }

    execute(
        params: IScheduleScheduleDTO, loadService: boolean
    ): Observable<IScheduleDTO[]> {
        return this.scheduleRepository.GetScheduleService(params, loadService);
    }
}