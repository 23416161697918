import { Observable } from 'rxjs';
import { UseCase } from '@management/base/management';
import {
    ServiceRepository,
    ICampusServiceNameDTO,
    ICampusServiceNameCampusServiceNameDTO
} from '@management/domain/management';

export class GetCampusServiceNameServiceUseCase implements UseCase<ICampusServiceNameCampusServiceNameDTO, ICampusServiceNameDTO[]> {

    private static instance: GetCampusServiceNameServiceUseCase;

    constructor(private campusServiceNameRepository: ServiceRepository) { }

    public static getInstance(campusServiceNameRepository: ServiceRepository): GetCampusServiceNameServiceUseCase {
        if (!GetCampusServiceNameServiceUseCase.instance)
            GetCampusServiceNameServiceUseCase.instance = new GetCampusServiceNameServiceUseCase(campusServiceNameRepository);
        return GetCampusServiceNameServiceUseCase.instance;
    }

    execute(
        params: ICampusServiceNameCampusServiceNameDTO, loadService: boolean
    ): Observable<ICampusServiceNameDTO[]> {
        return this.campusServiceNameRepository.GetCampusServiceNameService(params, loadService);
    }
}