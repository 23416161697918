import { Observable } from "rxjs";
//domain
import {
    PersonServiceRepository,
    IPersonServiceDTO,
    IPersonServiceGetListPersonServiceDTO,
    GetGetListPersonServiceServiceUseCase,
    ISavePersonServiceDTO,
    ISavePersonServiceSavePersonServiceDTO,
    SetSavePersonServiceServiceUseCase,
    IDeletePersonServiceDTO,
    IDeletePersonServicePersonServiceDTO,
    DeletePersonServiceServiceUseCase,
} from "@management/domain/management";


export class PersonServiceFacade extends PersonServiceRepository {

    private static instance: PersonServiceFacade;

    constructor(private personServiceRepository: PersonServiceRepository) {
        super();
    }

    public static getInstance(personServiceRepository: PersonServiceRepository): PersonServiceFacade {
        if (!PersonServiceFacade.instance)
            PersonServiceFacade.instance = new PersonServiceFacade(personServiceRepository);
        return PersonServiceFacade.instance;
    }

    GetGetListPersonServiceService(params: IPersonServiceGetListPersonServiceDTO, loadService: boolean): Observable<IPersonServiceDTO[]> {
        return GetGetListPersonServiceServiceUseCase.getInstance(this.personServiceRepository).execute(params, loadService);
    }

    SetSavePersonServiceService(params: ISavePersonServiceSavePersonServiceDTO, loadService: boolean): Observable<ISavePersonServiceDTO> {
        return SetSavePersonServiceServiceUseCase.getInstance(this.personServiceRepository).execute(params, loadService);
    }
    DeletePersonServiceService(params: IDeletePersonServicePersonServiceDTO, loadService: boolean): Observable<IDeletePersonServiceDTO> {
        return DeletePersonServiceServiceUseCase.getInstance(this.personServiceRepository).execute(params, loadService);
    }
}