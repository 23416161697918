
import { UseCase } from '@management/base/management';
import { AuthRepository } from '@management/domain/management';


export class CleanAuthReduxUseCase implements UseCase<void, void> {
    private static instance: CleanAuthReduxUseCase;
    constructor(private authRepository: AuthRepository) { }
    public static getInstance(authRepository: AuthRepository): CleanAuthReduxUseCase {
        if (!CleanAuthReduxUseCase.instance)
            CleanAuthReduxUseCase.instance = new CleanAuthReduxUseCase(authRepository);
        return CleanAuthReduxUseCase.instance;
    }
    execute(
    ): void {
        return this.authRepository.cleanAuthRedux();
    }
}